import React from 'react';
import styles from "./searchIcon.module.css"

const SearchIcon = ({size = 24, opacity = 1}) => {

    let iconStr = "/assets/imgs/search.svg"

    return (
        <img onContextMenu={() => {
            return false;
        }} style={{height: size, width: size, opacity: opacity}}
             className={`${styles.icon} noSelect`} src={iconStr} alt={"Search Icon"}/>
    );
};

export default SearchIcon;