const calculateSentimentTag = (sentimentID) => {

    if (sentimentID === -99) {
        return "Unknown"
    }

    if (sentimentID === null || sentimentID === undefined) {
        return ""
    }

    if (sentimentID === -1) {
        return "Negative"
    }

    if (sentimentID === 0) {
        return "Mixed"
    }

    if (sentimentID === 1) {
        return "Positive"
    }

    return "Error"
}

export default calculateSentimentTag