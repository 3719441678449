import React from 'react';
import styles from './TriageReferenceTopicModal.module.css'
import {createPortal} from "react-dom";
import useEscape from "../../../helper/keypressListeners/escape";
import getTriageReferenceTopicData from "../../../helper/getTriageReferenceTopicData";

const TriageReferenceTopicModal = ({isOpen, closeModal, selectedTopic, selectedSubtopic, data}) => {

    const clearAndCloseModal = () => {
        closeModal()
    }

    useEscape(clearAndCloseModal)

    if (data === null || isOpen === false) {
        return null;
    }

    let titleString = 'Unknown'

    if (selectedSubtopic != null && selectedSubtopic !== '') {
        titleString = `${selectedSubtopic}`
    } else if (selectedTopic != null && selectedTopic !== '') {
        titleString = selectedTopic.toString()
    }

    const topicInformation = getTriageReferenceTopicData(data, selectedTopic, selectedSubtopic)
    if (topicInformation === null) {
        return null;
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: isOpen ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!isOpen} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Reference: {titleString} &quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Reference: {titleString}</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>

                <div className={styles.inside_wrapper}>
                    <div className={styles.sectionInformation}>
                        <h3 className={styles.sectionHeader} style={{marginBottom: 0}}>Section Information:</h3>
                        <div className={styles.sectionLine}>
                            <p className={styles.labelText}>
                                Section:&nbsp;
                                <span
                                    className={styles.valueText}>{topicInformation.section == null ? "Unknown" : topicInformation.section}</span>
                            </p>
                            <p className={styles.labelText}>FR Page:&nbsp;
                                <span
                                    className={styles.valueText}>{topicInformation.fr_page == null ? "Unknown" : topicInformation.fr_page}</span>
                            </p>
                            <p className={styles.labelText}>
                                PDF Page:&nbsp;
                                <span
                                    className={styles.valueText}>{topicInformation.pdf_page == null ? "Unknown" : topicInformation.pdf_page}</span>
                            </p>
                        </div>
                        <p className={styles.labelText}>
                            Topic: <span className={styles.valueText}
                                         style={{marginRight: 32}}>{topicInformation.topic == null ? "Unknown" : topicInformation.topic}</span>
                        </p>
                        {
                            topicInformation.subtopic != null && topicInformation.subtopic !== '' &&
                            <p className={styles.labelText}>
                                Subtopic: <span className={styles.valueText}
                                                style={{marginRight: 32}}>{topicInformation.subtopic == null ? "Unknown" : topicInformation.subtopic}</span>
                            </p>
                        }
                    </div>

                    <div className={styles.topicInformationWrapper}>
                        <div className={styles.summaryInformation}>
                            <h3 className={styles.sectionHeader}>Summary:</h3>
                            <p className={styles.labelText}>Background: </p>
                            {
                                (topicInformation.background == null || topicInformation.background === "") ?
                                    <p className={styles.valueText}>No Background Text Found</p> :
                                    topicInformation.background.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                            <p style={{marginTop: 12}} className={styles.labelText}>Provision: </p>
                            {
                                (topicInformation.provision == null || topicInformation.provision === "") ?
                                    <p className={styles.valueText}>No Provision Text Found</p> :
                                    topicInformation.provision.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                            {
                                (selectedSubtopic === "" || selectedSubtopic == null) && <>
                                    {
                                        // Only do this section for topics, since they're the only one with other solicitations!
                                    }
                                    <p style={{marginTop: 12}} className={styles.labelText}>Other Solicitations: </p>
                                    {
                                        (topicInformation.other_solicitations == null || topicInformation.other_solicitations === "") ?
                                            <p className={styles.valueText}>No Other Solicitations Found</p> :
                                            topicInformation.other_solicitations.split("\n").map((text, idx) => (
                                                <p key={idx} className={styles.valueText}>{text}</p>
                                            ))
                                    }
                                </>

                            }

                            <p style={{marginTop: 12}} className={styles.labelText}>Notes: </p>
                            {
                                (topicInformation.notes == null || topicInformation.notes === "") ?
                                    <p className={styles.valueText}>No Notes Found</p> :
                                    topicInformation.notes.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                        </div>
                        <div className={styles.keywordInformation}>
                            <h3 className={styles.sectionHeader}>Keywords:</h3>
                            <p className={styles.valueText}>
                                {topicInformation.keywords == null ?
                                    "No Keywords Loaded" :
                                    topicInformation.keywords.map(keyword => (
                                        <span style={{display: "block"}} key={keyword}
                                              className={styles.valueText}>• {keyword}</span>
                                    ))
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};


export default TriageReferenceTopicModal;