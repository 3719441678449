import React from 'react';
import styles from './ReviewerCard.module.css';

const getReviewerQueues = (queue) => {
    if (queue === undefined) {
        return {
            primary: [],
            qa: []
        }
    }

    return queue
}

const ReviewerCard = ({reviewers, queues}) => {

    const [selectedReviewer, setSelectedReviewer] = React.useState(0)

    React.useEffect(() => {
        if (reviewers.length > 0) {
            setSelectedReviewer(reviewers[0][0])
        } else {
            setSelectedReviewer(0)
        }
    }, [reviewers])

    return (
        <div className={styles.wrapper}>
            <select className={styles.reviewerSelect} onChange={e => setSelectedReviewer(e.currentTarget.value)}
                    name={"dockets"} id={"dockets"} value={selectedReviewer} size={10}>
                {
                    reviewers.map(reviewer => <option key={reviewer[0]} value={reviewer[0]}>{reviewer[1]}</option>)
                }
            </select>
            <div className={styles.queueBox}>
                <h2>Primary Queue ({getReviewerQueues(queues[selectedReviewer]).primary.length}):</h2>
                {
                    getReviewerQueues(queues[selectedReviewer]).primary.map((element) => (
                        <p key={element}>{element}</p>
                    ))
                }
            </div>
            <div className={styles.queueBox}>
                <h2>QA Queue ({getReviewerQueues(queues[selectedReviewer]).qa.length}):</h2>
                {
                    getReviewerQueues(queues[selectedReviewer]).qa.map((element) => (
                        <p key={element}>{element}</p>
                    ))
                }
            </div>
        </div>
    );
};

export default ReviewerCard;