import React from 'react';
import styles from "./infoIcon.module.css"

const InfoIcon = ({size = 24}) => {

    let iconStr = "/assets/imgs/information.svg"

    return (
        <img onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}}
             className={`${styles.icon} noSelect`} src={iconStr} alt={"Open Icon"}/>
    );
};

export default InfoIcon;