import React from 'react';
import styles from "./sweepIcon.module.css"

const SweepIcon = ({
                       size = 24
                   }) => {

    let iconStr = "/assets/imgs/sweep.svg"


    return (
        <img className={styles.icon} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default SweepIcon;