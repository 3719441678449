import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import getUniqueColumnValues, {
    MajorCommenterReportFilterData, orderUniqueColumnValues
} from "../../../helper/getUniqueColumnValues";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import { clearReportFilters, setReportFilters } from "../../../store/reportsSlice";
import MultiSelectClear from "./multiselectFilter/multiselectClear";
import parseReportFilters from "../../../helper/parseReportFilters";
import updateReportFilters from "../../../helper/updateReportFilters";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 75,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 600
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 75
    },
    {
        accessorKey: "FL Name",
        header: "FL Name",
        size: 200
    }
    ,
    {
        accessorKey: "Topic",
        header: "Topic",
        size: 400
    }
]
//["Document ID", "Commenter", "MC Group", "FL Name", "Topic"]

const filterData = (data, topics, docIDs, fls, filteredTopics, mcGroups) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        // Removing timing from the reports
        tempItem.splice(1, 3)

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[0]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[2]}`))) {
                continue
            }
        }

        if (fls.length > 0) {
            if (!(fls.includes(tempItem[3]))) {
                continue
            }
        }

        if (filteredTopics.length > 0) {
            if (!(filteredTopics.includes(tempItem[4]))) {
                continue
            }
        }

        if (topics !== {}) {
            try {
                tempItem[4] = topics[tempItem[4]]["name"]
            } catch (e) {

            }
        }

        tempItem[2] = MC_TITLES[tempItem[2]]
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

export const getRiderTopicValueLabelPair = (topicsDict) => {
    let temp = []

    for (let key of Object.keys(topicsDict)) {
        if (topicsDict[key]["isRider"]) {
            temp.push({ value: key, label: topicsDict[key].name ?? key })
        }
    }

    return temp
}

const downloadRiderReport = (rawReportData, docket) => {

    let reportData = []

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem.splice(1, 0, `${docket}-${tempItem[0]}`)
        tempItem.splice(2, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[0]}`)

        reportData.push(tempItem)
    }

    let tempHeadings = [...headings]

    tempHeadings.splice(1, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(2, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    tempHeadings.splice(3, 0, {
        accessorKey: "posted_date",
        header: "Posted Date"
    })

    tempHeadings.splice(4, 0, {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "completed_date",
        header: "Completed Date"
    })

    downloadReportData(tempHeadings, reportData, "rider_report")
}


const RiderReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)

    const uniqueDocumentIDs = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const uniqueFLNames = React.useMemo(() => orderUniqueColumnValues(getUniqueColumnValues(rawReportData, 6)), [rawReportData])
    const allTopics = React.useMemo(() => getRiderTopicValueLabelPair(docketTopics), [docketTopics])

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const storedFilters = useSelector((state) => state.reports.riderReport);
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "riderReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 5).then(r => {
            setRawReportData(r)
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "riderReport" }))
    }

    const filteredData = React.useMemo(() =>
        filterData(rawReportData, docketTopics,
            parseReportFilters('docID', storedFilters), parseReportFilters('flName', storedFilters),
            parseReportFilters('topic', storedFilters), parseReportFilters('mcGroups', storedFilters)),
        [rawReportData, docketTopics, storedFilters])
    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"15vw"}
                    selected={parseReportFilters('docID', storedFilters)}
                    setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs} />
                <MultiSelectFilter title={"MC Group"} width={"10vw"}
                    selected={parseReportFilters('mcGroups', storedFilters)}
                    setSelected={(values) => updateFilter('mcGroups', values)}
                    options={MajorCommenterReportFilterData} />
                <MultiSelectFilter title={"FL Name"} width={"15vw"}
                    selected={parseReportFilters('flName', storedFilters)}
                    setSelected={(values) => updateFilter('flName', values)} options={uniqueFLNames} />
                <MultiSelectFilter title={"Topic"} width={"15vw"} selected={parseReportFilters('topic', storedFilters)}
                    setSelected={(values) => updateFilter('topic', values)} options={allTopics} />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "riderReport" }))} />
            </div>
            <div className={styles.title}>
                <p>Rider Report <span style={{ marginLeft: 16 }}>({uniqueCommentCount} comments shown)</span></p>
                <button onClick={() => downloadRiderReport(rawReportData, docket)}>
                    <DownloadIcon />
                </button>
            </div>
            <div className={styles.table}>
                {loading ? <Loading /> :
                    <GenericTable data={filteredData}
                        headingsList={finalHeadings} />}
            </div>
        </div>
    );
};

export default RiderReport;