import React from 'react';
import styles from "./components.module.css"
import {getNonFLParentAttachmentsAPI} from "../../../../api/formLetterAPI";
import {useSelector} from "react-redux";
import TextDisplay from "../../../General/TextDisplay/TextDisplay";
import {getTrackingNumberAPI} from "../../../../api/commentAPI";

const SelectStandard = ({standard, setStandard}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [attachments, setAttachments] = React.useState([])
    const [error, setError] = React.useState(false)

    const updateStandard = (value, index) => {
        let temp = [...standard]
        temp[index] = value
        setStandard([...temp])
    }

    const updateDocID = (value) => {
        setStandard([value, 0, ""])
        setAttachments([])
        setError(false)
    }

    const searchAttachments = () => {
        if (standard[0] === '') {
            return;
        }

        getNonFLParentAttachmentsAPI(jwt, docket, standard[0]).then(r => {
            if (r.success) {
                if (r.data.length > 0) {
                    setError(false)
                    setAttachments(r.data)
                    const first = r.data[0]

                    getTrackingNumberAPI(jwt, docket, standard[0]).then(r => {
                            if (r.length > 0) {
                                setStandard([standard[0], first, r])
                            } else {
                                setStandard([standard[0], first, ''])
                            }

                        }
                    )
                } else {
                    setAttachments([])
                    setError(true)
                    updateStandard(-1, 1)
                }
            }
        })

    }

    const rapidSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchAttachments();
        }
    }

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.sectionHeader}>Select Standard</h3>

            <div className={styles.standardSelect}>
                <div
                    style={{display: 'flex', flexDirection: 'column', gap: 16, width: 400, flexGrow: 0, flexShrink: 0}}>
                    <p className={styles.label}>Enter Document ID: </p>
                    <input value={standard[0]} onKeyDown={rapidSubmit} className={styles.input}
                           onChange={e => updateDocID(e.currentTarget.value)} type={'text'}/>
                    <button style={{marginBottom: 24}} className={styles.button} onClick={searchAttachments}>Find
                        Eligible Attachments
                    </button>

                    {
                        (!error && attachments.length > 0) ?
                            <React.Fragment>
                                <p className={styles.label}>Choose Eligible Attachment: </p>
                                <select className={styles.input} value={standard[1]}
                                        onChange={e => updateStandard(e.currentTarget.value, 1)}>
                                    {
                                        attachments.map(attachment => {
                                            if (attachment === 0) {
                                                return (<option key={attachment} value={0}>General Comment (0)</option>)
                                            }

                                            return (<option key={attachment}
                                                            value={attachment}>Attachment {attachment}</option>)
                                        })
                                    }
                                </select>
                            </React.Fragment> :
                            (error) ?
                                <p className={styles.error}>Either the document ID does not exist
                                    or all attachments are parents of other Form Letters</p>
                                : null
                    }

                </div>

                <div className={styles.textDisplayWrapper}>
                    {
                        (!error && attachments.length > 0) ?
                            <TextDisplay documentID={standard[0]} attachment={standard[1]} trackingNum={standard[2]}/> :
                            <p>Waiting for standard to be selected...</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectStandard;