import React from "react";
import WorkflowAdministration from "../components/WorkflowAdministration/WorkflowAdministration";
import CommentExplorer from "../components/CommentExplorer/CommentExplorer";
import Reports from "../components/Reports/Reports";
import Loading from "../components/General/loading/loading";
import Reviews from "../components/Reviews/Reviews";
import UserManagement from "../components/UserManagement/UserManagement";
import UserEmulation from "../components/UserEmulation/UserEmulation";
import UserGuide from "../components/UserGuide/UserGuide";
import Administration from "../components/Administration/Administration";
import TriageReference from "../components/TriageReference/TriageReference";
import BatchProcessing from "../components/BatchProcessing/BatchProcessing";

const getPageFromTabTitle = (title) => {

    if (title === undefined) {
        return <Loading/>
    }

    if (title === "Batch Processing") {
        return <BatchProcessing/>
    }

    if (title === "Comment Explorer") {
        return <CommentExplorer/>
    }

    if (title === "Reports") {
        return <Reports/>
    }

    if (title === "Review") {
        return <Reviews/>
    }

    if (title === "Administration") {
        return <Administration/>
    }

    if (title === "Triage Reference") {
        return <TriageReference/>
    }

    if (title === "User Guide") {
        return <UserGuide/>
    }

    //Deprecated: Replaced by Administration Tab
    if (title === "Workflow Administration") {
        return <WorkflowAdministration/>
    }

    //Deprecated: Replaced by Administration Tab
    if (title === "User Management") {
        return <UserManagement/>
    }

    //Deprecated: Replaced by Administration Tab
    if (title === "User Emulation") {
        return <UserEmulation/>
    }

    return "No Page Found"
}


export default getPageFromTabTitle;