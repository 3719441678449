import React from 'react';
import styles from './login.module.css'
import {useDispatch} from "react-redux";
import {unsetAll} from "../../store/userSlice";
import {LOGIN_STEP} from "../../helper/constants";
import {setLoginStatus} from "../../store/loginSlice";

const LoginWrapper = ({children}) => {

    const dispatch = useDispatch();

    const restart = () => {
        dispatch(unsetAll());
        dispatch(setLoginStatus(LOGIN_STEP.NEW_LOGIN));
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.gradient}/>
            <img onClick={restart} onContextMenu={() => {
                return false;
            }} className={styles.logo} src={"/assets/imgs/logo.jpg"} alt={"InsightsAI Logo"}/>
            {children}
        </div>
    );
};

export default LoginWrapper;