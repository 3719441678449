import {setDocToView} from "../store/navSlice";

const addOnClickToReportDocIDs = (headings, dispatch, docket) => {

    for (let dictItem of headings) {
        if (dictItem.accessorKey === "docid") {

            dictItem.muiTableBodyCellProps = ({cell}) => ({
                onClick: () => {
                    console.log(cell.getValue(), cell.id);
                    dispatch(setDocToView(`${docket}-${cell.getValue()}`))
                },
                sx: {
                    cursor: "pointer"
                }
            })

        }
    }

    return headings
}

export default addOnClickToReportDocIDs