import {useProductionURL} from "../helper/constants";

export const getTopLevelSummaryCountAPI = async (jwt, docketID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/ai/get_top_level_summary_count?` + new URLSearchParams({
        docket: docketID,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}
export const generateTopLevelSummariesAPI = async (jwt, docket, topicPairs) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/ai/generate_top_level_summary?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'topics': topicPairs,
        })
    })

    if (!resp.ok) {
        const error = await resp.text()

        throw new Error(error)
    }

    return await resp.json()
}

export const generateTopicCommentSummaryAPI = async (jwt, docket, topic, subtopic, tracking) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/ai/generate_topic_summary?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'tracking': tracking,
            'topic': topic,
            'subtopic': subtopic,
        })
    })

    if (!resp.ok) {
        const error = await resp.text()

        throw new Error(error)
    }

    return await resp.json()
}