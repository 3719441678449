import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import {Step, StepLabel, Stepper} from "@mui/material";
import SelectStandard from "./components/selectStandard";
import useEscape from "../../../helper/keypressListeners/escape";
import AddNewFormLetterChild from "./components/addNewFormLetterChild";
import ChangeFLName from "./components/changeFLName";
import ReviewNewFLBucket from "./components/reviewNewFLBucket";
import {createNewBucketAPI} from "../../../api/formLetterAPI";

const steps = ['Select Standard', 'Add Items to Batch', 'Apply Name to Batch', 'Review'];

const CreateModal = ({open, closeModal}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);

    const [activeStep, setActiveStep] = React.useState(0);

    const [standard, setStandard] = React.useState(['', 0, ''])
    const [items, setItems] = React.useState([])
    const [name, setName] = React.useState("Manual Form Letter Batch")

    const [error, setError] = React.useState("")

    const submitNewFLBucket = () => {
        createNewBucketAPI(jwt, docket, name, standard, items).then(r => {
            if (r.success) {
                clearAndCloseModal()
            } else {
                setError(r.msg)
            }
        })
    }

    const clearAndCloseModal = () => {
        setActiveStep(0)
        setStandard(['', 0, ''])
        setItems([])
        setName("Manual Form Letter Batch")

        closeModal()
    }

    const getActiveCard = () => {
        if (activeStep === 0) {
            return <SelectStandard standard={standard} setStandard={setStandard}/>
        }

        if (activeStep === 1) {
            return <AddNewFormLetterChild standard={standard} items={items} setItems={setItems}/>
        }

        if (activeStep === 2) {
            return <ChangeFLName name={name} setName={setName}/>
        }

        if (activeStep === 3) {
            return <ReviewNewFLBucket standard={standard} items={items} name={name} submit={submitNewFLBucket}
                                      error={error}/>
        }

        return null;
    }

    const nextStep = () => {

        let nextStep = activeStep + 1

        if (nextStep > steps.length - 1) {
            setActiveStep(steps.length - 1)
            return
        }

        setActiveStep(nextStep)
        setError("")
    }

    const pastStep = () => {
        let backStep = activeStep - 1

        if (backStep < 0) {
            setActiveStep(0)
            setStandard(["", 0, ""])
            return
        }

        if (backStep === 0) {
            if (!(window.confirm("Are you sure you would like to restart the process of creating a new form letter?"))) {
                return;
            }
            setStandard(["", 0, ""])
            setItems([])
        }

        setActiveStep(backStep)
        setError("")
    }

    const getNextDisabled = () => {
        if (activeStep >= steps.length - 1) {
            return true
        }

        if (activeStep === 0) {
            if (standard[2] === "") {
                return true
            }
        }

        if (activeStep === 1) {
            if (items.length < 4) {
                return true
            }
        }

        return false
    }

    useEscape(clearAndCloseModal)

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={() => {
        }}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Create Form Letter&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Create Form Letter</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.stepContentWrapper}>
                    <div className={styles.stepWrapper}>
                        <Stepper className={styles.stepper} activeStep={activeStep} sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: '#008000', // circle color (COMPLETED)
                            },
                        }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className={styles.contentWrapper}>
                        {getActiveCard()}
                    </div>
                    <div className={styles.buttonWrapper}>
                        <button disabled={activeStep === 0} onClick={pastStep}>
                            Previous
                        </button>
                        <button disabled={getNextDisabled()} onClick={nextStep}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default CreateModal;