const parseReportFilters = (filterName, filters) => {

    if (filters === undefined) {
        return []
    }

    if (filters.filters === undefined) {
        return []
    }

    if (filters.filters[filterName] === undefined) {
        return []
    }

    return filters.filters[filterName]
}

export const parseReportFiltersString = (filterName, filters) => {

    if (filters === undefined) {
        return ""
    }

    if (filters.filters === undefined) {
        return ""
    }

    if (filters.filters[filterName] === undefined) {
        return ""
    }

    return filters.filters[filterName]
}

export const parseReportFiltersDict = (filterName, filters) => {

    if (filters === undefined) {
        return {}
    }

    if (filters.filters === undefined) {
        return {}
    }

    if (filters.filters[filterName] === undefined) {
        return {}
    }

    return filters.filters[filterName]
}

export default parseReportFilters;