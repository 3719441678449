import React from 'react';
import styles from "./components.module.css"

const ReviewNewFLBucket = ({standard, items, name, submit, error}) => {

    const getChildrenNames = () => {
        let childrenList = ""

        for (const item of items) {
            childrenList += item[0] + " " + item[1] + ", "
        }

        return childrenList.substring(0, childrenList.length - 2)
    }

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.sectionHeader}>Review New FL Batch</h3>

            <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%', marginBottom: 32}}>
                <p className={styles.label}>New Batch Name: </p>
                <p className={styles.labelLight}>{name}</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%', marginBottom: 32}}>
                <p className={styles.label}>Standard: </p>
                <p className={styles.labelLight}>{standard[0]} {standard[1]}</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%', marginBottom: 32}}>
                <p className={styles.label}>Form Letter Children (minimum 4): </p>
                <p className={styles.labelLight}>{getChildrenNames()}</p>
            </div>

            {
                error !== "" &&
                <p style={{marginBottom: 8}} className={styles.error}>{error}</p>
            }

            <button onClick={submit} className={styles.submitButton}>
                Create Form Letter Batch
            </button>
        </div>
    );
};

export default ReviewNewFLBucket;