import React from 'react';
import styles from "./settingswidget.module.css"
import {useDispatch, useSelector} from "react-redux";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../General/icons/chevronIcon";
import {setDocket} from "../../store/navSlice";
import {LOGIN_STEP} from "../../helper/constants";
import UserSettingsModal from "./modals/userSettingsModal";
import {modalClose, modalOpen} from "../../store/modalSlice";
import useOutsideClick from "../../helper/useOutsideClick";
import signOutFunc from "../../helper/signOutFunc";
import {setLoginStatus} from "../../store/loginSlice";
import ChangeRequestModal from "./modals/changeRequestModal";
import {getChangeRequestUserAPI} from "../../api/changeRequestAPI";

const SettingsWidget = () => {
    const dispatch = useDispatch()


    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const displayName = useSelector((state) => state.user.displayName)
    const docketLockFlag = useSelector((state) => state.user.lockToCurrentDocketFlag)
    const isReviewer = useSelector((state) => state.user.isReviewer)
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const [settingLetter, setSettingLetter] = React.useState("?")

    const [expanded, setExpanded] = React.useState(false);
    const [isSettingModalOpen, setIsSettingModalOpen] = React.useState(false);
    const [isChangeRequestModalOpen, setIsChangeRequestModalOpen] = React.useState(false);

    const [changeRequestData, setChangeRequestData] = React.useState([])
    const alerts = changeRequestData.filter(element => (element.viewed === false && element.status === 'Closed')).length

    const closeModals = () => {
        dispatch(modalClose())
        setIsSettingModalOpen(false)
        setIsChangeRequestModalOpen(false)
    }

    const openSettingsModal = () => {
        dispatch(modalOpen())

        setIsSettingModalOpen(true)
        setIsChangeRequestModalOpen(false)
    }

    const openChangeRequestModal = () => {
        dispatch(modalOpen())

        setIsSettingModalOpen(false)
        setIsChangeRequestModalOpen(true)
    }

    const changeDocket = () => {
        dispatch(setDocket(""))
        dispatch(setLoginStatus(LOGIN_STEP.CHOOSE_DOCKET))
    }

    const signOut = () => {
        signOutFunc(dispatch)
    }


    React.useEffect(() => {
        if (displayName.length > 0) {
            setSettingLetter(displayName[0])
        } else {
            setSettingLetter("?")
        }
    }, [displayName])

    const sortFunc = (a, b) => {
        if (a.status < b.status) {
            return 1
        } else if (a.status > b.status) {
            return -1
        }

        if (a.documentID < b.documentID) {
            return -1
        } else if (a.documentID > b.documentID) {
            return 1
        }

        return 0
    }

    React.useEffect(() => {
        getChangeRequestUserAPI(jwt, docket).then(r => {
            if (r.success) {
                setChangeRequestData(r.data.sort(sortFunc))
            }
        })

        const timeoutID = window.setTimeout(() => {
            getChangeRequestUserAPI(jwt, docket).then(r => {
                if (r.success) {
                    setChangeRequestData(r.data.sort(sortFunc))
                }
            })
        }, 60 * 15 * 1000) //1000ms * 60s * 15m
        return () => {
            clearTimeout(timeoutID)
        }
    }, [jwt, docket, isChangeRequestModalOpen])

    const wrapperRef = React.useRef(null);
    useOutsideClick(wrapperRef, () => setExpanded(false));

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <button aria-label={"User Settings"} tabIndex="0" onClick={() => setExpanded(!expanded)}
                        className={styles.circle}>{settingLetter}</button>

                {
                    alerts > 0 &&
                    <div className={styles.alertCircle}>
                        <p>{alerts}</p>
                    </div>
                }

                <div onClick={() => setExpanded(!expanded)}>
                    <ChevronIcon blackColor={false}
                                 direction={expanded ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN}/>
                </div>
            </div>
            {
                expanded &&
                <div ref={wrapperRef} className={styles.optionsMenu}>
                    <button onClick={openSettingsModal} className={styles.optionItem}>Account Settings</button>
                    {
                        ((isReviewer && isAdmin) || (!isReviewer)) &&
                        <button onClick={openChangeRequestModal} className={styles.optionItem}>
                            {
                                alerts > 0 &&
                                <div className={styles.alertCircle}><p>{alerts}</p></div>
                            }
                            My Change Requests
                        </button>
                    }
                    {docketLockFlag ? null :
                        <button onClick={changeDocket} className={styles.optionItem}>Change Docket</button>}
                    <button onClick={signOut} className={styles.optionItem}>Sign Out</button>
                </div>
            }
            <UserSettingsModal open={isSettingModalOpen} closeModal={closeModals}/>
            <ChangeRequestModal changeRequests={changeRequestData} open={isChangeRequestModalOpen}
                                closeModal={closeModals}/>
        </React.Fragment>
    );
};

export default SettingsWidget;