import {useProductionURL} from "../helper/constants";

export const getTriageTopicListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/references/topics?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}


export const getTriageMCListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/references/major_commenters?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getTriageFLListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/references/form_letters?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}