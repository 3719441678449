import React from 'react';
import styles from './CommentsTable.module.css';
import {MAX_WF_ADMIN_ROWS, MC_TITLES, POSTED_OFFSET_USE_LOADED} from "../../helper/constants";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../General/icons/chevronIcon";
import {useDispatch} from "react-redux";
import {setDocToView} from "../../store/navSlice";
import sortDocIDsTo5Digits from "../../helper/sortDocIDsTo5Digits";
import downloadReportData from "../../helper/downloadReportData";
import DownloadIcon from "../General/icons/downloadIcon";
import CommentTimestampModal from "./modals/commentTimestampModal";
import calculateBusinessHourDifference from "../../helper/calculateBusinessHourDifference";
import {downloadHeaders, timingHeaders} from "./CommentTableHeaderList";

const headers = ["Document ID", "Commenter", "MC Status", "Queue Status", "Form Letter", "Primary Reviewer", "QA Reviewer", "Elapsed Time"]
const queueStatuses = ["In Queue [PR]", "In Progress [PR]", "Paused [PR]", "In Queue [QA]", "In Progress [QA]", "Paused [QA]", "Completed"]

const getReviewerFromList = (list, id) => {
    for (let user of list) {
        if (user[0] === id) {
            return user[1]
        }
    }

    if (id != null && id > 1) {
        return 'Unknown'
    }

    return ''
}

const calcStart = (data) => {
    // Invalid posted date
    if ('loaded' in data && 'posted' in data && data['loaded'] < data['posted']) {
        return data['loaded']
    }


    // Posted date is from previous years (loading test data), use loaded date from pipeline
    if ('loaded' in data && 'posted' in data && data['posted'] + POSTED_OFFSET_USE_LOADED < data['loaded']) {
        return data['loaded']
    }

    if ('posted' in data) {
        return data['posted']
    }

    if ('loaded' in data) {
        return data['loaded']
    }
}

const calcEnd = (data) => {
    if ('triageComplete' in data && data['triageComplete'] != null) {
        return data['triageComplete']
    }

    return Math.floor(Date.now() / 1000)
}

const TableRow = ({comment, reviewers, primaryReviewers, qaReviewers, changeReviewer, timingStats, openModal}) => {

    const dispatch = useDispatch()

    let docID = comment[1]
    let finalID = comment[2] ?? "";

    let commenter = "";

    if (comment[3] === "" || comment[3] === null) {
        commenter = "Unknown";
    } else {
        commenter = comment[3];
    }
    let mcStatus = MC_TITLES[comment[4] ?? 0] ?? "Unknown"
    let queueStatus = comment[5];
    let inFL = comment[6] === null ? 'No' : comment[6];

    if (comment[6]) {
        queueStatus = "In Form Letter"
    }

    let pr = "";
    let qa = "";

    if (comment[6] === false) {
        for (let reviewer of reviewers) {
            if (!(comment[7] == null)) {
                if (comment[7] === reviewer[0]) {
                    // eslint-disable-next-line no-unused-vars
                    pr = reviewer[1]
                }
            }

            if (!(comment[8] == null)) {
                if (comment[8] === reviewer[0]) {
                    // eslint-disable-next-line no-unused-vars
                    qa = reviewer[1]
                }
            }

        }
    }

    const openModalWithTimings = () => {
        if (!(comment[0] in timingStats)) {
            return "No Stats Available"
        }

        openModal(timingStats[comment[0]])
    }

    const getBusinessHours = (timings) => {
        return calculateBusinessHourDifference(calcStart(timings), calcEnd(timings))
    }

    const calculateTimingValueToShow = () => {
        if (!(comment[0] in timingStats)) {
            return {
                elapsedString: "No Stats Found",
                complete: true,
                overage: false
            }
        }

        if (comment[6]) {
            return {
                elapsedString: "View Statistics",
                complete: true,
                overage: false
            }
        }

        const timing = timingStats[comment[0]]

        const elapsed = getBusinessHours(timing)
        const done = ('triageComplete' in timing && timing['triageComplete'] != null)
        return {
            elapsedString: `${(elapsed - (elapsed % 8)) / 8} days, ${elapsed % 8} hours`,
            complete: done,
            overage: !done && elapsed > 24
        }
    }

    const {elapsedString, complete, overage} = calculateTimingValueToShow()

    return (
        <tr key={`${docID ?? ""}_${finalID ?? ""}`}>
            <td style={{cursor: "pointer"}} onClick={() => dispatch(setDocToView(docID ?? ""))}>{docID}</td>
            <td title={commenter} className={styles.alignLeft}>{(commenter ?? "").slice(0, 25)}</td>
            <td>{mcStatus}</td>
            <td className={styles.alignLeft}>{queueStatus}</td>
            <td>{inFL}</td>
            <td className={comment[6] ? styles.disabledCell : ""}>
                {
                    comment[6]
                        ? getReviewerFromList(primaryReviewers, comment[7]) :
                        <select className={styles.reviewerSelect} value={`${comment[7] ?? ""}`}
                                onChange={(event) => changeReviewer(comment[0], true, event.currentTarget.value)}>
                            <option value={""}></option>
                            {
                                primaryReviewers.map((element) => (
                                    <option key={element[0]} value={`${element[0]}`}>{element[1]}</option>
                                ))
                            }
                        </select>
                }
            </td>
            <td className={comment[6] ? styles.disabledCell : ""}>
                {
                    comment[6]
                        ? getReviewerFromList(qaReviewers, comment[8]) :
                        <select className={styles.reviewerSelect} value={`${comment[8] ?? ""}`}
                                onChange={(event) => changeReviewer(comment[0], false, event.currentTarget.value)}>
                            <option value={""}></option>
                            {
                                qaReviewers.map((element) => (
                                    <option key={element[0]} value={`${element[0]}`}>{element[1]}</option>
                                ))
                            }
                        </select>
                }
            </td>
            <td onClick={openModalWithTimings}
                style={{
                    textDecoration: "underline", fontSize: 16, cursor: "pointer",
                    color: overage ? "darkred" : "", fontWeight: overage ? 700 : 400
                }}
                className={comment[6] ? styles.disabledCell : ""}>
                {elapsedString}
            </td>
        </tr>
    )
}

const generatedSortedList = (allComments, timings, filters, sortColumn, sortDirection) => {

    //Filter comments based on filters provided
    let commentList = []
    for (let comment of allComments) {
        //Document ID
        if (filters[0] !== "") {
            if (!(comment[1].includes(filters[0]))) {
                continue
            }
        }

        //MC Status
        if (filters[3] !== "") {
            const lookupMCStatus = parseInt(filters[3])
            if (comment[4] !== lookupMCStatus) {
                continue
            }
        }

        //Queue Status
        if (filters[4] !== "") {
            if (comment[5] !== filters[4] || comment[6] === true) {
                continue
            }
        }

        //FL Status
        if (filters[5] !== "") {
            if (comment[6] === null && filters[5] === 'Yes') {
                continue
            }
            if (comment[6] !== null && filters[5] === 'No') {
                continue
            }
        }

        //Primary Reviewer
        if (filters[6] !== "") {
            let primaryReviewerID = parseInt(filters[6])
            if (comment[7] !== primaryReviewerID || comment[6] === true) {
                continue
            }
        }

        //QA Reviewer
        if (filters[7] !== "") {
            let qaReviewerID = parseInt(filters[7])
            if (comment[8] !== qaReviewerID || comment[6] === true) {
                continue
            }
        }

        commentList.push(comment)
    }

    //Sort comments based on column
    let compareFunc = () => {
    }

    //Sort alphabetically on commenter
    if ([1].indexOf(sortColumn) !== -1) {
        let dataCol = 0;

        //Align table column with comment data
        if (sortColumn === 0) {
            dataCol = 1;
        }
        if (sortColumn === 1) {
            dataCol = 2;
        }
        if (sortColumn === 2) {
            dataCol = 3;
        }

        compareFunc = (a, b) => {

            let valueA = a[dataCol] ?? "zzz";
            let valueB = b[dataCol] ?? "zzz";

            if (a[dataCol] === "") {
                valueA = "zzz"
            }

            if (b[dataCol] === "") {
                valueB = "zzz"
            }

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }
    //Sort by MC status
    else if (sortColumn === 2) {
        compareFunc = (a, b) => {

            let valueA = a[4];
            let valueB = b[4];

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }
    //Sort by queue status
    else if (sortColumn === 3) {
        compareFunc = (a, b) => {

            let valueA = queueStatuses.indexOf(a[5]);
            let valueB = queueStatuses.indexOf(b[5]);

            if (valueA === -1 || a[6] === true) {
                valueA = 100
            }
            if (valueB === -1 || b[6] === true) {
                valueB = 100
            }

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }
    //Sort by form letter status
    else if (sortColumn === 4) {
        compareFunc = (a, b) => {

            let valueA = a[6] ? "1" : "0";
            let valueB = b[6] ? "1" : "0";

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }
    //Sort alphabetically on document ID, final ID, commenter
    else if ([5, 6].indexOf(sortColumn) !== -1) {
        let dataCol;

        //Align table column with comment data
        if (sortColumn === 5) {
            dataCol = 7;
        }
        if (sortColumn === 6) {
            dataCol = 8;
        }

        compareFunc = (a, b) => {

            if (a[dataCol] === null) {
                return 1;
            }

            if (b[dataCol] === null) {
                return -1;
            }

            let valueA = a[dataCol];
            let valueB = b[dataCol];

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }
    // Sort by elapsed time
    else if (sortColumn === 7) {
        compareFunc = (a, b) => {

            const aTracking = a[0]
            const bTracking = b[0]

            if (timings[aTracking] === null) {
                return 1;
            }

            if (timings[bTracking] === null) {
                return -1;
            }

            if (a[6]) {
                return 1
            }

            if (b[6]) {
                return -1
            }

            let valueA = calculateBusinessHourDifference(calcStart(timings[aTracking]), calcEnd(timings[aTracking]))
            let valueB = calculateBusinessHourDifference(calcStart(timings[bTracking]), calcEnd(timings[bTracking]))

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }

    if ([0, 1].indexOf(sortColumn) !== -1) {
        //Offset for tracking number being in position 0
        commentList = sortDocIDsTo5Digits(commentList, sortColumn + 1)
    } else {
        commentList = commentList.sort(compareFunc)
    }

    if (sortDirection === false) {
        commentList = commentList.reverse()
    }

    return commentList
}

const generateDownloadList = (commentsList, allReviewers) => {
    let reportList = []

    for (let commentItem of commentsList) {
        let item = [...commentItem]

        if (item[6] !== undefined && item[6] !== false && item[6] !== '' && item[6] !== 'None' && item[6] !== null) {
            item[5] = 'In Form Letter'
        }

        item[4] = MC_TITLES[item[4]]

        if (item[7] !== null) {
            item[7] = getReviewerFromList(allReviewers, item[7])
        }

        if (item[8] !== null) {
            item[8] = getReviewerFromList(allReviewers, item[8])
        }

        let shortID = item[1].toString().split("-")
        shortID = shortID[shortID.length - 1]
        item.splice(1, 0, shortID)

        reportList.push(item)
    }

    return reportList
}

const generateReportHeaders = () => {
    let downloadHeader = [...downloadHeaders]

    downloadHeader.splice(1, 0, {
        accessorKey: "docid",
        header: "Doc ID"
    })

    return downloadHeader
}

const generateTimestamp = (timestampValue) => {
    if (typeof timestampValue === 'number') {
        return `${new Date(Math.round(timestampValue, 0) * 1000).toLocaleString()}`
    } else {
        return timestampValue
    }
}

const generateTimestampReport = (timingList) => {
    let temp = []

    for (let key of Object.keys(timingList)) {
        const itemDict = timingList[key]
        temp.push(
            [
                key, generateTimestamp(itemDict['documentID']), generateTimestamp(itemDict['posted']),
                generateTimestamp(itemDict['loaded']), generateTimestamp(itemDict['prEnd']),
                generateTimestamp(itemDict['qaEnd']), generateTimestamp(itemDict['flComplete']),
                generateTimestamp(itemDict['triageComplete'])
            ])
    }

    temp = sortDocIDsTo5Digits(temp, 1)

    return temp
}

const generateTimestampHeaders = () => {
    let downloadHeader = [...timingHeaders]

    return downloadHeader
}

const CommentsTable = ({commentsList, primaryReviewers, qaReviewers, allReviewers, changeReviewer, timingStats}) => {

    const [sortDesc, setSortDesc] = React.useState(true)
    const [sortCol, setSortCol] = React.useState(0)
    const [filters, setFilters] = React.useState(["", "", "", "", "", "No", "", ""])

    const [modalData, setModalData] = React.useState({})

    const [offset, setOffset] = React.useState(0)

    const getViewableComments = () => {
        return generatedSortedList(commentsList, timingStats, filters, sortCol, sortDesc)
    }

    const viewableComments = React.useMemo(() => getViewableComments(), [commentsList, filters, sortCol, sortDesc])

    const onClickTableHeader = (column) => {
        setOffset(0)
        if (sortCol === column) {
            setSortDesc(!sortDesc)
        } else {
            setSortDesc(true)
            setSortCol(column)
        }
    }

    const updateFilters = (filter, text) => {
        setOffset(0)
        let temp = [...filters]
        temp[filter] = text
        setFilters(temp)
    }

    const prevPage = () => {
        if (offset > 0) {
            setOffset(offset - 1)
        }
    }

    const nextPage = () => {
        if ((offset + 1) * MAX_WF_ADMIN_ROWS < viewableComments.length) {
            setOffset(offset + 1)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <div className={styles.filterSection}>
                    <input value={filters[0]} onChange={(event) => updateFilters(0, event.currentTarget.value)}
                           className={styles.inputBox} type={"text"}/>
                </div>
                <div className={styles.filterSection}>
                </div>
                <div className={styles.filterSection}>
                    <select value={filters[3]} onChange={(event) => updateFilters(3, event.currentTarget.value)}
                            className={styles.inputBox}>
                        <option value={""}>All</option>
                        <option value={"1"}>-- Priority MC</option>
                        <option value={"2"}>-- Other MC</option>
                        <option value={"3"}>-- Non-MC</option>
                    </select>
                </div>
                <div className={styles.filterSection}>
                    <select value={filters[4]} onChange={(event) => updateFilters(4, event.currentTarget.value)}
                            className={styles.inputBox}>
                        <option value={""}>All</option>
                        {
                            queueStatuses.map((element) => (
                                <option key={element} value={element}>-- {element}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={styles.filterSection}>
                    <select value={filters[5]} onChange={(event) => updateFilters(5, event.currentTarget.value)}
                            className={styles.inputBox}>
                        <option value={""}>All</option>
                        <option value={"Yes"}>-- In Form Letter</option>
                        <option value={"No"}>-- Unique</option>
                    </select>
                </div>
                <div className={styles.filterSection}>
                    <select value={filters[6]} onChange={(event) => updateFilters(6, event.currentTarget.value)}
                            className={styles.inputBox}>
                        <option value={""}>All</option>
                        {
                            primaryReviewers.map((element) => (
                                <option key={`${element[0]}`} value={`${element[0]}`}>-- {element[1]}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={styles.filterSection}>
                    <select value={filters[7]} onChange={(event) => updateFilters(7, event.currentTarget.value)}
                            className={styles.inputBox}>
                        <option value={""}>All</option>
                        {
                            qaReviewers.map((element) => (
                                <option key={`${element[0]}`} value={`${element[0]}`}>-- {element[1]}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={styles.filterSection}>
                </div>
            </div>
            <div className={styles.tables}>
                <table>
                    <thead>
                    <tr>
                        {
                            headers.map((text, index) => (
                                <th key={index} onClick={() => onClickTableHeader(index)}>
                                    <span style={{marginRight: 8}}>{text}</span>
                                    {
                                        sortCol === index &&
                                        <ChevronIcon size={20}
                                                     direction={sortDesc ? CHEVRON_DIRECTIONS.DOWN : CHEVRON_DIRECTIONS.UP}/>
                                    }
                                </th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        viewableComments.map((element, index) => {

                            if (index < MAX_WF_ADMIN_ROWS * offset || index > (offset + 1) * MAX_WF_ADMIN_ROWS) {
                                return null
                            }

                            return (
                                <TableRow key={element[1]} comment={element}
                                          reviewers={allReviewers} timingStats={timingStats}
                                          primaryReviewers={primaryReviewers}
                                          qaReviewers={qaReviewers} openModal={(val) => setModalData(val)}
                                          changeReviewer={changeReviewer}/>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className={styles.filteredCommentsCount}>
                <p className={styles.filteredCommentsNotice}>{viewableComments.length > MAX_WF_ADMIN_ROWS ? `Showing comments ${offset * MAX_WF_ADMIN_ROWS + 1} - ${((offset + 1) * MAX_WF_ADMIN_ROWS + 1) < viewableComments.length ? ((offset + 1) * MAX_WF_ADMIN_ROWS + 1) : viewableComments.length} || ` : ""} {viewableComments.length} comments
                    in selection
                </p>
                <button onClick={prevPage}>
                    <ChevronIcon direction={CHEVRON_DIRECTIONS.LEFT} bold={true} size={16}/>
                </button>
                <button onClick={nextPage}>
                    <ChevronIcon bold={true} size={16}/>
                </button>
            </div>
            <div className={styles.controlButtonDiv}>
                <button disabled={commentsList === undefined || commentsList.length < 1}
                        className={styles.downloadButton}
                        onClick={() => downloadReportData(generateReportHeaders(), generateDownloadList(commentsList, allReviewers), "workflow_administration")}>
                    Download Workflow Administration Report <DownloadIcon size={20}/>
                </button>
                <button disabled={commentsList === undefined || commentsList.length < 1}
                        className={styles.downloadButton}
                        onClick={() => downloadReportData(generateTimestampHeaders(), generateTimestampReport(timingStats), "workflow_timestamps")}>
                    Download Timestamp Report <DownloadIcon size={20}/>
                </button>
            </div>
            <CommentTimestampModal open={(Object.keys(modalData).length > 0)}
                                   closeModal={() => setModalData({})} data={modalData}/>
        </div>
    );
};

export default CommentsTable;