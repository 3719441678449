import React from 'react';
import styles from "./ReviewFormLetterData.module.css"
import TopicCard from "../../../../../../General/topicCard/topicCard";

const ReviewFormLetterData = ({
                                  selectedTopics, updateSelectedTopics,
                                  name, setName, saveName,
                                  removeMCIdentifications,
                                  textFields, setTextField,
                                  status, updateStatus
                              }) => {

    const [selectedTab, setSelectedTab] = React.useState(0);

    const [selectedTextField, setSelectedTextField] = React.useState('');

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                <Tab isSelected={selectedTab === 0} text={'General'} onClick={() => setSelectedTab(0)}/>
                <Tab isSelected={selectedTab === 1} text={'Topics'} onClick={() => setSelectedTab(1)}/>
                <Tab isSelected={selectedTab === 2} text={'Text Fields'} onClick={() => setSelectedTab(2)}/>
                <Tab isSelected={selectedTab === 3} text={'Status'} onClick={() => setSelectedTab(3)}/>
            </div>
            <div className={styles.content} style={{display: selectedTab === 0 ? 'flex' : 'none'}}>
                <label className={styles.metadataLabel} id="nameLabel" htmlFor="flName">Form Letter Name:</label>
                <input onChange={e => setName(e.currentTarget.value)} name={"flName"}
                       id={"flName"} className={styles.metadataField} type={"text"} value={name}/>
                <button className={styles.metadataButton} onClick={saveName} type={"button"}>Change Name</button>

                <div className={styles.divider}/>
                <button className={styles.metadataButton} onClick={removeMCIdentifications} type={"button"}>
                    Remove All MC Identifications
                </button>
            </div>
            <div className={styles.content} style={{display: selectedTab === 1 ? 'flex' : 'none'}}>
                <TopicCard useReferenceHelp={'modal'} selectedTopics={selectedTopics}
                           toggleSelectedTopicFunc={updateSelectedTopics}/>
            </div>
            <div className={styles.content} style={{display: selectedTab === 2 ? 'flex' : 'none', gap: 4}}>
                <select className={styles.textFieldSelect} value={selectedTextField}
                        onChange={e => setSelectedTextField(e.currentTarget.value)}>
                    <option value={''}>-- Select --</option>
                    <option value={'OOS'}>Out Of Scope</option>
                    <option value={'description'}>Description (Internal)</option>
                </select>
                <div className={styles.divider}/>
                <TextFieldDescriptions selected={selectedTextField}/>
                <textarea value={textFields[selectedTextField] ?? ''}
                          disabled={selectedTextField === ''}
                          className={styles.textField}
                          onChange={e => setTextField(selectedTextField, e.currentTarget.value.toString())}
                />
            </div>
            <div className={styles.content} style={{display: selectedTab === 3 ? 'flex' : 'none'}}>
                <button onClick={() => updateStatus("Reviewed")}
                        className={`${styles.statusButton} ${status === "Reviewing" ? styles.submitButton : ''}`}
                        disabled={status !== "Reviewing"}>
                    Mark Standard Review Complete
                </button>
                <button onClick={() => updateStatus("Released")}
                        className={`${styles.statusButton} ${status === "Reviewing" ? styles.releaseButton : ''}`}
                        disabled={status !== "Reviewing"}>
                    Release Batch for Review
                </button>
            </div>
        </div>
    );
};

const Tab = ({isSelected, text, onClick}) => {
    return <button onClick={onClick}
                   className={`${styles.tab} ${isSelected ? styles.selectedTab : ''}`}>
        {text}</button>
}

const TextFieldDescriptions = ({selected}) => {
    if (selected === 'OOS') {
        return (
            <p className={styles.textDescription}>
                <b>Out of Scope:</b> <br/>
                Note any information out of scope for this docket, visible in any reports with an Out of Scope field.
            </p>
        )
    }

    if (selected === 'description') {
        return (
            <p className={styles.textDescription}>
                <b>Description (Internal):</b> <br/>
                Write a brief description of the form letter campaign, visible only in the Form Letter & Triage
                Reference tabs.
            </p>
        )
    }

    return null
}

export default ReviewFormLetterData;