import React from 'react';
import styles from './changeRequestModal.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../General/icons/chevronIcon";
import {updateChangeRequestReadUserAPI} from "../../../api/changeRequestAPI";

const ChangeRequestModal = ({open, closeModal, changeRequests}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [expandedIndex, setExpandedIndex] = React.useState(-1);
    const clearAndCloseModal = () => {
        updateChangeRequestReadUserAPI(jwt, docket).then()
        closeModal()
    }

    const expandChangeRequest = (idx) => {
        if (expandedIndex === idx) {
            setExpandedIndex(-1)
        } else {
            setExpandedIndex(idx)
        }
    }

    useEscape(clearAndCloseModal)

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.content} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;My Change Requests&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <h1 className={styles.h1}>My Change Requests</h1>
                <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                </button>

                <div className={styles.fields}>
                    {
                        changeRequests.length < 1 &&
                        <div className={styles.changeRequest}>

                            <div className={styles.changeRequestTitle}>
                                <h2>No Change Requests Found</h2>
                            </div>

                        </div>
                    }
                    {
                        changeRequests.map((element, index) => {

                            const isExpanded = (expandedIndex === index);

                            let color = '#0300A3';
                            let inProgress = false;
                            let isNew = false;
                            let resolvedDate = 'N/A';
                            if (element.status === 'In Progress') {
                                color = '#9D750E'
                                inProgress = true;
                            } else if (element.status === 'Closed') {
                                color = '#108B15'
                                inProgress = true;
                                if (!element.viewed) {
                                    isNew = true;
                                }

                                resolvedDate = element.dateResolved
                            }

                            return (
                                <div key={index} style={{backgroundColor: index % 2 ? '#ECECEC' : '#FFFFFF'}}
                                     className={styles.changeRequest}>

                                    <div className={styles.changeRequestTitle}>
                                        <h2>{element.documentID}</h2>
                                        <p>Status: <span style={{color: color}}>{element.status}</span></p>
                                        {
                                            isNew &&
                                            <p><b>[Newly Complete]</b></p>
                                        }
                                        <button onClick={() => expandChangeRequest(index)}>
                                            <ChevronIcon
                                                direction={isExpanded ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN}
                                                size={28}/>
                                        </button>
                                    </div>

                                    <div style={{height: isExpanded ? 200 : 0}} className={styles.changeRequestDetails}>

                                        <div className={styles.detailSection}>
                                            <p><b style={{width: '40%', display: 'inline-block'}}>Topics to
                                                Remove: </b> {element.topicRemove === '' ? 'None' : element.topicRemove}
                                            </p>
                                            <p><b style={{width: '40%', display: 'inline-block'}}>Topics to
                                                Add: </b> {element.topicAdd === '' ? 'None' : element.topicAdd}</p>
                                            <p><b style={{width: '40%', display: 'inline-block'}}>Request
                                                Description: </b>
                                                <br/>{element.description === '' ? 'None' : element.description}</p>
                                        </div>

                                        <div className={styles.separation}/>

                                        <div style={{justifyContent: 'space-between'}} className={styles.detailSection}>
                                            <p><b style={{width: '40%', display: 'inline-block'}}>Resolution Notes: </b>
                                                <br/>
                                                {
                                                    element.resolutionNotes === '' ?
                                                        <span
                                                            style={{fontStyle: 'italic'}}>No Resolution Note Entered</span> :
                                                        <span> {element.resolutionNotes} </span>
                                                }
                                            </p>

                                            {
                                                inProgress &&
                                                <div style={{width: '100%'}}>
                                                    <p><b style={{width: '40%', display: 'inline-block'}}>Date
                                                        Submitted: </b> {element.dateSubmitted}</p>
                                                    <p><b style={{width: '40%', display: 'inline-block'}}>Date
                                                        Resolved: </b> {resolvedDate}</p>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>,
        document.body
    );
};

export default ChangeRequestModal;