import {useSelector} from "react-redux";
import React from "react";
import {getCommentOtherText} from "../../../../../api/commentAPI";
import styles from "./sections.module.css";
import Loading from "../../../../General/loading/loading";
import {updateOtherTextAPI} from "../../../../../api/reviewsAPI";

const SingleTextArea = ({
                            trackingNumber,
                            textCategory,
                            updateSavedState,
                            isPaused,
                            title = "",
                            requireConfirmation = false
                        }) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [otherText, setOtherText] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [shouldSaveText, setShouldSaveText] = React.useState(false)

    const [enableField, setEnable] = React.useState(requireConfirmation ? false : true)

    const updateOtherText = (text) => {
        setShouldSaveText(true)
        setOtherText(text)
        updateSavedState(false)
    }

    const enableCheckbox = () => {
        setEnable(true)
    }

    React.useEffect(() => {
        setLoading(true)
        getCommentOtherText(jwt, docket, trackingNumber, textCategory).then(r => {
            setLoading(false)
            setOtherText(r)

            if (r.length > 0) {
                setEnable(true)
            }
        })
    }, [trackingNumber, textCategory])

    React.useEffect(() => {
        if (!shouldSaveText) {
            return () => {
            }
        }

        let timeoutID = setTimeout(() => {
            updateOtherTextAPI(jwt, docket, trackingNumber, otherText, textCategory).then(r => {
                if (r.success) {
                    updateSavedState(true)
                }
            })
        }, 750)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [otherText, shouldSaveText])

    React.useEffect(() => {
        setEnable(!requireConfirmation)
    }, [requireConfirmation])

    return (
        <div className={styles.wrapperFlexLeft} style={{gap: 8}}>
            {
                title !== "" &&
                <p className={styles.label}>{title}</p>
            }
            {
                requireConfirmation && !enableField && !loading &&
                <button className={styles.enableButton}
                        onClick={enableCheckbox}>Enable {title === "" ? "Text Field" : title}</button>
            }
            {
                loading ? <Loading/> :
                    <textarea disabled={isPaused || !enableField} className={styles.textArea} value={otherText}
                              onChange={e => updateOtherText(e.currentTarget.value)}/>
            }
        </div>
    )
}

export default SingleTextArea