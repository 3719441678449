import React from 'react';
import styles from "./downloadIcon.module.css"

const DownloadIcon = ({
                          size = 24, disabled = false, invert = false, onClick = () => {
    }
                      }) => {

    const iconStr = "/assets/imgs/download.svg"

    const whenClicked = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <img onClick={whenClicked}
             className={`${disabled ? styles.disabled : styles.icon} ${invert ? styles.invert : ""}`}
             onContextMenu={() => {
                 return false;
             }} style={{height: size, width: size}} src={iconStr} alt={"Download Icon"}/>
    );
};


export default DownloadIcon;