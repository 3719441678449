const YEAR = new Date().getFullYear();

const FEDERAL_HOLIDAY_TICKS = {
    NEW_YEARS_DAY: getUnixTimestamp(new Date(YEAR, 0, 1, 0, 0, 0, 0)), // January 1
    MARTIN_LUTHER_KINGS_BIRTHDAY: getNthWeekdayOfMonth(YEAR, 0, 1, 3), // 3rd Monday of January
    WASHINGTONS_BIRTHDAY: getNthWeekdayOfMonth(YEAR, 1, 1, 3), // 3rd Monday of February
    MEMORIAL_DAY: getLastWeekdayOfMonth(YEAR, 4, 1), // Last Monday of May
    JUNETEENTH: getUnixTimestamp(new Date(YEAR, 5, 19, 0, 0, 0, 0)), // June 19
    INDEPENDENCE_DAY: getUnixTimestamp(new Date(YEAR, 6, 4, 0, 0, 0, 0)), // July 4
    LABOR_DAY: getNthWeekdayOfMonth(YEAR, 8, 1, 1), // 1st Monday of September
    COLUMBUS_DAY: getNthWeekdayOfMonth(YEAR, 9, 1, 2), // 2nd Monday of October
    VETERANS_DAY: getUnixTimestamp(new Date(YEAR, 10, 11, 0, 0, 0, 0)), // November 11
    THANKSGIVING_DAY: getNthWeekdayOfMonth(YEAR, 10, 4, 4), // 4th Thursday of November
    CHRISTMAS_DAY: getUnixTimestamp(new Date(YEAR, 11, 25, 0, 0, 0, 0)) // December 25
};

function getUnixTimestamp(date) {
    return Math.floor(date.getTime() / 1000);
}

function getNthWeekdayOfMonth(year, month, weekday, n) {
    const firstDayOfMonth = new Date(year, month, 1);
    const firstWeekday = firstDayOfMonth.getDay();
    const offset = (weekday - firstWeekday + 7) % 7;
    const day = 1 + offset + (n - 1) * 7;
    return Math.floor(new Date(year, month, day).getTime() / 1000);
}

function getLastWeekdayOfMonth(year, month, weekday) {
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const lastWeekday = lastDayOfMonth.getDay();
    const offset = (lastWeekday - weekday + 7) % 7;
    const day = lastDayOfMonth.getDate() - offset;
    return Math.floor(new Date(year, month, day).getTime() / 1000);
}

function getMonthDayFromTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert from Unix timestamp (seconds) to JavaScript Date
    return { month: date.getUTCMonth(), day: date.getUTCDate() };
}

function isFederalHoliday(date) {
    const month = date.getUTCMonth(); // Use UTC to match Unix timestamps
    const day = date.getUTCDate();
    
    // Get month and day for each holiday in FEDERAL_HOLIDAY_TICKS
    const holidays = Object.values(FEDERAL_HOLIDAY_TICKS);
    return holidays.some(timestamp => {
        const { month: holidayMonth, day: holidayDay } = getMonthDayFromTimestamp(timestamp);
        return holidayMonth === month && holidayDay === day;
    });
}


const businessHoursInDay = (date, startHour, endHour) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(startHour, 0, 0, 0);

    const endOfDay = new Date(date);
    endOfDay.setHours(endHour, 0, 0, 0);

    if (date <= startOfDay) {
        return 8;
    } else if (date >= endOfDay) {
     
        return 0;
    } else {
        return (endOfDay - date) / (1000 * 60 * 60);
    }
}

const isBusinessDay = (date) => {
  
    const day = date.getDay();
    if (isFederalHoliday(date)) {
      return false
    }
    return day !== 0 && day !== 6;
}

const getNextBusinessDay = (date) => {
    let nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
    while (!isBusinessDay(nextDay)) {
        nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
}

const calculateBusinessHourDifference = (unixOlder, unixNewer) => {
    const startDate = new Date(unixOlder * 1000);
    const endDate = new Date(unixNewer * 1000);
    const startHour = 9;
    const endHour = 17;

    let totalBusinessHours = 0;

    let currentDate = startDate;
    while (currentDate < endDate) {
        if (isBusinessDay(currentDate)) {
            totalBusinessHours += businessHoursInDay(currentDate, startHour, endHour);
        }
        currentDate = getNextBusinessDay(currentDate);
    }
    
    // Adjust for the end time of the last business day
    if (isBusinessDay(endDate)) {
        const endOfDay = new Date(endDate);
        endOfDay.setHours(endHour, 0, 0, 0);
        if (endDate < endOfDay) {
            totalBusinessHours -= (endOfDay - endDate) / (1000 * 60 * 60);
        }
    }

    return Math.ceil(totalBusinessHours);
}

export default calculateBusinessHourDifference;

// testing functions (uncomment from one line down)
// const printTestResult = (name, unixOlder, unixNewer, expected, t) => {
//     const result = calculateBusinessHourDifference(unixOlder, unixNewer, t);
//     console.log(`${name}  Got: ${result} Expected: ${expected}`);
// };

// // Test cases
// const test1 = () => {
//     const unixOlder = Math.floor(new Date('2024-12-24T10:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-12-26T15:00:00Z').getTime() / 1000); 
//     printTestResult('Test 1', unixOlder, unixNewer, 13); // Christmas
// };

// const test2 = () => {
//     const unixOlder = Math.floor(new Date('2024-01-12T09:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-01-15T17:00:00Z').getTime() / 1000); 
//     printTestResult('Test 2', unixOlder, unixNewer, 8); // Martin Luther King Jr. Day
// };

// const test3 = () => {
//     const unixOlder = Math.floor(new Date('2024-02-16T15:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-02-19T10:00:00Z').getTime() / 1000); 
//     printTestResult('Test 3', unixOlder, unixNewer, 2); // Presidents' Day
// };

// const test4 = () => {
//     const unixOlder = Math.floor(new Date('2024-05-24T18:00:00Z').getTime() / 1000); 
//     const unixNewer = Math.floor(new Date('2024-05-27T10:00:00Z').getTime() / 1000); 
//     printTestResult('Test 4', unixOlder, unixNewer, 0); // Memorial Day
// };

// const test5 = () => {
//     const unixOlder = Math.floor(new Date('2024-07-01T10:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-07-04T15:00:00Z').getTime() / 1000);
//     printTestResult('Test 5', unixOlder, unixNewer, 23); // Independence Day
// };

// const test6 = () => {
//     const unixOlder = Math.floor(new Date('2024-08-30T10:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-09-02T15:00:00Z').getTime() / 1000);
//     printTestResult('Test 6', unixOlder, unixNewer, 7); // Labor Day
// };
// const test7 = () => {
//     const unixOlder = Math.floor(new Date('2024-06-18T10:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-06-20T15:00:00Z').getTime() / 1000);
//     printTestResult('Test 7', unixOlder, unixNewer, 13); // Juneteenth
// };

// const test8 = () => {
//     const unixOlder = Math.floor(new Date('2024-09-01T09:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-09-04T17:00:00Z').getTime() / 1000); 
//     printTestResult('Test 8', unixOlder, unixNewer, 16); // Labor Day
// };

// const test9 = () => {
//     const unixOlder = Math.floor(new Date('2024-10-12T10:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-10-15T15:00:00Z').getTime() / 1000); 
//     printTestResult('Test 9', unixOlder, unixNewer, 6); // Columbus Day
// };

// const test10 = () => {
//     const unixOlder = Math.floor(new Date('2024-11-10T09:00:00Z').getTime() / 1000);
//     const unixNewer = Math.floor(new Date('2024-11-12T17:00:00Z').getTime() / 1000);
//     printTestResult('Test 10', unixOlder, unixNewer, 8); // Veterans Day
// };
// const test11 = () => {
//     const unixOlder = Math.floor(new Date('2024-11-24T09:00:00Z').getTime() / 1000); 
//     const unixNewer = Math.floor(new Date('2024-11-29T17:00:00Z').getTime() / 1000); 
//     printTestResult('Test 10', unixOlder, unixNewer, 32); // Thanksgiving
// };
// // Run tests
// test1();
// test2();
// test3();
// test4();
// test5();
// test6();
// test7();
// test8();
// test9();
// test10();
// test11();