import './App.css';
import LoginForm from "./components/Login/loginForm";
import {useDispatch, useSelector} from "react-redux";
import {LOGIN_STEP} from "./helper/constants";
import ForgotPassword from "./components/Login/forgotPassword";
import {isJWTValidAPI} from "./api/authAPI";
import {setAuthToken} from "./store/userSlice";
import ChooseDocket from "./components/Login/chooseDocket";
import AppWrapper from "./components/appWrapper";
import signOutFunc from "./helper/signOutFunc";
import {setLoginStatus} from "./store/loginSlice";
import {getUserPreferencesAPI} from "./api/preferenceAPI";
import {setKeywordsExpansionDefault, setPDFDownload, setViewAnnotation} from "./store/preferenceSlice";

function App() {

    const loginStep = useSelector((state) => state.login.loginStatus);
    const jwt = useSelector((state) => state.user.jwt);
    const modalOpen = useSelector((state) => state.modal.modalOpen);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const dispatch = useDispatch()

    const getPreferences = (newJWT) => {
        getUserPreferencesAPI(newJWT).then(r => {
            dispatch(setPDFDownload(r.pdf))
            dispatch(setViewAnnotation(r.annotation))
            dispatch(setKeywordsExpansionDefault(r.expanded_keywords))
        })
    }

    const getLoginElement = () => {
        if (loginStep === LOGIN_STEP.LOAD_PREVIOUS_LOGIN) {
            if (jwt === "") {
                dispatch(setLoginStatus(LOGIN_STEP.NEW_LOGIN))
            } else if (jwt.includes(":")) {
                signOutFunc(dispatch)
            } else {
                isJWTValidAPI(jwt).then(newJWT => {
                    if (newJWT !== "") {
                        dispatch(setAuthToken(newJWT))
                        if (docket === "") {
                            dispatch(setLoginStatus(LOGIN_STEP.CHOOSE_DOCKET))
                        } else {
                            dispatch(setLoginStatus(LOGIN_STEP.FINISHED))
                        }
                        getPreferences(newJWT)
                    } else {
                        signOutFunc(dispatch)
                    }
                })
            }
        } else if (loginStep === LOGIN_STEP.NEW_LOGIN) {
            return <LoginForm/>
        } else if (loginStep === LOGIN_STEP.FORGOT_PASSWORD) {
            return <ForgotPassword/>
        } else if (loginStep === LOGIN_STEP.CHOOSE_DOCKET) {
            return <ChooseDocket/>
        } else if (loginStep === LOGIN_STEP.FINISHED) {
            return <AppWrapper/>
        }
    }

    return (
        <div className="App">
            <div className="App-header" aria-hidden={modalOpen}>
                {
                    getLoginElement()
                }
            </div>
        </div>
    );
}

export default App;
