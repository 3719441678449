import React from 'react';
import styles from './KeywordTable.module.css'
import { useDispatch, useSelector } from "react-redux";
import { getAdminKeywordsListAPI, deleteKWFromListAPI } from '../../../api/adminAPI';
import TrashIcon from '../../General/icons/trashIcon';
const filter = async (jwt, docket, text) => { //filter currently not implemented
    let temp = []
    const data = await getAdminKeywordsListAPI(jwt, docket);
    for (let item of data) {
        if (item.topic.includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.subtopic.includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.keyword.includes(text)) {
            temp.push(item)
        }
    }
    return temp
}

const sortData = (data) => { //sort data again when updating table
    if (!data) return [];
    const compareFn = (a, b) => {
        if (a.topic < b.topic) {
            return -1
        }

        if (a.topic > b.topic) {
            return 1
        }

        if (a.subtopic < b.subtopic) {
            return -1
        }

        if (a.subtopic > b.subtopic) {
            return 1
        }

        return 0
    }

    return data.sort(compareFn)
}
const KeywordTable = ({filteredKWTableData, setFilteredKWTableData}) => {
    
    const [filterText, setFilterText] = React.useState('')

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const rightBorder = {
        borderRight: '2px solid #aaaaaa'
    }
    React.useEffect(() => {
        const fetchKWGroupData = async () => {
            filter(jwt, docket, filterText).then((data) => {
                setFilteredKWTableData(sortData(data));
            });
        }
        fetchKWGroupData();
    }, [filterText, jwt, docket]);

    const deleteKW = (kw, index) => {
        const data = {
            topic: kw.topic,
            subtopic: kw.subtopic,
            keyword: kw.keyword,
            docket: docket,
        };
        if (window.confirm(`Are you sure you want to delete the keyword "${kw.keyword}"?`)) {
        deleteKWFromListAPI(jwt, data)
            .then((r) => {
                if (r.success) {
                    // delete keyword from cached table
                    setFilteredKWTableData(filteredKWTableData => {
                        const newFilteredKWTableData = [
                            ...filteredKWTableData.slice(0, index),
                            ...filteredKWTableData.slice(index + 1)
                        ];
                        return sortData(newFilteredKWTableData);
                    });
                if (r.lastEntry) { //if deleted keyword was last, add the no keyword topic subtopic combination to cache
                    setFilteredKWTableData(filteredKWTableData => sortData([
                        ...filteredKWTableData,
                        {
                            topic: data.topic,
                            subtopic: data.subtopic,
                            keyword: '',
                            accuracy: null,
                            delete: false
                        }
                    ]));
                }
                } else {
                    if (r.error) {
                        window.alert(r.error)
                    }
                    else {
                        window.alert("Failed to delete Keyword.")
                    }
                }

            })
            .catch(() => {
                window.alert("An Unexpected Error Occurred. Please retry deleting the keyword.");
            });}
    }
    return (
        <div className={styles.table_wrapper}>
        <table>
            <thead>
                <tr style={{ backgroundColor: '#c7c7c7' }}>
                    <th style={{
                        ...rightBorder,
                        borderTopLeftRadius: 10,
                        paddingLeft: 8,
                        maxWidth: 10,
                        textAlign: 'left',
                        width: '10%'
                    }}>Topic
                    </th>
                    <th style={{
                        ...rightBorder,
                        minWidth: 100,
                        textAlign: 'left',
                        paddingLeft: 12,
                    }}>Subtopic
                    </th>
                    <th style={{ ...rightBorder, minWidth: 48, textAlign: 'left', maxWidth: '15%', paddingLeft: 8, }}>Keyword</th>
                    <th style={{ borderTopRightRadius: 2, textAlign: 'center', ...rightBorder, padding: 4 }}>Accuracy</th>
                    <th style={{ borderTopRightRadius: 2, minWidth: 70, width: '5%'}}>Delete</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredKWTableData.map((kw, index) => (
                        <tr key={`${index}_${kw.topic}_${kw.subtopic}_${kw.keyword}`}>
                            <td style={rightBorder}>{kw.topic}</td>
                            <td style={rightBorder}>{kw.subtopic}</td>
                            <td style={rightBorder}>{kw.keyword}</td>
                            <td style={{...rightBorder, textAlign: 'center',}}>{ kw.accuracy !== null ? (kw.accuracy * 100).toFixed(0) : ''}{kw.accuracy !== null ? '%' : 'N/A'}</td>
                            <td style={{textAlign: 'center', minWidth: '20%'}}>{kw.delete === true ? <TrashIcon onClick={() => {deleteKW(kw, index)}} useRed={true}/> : null}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
    )
}

export default KeywordTable;