import React from 'react';
import styles from './topicCard.module.css'
import {useDispatch, useSelector} from "react-redux";
import TriageReferenceTopicModal from "../TriageReferenceTopicModal/TriageReferenceTopicModal";
import {modalClose} from "../../../store/modalSlice";
import InfoIcon from "../icons/infoIcon";

const filter = (referenceData, rawTopicData, text) => {

    if (text === "") {
        return rawTopicData
    }

    let temp = {}

    const calculateItemValid = (item) => {

        const textLower = text.toLowerCase()
        if (item.topic != null && item.topic.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.section != null && item.section.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.subtopic != null && item.subtopic.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.keywords != null && item.keywords.join(", ").toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.notes != null && item.notes.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.other_solicitations != null && item.other_solicitations.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.background != null && item.background.toLowerCase().includes(textLower)) {
            return true;
        }

        if (item.provision != null && item.provision.toLowerCase().includes(textLower)) {
            return true;
        }

        return false;
    }

    for (let topicTitle of Object.keys(rawTopicData)) {
        const referenceTopicData = referenceData[topicTitle]

        const childrenList = []
        try {
            const referenceTopicChildren = referenceData[topicTitle].children

            for (let subtopicTitle of Object.keys(referenceTopicChildren)) {
                const subtopicData = referenceTopicChildren[subtopicTitle]

                if (calculateItemValid(subtopicData)) {
                    for (let topicDataChild of rawTopicData[topicTitle].children) {
                        if (topicDataChild[0] === subtopicTitle || topicDataChild[1] === subtopicTitle) {
                            childrenList.push(topicDataChild)
                            break
                        }
                    }
                }
            }
        } catch (e) {
            // Search failed for this set of subtopics
            // Note: they may not exist?
        }

        if (childrenList.length > 0) {
            temp[topicTitle] = {
                ...rawTopicData[topicTitle],
                children: childrenList
            }
        } else if (referenceTopicData !== undefined && calculateItemValid(referenceTopicData)) {
            temp[topicTitle] = {
                ...rawTopicData[topicTitle],
                children: []
            }
        }
    }

    return temp
}

const TopicSection = ({
                          topic,
                          friendlyName,
                          children,
                          isRider,
                          selectedTopics,
                          toggleSelectedTopicFunc,
                          isPaused = false,
                          useSentiment = true,
                          disabled,
                          useReference = false,
                          referenceOpenFunction = () => {
                          },
                          referenceData = {},
                          autoExpandChildren = false
                      }) => {

    const getTopicSentiment = (topic) => {
        if (topic in selectedTopics) {
            return selectedTopics[topic]["sentiment"]
        }

        return -100
    }

    const getSubtopicSentiment = (subtopic) => {
        if (topic in selectedTopics) {
            if (subtopic in selectedTopics[topic]["children"]) {
                return selectedTopics[topic]["children"][subtopic]
            }
            return -100
        }

        return -100
    }

    const toggleOnTopicDivClick = (topic, shouldUseSentiment, isRider) => {
        if (isPaused || disabled) {
            return
        }

        toggleSelectedTopicFunc(topic, "", -99)
    }

    const toggleOnSubtopicDivClick = (topic, subtopic, shouldUseSentiment) => {
        if (isPaused || disabled) {
            return
        }

        toggleSelectedTopicFunc(topic, subtopic, -99)
    }

    const openHelpMenu = (event, topic, subtopic) => {
        event.stopPropagation();
        referenceOpenFunction(topic, subtopic)
    }

    return (
        <div className={styles.topicSection}>
            <div
                onClick={() => toggleOnTopicDivClick(topic, useSentiment, isRider)}
                className={`${styles.topicRow} ${isRider ? styles.rider : undefined} ${disabled && styles.lineDisabled} ${getTopicSentiment(topic) !== -100 && styles.selected}`}>
                <p>{isRider ? "**" : ""}{friendlyName}</p>
                {
                    useReference &&
                    Object.keys(referenceData).includes(topic) &&
                    <button onClick={(e) => openHelpMenu(e, topic, null)}><InfoIcon size={16}/></button>
                }
            </div>
            {
                (autoExpandChildren || getTopicSentiment(topic) !== -100) &&
                children.map((child) => {

                        if (autoExpandChildren && getTopicSentiment(topic) === -100) {
                            return (
                                <div key={child[0]}
                                     className={`${styles.subtopicRow} ${styles.lineDisabled} ${getSubtopicSentiment(child[0]) !== -100 && styles.selected}`}>
                                    <p>{child[1]}</p>
                                    {
                                        useReference &&
                                        Object.keys(referenceData).includes(topic) &&
                                        Object.keys(referenceData[topic]['children']).includes(child[0]) &&
                                        <button onClick={(e) => openHelpMenu(e, topic, child[0])}><InfoIcon size={16}/>
                                        </button>
                                    }
                                </div>
                            )
                        }

                        return (
                            <div key={child[0]}
                                 onClick={() => toggleOnSubtopicDivClick(topic, child[0], useSentiment)}
                                 className={`${styles.subtopicRow}  ${disabled && styles.lineDisabled} ${getSubtopicSentiment(child[0]) !== -100 && styles.selected}`}>
                                <p>{child[1]}</p>
                                {
                                    useReference &&
                                    Object.keys(referenceData).includes(topic) &&
                                    Object.keys(referenceData[topic]['children']).includes(child[0]) &&
                                    <button onClick={(e) => openHelpMenu(e, topic, child[0])}><InfoIcon size={16}/></button>
                                }
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}

const TopicCard = ({
                       selectedTopics,
                       toggleSelectedTopicFunc,
                       isPaused,
                       useSentiment = true,
                       disabled = false,
                       riderShow = 'all',
                       useReferenceHelp = 'none',
                       customReferenceFunction = (topic, subtopic) => {
                       }
                   }) => {

    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics);
    const docketReference = useSelector((state) => state.dockets.docketTopicsReference);
    const dispatch = useDispatch();

    const [referenceModalOpen, setReferenceModalOpen] = React.useState({isOpen: false, topic: null, subtopic: null});
    const [searchValue, setSearchValue] = React.useState('');

    const openModal = (topic, subtopic) => {
        dispatch(modalClose())

        setReferenceModalOpen({isOpen: true, topic: topic, subtopic: subtopic})
    }

    const closeModal = () => {
        dispatch(modalClose())

        setReferenceModalOpen({isOpen: false, topic: null, subtopic: null})
    }

    const toggleReferenceFunction = (topic, subtopic) => {
        if (useReferenceHelp === 'modal') {
            openModal(topic, subtopic)
            return;
        } else if (useReferenceHelp === 'custom') {
            customReferenceFunction(topic, subtopic)
            return;
        }

        return; // Do nothing
    }

    let allTopicsFiltered = filter(docketReference, docketTopics, searchValue)
    let temp = {}
    for (let key of Object.keys(allTopicsFiltered)) {
        if (riderShow === 'only' && !docketTopics[key]["isRider"]) {
            continue
        }

        if (riderShow === 'none' && docketTopics[key]["isRider"]) {
            continue
        }

        temp[key] = allTopicsFiltered[key]
    }

    allTopicsFiltered = {...temp}

    return (
        <React.Fragment>
            <input className={styles.searchSection} placeholder={"Search"}
                   type={"text"} value={searchValue} onChange={e => setSearchValue(e.currentTarget.value)}/>
            <div className={styles.wrapper}>
                {
                    Object.keys(allTopicsFiltered).length < 1 &&
                    <p className={styles.noTopics}>No Topics Found</p>
                }
                {
                    Object.keys(allTopicsFiltered).map((key, index) => {
                            return (
                                <TopicSection useSentiment={useSentiment} key={key} selectedTopics={selectedTopics}
                                              topic={key}
                                              isPaused={isPaused} disabled={disabled}
                                              toggleSelectedTopicFunc={toggleSelectedTopicFunc}
                                              friendlyName={allTopicsFiltered[key]["name"]}
                                              children={allTopicsFiltered[key]["children"]}
                                              isRider={allTopicsFiltered[key]["isRider"]}
                                              useReference={useReferenceHelp !== 'none'}
                                              referenceOpenFunction={toggleReferenceFunction}
                                              referenceData={docketReference}
                                              autoExpandChildren={searchValue !== ""}
                                />
                            )
                        }
                    )
                }
                <TriageReferenceTopicModal isOpen={referenceModalOpen.isOpen} closeModal={closeModal}
                                           data={docketReference} selectedTopic={referenceModalOpen.topic}
                                           selectedSubtopic={referenceModalOpen.subtopic}
                />
            </div>
        </React.Fragment>
    );
};

export default TopicCard;
