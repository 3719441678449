import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {getCommentSummary} from "../../../../../api/commentAPI";
import styles from "./sections.module.css";
import Loading from "../../../../General/loading/loading";
import {updateSummaryAPI} from "../../../../../api/reviewsAPI";
import {generateTopicCommentSummaryAPI} from "../../../../../api/aiAPI";
import {modalClose} from "../../../../../store/modalSlice";
import InfoIcon from "../../../../General/icons/infoIcon";
import TriageReferenceTopicModal from "../../../../General/TriageReferenceTopicModal/TriageReferenceTopicModal";
import getNonRiderTopics from "../../../../../helper/getNonRiderTopics";


const Summary = ({trackingNumber, topics, updateSavedState, isPaused, useExcerpts = false, excerpts = {}}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const allDocketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const docketReference = useSelector((state) => state.dockets.docketTopicsReference);
    const dispatch = useDispatch();
    const [topicFilter, setTopicFilter] = React.useState("")

    const [summaryText, setSummaryText] = React.useState("")
    const [summaryLoading, setSummaryLoading] = React.useState(false);
    const [aiLoading, setAILoading] = React.useState(false);
    const [shouldSaveSummary, setShouldSaveSummary] = React.useState(false);
    const [referenceModalOpen, setReferenceModalOpen] = React.useState(false);

    const summaryTopics = React.useMemo(() => getNonRiderTopics(jwt, docket, topics, allDocketTopics), [allDocketTopics])

    const openModal = () => {
        dispatch(modalClose())

        setReferenceModalOpen(true)
    }

    const closeModal = () => {
        dispatch(modalClose())

        setReferenceModalOpen(false)
    }

    const updateSummaryText = (text) => {
        setShouldSaveSummary(true)
        setSummaryText(text)
        updateSavedState(false)
    }

    const calculateExcerpt = () => {
        const excerptText = []
        let topicSubtopicList = topicFilter.split("||")
        const topic = topicSubtopicList[0]
        const subtopic = topicSubtopicList[1]

        if (useExcerpts) {
            if (topic !== undefined && topic !== "") {
                for (let attach of Object.keys(excerpts)) {
                    for (let excerpt of excerpts[attach]) {
                        if (Object.keys(excerpt.topics).includes(topic)) {
                            if (subtopic === undefined || subtopic === "") {
                                excerptText.push(excerpt.text)
                            } else {
                                for (let subtopicIteration of excerpt.topics[topic].subtopics) {
                                    if (subtopicIteration[0] === subtopic) {
                                        excerptText.push(excerpt.text)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return excerptText
    }

    const generateAISummary = () => {
        if (summaryText.length > 5) {
            if (!(window.confirm("Are you sure you want to overwrite your existing summary for this topic/subtopic?"))) {
                return
            }
        }

        let topicSubtopicList = topicFilter.split("||")
        const topic = topicSubtopicList[0]
        let subtopic = topicSubtopicList[1]

        if (subtopic === undefined) {
            subtopic = ""
        }

        setAILoading(true)
        generateTopicCommentSummaryAPI(jwt, docket, topic, subtopic, trackingNumber).then(r => {
            if (r.success) {
                setSummaryText(r.data)
                setAILoading(false)
            }
        })
    }

    const checkButtonDisabled = () => {

        if (topicFilter === '') {
            return true
        }

        let topicSubtopicList = topicFilter.split("||")
        const topic = topicSubtopicList[0]
        let subtopic = topicSubtopicList[1]

        if (subtopic !== '') {
            if (Object.keys(docketReference).includes(topic)) {
                if (Object.keys(docketReference[topic]['children']).includes(subtopic)) {
                    return false
                }
            }
        } else {
            if (Object.keys(docketReference).includes(topic)) {
                return false
            }
        }

        return true
    }

    React.useEffect(() => {
        if (topicFilter === "") {
            setSummaryText("")
        } else {
            let topicSubtopicSplit = topicFilter.split("||")
            setSummaryLoading(true)
            setSummaryText("")
            getCommentSummary(jwt, docket, trackingNumber, topicSubtopicSplit[0], topicSubtopicSplit[1]).then(r => {
                setSummaryLoading(false)
                setSummaryText(r)
                setShouldSaveSummary(false)
            })
        }

        setAILoading(false)
    }, [topicFilter])

    React.useEffect(() => {
        setTopicFilter("")
        setSummaryLoading(false)
        setSummaryText("")
    }, [trackingNumber])

    React.useEffect(() => {
        if (!shouldSaveSummary) {
            return () => {
            }
        }

        let timeoutID = setTimeout(() => {
            let topicSubtopicSplit = topicFilter.split("||")
            updateSummaryAPI(jwt, docket, trackingNumber, summaryText, topicSubtopicSplit[0], topicSubtopicSplit[1]).then(r => {
                if (r.success) {
                    updateSavedState(true)
                }
            })
        }, 750)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [summaryText, shouldSaveSummary])

    const excerptList = React.useMemo(() => calculateExcerpt(), [excerpts, topicFilter, topics]);

    let topicSubtopicFilterSplit = topicFilter.split("||")
    const currentTopic = topicSubtopicFilterSplit[0]
    let currentSubtopic = ''
    if (topicSubtopicFilterSplit.length > 1) {
        currentSubtopic = topicSubtopicFilterSplit[1].toString()
    }

    const infoButtonDisabled = checkButtonDisabled();

    return (
        <div className={styles.wrapperFlexCenter}>
            <div className={styles.dropdownWrapper}>
                <select disabled={aiLoading} className={styles.dropdown} aria-label={"Summary Topic Select"}
                        value={topicFilter}
                        onChange={(event) => setTopicFilter(event.currentTarget.value)}>
                    <option value={""}>-- Select --</option>
                    {
                        Object.keys(summaryTopics).map(topic => (
                            <React.Fragment key={`${topic}||`}>
                                <option value={`${topic}||`}>{topic}</option>
                                {
                                    Object.keys(summaryTopics[topic]['children']).map(subtopic => (
                                        <option key={`${topic}||${subtopic}`}
                                                value={`${topic}||${subtopic}`}>{topic} || {subtopic}</option>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </select>
                <button onClick={openModal} disabled={infoButtonDisabled}><InfoIcon size={20}/></button>
            </div>

            {
                aiLoading
                    ?
                    <div style={{
                        border: '1px solid #777777', display: "flex", backgroundColor: "#dddddd",
                        alignItems: "center", justifyContent: "center"
                    }}
                         className={styles.textArea}>
                        <Loading/>
                    </div>
                    :
                    <textarea disabled={isPaused || summaryLoading || topicFilter === ""} className={styles.textArea}
                              value={summaryText}
                              onChange={e => updateSummaryText(e.currentTarget.value)}/>
            }


            {
                useExcerpts && excerptList.length > 0 &&
                <button onClick={generateAISummary} className={styles.summarizeButton}>Generate AI Summary
                    Draft</button>
            }

            {
                useExcerpts && excerptList.length > 0 &&
                <div className={styles.summaryExcerptWrapper}>
                    <h2>Excerpts:</h2>
                    <div className={styles.summaryExcerptList}>
                        {
                            useExcerpts && excerptList.map(
                                (text, index) => (
                                    <p key={text}><b>{index + 1}. </b>{text}</p>
                                )
                            )
                        }
                    </div>
                </div>
            }

            <TriageReferenceTopicModal isOpen={referenceModalOpen} closeModal={closeModal} data={docketReference}
                                       selectedTopic={currentTopic} selectedSubtopic={currentSubtopic}/>
        </div>
    )
}

export default Summary