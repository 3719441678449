const sortDocIDsTo5Digits = (commentList, column) => {

    let temp = [...commentList]

    const docID_compareFunction = (a, b) => {

        let documentID_splitA = '00000'
        let documentID_splitB = '00000'

        if (a != null && a[column] != null) {
            documentID_splitA = ((a[column]).split('-')).pop().padStart(5, '0')
        }

        if (b != null && b[column] != null) {
            documentID_splitB = ((b[column]).split('-')).pop().padStart(5, '0')
        }

        if (documentID_splitA < documentID_splitB) {
            return -1;
        }
        if (documentID_splitA > documentID_splitB) {
            return 1;
        }
        return 0;
    }

    temp = temp.sort(docID_compareFunction)

    return temp
}

export const sortReportFilterDocIDs = (valueLabelPairs) => {

    let temp = [...valueLabelPairs]


    const docID_compareFunction = (a, b) => {

        let documentID_splitA = '00000'
        let documentID_splitB = '00000'

        if (a != null && a['value'] != null) {
            documentID_splitA = ((a['value']).split('-')).pop().padStart(5, '0')
        }

        if (b != null && b['value'] != null) {
            documentID_splitB = ((b['value']).split('-')).pop().padStart(5, '0')
        }

        if (documentID_splitA < documentID_splitB) {
            return -1;
        }
        if (documentID_splitA > documentID_splitB) {
            return 1;
        }
        return 0;
    }

    temp = temp.sort(docID_compareFunction)

    return temp
}

export default sortDocIDsTo5Digits