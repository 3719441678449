import React from 'react';
import styles from "./sections.module.css"
import { useSelector } from "react-redux";
import sortMCList from "../../../../../helper/sortMCList";
import { MC_TITLES } from "../../../../../helper/constants";
const Commenter = ({ commenterInfo, updateCommenter, trackingNumber, updateSavedState, isPaused, savedState, MCList }) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [commenterName, setCommenterName] = React.useState(commenterInfo[0] ?? "")

    const [possibleCommenters, setPossibleCommenters] = React.useState([])

    const updateCommenterName = (e) => {
        const cName = e.currentTarget.value

        if (cName !== commenterInfo[0]) {
            updateSavedState(false)
        } else {
            updateSavedState(true)
        }

        setCommenterName(cName)
    }

    const submitCommenterName = () => {
        updateCommenter(commenterName)
        updateSavedState(true)
    }

    const reset = () => {
        setCommenterName(commenterInfo[0] ?? "")
        updateSavedState(true)
    }

    const clear = () => {
        updateCommenter("")
        updateSavedState(true)
    }

    React.useEffect(() => {
        setPossibleCommenters(sortMCList(commenterName, MCList))
    }, [MCList])

    React.useEffect(() => {
        reset()
        setPossibleCommenters(sortMCList(commenterName, MCList))
    }, [trackingNumber, commenterInfo])


    React.useEffect(() => {
        let timeoutInterval = setTimeout(() => {
            setPossibleCommenters(sortMCList(commenterName, MCList))
        }, 250)

        return () => {
            clearTimeout(timeoutInterval)
        }
    }, [jwt, docket, commenterName])


    return (
        <div className={styles.wrapperFlexLeft}>
            <p className={styles.label}>Commenter Name:</p>
            <input disabled={isPaused} onChange={e => updateCommenterName(e)} value={commenterName}
                   className={styles.singleLineTextInput}/>
            <select disabled={isPaused} value={commenterName} onChange={e => updateCommenterName(e)}
                    className={styles.multiSelect} name="inProgress" id="inProgress" size={8}>
                <option value={""}>---</option>
                {
                    possibleCommenters.map((commenterData, index) => (
                        <option key={`${commenterData.organization}_${index}`}
                                value={commenterData.organization}>
                            {commenterData.organization}&nbsp;
                            ({commenterData.abbreviation !== 'None' ? `${commenterData.abbreviation}, ` : ""}
                            {MC_TITLES[commenterData.mc_group_id]})</option>
                    ))
                }
            </select>
            <div className={styles.lineItem}>
                <button className={styles.normalButton}
                        disabled={isPaused || commenterName === "" || commenterName === commenterInfo[0]}
                        onClick={clear}>Clear
                </button>
                <button className={styles.normalButton} disabled={isPaused || commenterName === commenterInfo[0]}
                        onClick={submitCommenterName}>Save
                </button>
            </div>
        </div>
    );
};

export default Commenter;