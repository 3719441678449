import React from 'react';
import styles from "./tabs.module.css"

const Tabs = ({tabsList, selectedTab, selectItem}) => {

    return (
        <div className={styles.wrapper}>
            {
                tabsList.map((element, idx) => (
                    <Tab onClick={() => selectItem(idx)} key={element} tabName={element}
                         selected={selectedTab === idx}/>))
            }
        </div>
    );
};

export default Tabs;

const Tab = ({tabName, selected, onClick}) => {
    return <button onClick={onClick} className={`${styles.item} ${selected ? styles.selected : ""}`}>
        {tabName}
    </button>
}