import React from 'react';
import styles from './cacheClearModal.module.css'
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import { clearCacheForDocketAPI } from '../../../api/adminAPI';

const ClearCacheModal = ({
    open, closeModal,
}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const dispatch = useDispatch();

    const clearAndCloseModal = () => {
        closeModal()
    }
    useEscape(clearAndCloseModal)

    const clearCache = () => {
            clearCacheForDocketAPI(jwt, docket).then(r => {
                if (r.success) {
                    //do nothing
                }
                else {
                    window.alert("Error: Cache could not be cleared")
                }
                clearAndCloseModal()
            })
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a paragraph stating &quot;Manually clearing the 
                    docket cache will propagate the newest data on the comment explorer and reports screens; 
                    however, the first load of this new data will be slower than normal. Would you like to proceed?&quot;.
                    Pressing the stay signed in button at the top will close the modal and bring you back to where you were on the page.
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.modalText}>Manually clearing the docket cache will propagate the newest data
                        on the comment explorer and reports screens;
                        however, the first load of this new data will be slower than normal. This feature is in beta and
                        may not work as intended. Would you like to proceed?
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.leftButton} onClick={clearAndCloseModal}>Cancel</button>
                        <button className={styles.rightButton} onClick={clearCache}>Yes, Wipe Cache</button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ClearCacheModal;