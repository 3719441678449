export const getRiderTopics = (topicsDict) => {
    let temp = []

    for (let key of Object.keys(topicsDict)) {
        if (topicsDict[key]["isRider"]) {
            temp.push(key)
        }
    }

    return temp
}