import {useSelector} from "react-redux";
import React from "react";
import {getCommentPDFAPI} from "../../../api/commentAPI";
import {Document, Page} from "react-pdf"
import {pdfjs} from 'react-pdf';
import styles from "./TextDisplay.module.css";
import ZoomIcon from "../icons/zoomIcon";
import DownloadIcon from "../icons/downloadIcon";
import OpenIcon from "../icons/openIcon";
import {EXCERPT_HIGHLIGHT_1, EXCERPT_HIGHLIGHT_2, KEYWORD_HIGHLIGHT_1} from "../../../helper/constants";
import RefreshIcon from "../icons/refreshIcon";
import calculateOverlappingExcerptText from "../../../helper/calculateOverlappingExcerptText";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const checkIfInExcerpt = (excerpt, pageNum, pageIndex) => {

    if (pageNum > excerpt.startPage && pageNum < excerpt.endPage) {
        return true
    }

    if (excerpt.startPage !== excerpt.endPage) {
        if (pageNum === excerpt.startPage && pageIndex >= excerpt.startIndex) {
            return true
        }

        if (pageNum === excerpt.endPage && pageIndex <= excerpt.endIndex) {
            return true
        }
    } else {
        if (pageNum === excerpt.startPage && pageIndex >= excerpt.startIndex && pageIndex <= excerpt.endIndex) {
            return true
        }
    }

    return false
}

const checkIfExcerptEnd = (excerpt, pageNum, pageIndex) => {

    return pageNum === excerpt.endPage && pageIndex === excerpt.endIndex;

}

const checkIfExcerptStart = (excerpt, pageNum, pageIndex) => {

    return pageNum === excerpt.startPage && pageIndex === excerpt.startIndex;

}

const PDFElement = ({
                        fileURL,
                        fileName,
                        keywordList,
                        useExcerpt = false,
                        excerpts,
                        attachment,
                        setHoveredExcerptTopics
                    }) => {

    //Documentation: https://react-pdf.org/components
    const shouldDownload = useSelector((state) => state.prefs.pdfDownload);

    const [numPages, setNumPages] = React.useState(null);
    const [pdfZoom, setPDFZoom] = React.useState(0.95);
    const pdfRef = React.useRef(undefined);

    const [reloadCounter, setReloadCounter] = React.useState(0);

    const canvasRefs = React.useRef([])

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const zoomOutFunc = () => {
        let newZoom = pdfZoom
        newZoom -= 0.1
        if (newZoom < 0.4) {
            newZoom = 0.4
        }

        setPDFZoom(newZoom)
    }

    const zoomInFunc = () => {
        let newZoom = pdfZoom
        newZoom += 0.1
        if (newZoom > 2) {
            newZoom = 2
        }

        setPDFZoom(newZoom)
    }

    const openPDF = () => {
        window.open(fileURL, "_blank", 'location=yes,height=900,width=800,scrollbars=yes');
    }

    const downloadPDF = () => {

        getCommentPDFAPI(fileURL).then(r => {

            const processBlob = async (resp) => {
                const blb = await resp.blob()

                const blob = new Blob([blb], {type: "application/pdf"});
                const url = URL.createObjectURL(blob);

                const anchorElement = document.createElement('a');
                anchorElement.href = url;
                anchorElement.download = fileName
                anchorElement.target = "_blank";

                anchorElement.click();
                anchorElement.remove();
                URL.revokeObjectURL(url);
            }

            processBlob(r)
        }).catch()
    }

    const processHover = (e) => {
        if (!useExcerpt || setHoveredExcerptTopics === undefined) {
            return
        }

        if (e.target.tagName === 'SPAN') {
            if (e.target.dataset.pageIndex !== undefined && e.target.dataset.index !== undefined) {
                if (excerpts[attachment] !== undefined && excerpts[attachment].length > 0) {
                    const list = {}

                    for (let excerpt of excerpts[attachment]) {
                        if (checkIfInExcerpt(excerpt, Number(e.target.dataset.pageIndex), Number(e.target.dataset.index))) {
                            for (let topic of Object.keys(excerpt.topics)) {
                                if (!(Object.keys(list).includes(topic))) {
                                    list[topic] = []
                                }

                                for (let subtopic of excerpt.topics[topic].subtopics) {
                                    if (!(list[topic].includes(subtopic[0]))) {
                                        list[topic].push(subtopic[0])
                                    }
                                }
                            }
                        }
                    }

                    setHoveredExcerptTopics(list)
                }
            }
        } else {
            setHoveredExcerptTopics({})
        }
    }

    let alternateExcerptColor = true;
    const textRenderer = (textItem, pageNumber) => {
        // Extract vertical and horizontal positions from textItem
        const verticalPosition = textItem.transform[4]; // Y coordinate
        const horizontalPosition = textItem.transform[5]; // X coordinate

        const pageNum = textItem.pageNumber;
        const pageIdx = textItem.itemIndex;

        let found = false;
        let endOfExcerpt = '';
        let startOfExcerpt = '';
        if (useExcerpt && excerpts[attachment] !== undefined && excerpts[attachment].length > 0) {
            for (let excerpt of excerpts[attachment]) {
                if (checkIfInExcerpt(excerpt, pageNum, pageIdx)) {
                    found = true;

                    if (checkIfExcerptEnd(excerpt, pageNum, pageIdx)) {
                        endOfExcerpt = excerpt.text;
                    }

                    if (checkIfExcerptStart(excerpt, pageNum, pageIdx)) {
                        startOfExcerpt = excerpt.text;
                    }

                    break;
                }
            }
        }

        let textBlock = textItem.str;

        if (found) {

            for (let kw of keywordList) {
                const regex1 = new RegExp(`[( ]${kw}[) ]`, 'ig')
                if (regex1.test(textBlock)) {
                    textBlock = textBlock.replace(regex1, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                    continue
                }

                const regex3 = new RegExp(`${kw}[. ]`, 'ig')
                if (regex3.test(textBlock)) {
                    textBlock = textBlock.replace(regex3, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                    continue
                }

                const regex4 = new RegExp(`${kw}`, 'ig')
                if (kw.length > 5) {
                    textBlock = textBlock.replace(regex4, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                }
            }

            let returnStr = '';
            let color = alternateExcerptColor ? EXCERPT_HIGHLIGHT_1 : EXCERPT_HIGHLIGHT_2

            if (startOfExcerpt !== '' && !(startOfExcerpt.includes(textItem.str))) {
                const excerptText = calculateOverlappingExcerptText(textItem.str, startOfExcerpt)
                const uniqueText = textItem.str.split(excerptText)[0]

                returnStr = `<customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}">${uniqueText}</customtag><customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}"><span data-page-index="${pageNum}" data-index="${pageIdx}" style="background-color: ${color}" class=${styles.excerpt_highlight_pdf}>${excerptText}</span></customtag>`;

            } else if (endOfExcerpt !== '' && !(endOfExcerpt.includes(textItem.str))) {
                // Do something else if last part of excerpt
                const excerptText = calculateOverlappingExcerptText(textItem.str, endOfExcerpt)
                const uniqueText = textItem.str.split(excerptText)[1]

                returnStr = `<customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}"><span data-page-index="${pageNum}" data-index="${pageIdx}" style="background-color: ${color}" class=${styles.excerpt_highlight_pdf}>${excerptText}</span></customtag>
                <customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}">${uniqueText}</customtag>
                `;

            } else {
                //Do normal stuff for everything else
                returnStr = `<customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}"><span data-page-index="${pageNum}" data-index="${pageIdx}" style="background-color: ${color}" class=${styles.excerpt_highlight_pdf}>${textBlock}</span></customtag>`;
            }

            if (endOfExcerpt !== '') {
                alternateExcerptColor = !alternateExcerptColor
            }

            return returnStr;
        }

        if (keywordList !== undefined) {
            for (let kw of keywordList) {
                const regex1 = new RegExp(`[( ]${kw}[) ]`, 'ig')
                if (regex1.test(textBlock)) {
                    textBlock = textBlock.replace(regex1, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                    continue
                }

                const regex3 = new RegExp(`${kw}[. ]`, 'ig')
                if (regex3.test(textBlock)) {
                    textBlock = textBlock.replace(regex3, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                    continue
                }

                const regex4 = new RegExp(`${kw}`, 'ig')
                if (kw.length > 5) {
                    textBlock = textBlock.replace(regex4, `<b style="background-color: ${KEYWORD_HIGHLIGHT_1}; font-weight: normal">$&</b>`)
                }
            }
        }

        // Return the text content with custom tags including vertical and horizontal positions
        return `<customtag data-vertical="${verticalPosition}" data-horizontal="${horizontalPosition}" 
                data-page="${pageNumber}" data-page-index="${pageNum}" data-index="${pageIdx}"
                data-in-excerpt="${found}">${textBlock}</customtag>`;
    }

    const refFunction = (ref) => {
        if (ref != null && canvasRefs.current.length < 2) {
            canvasRefs.current.push(ref)
        }
    }

    let pages = []
    if (numPages != null) {
        for (let i = 1; i <= numPages; i++) {
            if (pdfRef === undefined) {
                pages.push(<Page canvasRef={refFunction} renderAnnotationLayer={false} key={`${i}_${reloadCounter}`}
                                 pageNumber={i} customTextRenderer={textRenderer}/>)
            } else {
                pages.push(<Page canvasRef={refFunction} renderAnnotationLayer={false} key={`${i}_${reloadCounter}`}
                                 pageNumber={i} customTextRenderer={textRenderer}
                                 width={pdfRef.current.clientWidth} scale={pdfZoom}/>)
            }
        }
    }

    return (
        <div className={styles.pdfWrapper}>
            <div className={styles.pdfDiv}>
                <Document inputRef={pdfRef} file={fileURL} onLoadSuccess={onDocumentLoadSuccess}
                          onMouseOver={processHover}>
                    {
                        pages
                    }
                </Document>
            </div>
            <div style={{bottom: useExcerpt ? 52 : 0}} className={styles.zoomBar}>

                <button onClick={zoomInFunc} disabled={pdfZoom >= 2} className={styles.zoomButton}>
                    <ZoomIcon size={24} zoomIn={true}/>
                </button>
                <button onClick={zoomOutFunc} disabled={pdfZoom <= 0.4} className={styles.zoomButton}>
                    <ZoomIcon size={24} zoomIn={false}/>
                </button>
                {
                    shouldDownload ?
                        <button onClick={downloadPDF} className={styles.zoomButton}>
                            <DownloadIcon size={24} invert={true}/>
                        </button>
                        :
                        <button onClick={openPDF} className={styles.zoomButton}>
                            <OpenIcon size={24}/>
                        </button>
                }
                <button onClick={() => setReloadCounter(reloadCounter + 1)}
                        className={styles.zoomButton}>
                    <RefreshIcon size={24}/>
                </button>
            </div>
        </div>
    )
}

export default PDFElement