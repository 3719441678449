import {useProductionURL} from "../helper/constants";

//Returns all data for a report based on docket and the report ID
export const getReportData = async (jwt, docket, reportID) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/report/get_reports?` + new URLSearchParams({
        docket: docket,
        report: reportID
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns all data for a report based on docket and the report ID
export const getOverviewData = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/report/get_overview?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}
