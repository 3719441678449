import React from 'react';
import styles from "./chevronIcon.module.css"

const ChevronIcon = ({
                         size = 24,
                         bold = false,
                         disabled = false,
                         direction = CHEVRON_DIRECTIONS.RIGHT,
                         blackColor = true
                     }) => {

    let iconStr = ""
    if (bold) {
        iconStr = "/assets/imgs/chevron_bold.svg"
    } else {
        iconStr = "/assets/imgs/chevron.svg"
    }

    let rotation = styles.right
    switch (direction) {
        case CHEVRON_DIRECTIONS.DOWN:
            rotation = styles.down
            break
        case CHEVRON_DIRECTIONS.LEFT:
            rotation = styles.left
            break
        case CHEVRON_DIRECTIONS.UP:
            rotation = styles.up
            break
        default:
            rotation = styles.right
    }

    return (
        <img onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}}
             className={`${styles.icon} ${rotation} ${disabled ? styles.disabled : ""} ${blackColor ? "" : styles.flipColor} noSelect`}
             src={iconStr} alt={"Chevron Arrow"}/>
    );
};

export const CHEVRON_DIRECTIONS = {
    RIGHT: "right",
    DOWN: "down",
    LEFT: "left",
    UP: "up"
}

export default ChevronIcon;