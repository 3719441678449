import React from 'react';
import styles from "./zoomIcon.module.css"

const HelpIcon = ({
                      size = 24, zoomIn = true, disabled = false, onClick = () => {
    }
                  }) => {

    let iconStr = "/assets/imgs/zoom_in.svg"
    if (!zoomIn) {
        iconStr = "/assets/imgs/zoom_out.svg"
    }

    const whenClicked = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <img onClick={whenClicked} className={disabled ? styles.disabled : styles.icon} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default HelpIcon;