import React from 'react';
import styles from './MCTable.module.css'
import { useDispatch, useSelector } from "react-redux";
import { getAdminMCListAPI } from '../../../api/adminAPI';
const filter = async (jwt, docket, text) => {
    let temp = []
    const data = await getAdminMCListAPI(jwt, docket);
    for (let item of data) {
        if (item.org.includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.acronym.includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.comments.includes(text)) {
            temp.push(item)
        }
    }
    return temp
}

const sortData = (data) => {
    if (!data) return [];
    const compareFn = (a, b) => {
        if (a.mcGroup < b.mcGroup) {
            return -1
        }

        if (a.mcGroup > b.mcGroup) {
            return 1
        }

        if (a.org < b.org) {
            return -1
        }

        if (a.org > b.org) {
            return 1
        }

        return 0
    }

    return data.sort(compareFn)
}
const MCTable = ({filteredMCTableData, setFilteredMCTableData}) => {
    
    const [filterText, setFilterText] = React.useState('')

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const rightBorder = {
        borderRight: '2px solid #aaaaaa'
    }
    React.useEffect(() => {
        const fetchMCGroupData = async () => {
            filter(jwt, docket, filterText).then((data) => {
                setFilteredMCTableData(sortData(data));
            });
        }
        fetchMCGroupData();
    }, [filterText, jwt, docket]);

    return (
        <div className={styles.table_wrapper}>
        <table>
            <thead>
                <tr style={{ backgroundColor: '#c7c7c7' }}>
                    <th style={{
                        ...rightBorder,
                        borderTopLeftRadius: 24,
                        minWidth: 160,
                        width: '10%'
                    }}>Group
                    </th>
                    <th style={{
                        ...rightBorder,
                        minWidth: 10,
                        textAlign: 'left',
                        paddingLeft: 12,
                    }}>Organization
                    </th>
                    <th style={{ ...rightBorder, minWidth: 48, width: '20%' }}>Acronym</th>
                    <th style={{ borderTopRightRadius: 2, minWidth: 90, width: '20%' }}># Submitted</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredMCTableData.map((mc, index) => (
                        <tr key={`${index}_${mc.org}_${mc.acronym}`}>
                            <td style={rightBorder}>{mc.mcGroup === 1 ? "Priority MC" : "Other MC"}</td>
                            <td style={rightBorder}>{mc.org}</td>
                            <td style={rightBorder}>{mc.acronym}</td>
                            <td>{mc.commentsTotal}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
    )
}

export default MCTable;