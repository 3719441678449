//Returns a list of all available tabs for a user
import {useProductionURL} from "../helper/constants";

export const getUserTabs = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/docket/get_tabs?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}