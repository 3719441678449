import {unsetAll} from "../store/userSlice";
import {setDocket} from "../store/navSlice";
import {LOGIN_STEP} from "./constants";
import {unsetPreferences} from "../store/preferenceSlice";
import {setLoginStatus} from "../store/loginSlice";

const signOutFunc = (dispatch) => {

    dispatch(unsetAll())
    dispatch(setLoginStatus(LOGIN_STEP.NEW_LOGIN))

    dispatch(unsetPreferences())
    dispatch(setDocket(""))
}

export default signOutFunc