import React from 'react';
import styles from "./BatchProcessing.module.css"
import Tabs from "./tabs/tabs";
import FormLetters from "./Pages/FormLetters/FormLetters";
import {useSelector} from "react-redux";
import {getDuplicateTabsAPI} from "../../api/duplicateAPI";
import Duplicates from "./Pages/Duplicates/Duplicates";

const getBatchPage = (pageName) => {
    if (pageName === "Form Letters") {
        return <FormLetters/>
    }

    if (pageName === "Duplicates") {
        return <Duplicates/>
    }

    return null
}

const BatchProcessing = () => {

    const [pages, setPages] = React.useState([])
    const [selectedPage, setSelectedPage] = React.useState(0)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    React.useEffect(() => {
        getDuplicateTabsAPI(jwt, docket).then(r => setPages(r))
    }, [jwt, docket])


    return (
        <div className={styles.parent}>

            <Tabs selectedTab={selectedPage} tabsList={pages} selectItem={(item) => setSelectedPage(item)}/>
            <div className={styles.content}>
                {getBatchPage(pages[selectedPage])}
            </div>
        </div>
    );
};

export default BatchProcessing;