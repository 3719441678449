import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import getUniqueColumnValues, {
    MajorCommenterReportFilterData,
    orderUniqueColumnValues
} from "../../../helper/getUniqueColumnValues";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import { clearReportFilters, setReportFilters } from "../../../store/reportsSlice";
import parseReportFilters from "../../../helper/parseReportFilters";
import updateReportFilters from "../../../helper/updateReportFilters";
import MultiSelectClear from "./multiselectFilter/multiselectClear";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 75,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 200
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 75
    },
    {
        accessorKey: "FL Name".toString().replaceAll(" ", "").toLowerCase(),
        header: "FL Name",
        size: 150
    },
    {
        accessorKey: "textfield",
        header: "Out of Scope Summary",
        size: 1000
    }
]

//["Document ID", "Commenter", "MC Group", "Out of Scope Summary"]
const filterData = (data, docIDs, mcGroups, flNames) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        // Removing timing from the reports
        tempItem.splice(1, 3)

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[0]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[2]}`))) {
                continue
            }
        }

        if (flNames.length > 0) {
            if (!(flNames.includes(tempItem[3]))) {
                continue
            }
        }

        tempItem[2] = MC_TITLES[tempItem[2]]
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

const downloadOutOfScopeReport = (rawReportData, docket) => {

    let reportData = []

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem.splice(1, 0, `${docket}-${tempItem[0]}`)
        tempItem.splice(2, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[0]}`)

        if (tempItem[8] == null) {
            tempItem[8] = ""
        }

        reportData.push(tempItem)
    }

    reportData = sortDocIDsTo5Digits(reportData, 1)

    let tempHeadings = [...headings]

    tempHeadings.splice(1, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(2, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    tempHeadings.splice(3, 0, {
        accessorKey: "posted_date",
        header: "Posted Date"
    })

    tempHeadings.splice(4, 0, {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "completed_date",
        header: "Completed Date"
    })

    downloadReportData(tempHeadings, reportData, "out_of_scope_report")
}

const OutOfScopeReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const storedFilters = useSelector((state) => state.reports.outOfScopeReport);
    const dispatch = useDispatch()

    const uniqueDocumentIDs = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const [loading, setLoading] = React.useState(false)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "outOfScopeReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 8).then(r => {
            setRawReportData(r)
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "outOfScopeReport" }))
    }

    const filteredData = React.useMemo(() =>
            filterData(rawReportData,
                parseReportFilters('docID', storedFilters),
                parseReportFilters('mcGroups', storedFilters),
                parseReportFilters('flName', storedFilters)
            ),
        [rawReportData, storedFilters])
    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])
    const uniqueFLNames = React.useMemo(() => orderUniqueColumnValues(getUniqueColumnValues(rawReportData, 6)), [rawReportData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"15vw"}
                                   selected={parseReportFilters('docID', storedFilters)}
                                   setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs}/>
                <MultiSelectFilter title={"MC Group"} width={"10vw"}
                                   selected={parseReportFilters('mcGroups', storedFilters)}
                                   setSelected={(values) => updateFilter('mcGroups', values)}
                                   options={MajorCommenterReportFilterData}/>
                <MultiSelectFilter title={"FL Name"} width={"15vw"}
                                   selected={parseReportFilters('flName', storedFilters)}
                                   setSelected={(values) => updateFilter('flName', values)} options={uniqueFLNames}/>

                <MultiSelectClear mLeft={"auto"} width={"160px"}
                                  clearFunc={() => dispatch(clearReportFilters({reportType: "outOfScopeReport"}))}/>
            </div>
            <div className={styles.title}>
                <p>Out of Scope Report <span style={{marginLeft: 16}}>({uniqueCommentCount} comments shown)</span></p>
                <button onClick={() => downloadOutOfScopeReport(rawReportData, docket)}>
                    <DownloadIcon/>
                </button>
            </div>
            <div className={styles.table}>
                {loading ? <Loading /> :
                    <GenericTable data={filteredData}
                        headingsList={finalHeadings} allowExpansionPanel={true} />}
            </div>
        </div>
    );
};

export default OutOfScopeReport;