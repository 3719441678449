import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import getUniqueColumnValues, {
    getSubtopicValueLabelPair,
    MajorCommenterReportFilterData_onlyMCs
} from "../../../helper/getUniqueColumnValues";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import maskSentimentValues from "../../../helper/maskSentimentValues";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import Loading from "../../General/loading/loading";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import { clearReportFilters, setReportFilters } from "../../../store/reportsSlice";
import MultiSelectClear from "./multiselectFilter/multiselectClear";
import updateReportFilters from "../../../helper/updateReportFilters";
import parseReportFilters from "../../../helper/parseReportFilters";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 100,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 75
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 200
    },
    {
        accessorKey: "Topic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Topic",
        size: 250
    },
    {
        accessorKey: "Subtopic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Subtopic",
        size: 250
    },
    {
        accessorKey: "Sentiment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Sentiment",
        size: 100
    },
    {
        accessorKey: "textfield".toString().replaceAll(" ", "").toLowerCase(),
        header: "Summary",
        size: 400
    }
]
//["Document ID", "MC Group", "Commenter", "Topic", "Subtopic", "Sentiment", "Summary"]

const filterData = (data, topics, docIDs, commenters, mcGroups, filteredTopics, filteredSubtopics, docket) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        // Removing timing from the reports
        tempItem.splice(1, 3)

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[0]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[1]}`))) {
                continue
            }
        }

        if (commenters.length > 0) {
            if (!(commenters.includes(tempItem[2]))) {
                continue
            }
        }

        if (filteredTopics.length > 0) {
            if (!(filteredTopics.includes(tempItem[3]))) {
                continue
            }
        }

        if (filteredSubtopics.length > 0) {
            if (!(filteredSubtopics.includes(tempItem[4]))) {
                continue
            }
        }

        if (topics !== {}) {
            try {
                tempItem[3] = topics[tempItem[3]]["name"]
            } catch (e) {

            }
        }

        tempItem[5] = maskSentimentValues(tempItem[5], tempItem[1], undefined, docket)
        tempItem[1] = MC_TITLES[tempItem[1]]
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

const downloadSummaryReport = (rawReportData, docket) => {

    let reportData = []

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem[8] = maskSentimentValues(tempItem[8], tempItem[4], undefined, docket)
        tempItem[4] = MC_TITLES[tempItem[4]]

        tempItem.splice(1, 0, `${docket}-${tempItem[0]}`)
        tempItem.splice(2, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[0]}`)

        reportData.push(tempItem)
    }

    reportData = sortDocIDsTo5Digits(reportData, 0)

    let tempHeadings = [...headings]

    tempHeadings.splice(1, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(2, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    tempHeadings.splice(3, 0, {
        accessorKey: "posted_date",
        header: "Posted Date"
    })

    tempHeadings.splice(4, 0, {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "completed_date",
        header: "Completed Date"
    })

    downloadReportData(tempHeadings, reportData, "summary_report")
}

export const getNonRiderTopicValueLabelPair = (topicsDict) => {
    let temp = []

    for (let key of Object.keys(topicsDict)) {
        if (!(topicsDict[key]["isRider"])) {
            temp.push({ value: key, label: topicsDict[key].name ?? key })
        }
    }

    return temp
}

const SummaryReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const [loading, setLoading] = React.useState(false)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const storedFilters = useSelector((state) => state.reports.summaryReport);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const dispatch = useDispatch()

    const uniqueDocumentIDs = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const uniqueCommenters = React.useMemo(() => getUniqueColumnValues(rawReportData, 5), [rawReportData])
    const allTopics = React.useMemo(() => getNonRiderTopicValueLabelPair(docketTopics), [docketTopics])
    const allSubtopics = React.useMemo(() => getSubtopicValueLabelPair(docketTopics, parseReportFilters('topic', storedFilters)), [docketTopics, storedFilters])

    const updateFilter = (key, value) => {

        updateReportFilters(dispatch, setReportFilters, "summaryReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 7).then(r => {
            setLoading(false)
            setRawReportData(r)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "summaryReport" }))
    }

    const filteredData = React.useMemo(() =>
        filterData(rawReportData, docketTopics,
            parseReportFilters('docID', storedFilters), parseReportFilters('commenter', storedFilters),
            parseReportFilters('mcGroups', storedFilters),
            parseReportFilters('topic', storedFilters), parseReportFilters('subtopic', storedFilters),
            docket),
        [rawReportData, docketTopics, storedFilters])
    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"15vw"}
                    selected={parseReportFilters('docID', storedFilters)}
                    setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs} />
                <MultiSelectFilter title={"Commenter"} width={"15vw"}
                    selected={parseReportFilters('commenter', storedFilters)}
                    setSelected={(values) => updateFilter('commenter', values)}
                    options={uniqueCommenters} />
                <MultiSelectFilter title={"MC Group"} width={"10vw"}
                    selected={parseReportFilters('mcGroups', storedFilters)}
                    setSelected={(values) => updateFilter('mcGroups', values)}
                    options={MajorCommenterReportFilterData_onlyMCs} />

                <MultiSelectFilter title={"Topic"} width={"15vw"} selected={parseReportFilters('topic', storedFilters)}
                    setSelected={(values) => updateFilter('topic', values)} options={allTopics} />
                <MultiSelectFilter title={"Subtopic"} width={"15vw"}
                    selected={parseReportFilters('subtopic', storedFilters)}
                    setSelected={(values) => updateFilter('subtopic', values)} options={allSubtopics}
                    noOptionsText={parseReportFilters('topic', storedFilters).length === 0
                        ? "No Topics Selected"
                        : "No Subtopics for Selected Topics"}
                />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "summaryReport" }))} />
            </div>
            <div className={styles.title}>
                <p>MC Summary Report <span style={{ marginLeft: 16 }}>({uniqueCommentCount} comments shown)</span></p>
                <button onClick={() => downloadSummaryReport(rawReportData, docket)}>
                    <DownloadIcon />
                </button>
            </div>
            <div className={styles.table}>
                {
                    loading ? <Loading /> :
                        <GenericTable data={filteredData}
                            headingsList={finalHeadings} allowExpansionPanel={true} />
                }

            </div>
        </div>
    );
};

export default SummaryReport;