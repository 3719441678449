import React from 'react';
import styles from './UserEmulation.module.css'
import {setAuthToken, setDisplayName, setUsername as reducerSetUsername} from "../../store/userSlice";
import {adminEmulateUserAPI} from "../../api/usersAPI";
import {useDispatch, useSelector} from "react-redux";

const UserEmulation = () => {

    const dispatch = useDispatch();
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);

    const [username, setUsername] = React.useState("");

    const emulateUser = () => {
        adminEmulateUserAPI(jwt, docket, username).then(r => {
            if (r.success) {
                dispatch(setAuthToken(r.data.jwt));
                dispatch(reducerSetUsername(r.data.username));
                dispatch(setDisplayName(r.data.displayName));
            }
        })
    }

    return (
        <div className={styles.wrapper}>
            <h4 className={styles.header}>User to Emulate:</h4>
            <input value={username} onChange={(e) => setUsername(e.currentTarget.value)} type={"text"}/>
            <button onClick={emulateUser}>Emulate User</button>
        </div>
    );
};

export default UserEmulation;