export const downloadHeaders = [
    {
        accessorKey: "tracking",
        header: "Tracking Number"
    },
    {
        accessorKey: "docid",
        header: "Document ID"
    },
    {
        accessorKey: "finalID",
        header: "Final ID"
    },
    {
        accessorKey: "commenter",
        header: "Commenter"
    },
    {
        accessorKey: "mcstatus",
        header: "MC Status"
    },
    {
        accessorKey: "queuestatus",
        header: "Queue Status"
    },
    {
        accessorKey: "formletter",
        header: "Form Letter"
    },
    {
        accessorKey: "pr",
        header: "Primary Reviewer"
    },
    {
        accessorKey: "qa",
        header: "QA Reviewer"
    }
]

export const timingHeaders = [
    {
        accessorKey: "tracking",
        header: "Tracking Number"
    },
    {
        accessorKey: "docid",
        header: "Document ID"
    },
    {
        accessorKey: "posted_date",
        header: "Posted Date"
    },
    {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    },
    {
        accessorKey: "pr_complete",
        header: "Primary Review Complete"
    },
    {
        accessorKey: "qa_complete",
        header: "QA Review Complete"
    },
    {
        accessorKey: "fl_approved",
        header: "Form Letter Reviewed"
    },
    {
        accessorKey: "triageComplete",
        header: "Triage Complete"
    }
]