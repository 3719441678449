import React from 'react';
import styles from "./reviewIcon.module.css"

const ReviewIcon = ({
                        size = 24
                    }) => {

    let iconStr = "/assets/imgs/history.svg"


    return (
        <img className={styles.icon} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default ReviewIcon;