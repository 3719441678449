import {useEffect} from 'react';

const useEscape = (onEscapeFunction) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onEscapeFunction();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onEscapeFunction]);
}

export default useEscape