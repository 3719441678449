import React from 'react';
import styles from "./Duplicates.module.css"
import DuplicateDisplay from "./Components/DuplicateDisplay";
import ApproveIcon from "../../../General/icons/approveIcon";
import RejectIcon from "../../../General/icons/rejectIcon";
import SelectDuplicate from "./Components/SelectDuplicate";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../General/icons/chevronIcon";
import InfoIcon from "../../../General/icons/infoIcon";
import DuplicateHelp from "./Components/DuplicateHelp";
import {approveDuplicateAPI, denyDuplicateAPI, getDuplicateQueueAPI} from "../../../../api/duplicateAPI";
import {useSelector} from "react-redux";
import Loading from "../../../General/loading/loading";
import DiffDisplay from "../../../General/DiffDisplay/DiffDisplay";

const Duplicates = () => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [duplicateList, setDuplicateList] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const [helpExpanded, setHelpExpanded] = React.useState(false);
    const [showDiff, setShowDiff] = React.useState(false);

    const commentData = duplicateList[selectedIndex];

    const updateSelected = (idx) => {
        setShowDiff(false)
        setSelectedIndex(idx)
    }

    const removeDuplicateFromList = () => {
        let temp = []

        for (let i = 0; i < duplicateList.length; i++) {
            if (i === selectedIndex) {
                continue
            }

            temp.push(duplicateList[i])
        }

        setDuplicateList([...temp])
        setSelectedIndex(0)
    }

    const acceptDuplicate = () => {
        approveDuplicateAPI(jwt, docket,
            commentData[0]["tracking"], commentData[0]["attach"],
            commentData[1]["tracking"], commentData[1]["attach"]).then(r => {
            if (r.success) {
                removeDuplicateFromList()
            }
        })
    }

    const rejectDuplicate = () => {
        denyDuplicateAPI(jwt, docket,
            commentData[0]["tracking"], commentData[0]["attach"],
            commentData[1]["tracking"], commentData[1]["attach"]).then(r => {
            if (r.success) {
                removeDuplicateFromList()
            }
        })
    }

    React.useEffect(() => {
        setLoading(true)
        getDuplicateQueueAPI(jwt, docket).then(r => {
            setLoading(false)
            if (r.success) {
                setDuplicateList(r.data)
            }
        })
    }, [])

    let flagCommenter;
    let flagAttachmentCount;
    const duplicateCount = duplicateList.length;
    if (duplicateList.length < 1) {
        // Do nothing
    } else {
        flagCommenter = commentData[0].commenter !== commentData[1].commenter
        flagAttachmentCount = commentData[0].attachCount !== commentData[1].attachCount
    }

    return (
        <div className={styles.parent}>
            <div className={styles.selector}>
                {
                    loading &&
                    <Loading/>
                }
                {
                    duplicateCount >= 1 &&
                    <SelectDuplicate comments={duplicateList} selected={selectedIndex} setSelected={updateSelected}/>
                }
            </div>

            <div className={styles.verticalDivider}/>

            <div className={styles.duplicates}>

                <div className={styles.displays}>
                    {
                        duplicateCount >= 1 && !showDiff &&
                        <>
                            <DuplicateDisplay
                                trackingNumber={commentData[0]["tracking"]} documentID={commentData[0]["docID"]}
                                attachmentSeq={commentData[0]["attach"]} commenter={commentData[0]["commenter"]}
                                attachCount={commentData[0]["attachCount"]} status={commentData[0]['status']}
                                flagCommenter={flagCommenter} flagAttachCount={flagAttachmentCount}
                            />
                            <DuplicateDisplay
                                trackingNumber={commentData[1]["tracking"]} documentID={commentData[1]["docID"]}
                                attachmentSeq={commentData[1]["attach"]} commenter={commentData[1]["commenter"]}
                                attachCount={commentData[1]["attachCount"]} status={commentData[1]['status']}
                                flagCommenter={flagCommenter} flagAttachCount={flagAttachmentCount}
                                score={commentData[1]["score"]}
                            />
                        </>
                    }
                    {
                        duplicateCount >= 1 && showDiff &&
                        <div className={styles.column}
                             style={{border: "1px solid black", boxSizing: "border-box", padding: 8}}>
                            <DiffDisplay trackingNum1={commentData[0]["tracking"]}
                                         attachment1={commentData[0]["attach"]}
                                         trackingNum2={commentData[1]["tracking"]}
                                         attachment2={commentData[1]["attach"]}
                                         mainDocumentName={"Comment #1"} secondDocumentName={"Comment #2"}
                                         showSimilarities={false}
                            />
                        </div>
                    }
                </div>

                <div className={styles.controls}>
                    <button className={styles.controlButton} onClick={() => setShowDiff(!showDiff)} style={{
                        backgroundColor: "#baddf1",
                        color: "#000000"
                    }}>Show {showDiff ? "PDF" : "Text Difference"} Display
                    </button>
                    <div className={styles.decisionWrapper}>
                        <button className={`${styles.controlButton} ${styles.darkOnHover}`} onClick={rejectDuplicate}
                                style={{backgroundColor: "#D13A3A"}}>Reject Duplicate <RejectIcon size={24}
                                                                                                  invert={true}/>
                        </button>
                        <button className={`${styles.controlButton} ${styles.darkOnHover}`} onClick={acceptDuplicate}
                                style={{backgroundColor: "#44B54D"}}>Accept Duplicate <ApproveIcon size={24}
                                                                                                   invert={true}/>
                        </button>
                    </div>
                </div>
            </div>

            <div className={`${styles.help} ${helpExpanded ? styles.help_expanded : ""}`}>
                <div className={styles.helpControls}>
                    <button onClick={() => setHelpExpanded(!helpExpanded)}>
                        <ChevronIcon size={32}
                                     direction={helpExpanded ? CHEVRON_DIRECTIONS.RIGHT : CHEVRON_DIRECTIONS.LEFT}/>
                        <InfoIcon size={24}/>
                    </button>
                </div>
                <DuplicateHelp show={helpExpanded}/>
            </div>


        </div>
    );
};


export default Duplicates;