import React from 'react';
import styles from './DocketAdministration.module.css'
import AdminChangeRequests from "./ChangeRequests/AdminChangeRequests";
import WriteIcon from "../General/icons/writeIcon";
import SweepIcon from "../General/icons/sweepIcon";
import LoginIcon from "../General/icons/loginIcon";
import ReplaceIcon from "../General/icons/replaceIcon";
import ReviewIcon from "../General/icons/reviewIcon";
import ValidateIcon from "../General/icons/validateIcon";
import SummaryOfSummariesModal from "./modals/summaryOfSummariesModal";
import ReplacePDFModal from "./modals/replacePDFModal"
import ViewerModeModal from './modals/viewerModeModal';
import { modalClose, modalOpen } from "../../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import ValidateLoadedComments from './modals/validateCommentsModal';
import ClearCacheModal from './modals/cacheClearModal';
import ChevronIcon, { CHEVRON_DIRECTIONS } from "../General/icons/chevronIcon";
import MCTableEntryModal from './modals/MCTableEntryModal';
import KWTableEntryModal from './modals/KWTableEntryModal';
import MCTable from './Tables/MCTable';
import KeywordTable from './Tables/KeywordTable';
const MODALS = {
    NONE: null,
    TOP_LEVEL_SUMMARIES: "TOP_LEVEL_SUMMARIES",
    REPLACE_PDF: "REPLACE_PDF",
    VALIDATE_LOADED_COMMENTS: "VALIDATE_LOADED_COMMENTS",
    VIEWER_MODE: "VIEWER_MODE",
    CACHE_CLEAR: "CACHE_CLEAR",
    ADD_MC_TABLE_ENTRY: "ADD_MC_TABLE_ENTRY",
    ADD_KW_TABLE_ENTRY: "ADD_KW_TABLE_ENTRY"
}

const rightBorder = {
    borderRight: '2px solid #aaaaaa'
}

const docketTables = ["mcTable", "keywordTable"]

const DocketAdministration = () => {
    const [modalControl, setModalControl] = React.useState("");
    const [filteredMCTableData, setFilteredMCTableData] = React.useState([]);
    const [filteredKWTableData, setFilteredKWTableData] = React.useState([]);
    const [tableIndex, setTableIndex] = React.useState(0)
    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const dispatch = useDispatch();

    const openModal = (modalID) => {
        setModalControl(modalID)
        dispatch(modalOpen())
    }

    const closeAllModals = () => {
        setModalControl(MODALS.NONE)
        dispatch(modalClose())
    }
    const incrementTable = () => {
        if (tableIndex === docketTables.length - 1) {
            return
        }
        setTableIndex(tableIndex + 1);
    }
    const decrementTable = () => {
        if (tableIndex === 0) {
            return
        }
        setTableIndex(tableIndex - 1);
    }
    return (
        <div className={styles.parent}>

            <div className={styles.pipeline}>
                <p>Pipeline tracking in future update</p>
            </div>

            <div className={styles.buttons}>
                <button disabled={true}>
                    <ReviewIcon size={64} />
                    View Pipeline History
                </button>
                <button onClick={() => openModal(MODALS.VALIDATE_LOADED_COMMENTS)}>
                    <ValidateIcon size={64} />
                    Validate Loaded Comments
                </button>
                <button onClick={() => openModal(MODALS.REPLACE_PDF)}>
                    <ReplaceIcon size={64} />
                    Replace PDF
                </button>
                <button onClick={() => openModal(MODALS.VIEWER_MODE)}>
                    <LoginIcon size={64} />
                    Enter Viewer Mode
                </button>
                <button onClick={() => openModal(MODALS.CACHE_CLEAR)}>
                    <SweepIcon size={64}/>
                    Clear Cached Data (Beta)
                </button>
                <button onClick={() => openModal(MODALS.TOP_LEVEL_SUMMARIES)}>
                    <WriteIcon size={64} />
                    AI Top-Level Summaries
                </button>
            </div>

            <div className={styles.tables}>
                <div className={styles.buttonsContainer}>
                {docketTables[tableIndex] === "mcTable" &&<h2>Major Commenter List</h2>}
                {docketTables[tableIndex] === "keywordTable" &&<h2>Keyword List</h2>}
                    <button className={styles.button} 
                    onClick={() => openModal(docketTables[tableIndex] === "mcTable" ? MODALS.ADD_MC_TABLE_ENTRY : MODALS.ADD_KW_TABLE_ENTRY)}>
                        Add New</button>
                    <div onClick={decrementTable}><ChevronIcon direction={CHEVRON_DIRECTIONS.LEFT} disabled={tableIndex===0}/></div>
                    <div onClick={incrementTable} ><ChevronIcon direction={CHEVRON_DIRECTIONS.RIGHT} disabled={tableIndex===docketTables.length-1}/></div>
                </div>
                {docketTables[tableIndex] === "mcTable" &&
                   <MCTable filteredMCTableData={filteredMCTableData} setFilteredMCTableData={setFilteredMCTableData}/>
                }
                {docketTables[tableIndex] === "keywordTable" && 
                    <KeywordTable filteredKWTableData={filteredKWTableData} setFilteredKWTableData={setFilteredKWTableData}/>
                }
                <AdminChangeRequests />
            </div>

            <SummaryOfSummariesModal open={modalControl === MODALS.TOP_LEVEL_SUMMARIES} closeModal={closeAllModals} />
            <ReplacePDFModal open={modalControl === MODALS.REPLACE_PDF} closeModal={closeAllModals} />
            <ValidateLoadedComments open={modalControl === MODALS.VALIDATE_LOADED_COMMENTS} closeModal={closeAllModals} />
            <ViewerModeModal open={modalControl === MODALS.VIEWER_MODE} closeModal={closeAllModals} />
            <ClearCacheModal open={modalControl === MODALS.CACHE_CLEAR} closeModal={closeAllModals} />
            <MCTableEntryModal open={modalControl === MODALS.ADD_MC_TABLE_ENTRY}
            closeModal={closeAllModals} filteredMCTableData={filteredMCTableData} setFilteredMCTableData={setFilteredMCTableData}/>
            <KWTableEntryModal open={modalControl === MODALS.ADD_KW_TABLE_ENTRY}
            closeModal={closeAllModals} filteredKWTableData={filteredKWTableData}  setFilteredKWTableData={setFilteredKWTableData}/>
            
        </div>
    );
};

export default DocketAdministration;
