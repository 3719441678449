import React from 'react';
import styles from "./components.module.css"

const ChangeFLName = ({name, setName}) => {

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.sectionHeader}>Apply Name to Batch</h3>

            <div className={styles.standardSelect}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    width: '30%',
                    minWidth: 500,
                    flexGrow: 0,
                    flexShrink: 0
                }}>
                    <p className={styles.label}>Enter New Batch Name: </p>
                    <input value={name} className={styles.input} onChange={e => setName(e.currentTarget.value)}
                           type={'text'}/>

                </div>
            </div>
        </div>
    );
};

export default ChangeFLName;