import React from 'react';
import styles from './splashBar.module.css'
import {useSelector} from "react-redux";

const SplashBar = ({tab = "Unknown"}) => {

    const displayName = useSelector((state) => state.user.displayName);
    const docket = useSelector((state) => state.navigation.currentDocket);


    return (
        <div className={styles.wrapper}>
            <p className={styles.docketText}>{docket}, {displayName}</p>
            <p className={styles.tabText}>{tab}</p>
            <div className={styles.logoWrapper}>
                <img onContextMenu={() => {
                    return false;
                }} className={styles.logo} src={"/assets/imgs/logo.jpg"} alt={"InsightsAI Logo"}/>
            </div>
        </div>
    );
};

export default SplashBar;