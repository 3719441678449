import React from 'react';
import loginStyles from './login.module.css'
import LoginWrapper from "./loginWrapper";
import {useDispatch} from "react-redux";
import {LOGIN_STEP} from "../../helper/constants";
import {loginAPI} from "../../api/authAPI";
import {setAuthToken, setDisplayName, setUsername as reducerSetUsername} from "../../store/userSlice";
import useEnter from "../../helper/keypressListeners/enter";
import {setLoginStatus} from "../../store/loginSlice";

const LoginForm = () => {

    const dispatch = useDispatch()

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [signInError, setSignInError] = React.useState(false);

    const login = () => {
        if (username === "" || password === "") {
            setSignInError(true);
            return false;
        }

        loginAPI(username, password).then(r => {
            if (r.success) {
                dispatch(setAuthToken(r.data.jwt));
                dispatch(reducerSetUsername(r.data.username));
                dispatch(setDisplayName(r.data.displayName));
                dispatch(setLoginStatus(LOGIN_STEP.CHOOSE_DOCKET));
            } else {
                setSignInError(true)
            }
        }).catch(e => {
            setSignInError(true)
        });

        return false;
    }

    const forgotPassword = () => {
        dispatch(setLoginStatus(LOGIN_STEP.FORGOT_PASSWORD));
        return false;
    }

    useEnter(login)

    return (
        <LoginWrapper>
            {
                signInError &&
                <p className={loginStyles.errorText}>
                    Username or password is invalid.
                </p>
            }
            <form action="" className={loginStyles.form}>
                <label className={loginStyles.fieldLabel} id="usernameLabel" htmlFor="username">Username:</label>
                <input onChange={e => setUsername(e.currentTarget.value)} name={"username"}
                       id={"username"} className={loginStyles.textField} type={"text"} value={username}/>
                <label className={loginStyles.fieldLabel} id="passwordLabel" htmlFor="password">Password:</label>
                <input autoComplete={"current-password"} onChange={e => setPassword(e.currentTarget.value)}
                       name={"password"}
                       id={"password"} className={loginStyles.textField} type={"password"} value={password}/>
                <button disabled={false} onClick={forgotPassword} type={"button"}
                        className={loginStyles.forgotPasswordButton}>Forgot
                    Password?
                </button>
                <button style={{marginTop: 50}} onClick={login} type={"button"} className={loginStyles.button}>Sign In
                </button>
            </form>
        </LoginWrapper>
    );
};

export default LoginForm;