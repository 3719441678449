const flattenTopicsList = (allTopics) => {

    let topicsList = []

    for (let topic of Object.keys(allTopics)) {
        topicsList.push([[topic, ""], allTopics[topic]["name"]])
        for (let subtopic of allTopics[topic]["children"]) {
            topicsList.push([[topic, subtopic[0]], `${allTopics[topic]["name"]} || ${subtopic[1]}`])
        }
    }

    return topicsList
}

export default flattenTopicsList