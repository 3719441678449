import React from 'react';
import styles from './appWrapper.module.css'
import TabBar from "./General/tabbar/tabbar";
import SettingsWidget from "./UserSettings/settingsWidget";
import { useDispatch, useSelector } from "react-redux";
import { getUserTabs } from "../api/navAPI";
import getPageFromTabTitle from "../helper/getPageFromTabTitle";
import SplashBar from "./General/splashBar/splashBar";
import signOutFunc from "../helper/signOutFunc";
import { getAvailableDocketsMetadataAPI } from "../api/authAPI";
import { setDocketExcerpts, setDocketLinks, setDocketTopics, setDocketTopicsReference } from "../store/docketsSlice";
import { getDocketTopicsAPI } from "../api/docketAPI";
import { getTriageTopicListAPI } from "../api/triageReferenceAPI";


const AppWrapper = () => {

    const [page, setPage] = React.useState(0);
    const [tabs, setTabs] = React.useState([]);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const referredDocument = useSelector((state) => state.navigation.docToView);
    const dispatch = useDispatch()

    const selectedComponent = React.useMemo(() => getPageFromTabTitle(tabs[page]), [tabs, page])

    React.useEffect(() => {
        getUserTabs(jwt, docket).then(r => {

            //Prevents any non-review users from accessing the development version of the tool
            if (!(r.includes('Review'))) {
                if ((window.location.hostname).includes('dev.')) {
                    signOutFunc(dispatch)
                    window.location.replace(`https://${window.location.hostname.split('dev.')[1]}`);
                }

                if ((window.location.hostname).includes('staging.')) {
                    signOutFunc(dispatch)
                    window.location.replace(`https://${window.location.hostname.split('staging.')[1]}`);
                }
            } else {
                // Only get the triage topics list if the person has review permissions
                getTriageTopicListAPI(jwt, docket).then(r => {
                    dispatch(setDocketTopicsReference(r))
                }).catch((e) => dispatch(setDocketTopicsReference({})))
            }

            if (r.length === 0) {
                signOutFunc(dispatch)
            }

            setTabs(r);
            setPage(0);
        })

        getAvailableDocketsMetadataAPI(jwt).then(r => {
            dispatch(setDocketLinks(r.buckets))
            dispatch(setDocketExcerpts(r.excerpts))
        })

        getDocketTopicsAPI(jwt, docket).then(r => dispatch(setDocketTopics(r)))


    }, [jwt, docket])

    React.useEffect(() => {
        if (referredDocument !== undefined && referredDocument !== "") {
            setPage(0)
        }
    }, [referredDocument])

    return (
        <div className={styles.wrapper}>
            <header className={styles.tabbar}>
                <TabBar ariaLabelPrefix={"Page"} tabs={tabs} selectedTabIndex={page}
                    updateTab={(tabIndex) => setPage(tabIndex)} />
            </header>
            <div className={styles.user}>
                <SettingsWidget />
            </div>
            <div className={styles.splashBar}>
                <SplashBar tab={tabs[page]} />
            </div>
            <div className={styles.content}>
                {
                    selectedComponent
                }
            </div>
        </div>
    );
};

export default AppWrapper;