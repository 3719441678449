//Returns a list of form letters in a docket
import {useProductionURL} from "../helper/constants";

export const getFormLettersAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/get_form_letters?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterStatusAPI = async (jwt, docket, batch, status) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/set_status?` + new URLSearchParams({
        docket: docket,
        status: status,
        batch: batch
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterNameAPI = async (jwt, docket, batch, name) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/set_name?` + new URLSearchParams({
        docket: docket,
        name: name,
        batch: batch
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterStandardAPI = async (jwt, docket, batch, tracking, attach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/set_standard?` + new URLSearchParams({
        docket: docket,
        batch: batch,
        tracking: tracking,
        attach: attach
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const removeFromFormLetterAPI = async (jwt, docket, batch, tracking, attach, isMC) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/remove_item?` + new URLSearchParams({
        docket: docket,
        batch: batch,
        tracking: tracking,
        attach: attach,
        mc: isMC
    }), {
        method: "DELETE",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const addToFormLetterAPI = async (jwt, docket, batch, document, attach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/add_item?` + new URLSearchParams({
        docket: docket,
        batch: batch,
        document: document,
        attach: attach
    }), {
        method: "POST",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterViewedAPI = async (jwt, docket, tracking, attach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/mark_as_viewed?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        attach: attach
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterUnViewedAPI = async (jwt, docket, tracking, attach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/mark_as_unviewed?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        attach: attach
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const bulkViewFormLetterAPI = async (jwt, docket, batch, size) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/bulk_view?` + new URLSearchParams({
        docket: docket,
        size: size,
        batch: batch
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const removeMCIdentificationsAPI = async (jwt, docket, batch,) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/remove_major_commenter_identifications?` + new URLSearchParams({
        docket: docket,
        batch: batch,
    }), {
        method: "DELETE",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const mergeFormLetterBucketsAPI = async (jwt, docket, keepBucket, mergeBucket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/merge_buckets?` + new URLSearchParams({
        docket: docket,
        keep: keepBucket,
        merge: mergeBucket
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const rescoreFormLetterBucketsAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/rescore_buckets?` + new URLSearchParams({
        docket: docket
    }), {
        method: "POST",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getNonFLParentAttachmentsAPI = async (jwt, docket, documentID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/get_non_parent_attachments?` + new URLSearchParams({
        docket: docket,
        documentID: documentID
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const createNewBucketAPI = async (jwt, docket, name, standard, children) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/create_new_bucket?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'name': name,
            'docket': docket,
            'children': children,
            'standard': standard
        })
    })

    return await resp.json()
}

export const calculateTempScoreFLAPI = async (jwt, docket, tracking1, attach1, tracking2, attach2) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/calculate_temp_score?` + new URLSearchParams({
        docket: docket,
        tracking1: tracking1,
        attach1: attach1,
        tracking2: tracking2,
        attach2: attach2
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getFormLetterTextFieldsAPI = async (jwt, docket, batch) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/text_fields?` + new URLSearchParams({
        docket: docket,
        batch: batch,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateFormLetterTextFieldAPI = async (jwt, docket, text, batch, category) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/form_letter/text_fields?` + new URLSearchParams({}), {
        method: "PUT",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'text': text,
            'docket': docket,
            'batch': batch,
            'category': category
        })
    })

    return await resp.json()
}