const getHighlightedIndexes = (attachment) => {
    if (window.getSelection) {
        if (attachment !== 0 && attachment !== "0") {
            const selection = window.getSelection();
            const nodesList = []

            // Using matching horizontal positions and page numbers tags to group text into lines
            // Storing vertical positions to format the lines in the next step

            for (let i = 0; i < selection.rangeCount; i++) {
                let range = window.getSelection().getRangeAt(i);
                // Extract nodes with the custom tag within the range
                let nodes = range.cloneContents().querySelectorAll('customtag');

                nodes.forEach(node => {
                    // Extract custom data attributes
                    let pageNum = parseFloat(node.dataset.pageIndex);
                    let pageIndex = parseFloat(node.dataset.index);

                    nodesList.push([pageNum, pageIndex])
                });
            }

            nodesList.sort((a, b) => {
                if (a[0] < b[0]) {
                    return -1
                }

                if (a[0] > b[0]) {
                    return 1
                }

                if (a[1] < b[1]) {
                    return -1
                }

                if (a[1] > b[1]) {
                    return 1
                }

                return 0
            })

            if (nodesList.length < 2) {
                return undefined
            }
            const last = nodesList.length - 1
            return [nodesList[0][0], nodesList[0][1], nodesList[last][0], nodesList[last][1]];
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

export default getHighlightedIndexes;