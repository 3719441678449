/* eslint-disable */

const getHightlightedCommentText = (attachment) => {
    let text = "";
    let groupedLines = {};

    if (window.getSelection) {
        if (attachment !== 0 && attachment !== "0") {
            const selection = window.getSelection();

            // Using matching horizontal positions and page numbers tags to group text into lines
            // Storing vertical positions to format the lines in the next step
            for (let i = 0; i < selection.rangeCount; i++) {
                let range = window.getSelection().getRangeAt(i);
                // Extract nodes with the custom tag within the range
                let nodes = range.cloneContents().querySelectorAll('customtag');

                nodes.forEach(node => {
                    // Extract custom data attributes
                    let horizontalPosition = parseFloat(node.dataset.horizontal);
                    let verticalPosition = parseFloat(node.dataset.vertical);
                    let pageNumber = parseInt(node.dataset.page);

                    // Group lines based on both horizontal position and page number
                    let key = horizontalPosition + '_' + pageNumber;
                    if (!groupedLines[key]) {
                        groupedLines[key] = [];
                    }
                    // Store the extracted text along with vertical position
                    groupedLines[key].push({
                        text: node.textContent,
                        verticalPosition: verticalPosition
                    });
                });
            }

            // Construct lines based on lowest vertical position to the highest
            // Creating a tag with the lowest vertical position for each line showing theoretically were is starts
            Object.keys(groupedLines).forEach(key => {
                let lineText = "";
                let sortedItems = groupedLines[key].sort((a, b) => a.verticalPosition - b.verticalPosition);
                sortedItems.forEach(item => {
                    lineText += item.text;
                });
                text += `<customline data-lowest-vertical="${sortedItems[0].verticalPosition}" data-horizontal="${key.split('_')[0]}" data-page="${key.split('_')[1]}">${lineText}</customline>`;
            });

            // Sort lineText page by page and then the lines within the page by horizontal position
            let sortedLines = text.split('</customline>').map(line => line.trim()).filter(Boolean).sort((a, b) => {
                let pageA = parseInt(a.match(/data-page="([^"]+)"/)[1]);
                let pageB = parseInt(b.match(/data-page="([^"]+)"/)[1]);
                if (pageA !== pageB) {
                    return pageB - pageA; // Sort by page number in descending order
                } else {
                    let posA = parseFloat(a.match(/data-horizontal="([^"]+)"/)[1]);
                    let posB = parseFloat(b.match(/data-horizontal="([^"]+)"/)[1]);
                    return posB - posA; // Within the same page, sort by horizontal position
                }
            });

            // Combine lines of text into combinedText according to the given rules
            // Starts with top line of text and compares it to the next line based on horizontal position
            // Adds the line to a new paragraph (/n/n) if:
            // It is less more than 25 horizontal units away
            // It starts with a bullet point or number
            // The line is 20 characters longer than the first line and has the same vertical starting position
            let combinedText = "";
            for (let i = 0; i < sortedLines.length - 1; i++) {
                combinedText += sortedLines[i].replace(/<[^>]+>/g, ''); // Remove HTML tags before combining
                let posA = parseFloat(sortedLines[i].match(/data-horizontal="([^"]+)"/)[1]);
                let posB = parseFloat(sortedLines[i + 1].match(/data-horizontal="([^"]+)"/)[1]);
                let lowestVerticalA = parseFloat(sortedLines[i].match(/data-lowest-vertical="([^"]+)"/)[1]);
                let lowestVerticalB = parseFloat(sortedLines[i + 1].match(/data-lowest-vertical="([^"]+)"/)[1]);

                let lineB = sortedLines[i + 1].replace(/<[^>]+>/g, '');

                if (Math.abs(posA - posB) < 25) {
                    let lineBStartsWithNumberOrBullet = /^[0-9•]/.test(lineB); // Check if line B starts with a number or bullet point (•)

                    let lengthA = sortedLines[i].replace(/<[^>]+>/g, '').length;
                    let lengthB = sortedLines[i + 1].replace(/<[^>]+>/g, '').length;

                    if ((lineBStartsWithNumberOrBullet || ((lengthB - lengthA) >= 20)) && (lowestVerticalA === lowestVerticalB) || (lengthB < 25 && lengthA < 25)) {
                        combinedText += "\n\n";
                    } else {
                        combinedText += " ";
                    }
                } else {
                    combinedText += "\n\n";
                }
            }

            if (sortedLines.length > 0) {
                // If there are sorted lines, add the last line text without HTML tags to the combinedText
                combinedText += sortedLines[sortedLines.length - 1].replace(/<[^>]+>/g, '');
            }
            // console.log(combinedText);
            return combinedText;
            // If the selection is not an attachment, get the selected text from the original comment
        } else {
            text = window.getSelection().toString();
            // Trim any leading or trailing whitespace
            text = text.trim();
            if (text.length > 0) {
                // console.log(text);
                return text;
            }
        }
    } else {
        // If the window.getSelection method is not available, use document.selection
        if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
            return text;
        }
    }
}

export default getHightlightedCommentText;