import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import {getFormLetterBatches} from "../../../api/reviewsAPI";
import TextDisplay from "../../General/TextDisplay/TextDisplay";
import {mergeFormLetterBucketsAPI, rescoreFormLetterBucketsAPI} from "../../../api/formLetterAPI";
import Loading from "../../General/loading/loading";

const MergeModal = ({open, closeModal}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);

    const [batches, setBatches] = React.useState([])

    const [keepBatch, setKeepBatch] = React.useState(0)
    const [parentToKeep, setParentToKeep] = React.useState(["", 0, ""])

    const [mergeBatch, setMergeBatch] = React.useState(0)
    const [parentToMerge, setParentToMerge] = React.useState(["", 0, ""])

    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false)

    const submitFunction = () => {
        if (window.confirm("Are you sure that you would like to merge these two batches together?")) {
            mergeFormLetterBucketsAPI(jwt, docket, keepBatch, mergeBatch).then(r => {
                if (r.success) {
                    setShowSuccessMessage(true)
                } else {
                    window.alert("There was an error merging the batches together")
                }
            })
        }
    };

    const clearAndCloseModal = () => {
        setKeepBatch(0)
        setParentToKeep(["", 0, ""])
        setMergeBatch(0)
        setParentToMerge(["", 0, ""])

        setShowSuccessMessage(false)

        closeModal()
    }

    const updateKeepBatch = (newBatchID) => {

        if (newBatchID === mergeBatch) {
            return;
        }

        for (let batch of batches) {
            // eslint-disable-next-line eqeqeq
            if (batch.batchID == newBatchID) {
                setKeepBatch(newBatchID)
                setParentToKeep([...batch.parent])
                break;
            }
        }
    }

    const updateMergeBatch = (newBatchID) => {

        if (newBatchID === keepBatch) {
            return;
        }

        for (let batch of batches) {
            // eslint-disable-next-line eqeqeq
            if (batch.batchID == newBatchID) {
                setMergeBatch(newBatchID)
                setParentToMerge([...batch.parent])
                break;
            }
        }
    }

    useEscape(clearAndCloseModal)

    React.useEffect(() => {

        if (!open) {
            return;
        }

        getFormLetterBatches(jwt, docket).then(r => {
            if (r.success) {
                console.log(r.data)
                setBatches(r.data)

                if (r.data.length > 1) {
                    setKeepBatch(r.data[0].batchID)
                    setParentToKeep(r.data[0].parent)
                    setMergeBatch(r.data[1].batchID)
                    setParentToMerge(r.data[1].parent)
                } else {
                    setKeepBatch(0)
                    setParentToKeep(["", 0, ""])
                    setMergeBatch(0)
                    setParentToMerge(["", 0, ""])
                }
            }
        })
    }, [jwt, docket, open])

    React.useEffect(() => {
        if (showSuccessMessage) {
            rescoreFormLetterBucketsAPI(jwt, docket).then(r => {
                if (r.success) {
                    clearAndCloseModal()
                } else {
                    window.alert("There was an error rescoring the batch; however, the merge was successful.")
                }
            })
        }
    }, [showSuccessMessage])

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={() => {
        }}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div onClick={(event) => {
                event.stopPropagation();
            }} className={styles.largeContent}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Merge Form Letters&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Merge Form Letters</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    !showSuccessMessage ?
                        <React.Fragment>
                            <div className={styles.formLetterWrapper}>
                                <div className={styles.textDisplayWrapper}>
                                    <p className={styles.header}>Form Letter to Keep:</p>
                                    <div className={styles.horizontalBar}/>

                                    <select value={keepBatch} onChange={e => updateKeepBatch(e.currentTarget.value)}
                                            name="inProgress" id="inProgress">
                                        {
                                            batches.map(batch => (
                                                batch.batchID !== mergeBatch && <option key={batch.batchID}
                                                                                        value={batch.batchID}>{batch.name === "" ? `Form Letter #${batch.batchID}` : batch.name}</option>
                                            ))
                                        }
                                    </select>
                                    <div className={styles.textDisplay}>
                                        <TextDisplay documentID={parentToKeep[0]} attachment={parentToKeep[1]}
                                                     trackingNum={parentToKeep[2]}/>
                                    </div>
                                </div>
                                <div className={styles.textDisplayWrapper}>
                                    <p className={styles.header}>Form Letter to Merge:</p>
                                    <div className={styles.horizontalBar}/>

                                    <select value={mergeBatch} onChange={e => updateMergeBatch(e.currentTarget.value)}
                                            name="inProgress" id="inProgress">
                                        {
                                            batches.map(batch => (
                                                batch.batchID !== keepBatch && <option key={batch.batchID}
                                                                                       value={batch.batchID}>{batch.name === "" ? `Form Letter #${batch.batchID}` : batch.name}</option>
                                            ))
                                        }
                                    </select>
                                    <div className={styles.textDisplay}>
                                        <TextDisplay documentID={parentToMerge[0]} attachment={parentToMerge[1]}
                                                     trackingNum={parentToMerge[2]}/>
                                    </div>
                                </div>
                            </div>
                            <button className={styles.submitButton} onClick={submitFunction}>Merge Batch</button>
                        </React.Fragment> :
                        <React.Fragment>
                            <div className={styles.successWrapper}>
                                <p className={styles.successMessage}>Successfully Merged Batch!</p>
                                <p className={styles.successMessage}>Batch is currently rescoring (est. time: under 5
                                    minutes)... </p>
                                <p className={styles.textLabel}> Modal can be closed to run rescoring in background
                                    OR </p>
                                <p style={{marginBottom: 64}} className={styles.textLabel}>This popup will close
                                    automatically when finished.</p>
                                <Loading/>
                            </div>
                        </React.Fragment>
                }

            </div>
        </div>,
        document.body
    );
};

export default MergeModal;