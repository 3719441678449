import React from 'react';
import styles from './WorkflowAdministration.module.css'
import StatsCard from "./StatsCard";
import ReviewerCard from "./ReviewerCard";
import {useSelector} from "react-redux";
import {getAllCommentInfoAPI, getAllTrackingInfoAPI, getReviewersAPI, postChangeReviewerAPI} from "../../api/adminAPI";
import CommentsTable from "./CommentsTable";
import Loading from "../General/loading/loading";

const sortFunc = (a, b) => {
    let valueA = a[1] ?? "zzz";
    let valueB = b[1] ?? "zzz";

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }

    return 0;
}

const getUniqueReviewersList = (PR, QA) => {
    let combined = [];
    let ids = [];

    for (let user of PR) {
        if (ids.indexOf(user[0]) === -1) {
            combined.push(user)
            ids.push(user[0])
        }
    }

    for (let user of QA) {
        if (ids.indexOf(user[0]) === -1) {
            combined.push(user)
            ids.push(user[0])
        }
    }

    combined.sort(sortFunc)

    return combined
}

const getQueues = (commentsList) => {

    let prQueue = [];
    let prActive = [];
    let prPaused = [];
    let qaQueue = [];
    let qaActive = [];
    let qaPaused = [];

    let finished = 0;
    for (let comment of commentsList) {
        const state = comment[5]
        const inFL = comment[6]

        if (inFL) {
            continue
        }

        if (state === "In Queue [PR]") {
            prQueue.push(comment)
        } else if (state === "In Progress [PR]") {
            prActive.push(comment)
        } else if (state === "Paused [PR]") {
            prPaused.push(comment)
        } else if (state === "In Queue [QA]") {
            qaQueue.push(comment)
        } else if (state === "In Progress [QA]") {
            qaActive.push(comment)
        } else if (state === "Paused [QA]") {
            qaPaused.push(comment)
        } else if (state === "Completed") {
            finished += 1
        }
    }
    return [prQueue, prActive, prPaused, qaQueue, qaActive, qaPaused, finished]
}

const getReviewerLevelStats = (commentsList, reviewers) => {

    let reviewersDict = {}

    for (let comment of commentsList) {
        const primaryReviewer = comment[7]
        const qaReviewer = comment[8]
        const state = comment[5]
        const draftID = comment[1]
        const inFL = comment[6]

        if (inFL) {
            continue
        }

        //Make sure to have reviewer in dict
        if (!(primaryReviewer == null)) {
            if (!(primaryReviewer in reviewersDict)) {
                reviewersDict[primaryReviewer] = {
                    primary: [],
                    qa: []
                }
            }
        }

        //Make sure to have reviewer in dict
        if (!(qaReviewer == null)) {
            if (!(qaReviewer in reviewersDict)) {
                reviewersDict[qaReviewer] = {
                    primary: [],
                    qa: []
                }
            }
        }

        if (state === "In Progress [QA]" || state === "Paused [QA]") {
            if (!(qaReviewer == null)) {
                reviewersDict[qaReviewer].qa.push(draftID)
            }
        } else if (state === "In Progress [PR]" || state === "Paused [PR]") {
            if (!(primaryReviewer == null)) {
                reviewersDict[primaryReviewer].primary.push(draftID)
            }
        }
    }

    return reviewersDict
}

const WorkflowAdministration = () => {

    const [primaryReviewers, setPrimaryReviewers] = React.useState([])
    const [qaReviewers, setQAReviewers] = React.useState([]);
    const reviewers = React.useMemo(() => getUniqueReviewersList(primaryReviewers, qaReviewers), [primaryReviewers, qaReviewers])

    const [allComments, setAllComments] = React.useState([])
    const [allCommentTracking, setAllCommentTracking] = React.useState({})
    const [waitingPRQueue, activePRQueue, pausedPRQueue, waitingQAQueue, activeQAQueue, pausedQAQueue, reviewFinishedCount] = React.useMemo(() => getQueues(allComments), [allComments])
    const reviewerQueues = React.useMemo(() => getReviewerLevelStats(allComments, reviewers), [allComments, reviewers])

    const [loading, setLoading] = React.useState(false);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const changeReviewer = (trackingNumber, isPR, newReviewer) => {
        //window.alert("Trying to change reviewers")

        let temp = []

        for (let comment of allComments) {
            if (comment[0] === trackingNumber) {
                if (isPR) {
                    if (newReviewer === "") {
                        comment[7] = null
                        comment[8] = null
                        comment[5] = "In Queue [PR]"
                    } else {
                        comment[7] = parseInt(newReviewer)
                        comment[8] = null
                        comment[5] = "Paused [PR]"
                    }
                } else {
                    if (newReviewer === "") {
                        comment[8] = null
                        comment[5] = "In Queue [QA]"
                    } else {
                        comment[8] = parseInt(newReviewer)
                        comment[5] = "Paused [QA]"
                    }
                }
            }

            temp.push(comment)
        }

        setAllComments(temp)
        postChangeReviewerAPI(jwt, docket, trackingNumber, newReviewer, isPR);
    }

    React.useEffect(() => {
        getReviewersAPI(jwt, docket).then(r => {
            setPrimaryReviewers((r[0]).sort(sortFunc))
            setQAReviewers((r[1]).sort(sortFunc))
        })

        setLoading(true)
        getAllCommentInfoAPI(jwt, docket).then(r => {
            setAllComments(r)
            getAllTrackingInfoAPI(jwt, docket).then(r => {
                if (r.success) {
                    setAllCommentTracking(r.data)
                }
                setLoading(false)
            }).catch(e => setLoading(false))
        })
    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            <div className={styles.statsCard}>
                {
                    loading ? <LoadingWorkflowCard/> :
                        <StatsCard
                            primaryQueue={waitingPRQueue} primaryActive={activePRQueue} primaryPaused={pausedPRQueue}
                            qaQueue={waitingQAQueue} qaActive={activeQAQueue} qaPaused={pausedQAQueue}
                            finished={reviewFinishedCount}/>
                }
            </div>
            <div className={styles.reviewerCard}>
                {
                    loading ? <LoadingWorkflowCard/> :
                        <ReviewerCard reviewers={reviewers} queues={reviewerQueues}/>
                }
            </div>
            <div className={styles.table}>
                {
                    loading ? <LoadingWorkflowCard/> :
                        <CommentsTable commentsList={allComments} primaryReviewers={primaryReviewers}
                                       qaReviewers={qaReviewers} timingStats={allCommentTracking}
                                       allReviewers={reviewers} changeReviewer={changeReviewer}/>
                }
            </div>
        </div>
    );
};

const LoadingWorkflowCard = () => {
    return (
        <div className={styles.loadingWrapper}>
            <Loading/>
        </div>
    )
}

export default WorkflowAdministration;