import React from 'react';
import styles from "./SelectDuplicate.module.css"

const SelectDuplicate = ({comments, selected, setSelected}) => {
    return (
        <div className={styles.wrapper}>
            {
                comments.map((element, index) => (
                    <div
                        style={{backgroundColor: index === selected ? '#baf2ff' : '#f3f3f3'}}
                        className={styles.contentBox}
                        onClick={() => setSelected(index)}
                    >
                        <p>{element[0]['docID']}</p>
                        <p>{element[0]['commenter']}</p>
                        <div
                            style={{backgroundColor: index === selected ? '#555555' : '#dddddd'}}
                            className={styles.divider}
                        />
                        <p>{element[1]['docID']}</p>
                        <p>{element[1]['commenter']}</p>

                    </div>
                ))
            }
        </div>
    );
};

export default SelectDuplicate;