import React from 'react';
import styles from './CreateExcerptModal.module.css'
import {createPortal} from "react-dom";
import useEscape from "../../../../helper/keypressListeners/escape";
import TopicCard from "../../topicCard/topicCard";
import cleanUpHTMLTags from "../../../../helper/cleanUpHTMLTags";
import updateTopicsDictionary from "../../../../helper/updateTopicsDictionary";
import Loading from "../../loading/loading";
import ExcerptTopicReference from "./ExcerptTopicReference/ExcerptTopicReference";

const CreateExcerptModal = ({isOpen, closeModal, data, onMouseOver, submitFunc}) => {

    const [selectedTopics, setSelectedTopics] = React.useState({})
    const [submitting, setSubmitting] = React.useState(false);

    const [topicReferenceInformation, setTopicReferenceInformation] = React.useState({
        isOpen: false,
        topic: null,
        subtopic: null
    })

    const updateTopicReferenceInformation = (topic, subtopic) => {
        setTopicReferenceInformation({isOpen: true, topic: topic, subtopic: subtopic})
    }

    const closeTopicReferenceInformation = () => {
        setTopicReferenceInformation({isOpen: false, topic: null, subtopic: null})
    }

    const clearAndCloseModal = () => {
        closeTopicReferenceInformation()
        closeModal()
    }

    const updateSelectedTopics = (topic, subtopic, sentiment) => {
        let resp = updateTopicsDictionary(topic, subtopic, sentiment, selectedTopics);
        setSelectedTopics(resp)
    }

    const submitExcerpts = () => {
        let provMap = []

        for (let topic of Object.keys(selectedTopics)) {
            provMap.push([topic, ''])
            for (let subtopic of Object.keys(selectedTopics[topic].children)) {
                provMap.push([topic, subtopic])
            }
        }

        submitFunc(data.text, data.indexes, provMap)
        setSubmitting(true)
    }


    useEscape(clearAndCloseModal)

    React.useEffect(() => {
        setSelectedTopics({})
        setSubmitting(false)
    }, [isOpen])

    if (data === null || data === undefined) {
        return null;
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: isOpen ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!isOpen} onClick={clearAndCloseModal} onMouseOver={onMouseOver}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Create Excerpt&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Create Excerpt</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>

                {
                    submitting ?

                        <div className={styles.loading_wrapper}>
                            <p>Saving new excerpt...</p>
                            <Loading/>
                        </div> :

                        <div className={styles.inside_wrapper}>

                            <div className={styles.raw_text_display}>
                                {
                                    cleanUpHTMLTags(data.text).split("\n").map(
                                        (textChunk, idx) => (
                                            <p key={idx}>{textChunk}</p>
                                        )
                                    )
                                }
                            </div>
                            <div className={styles.topicsSection}>
                                <div className={styles.topicsWrapper} style={{display: "flex", flexDirection: "column"}}>
                                    <TopicCard useReferenceHelp={'custom'} selectedTopics={selectedTopics}
                                               isPaused={false} riderShow={'none'}
                                               useSentiment={false} toggleSelectedTopicFunc={updateSelectedTopics}
                                               customReferenceFunction={updateTopicReferenceInformation}/>
                                </div>
                                <button onClick={submitExcerpts} className={styles.submitButton}>Save Excerpt</button>
                            </div>
                            <div style={{
                                width: topicReferenceInformation.isOpen ? 500 : 0,
                                border: topicReferenceInformation.isOpen ? '1px solid grey' : 'none'
                            }}
                                 className={styles.referenceSection}>
                                <ExcerptTopicReference open={topicReferenceInformation.isOpen}
                                                       closeFunction={closeTopicReferenceInformation}
                                                       topic={topicReferenceInformation.topic}
                                                       subtopic={topicReferenceInformation.subtopic}
                                />
                            </div>
                        </div>
                }
            </div>
        </div>,
        document.body
    );
};


export default CreateExcerptModal;