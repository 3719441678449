import React from 'react';
import styles from './StatsCard.module.css';
import StatsInProgressModal from "./StatsInProgressModal";
import {modalClose, modalOpen} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import {setDocToView} from "../../store/navSlice";

const StatsCard = ({primaryQueue, primaryActive, primaryPaused, qaQueue, qaActive, qaPaused, finished}) => {
    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [list, setList] = React.useState([])
    const [title, setTitle] = React.useState("")

    const closeModal = () => {
        dispatch(modalClose())
        setIsModalOpen(false);
        setList([])
        setTitle("")
    }

    const getDataFromTitle = (title) => {
        if (title === 'Primary Queue') {
            return primaryQueue
        }

        if (title === 'Primary In Progress') {
            return primaryActive
        }

        if (title === 'Primary Paused') {
            return primaryPaused
        }

        if (title === 'QA Queue') {
            return qaQueue
        }

        if (title === 'QA In Progress') {
            return qaActive
        }

        if (title === 'QA Paused') {
            return qaPaused
        }

        return []
    }

    const openModal = (title) => {
        dispatch(modalOpen())
        setIsModalOpen(true)
        setList(getDataFromTitle(title))
        setTitle(title)
    }

    const goToDocument = (doc) => {
        dispatch(setDocToView(doc))
    }

    return (
        <div className={styles.wrapper}>
            <table style={{width: "100%", height: '80%'}}>
                <thead>
                <tr>
                    <th style={{width: "13%"}}></th>
                    <th className={styles.queueTitleText} style={{width: "29%"}}>In Queue</th>
                    <th className={styles.queueTitleText} style={{width: "29%"}}>In Progress</th>
                    <th className={styles.queueTitleText} style={{width: "29%"}}>Paused</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={styles.queueTitleText}>PR</td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                primaryQueue.length > 0
                                    ?
                                    <button onClick={() => openModal('Primary Queue')}
                                            className={styles.queueTitleButton}>
                                        {primaryQueue.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{primaryQueue.length}</p>
                            }
                        </div>
                    </td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                primaryActive.length > 0
                                    ?
                                    <button onClick={() => openModal('Primary In Progress')}
                                            className={styles.queueTitleButton}>
                                        {primaryActive.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{primaryActive.length}</p>
                            }
                        </div>
                    </td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                primaryPaused.length > 0
                                    ?
                                    <button onClick={() => openModal('Primary Paused')}
                                            className={styles.queueTitleButton}>
                                        {primaryPaused.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{primaryPaused.length}</p>
                            }
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={styles.queueTitleText}>QA</td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                qaQueue.length > 0
                                    ?
                                    <button onClick={() => openModal('QA Queue')}
                                            className={styles.queueTitleButton}>
                                        {qaQueue.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{qaQueue.length}</p>
                            }
                        </div>
                    </td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                qaActive.length > 0
                                    ?
                                    <button onClick={() => openModal('QA In Progress')}
                                            className={styles.queueTitleButton}>
                                        {qaActive.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{qaActive.length}</p>
                            }
                        </div>
                    </td>
                    <td>
                        <div className={styles.tdWrapper}>
                            {
                                qaPaused.length > 0
                                    ?
                                    <button onClick={() => openModal('QA Paused')}
                                            className={styles.queueTitleButton}>
                                        {qaPaused.length}
                                    </button>
                                    :
                                    <p className={styles.queueValue}>{qaPaused.length}</p>
                            }
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className={styles.lineItem}>
                <p className={styles.queueTitleText}>Finished</p>
                <p className={styles.queueTitleColon}>:</p>
                <p className={styles.queueValue}>{finished}</p>
            </div>
            <StatsInProgressModal open={isModalOpen} closeModal={closeModal} list={list} title={title}
                                  selectDoc={goToDocument}/>
        </div>
    );
};

export default StatsCard;