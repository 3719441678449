const getUniqueArrayLinesByValue = (idList, idIndex) => {

    let foundIDs = []

    for (let line of idList) {
        let id = line[idIndex]

        if (!(foundIDs.includes(id))) {
            foundIDs.push(id)
        }
    }

    return foundIDs.length
}

export default getUniqueArrayLinesByValue