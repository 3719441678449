const updateTopicsDictionary = (topic, subtopic, sentiment, currentTopics) => {
    let temp = {...currentTopics}

    if (topic in temp) {
        if (subtopic === "") {
            if (sentiment === temp[topic]["sentiment"]) {
                delete temp[topic]
            } else {
                temp[topic]["sentiment"] = sentiment
            }
        } else {
            if (subtopic in temp[topic]["children"]) {
                if (sentiment === temp[topic]["children"][subtopic]) {
                    delete temp[topic]["children"][subtopic]
                } else {
                    temp[topic]["children"][subtopic] = sentiment
                }
            } else {
                temp[topic]["children"][subtopic] = sentiment
            }
        }
    } else {
        temp[topic] = {
            "sentiment": sentiment,
            "children": {}
        }
    }

    return temp
}

export default updateTopicsDictionary