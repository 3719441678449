import React from 'react';
import styles from './ManageDocuments.module.css'
import {useSelector} from "react-redux";
import FlDocumentSelector from "./FLDocumentSelector";
import TextDisplay from "../../../../General/TextDisplay/TextDisplay";

const ManageDocuments = ({
                             formLetter,
                             addFunc,
                             removeFunc,
                             changeStandardFunc,
                             viewElementFunc,
                             unviewElementFunc,
                             bulkViewFunc
                         }) => {

    const [addField, setAddField] = React.useState("");
    const [selectedDocument, setSelectedDocument] = React.useState(["", 0, ""]);

    const [markViewedPercent, setMarkViewedPercent] = React.useState(0);

    const docket = useSelector((state) => state.navigation.currentDocket);

    const getSelected = () => {
        const def = {
            "document": ["", 0, "", false],
            "score": -1,
            "viewed": false,
            "source": "SYSTEM"
        }

        if (selectedDocument[0] === "") {
            return def
        }

        for (let child of formLetter['children']) {
            if (child["document"][2] === selectedDocument[2]) {
                return child
            }
        }

        return def
    }

    const addDocument = () => {
        if (addField.indexOf(" ") !== -1) {
            addFunc(addField.split(" ")[0], parseInt(addField.split(" ")[1]), formLetter["batchID"])

            setAddField(`${docket}-`)
        } else {
            window.alert("Please make sure to specify an attachment number\n[ex. CMS-0000-0000-0000 1]")
        }
    }

    const removeDocument = (isMC) => {
        if (formLetter["children"].length > 4) {

            //(trackingNumber, attachment, batchID, isMC)

            removeFunc(selectedDocument[2], selectedDocument[1], formLetter["batchID"], isMC)
        } else {
            window.alert("Removing this document will cause the batch to drop below the threshold. Please contact a system administrator.")
        }
    }

    const changeStandard = () => {
        if (window.confirm("Are you sure that you want to change the standard?\nAny associated topic & excerpt data will be removed.")) {
            changeStandardFunc(selectedDocument[2], selectedDocument[1], formLetter["batchID"])
        }
    }

    const changeViewed = (newSelected) => {

        if (newSelected[3] !== undefined) {
            viewElementFunc(newSelected[2], newSelected[1], formLetter["batchID"])
        }

        setSelectedDocument(newSelected)
    }

    const changeToUnviewed = () => {
        unviewElementFunc(selectedDocument[2], selectedDocument[1], formLetter['batchID'])
    }

    const bulkViewTrigger = () => {
        bulkViewFunc(markViewedPercent, formLetter['batchID'])
    }

    const updateViewedPercent = (e) => {
        const val = e.currentTarget.value
        if (val === undefined || val === "") {
            setMarkViewedPercent(0)
            return
        }

        setMarkViewedPercent(val)
    }

    React.useEffect(() => {
        setAddField(`${docket}-`)
    }, [docket])

    React.useEffect(() => {
        if (formLetter["children"].length !== 0) {
            setSelectedDocument(formLetter["children"][0]["document"])
        }
    }, [formLetter])

    const selected = getSelected()

    return (
        <div className={styles.wrapper}>
            <div className={styles.selector}>
                <FlDocumentSelector list={formLetter["children"]} selected={selectedDocument}
                                    setSelected={changeViewed}/>
            </div>
            <div className={styles.textArea}>
                <div className={styles.documentText}>
                    <h3>Selected</h3>
                    <div className={styles.horizontalSpacer}/>
                    <div className={styles.htmlWrapper}>
                        <TextDisplay documentID={selectedDocument[0]} attachment={selectedDocument[1]}
                                     trackingNum={selectedDocument[2]}/>
                    </div>
                </div>
                <div className={styles.verticalSpacer}/>
                <div className={styles.documentText}>
                    <h3>Standard</h3>
                    <div className={styles.horizontalSpacer}/>
                    <div className={styles.htmlWrapper}>
                        <TextDisplay documentID={formLetter["parent"][0]} attachment={formLetter["parent"][1]}
                                     trackingNum={formLetter["parent"][2]}/>
                    </div>
                </div>
            </div>

            <div className={styles.information}>
                <p><span>Standard:</span> {formLetter["parent"][0]} {formLetter["parent"][1]}</p>
                <p><span>Status:</span> {formLetter["status"]}</p>
                <p><span>Batch ID:</span> {formLetter["batchID"]}</p>

                <button className={styles.controlButtonShort} onClick={changeStandard}>
                    Mark Selected As Standard
                </button>

                <div className={styles.metadata}>
                    <p><span>Percent Match:</span> {selected.score} </p>
                    <p><span>Source:</span> {selected.source === "SYSTEM" ? "Pipeline" : selected.source} </p>
                </div>
            </div>

            <div className={styles.removeSection}>
                <button className={styles.controlButtonShort} onClick={() => removeDocument(false)}>
                    Remove From Batch
                </button>
                <button className={styles.controlButtonShort} onClick={() => removeDocument(true)}>
                    Remove As MC
                </button>
            </div>

            <div className={styles.viewedControls}>
                <button className={styles.controlButtonShort} onClick={changeToUnviewed}>
                    Mark Comment as Unread
                </button>
            </div>


            <div className={styles.controls}>
                <form action="src/components/BatchProcessing/Pages/FormLetters/Components/ManageDocuments"
                      className={styles.form}>
                    <label className={styles.fieldLabel} id="addLabel" htmlFor="documentID">Document ID:</label>
                    <input onChange={e => setAddField(e.currentTarget.value)} name={"documentID"}
                           id={"documentID"} className={styles.textField} type={"text"} value={addField}/>
                    <button className={styles.controlButtonShort} disabled={false} onClick={addDocument}
                            type={"button"}>Add Document
                    </button>
                </form>

                <div className={styles.formDivider}/>

                <form action="src/components/BatchProcessing/Pages/FormLetters/Components/ManageDocuments"
                      className={styles.form}>
                    <label className={styles.fieldLabel} id="addLabel" htmlFor="documentID">Mark Viewed Percent:</label>
                    <input onChange={e => updateViewedPercent(e)} name={"documentID"}
                           id={"documentID"} className={styles.textField} type={"number"} min={1} max={100} step={1}
                           value={markViewedPercent}/>
                    <button className={styles.controlButtonShort} disabled={false} onClick={bulkViewTrigger}
                            type={"button"}>Bulk View
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ManageDocuments;