import React from 'react';
import styles from "./MajorCommenters.module.css"
import SearchIcon from "../../../General/icons/searchIcon";

const rightBorder = {
    borderRight: '2px solid #aaaaaa'
}

const filter = (data, text) => {
    let temp = []

    const lowerText = text.toLowerCase()
    for (let item of data) {
        if (item.org.toLowerCase().includes(lowerText)) {
            temp.push(item)
            continue;
        }

        if (item.acronym.toLowerCase().includes(lowerText)) {
            temp.push(item)
            continue;
        }

        if (item.alt_name.toLowerCase().includes(lowerText)) {
            temp.push(item)
            continue;
        }

        if (item.notes.toLowerCase().includes(lowerText)) {
            temp.push(item)
        }
    }

    return temp
}

const sortData = (data) => {
    const compareFn = (a, b) => {
        if (a.mcGroup < b.mcGroup) {
            return -1
        }

        if (a.mcGroup > b.mcGroup) {
            return 1
        }

        if (a.org < b.org) {
            return -1
        }

        if (a.org > b.org) {
            return 1
        }

        return 0
    }

    return data.sort(compareFn)
}

const MajorCommenters = ({data}) => {

    const [filterText, setFilterText] = React.useState('')

    const filteredData = React.useMemo(() => sortData(filter(data, filterText)), [data, filterText])

    return (
        <div className={styles.content}>
            <div className={styles.search_wrapper}>
                <input value={filterText}
                       onChange={e => setFilterText(e.currentTarget.value)}
                       type={'text'}
                       placeholder={'Search All Fields...'}/>
                <button>
                    <SearchIcon size={28} opacity={0.6}/>
                </button>
            </div>
            <div className={styles.table_wrapper}>
                <table>
                    <thead>
                    <tr style={{backgroundColor: '#c7c7c7'}}>
                        <th style={{
                            ...rightBorder,
                            borderTopLeftRadius: 24,
                            minWidth: 160,
                            width: '10%'
                        }}>MC Group
                        </th>
                        <th style={{
                            ...rightBorder,
                            minWidth: 160,
                            width: '30%'
                        }}>Organization
                        </th>
                        <th style={{...rightBorder, minWidth: 160, width: '30%'}}>Alternative Name</th>
                        <th style={{...rightBorder, minWidth: 48, width: '10%'}}>Acronym</th>
                        <th style={{borderTopRightRadius: 12, minWidth: 160, width: '20%'}}>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        filteredData.map((mc, index) => (
                            <tr key={`${index}_${mc.org}_${mc.alt_name}_${mc.acronym}`}>
                                <td style={rightBorder}>{mc.mcGroup === 1 ? "Priority MC" : "Other MC"}</td>
                                <td style={rightBorder}>{mc.org}</td>
                                <td style={rightBorder}>{mc.alt_name}</td>
                                <td style={rightBorder}>{mc.acronym}</td>
                                <td>{mc.notes}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MajorCommenters;