function sortMCList(name, mcList) {
    if (name === "") {
        return [];
    }
    const searchPattern = new RegExp(name, 'i'); // Case-insensitive search pattern

    const filteredOrganizations = mcList.filter(item =>
        searchPattern.test(item.organization) || searchPattern.test(item.abbreviation)
    );

    const sortedOrganizations = filteredOrganizations.sort((a, b) => {
        if (a.mc_group_id < b.mc_group_id) {
            return -1;
        } else if (a.mc_group_id > b.mc_group_id) {
            return 1;
        } else {
            // Secondary sort by organization name alphabetically if mc_group_id is the same
            return a.organization.localeCompare(b.organization);
        }
    });

    //console.log(sortedOrganizations);
    return sortedOrganizations;
}

export default sortMCList;
