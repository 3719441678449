import React from 'react';
import styles from './StatsInProgress.module.css'
import {createPortal} from "react-dom";
import useEscape from "../../helper/keypressListeners/escape";
import {MC_TITLES} from "../../helper/constants";

const StatsInProgressModal = ({open, closeModal, list, title, selectDoc}) => {

    useEscape(closeModal)

    const group1List = []
    const group2List = []
    const group3List = []
    list.forEach((comment) => {
        const commenter = (comment[3] ?? "") === "" ? "Unknown" : comment[3]
        if (comment[4] === 1) {
            group1List.push(<p key={comment[1]} onClick={() => selectDoc(comment[1])}
                               className={styles.commentInfo}>{comment[1]} ({commenter})</p>)
        } else if (comment[4] === 2) {
            group2List.push(<p key={comment[1]} onClick={() => selectDoc(comment[1])}
                               className={styles.commentInfo}>{comment[1]} ({commenter})</p>)
        } else {
            group3List.push(<p key={comment[1]} onClick={() => selectDoc(comment[1])}
                               className={styles.commentInfo}>{comment[1]} ({commenter})</p>)
        }
    })

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={closeModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div onClick={(event) => {
                event.stopPropagation();
            }} className={styles.content}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;{title}&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <h1 className={styles.h1}>{title}</h1>
                <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={closeModal}>X
                </button>

                <h2 className={styles.sectionHeader}>{MC_TITLES[1]}</h2>
                {
                    group1List.length ? group1List :
                        <p className={styles.commentInfo}><b>No Comments in this MC Group</b></p>
                }
                <h2 className={styles.sectionHeader}>{MC_TITLES[2]}</h2>
                {
                    group2List.length ? group2List :
                        <p className={styles.commentInfo}><b>No Comments in this MC Group</b></p>
                }
                <h2 className={styles.sectionHeader}>{MC_TITLES[3]}</h2>
                {
                    group3List.length ? group3List :
                        <p className={styles.commentInfo}><b>No Comments in this MC Group</b></p>
                }
            </div>
        </div>,
        document.body
    );
};

export default StatsInProgressModal;