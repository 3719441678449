import React from 'react';
import styles from "./layouts.module.css"
import {useDispatch, useSelector} from "react-redux";
import {getReportData} from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import Loading from "../../General/loading/loading";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import cleanUpHTMLTags from "../../../helper/cleanUpHTMLTags";
import useEnter from "../../../helper/keypressListeners/enter";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../General/icons/chevronIcon";
import parseReportFilters from "../../../helper/parseReportFilters";
import {FILTER_PERSIST_EXPIRY} from "../../../helper/constants";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import {clearReportFilters, setReportFilters} from "../../../store/reportsSlice";
import updateReportFilters from "../../../helper/updateReportFilters";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 75,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Att".toString().replaceAll(" ", "").toLowerCase(),
        header: "Att",
        size: 40
    },
    {
        accessorKey: "textfield".toString().replaceAll(" ", "").toLowerCase(),
        header: "Comment",
        size: 1000,
        enableSorting: false
    }
]
//["Document ID", "Attachment #", "Comment"]

const filterData = (data, flFilter, textGroupings) => {
    let temp = []

    for (let item of data) {

        //Filter out form letters
        if (flFilter) {
            if (item[3] === true) {
                continue
            }
        }

        //Filter out by text
        /*if (textFilter !== "") {
            if (!(item[2].toLowerCase().includes(textFilter.toLowerCase()))) {
                continue
            }
        }*/

        if (textGroupings.length === 0 || textGroupings[0].length === 0 || textGroupings[0][0] === '') {
            //Do nothing if
        } else {
            let found = false
            let itemText = item[2].toLowerCase()

            for (let majorGrouping of textGroupings) {

                let success = true
                for (let minorGroupingText of majorGrouping) {

                    if (!(itemText.includes(minorGroupingText.toLowerCase()))) {
                        success = false
                        break;
                    }
                }

                if (success) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                continue
            }
        }

        let tempItem = []
        tempItem.push(item[0])
        tempItem.push(item[1])

        // 500,000 character cap
        let text = cleanUpHTMLTags(item[2]).replaceAll(/(<([^>]+)>)/ig, '');
        if (text.length > 500000) {
            text = text.substring(0,500000) + "... [See comment explorer for full comment text]"
        }
        tempItem.push(text)
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

const generateBooleanSearchGroupings = (textFilter) => {

    try {
        let majorGroupings = textFilter.split("OR")

        let minorGroupings = []

        for (let majorGrouping of majorGroupings) {
            let temp = majorGrouping.split('AND')

            for (let itemIndex in temp) {
                temp[itemIndex] = temp[itemIndex].toString().trim()
            }

            minorGroupings.push(temp)
        }

        return minorGroupings
    } catch (e) {
        return []
    }
}

const SearchCommentText = () => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const storedFilters = useSelector((state) => state.reports.searchCommentText);
    const dispatch = useDispatch()

    const [rawReportData, setRawReportData] = React.useState([]);
    const [searchGroups, setSearchGroups] = React.useState([])
    const [filteredData, setFilteredData] = React.useState([])
    const [loading, setLoading] = React.useState(false);

    const [isHelpExpanded, setIsHelpExpanded] = React.useState(false)

    const formLetterFilter = parseReportFilters('formLetters', storedFilters)
    const textSearch = parseReportFilters('textSearch', storedFilters)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "searchCommentText", storedFilters, key, value)
    }

    const runSearch = () => {
        let searchableGroups = generateBooleanSearchGroupings(textSearch)
        setFilteredData(filterData(rawReportData, formLetterFilter, searchableGroups))
        setSearchGroups(searchableGroups)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 1).then(r => {
            setRawReportData(r);
        })
    }, [jwt, docket]);

    React.useEffect(() => {
        if (filteredData.length < 1 && rawReportData.length > 0) {
            setLoading(false);
            if (formLetterFilter === [] || textSearch === []) {
                setFilteredData(filterData(rawReportData, false, ""))
            } else {
                runSearch()
            }
        }
    }, [rawReportData])

    useEnter(runSearch)

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({reportType: "searchCommentText"}))
    }

    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])
    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={`${styles.helpBar} ${isHelpExpanded ? styles.helpBarExpanded : ''}`}>
                <button onClick={() => setIsHelpExpanded(!isHelpExpanded)}>Boolean Search Help <ChevronIcon
                    direction={isHelpExpanded ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN}/></button>
                <p>Single Phrase - Enter desired phrase (telehealth)</p>
                <p>Multiple Phrases - Enter desired phrases seperated by AND (telehealth AND surgery)</p>
                <p>One or More Phrases - Enter desired phrases seperated by OR (telehealth OR surgery)</p>
                <p>Phrase Combinations - Enter desired phrases groupings (using AND) seperated by OR (telehealth AND
                    cuts OR surgery)</p>
            </div>
            <div className={styles.filters}>
                <div style={{width: "15vw"}} className={styles.flatFilter}>
                    <input className={styles.checkbox} type={"checkbox"} checked={!formLetterFilter}
                           onChange={() => updateFilter("formLetters", !formLetterFilter)}/>
                    <p className={styles.filterText}>Search Form Letters</p>
                </div>
                <div style={{width: "30vw"}} className={styles.stackedWithSubmit}>
                    <div className={styles.stackedSubmitWrapper}>
                        <p className={styles.filterText}>Search Comment Text</p>
                        <input className={styles.textEntry} type={"text"} value={textSearch}
                               onChange={(e) => updateFilter("textSearch", e.currentTarget.value)}/>
                    </div>
                    <button onClick={runSearch} className={styles.searchCommentTextSubmit}>Submit</button>
                </div>
                <div style={{width: "50vw"}} className={styles.flexLengthFilter}>
                    <p className={styles.filterText}>Searching for:</p>
                    {
                        (searchGroups.length === 0 || searchGroups[0].length === 0 || searchGroups[0][0] === '') ?
                            <p className={styles.searchCommentTextOptions}>All Text Values</p> :
                            searchGroups.length === 1 && searchGroups[0].length === 1 && searchGroups[0][0] !== '' ?
                                <p className={styles.searchCommentTextOptions}>{searchGroups[0][0]}</p> :
                                searchGroups.map((group, index) => (
                                    <div style={{display: "inline", marginRight: 8}} key={index}>
                                        <p className={styles.searchCommentTextOptions}>(</p>
                                        <p className={styles.searchCommentTextOptions}>{group.join(', ')})</p>
                                        {
                                            index !== searchGroups.length - 1 && (
                                                <p className={styles.searchCommentTextOptions}>,{" "}</p>
                                            )
                                        }
                                    </div>
                                ))
                    }
                </div>
            </div>
            <div className={styles.title}>
                <p>Search Comment Text <span style={{marginLeft: 16}}>({uniqueCommentCount} comments shown)</span></p>
            </div>
            <div className={styles.table}>
                {
                    loading ? <Loading/> :
                        <GenericTable allowExpansionPanel={true} data={filteredData} headingsList={finalHeadings}/>
                }

            </div>
        </div>
    );
};

export default SearchCommentText;
