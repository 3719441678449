//Returns all data for a report based on docket and the report ID
import { useProductionURL } from "../helper/constants";

export const getReviewerTypeAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_allowed_reviewer_types?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getInProgressReviewsAPI = async (jwt, docket, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_in_progress?` + new URLSearchParams({
        docket: docket,
        isPR: isPR
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getCommentMetadataAPI = async (jwt, docket, trackingNumber) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_metadata?` + new URLSearchParams({
        docket: docket,
        tracking: trackingNumber
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const changeCommentStateAPI = async (jwt, docket, tracking, movingToPaused, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/update_state?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        isPR: isPR,
        isPaused: movingToPaused
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const markCommentAsFinished = async (jwt, docket, tracking, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/finish_comment?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        isPR: isPR
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const startNewReviewAPI = async (jwt, docket, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/start_new_review?` + new URLSearchParams({
        docket: docket,
        isPR: isPR
    }), {
        method: "POST",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const pauseAllAPI = async (jwt, docket, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/pause_all?` + new URLSearchParams({
        docket: docket,
        isPR: isPR,
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}
export const getMatchingNamesAPI = async (jwt, docket, name) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/search_mcs?` + new URLSearchParams({
        docket: docket,
        name: name
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}
export const getMCNamesAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_mcs?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateNameAPI = async (jwt, docket, tracking, name) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/update_commenter?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        commenter: name,
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}


export const updateSummaryAPI = async (jwt, docket, tracking, text, topic, subtopic) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/update_summary?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        topic: topic,
        subtopic: subtopic
    }), {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "text": text
        })
    })

    return await resp.json()
}

export const updateOtherTextAPI = async (jwt, docket, tracking, text, otherType) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/update_other_text?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        type: otherType
    }), {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "text": text
        })
    })

    return await resp.json()
}

export const updateTopicsAPI = async (jwt, docket, tracking, attachment, sentiment, topic, subtopic) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/update_topics?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        attachment: attachment,
        sentiment: sentiment,
        topic: topic ?? "",
        subtopic: subtopic ?? ""
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const checkIfCanReturnToPR_API = async (jwt, docket, tracking) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/check_return_from_pr?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const returnToPR_API = async (jwt, docket, tracking) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/return_to_pr?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getReviewerHistory = async (jwt, docket, isPrimaryReview) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_reviewer_history?` + new URLSearchParams({
        docket: docket,
        isPR: isPrimaryReview
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getReviewerDashboardStats = async (jwt, docket, isPrimaryReview) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_reviewer_dashboard?` + new URLSearchParams({
        docket: docket,
        isPR: isPrimaryReview
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getFormLetterBatches = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_form_letter_batches?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getCommentKeywords_API = async (jwt, docket, tracking) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/review/get_comment_keywords?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
    }), {
        method: "GET",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}