import React from 'react';
import styles from './ReviewFormLetter.module.css'
import TextDisplay from "../../../../General/TextDisplay/TextDisplay";
import updateTopicsDictionary from "../../../../../helper/updateTopicsDictionary";
import {getCommentTopicsAPI} from "../../../../../api/commentAPI";
import {useSelector} from "react-redux";
import {updateTopicsAPI} from "../../../../../api/reviewsAPI";
import {
    getFormLetterTextFieldsAPI,
    removeMCIdentificationsAPI,
    updateFormLetterTextFieldAPI
} from "../../../../../api/formLetterAPI";
import ReviewFormLetterData from "./Cards/ReviewFormLetterData/ReviewFormLetterData";

const ReviewFormLetter = ({formLetter, changeNameFunc, stateFunc}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [name, setName] = React.useState("");
    const [selectedTopics, setSelectedTopics] = React.useState({});

    const [textFields, setTextFields] = React.useState({
        'description': '',
        'OOS': ''
    });
    const textFieldIntervalRef = React.useRef(undefined)

    const updateSelectedTopics = (topic, subtopic, sentiment) => {
        updateTopicsAPI(jwt, docket, formLetter.parent[2], formLetter.parent[1], sentiment, topic, subtopic).then(r => {
            if (r.success) {
                let resp = updateTopicsDictionary(topic, subtopic, sentiment, selectedTopics);
                setSelectedTopics(resp)
            }
        })
    }

    const removeMCIdentifications = () => {
        if (window.confirm('Are you sure that you want to change all comments currently identified as group 1 or group 2 in this batch to Unknown (group 3)')) {
            removeMCIdentificationsAPI(jwt, docket, formLetter["batchID"]).then(r => {
                if (r.success) {
                    window.alert(`Successfully changed all group 1 and group 2 commenters in ${formLetter["name"]}`)
                } else {
                    window.alert('Error in changing group 1 and group 2 commenters.')
                }
            })
        }
    }

    const submitName = () => {
        changeNameFunc(formLetter["batchID"], name)
    }

    const submitStatusChange = (status) => {
        stateFunc(formLetter["batchID"], status)
    }

    const updateTextFields = (key, value) => {
        setTextFields({...textFields, [key]: value})

        if (textFieldIntervalRef != null && textFieldIntervalRef.current !== undefined) {
            clearInterval(textFieldIntervalRef.current)
        }

        textFieldIntervalRef.current = setTimeout(() => {
            updateFormLetterTextFieldAPI(jwt, docket, value, formLetter["batchID"], key).then()
        }, 500)
    }

    React.useEffect(() => {
        getCommentTopicsAPI(jwt, docket, formLetter.parent[2]).then(r => {
            if (r.success) {
                setSelectedTopics(r.data)
            }
        })

        setName(formLetter["name"])

        getFormLetterTextFieldsAPI(jwt, docket, formLetter["batchID"]).then(r => {
            if (r.success) {
                setTextFields(r.data)
            }
        })
    }, [formLetter])

    return (
        <div className={styles.wrapper}>
            <div className={styles.textArea}>
                <TextDisplay trackingNum={formLetter["parent"][2]}
                             attachment={formLetter["parent"][1]} documentID={formLetter["parent"][0]}/>
            </div>

            <div className={styles.topicsArea}>
                <ReviewFormLetterData
                    selectedTopics={selectedTopics} updateSelectedTopics={updateSelectedTopics}
                    name={name} setName={setName} saveName={submitName}
                    removeMCIdentifications={removeMCIdentifications}
                    textFields={textFields} setTextField={updateTextFields}
                    status={formLetter["status"]} updateStatus={submitStatusChange}
                />
            </div>


        </div>
    );
};

export default ReviewFormLetter;