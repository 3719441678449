import {combineReducers, configureStore} from '@reduxjs/toolkit'
import userSlice from "./userSlice";
import navigationSlice from "./navSlice";
import preferenceSlice from "./preferenceSlice"
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import thunk from 'redux-thunk';
import modalSlice from "./modalSlice";
import loginSlice from "./loginSlice";
import docketsSlice from "./docketsSlice";
import reportsSlice from "./reportsSlice";

const persistConfig = {
    key: 'root',
    blacklist: ['login'],
    storage,
}

const navConfig = {
    key: 'navigation',
    blacklist: ['currentDocket'],
    storage,
}

const rootReducer = combineReducers({
    user: userSlice,
    navigation: persistReducer(navConfig, navigationSlice),
    modal: modalSlice,
    prefs: preferenceSlice,
    login: loginSlice,
    dockets: docketsSlice,
    reports: reportsSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)