import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";

const CommentVisibilityModal = ({open, closeModal, setFilter}) => {

    const clearAndCloseModal = () => {
        closeModal()
    }

    const updateFilters = (newDict) => {
        setFilter(newDict)
        clearAndCloseModal()
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.content} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Comment Visibility Filters&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <h1 className={styles.h1}>Comment Visibility Filters</h1>
                <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                </button>

                <div className={styles.lineItem}>
                    <div className={styles.top}>
                        <h2>Full Visibility Mode</h2>
                        <button onClick={() => updateFilters({personal: false, team: false})}>Apply</button>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.filterWrapper}>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Team's Viewed Comments"} type={"checkbox"} checked={false}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude My Viewed Comments</p>
                            </div>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Others' Viewed Comments"} type={"checkbox"} checked={false}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude Others' Viewed Comments</p>
                            </div>
                        </div>
                        <div className={styles.divider}/>
                        <p>
                            Displays all comments without any filtering. Comments that you have already viewed, those
                            viewed by others, and any comments that no one has viewed yet.
                        </p>
                    </div>
                </div>

                <div className={styles.lineItem}>
                    <div className={styles.top}>
                        <h2>Personal Focus Mode</h2>
                        <button onClick={() => updateFilters({personal: true, team: false})}>Apply</button>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.filterWrapper}>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Team's Viewed Comments"} type={"checkbox"} checked={true}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude My Viewed Comments</p>
                            </div>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Others' Viewed Comments"} type={"checkbox"} checked={false}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude Others' Viewed Comments</p>
                            </div>
                        </div>
                        <div className={styles.divider}/>
                        <p>
                            Filters out and hides the comments that you have personally viewed. This helps in focusing
                            on comments that are new to you but includes views by others.
                        </p>
                    </div>
                </div>

                <div className={styles.lineItem}>
                    <div className={styles.top}>
                        <h2>Team Activity Focus Mode</h2>
                        <button onClick={() => updateFilters({personal: false, team: true})}>Apply</button>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.filterWrapper}>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Team's Viewed Comments"} type={"checkbox"} checked={false}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude My Viewed Comments</p>
                            </div>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Team's Viewed Comments"} type={"checkbox"} checked={true}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude Others' Viewed Comments</p>
                            </div>
                        </div>
                        <div className={styles.divider}/>
                        <p>
                            Removes comments viewed by team members from view, allowing you to concentrate on new
                            comments to others, including some you may have already seen.
                        </p>
                    </div>
                </div>

                <div className={styles.lineItem}>
                    <div className={styles.top}>
                        <h2>Unviewed Comments Only Mode</h2>
                        <button onClick={() => updateFilters({personal: true, team: true})}>Apply</button>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.filterWrapper}>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Team's Viewed Comments"} type={"checkbox"} checked={true}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude My Viewed Comments</p>
                            </div>
                            <div className={styles.filters}>
                                <input aria-label={"Exclude Others' Viewed Comments"} type={"checkbox"} checked={true}
                                       onChange={() => {
                                       }}/>
                                <p>Exclude Others' Viewed Comments</p>
                            </div>
                        </div>
                        <div className={styles.divider}/>
                        <p>
                            Strictly displays only the comments that neither you nor others have viewed. Ideal for
                            focusing solely on new feedback items that require attention.
                        </p>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default CommentVisibilityModal;