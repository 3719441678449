import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import {createChangeRequestAPI} from "../../../api/changeRequestAPI";
import {useSelector} from "react-redux";

const CreateChangeRequestModal = ({open, closeModal, selectedDocument, trackingNum}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [enableRemoveCheckbox, setEnableRemoveCheckbox] = React.useState(false)
    const [removeTopics, setRemoveTopics] = React.useState("")

    const [enableAddCheckbox, setEnableAddCheckbox] = React.useState(false)
    const [addTopics, setAddTopics] = React.useState("")

    const [message, setMessage] = React.useState('')

    const [description, setDescription] = React.useState('')

    const toggleRemoveCheckbox = () => {
        if (enableRemoveCheckbox) {
            setRemoveTopics("")
        }

        setEnableRemoveCheckbox(!enableRemoveCheckbox)
    }

    const toggleAddCheckbox = () => {
        if (enableAddCheckbox) {
            setAddTopics("")
        }

        setEnableAddCheckbox(!enableAddCheckbox)
    }

    const editDescription = (newDesc) => {
        setDescription(newDesc.toString().slice(0, 250))
    }

    const clearModal = () => {
        setRemoveTopics('')
        setEnableRemoveCheckbox(false)
        setAddTopics('')
        setEnableAddCheckbox('')
        setDescription('')
    }

    const closeModalInternal = () => {
        setMessage("")
        closeModal()
    }

    const clearAndCloseModal = () => {
        closeModalInternal()
        clearModal()
    }

    const submitChangeRequest = () => {

        if (addTopics === '' && removeTopics === '' && description === '') {
            window.alert("You cannot submit a change request without any accompanying information.")
            return
        }

        if (addTopics === '' && enableAddCheckbox) {
            window.alert("You cannot submit a change request without listing topics to be added.")
            return
        }

        if (removeTopics === '' && enableRemoveCheckbox) {
            window.alert("You cannot submit a change request without listing topics to be removed.")
            return
        }

        createChangeRequestAPI(jwt, docket, addTopics, removeTopics, trackingNum, description).then(r => {
            clearModal()
            setMessage("Your Request Has Been Successfully Submitted!")
        })
    }

    React.useEffect(() => {
        if (open && (selectedDocument === '' || trackingNum == null || trackingNum === 'none')) {
            clearAndCloseModal()
        }
    }, [open, selectedDocument, trackingNum])

    React.useEffect(() => {
        clearModal()
    }, [trackingNum])

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={closeModalInternal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.content} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Create Change Request&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <h1 className={styles.h1}>Create Change Request</h1>
                <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={closeModalInternal}>X
                </button>

                <div className={styles.flexContent}>
                    {
                        message !== '' &&
                        <h3 style={{
                            width: '95%',
                            textAlign: 'left',
                            margin: 0,
                            padding: '4px 24px',
                            marginBottom: 16,
                            backgroundColor: "lightgreen",
                            borderRadius: 4
                        }}>
                            {message}
                        </h3>
                    }
                    <div style={{marginBottom: 28}} className={styles.rowLine}>
                        <h3>Document ID: </h3>
                        <input disabled={true} value={selectedDocument} onChange={() => {
                        }} style={{maxWidth: 240}} type={'text'}/>
                    </div>

                    <div style={{width: '100%'}}>
                        <div className={styles.rowLine}>
                            <input type={'checkbox'} checked={enableRemoveCheckbox} onChange={toggleRemoveCheckbox}/>
                            <h3>Request Topic(s) to Be <b style={{fontWeight: 800}}>Removed</b></h3>
                        </div>
                        {
                            enableRemoveCheckbox &&
                            <div style={{marginBottom: 20}} className={styles.rowLine}>
                                <h3>Topic Identifiers (1A, 2B2, etc.): </h3>
                                <input value={removeTopics} onChange={e => setRemoveTopics(e.currentTarget.value)}
                                       type={'text'}/>
                            </div>
                        }

                        <div style={{
                            height: 1,
                            width: '50%',
                            backgroundColor: 'black',
                            marginTop: 16,
                            marginBottom: 16
                        }}/>

                        <div className={styles.rowLine}>
                            <input type={'checkbox'} checked={enableAddCheckbox} onChange={toggleAddCheckbox}/>
                            <h3>Request Topic(s) to Be <b style={{fontWeight: 800}}>Added</b></h3>
                        </div>
                        {
                            enableAddCheckbox &&
                            <div className={styles.rowLine}>
                                <h3>Topic Identifiers (1A, 2B2, etc.): </h3>
                                <input value={addTopics} onChange={e => setAddTopics(e.currentTarget.value)}
                                       type={'text'}/>
                            </div>
                        }
                    </div>

                    <div className={styles.textAreaWrapper}>
                        <h3 style={{fontWeight: 500}}>Request Description</h3>
                        <textarea value={description} onChange={e => editDescription(e.currentTarget.value)}
                                  className={styles.textArea}/>
                        <p className={styles.characterCounter}>{description.length}/250 characters</p>
                    </div>

                    <button onClick={submitChangeRequest} className={styles.createButton}>Submit Change Request *
                    </button>
                    <p style={{fontSize: 14, margin: 0, padding: 0}}>*Your Change Request is temporarily stored and will
                        remain until either submitted or a new document ID is viewed in the comment explorer.
                        <br/>
                        Please note: Closing the comment review application will result in the loss of any unsaved
                        changes. </p>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default CreateChangeRequestModal;