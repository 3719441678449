import React from 'react';
import styles from "./Provisions.module.css"
import SearchIcon from "../../../General/icons/searchIcon";
import useEnter from "../../../../helper/keypressListeners/enter";
import TopicSelection from "./components/TopicSelection";

const filter = (data, filters, text) => {
    let temp = {}

    const calculateItemValid = (item) => {

        const textLower = text.toLowerCase()
        if (filters.section) {
            if (item.topic != null && item.topic.toLowerCase().includes(textLower)) {
                return true;
            }

            if (item.section != null && item.section.toLowerCase().includes(textLower)) {
                return true;
            }

            if (item.subtopic != null && item.subtopic.toLowerCase().includes(textLower)) {
                return true;
            }
        }

        if (filters.keywords) {
            if (item.keywords != null && item.keywords.join(", ").toLowerCase().includes(textLower)) {
                return true;
            }
        }

        if (filters.information) {
            if (item.background != null && item.background.toLowerCase().includes(textLower)) {
                return true;
            }

            if (item.provision != null && item.provision.toLowerCase().includes(textLower)) {
                return true;
            }

            if (item.other_solicitations != null && item.other_solicitations.toLowerCase().includes(textLower)) {
                return true;
            }
        }

        if (filters.notes) {
            if (item.notes != null && item.notes.toLowerCase().includes(textLower)) {
                return true;
            }
        }

        return false;
    }

    for (let topicTitle of Object.keys(data)) {
        const topicData = data[topicTitle]
        const topicChildren = data[topicTitle].children

        const childrenDict = {}
        for (let subtopicTitle of Object.keys(topicChildren)) {
            const subtopicData = topicChildren[subtopicTitle]

            if (calculateItemValid(subtopicData)) {
                childrenDict[subtopicTitle] = {...subtopicData}
            }
        }

        if (Object.keys(childrenDict).length > 0) {
            temp[topicTitle] = {
                ...topicData,
                children: childrenDict
            }
        } else if (calculateItemValid(topicData)) {
            temp[topicTitle] = {
                ...topicData,
                children: {}
            }
        }
    }

    return temp
}

const retrieveData = (data, selection) => {
    if (selection.subtopic === '') {
        return data[selection.topic]
    } else {
        return data[selection.topic].children[selection.subtopic]
    }
}

const Provisions = ({data}) => {

    const [filteredData, setFilteredData] = React.useState(data)
    const [filterText, setFilterText] = React.useState('')
    const [filterTypes, setFilterTypes] = React.useState({
        'section': true,
        'keywords': true,
        'information': true,
        'notes': true
    })

    const [selection, setSelection] = React.useState({
        'topic': '',
        'subtopic': ''
    })

    const updateSelection = (topic, subtopic) => {
        setSelection({
            'topic': topic,
            'subtopic': subtopic
        })
    }

    const clearSelection = () => {
        setSelection({
            'topic': '',
            'subtopic': ''
        })
    }

    const filterData = () => {
        clearSelection()
        setFilteredData(filter(data, filterTypes, filterText))
    }

    React.useEffect(() => {
        filterData()
    }, [data])

    useEnter(filterData)

    const calculatedFields = React.useMemo(() => retrieveData(data, selection), [data, selection])

    return (
        <div className={styles.content}>
            <div className={styles.topicColumn}>
                <div className={styles.search_section}>
                    <div className={styles.search_bar}>
                        <input value={filterText}
                               onChange={e => setFilterText(e.currentTarget.value)}
                               type={'text'}
                               placeholder={'Search All Entries...'}/>
                        <button onClick={filterData}>
                            <SearchIcon size={28} opacity={0.8}/>
                        </button>
                    </div>
                    <div className={styles.modifier_bar}>
                        <div>
                            <input checked={filterTypes.section}
                                   onChange={() => setFilterTypes({...filterTypes, section: !filterTypes.section})}
                                   type={'checkbox'}/>
                            <p>Section</p>
                        </div>
                        <div>
                            <input checked={filterTypes.keywords}
                                   onChange={() => setFilterTypes({...filterTypes, keywords: !filterTypes.keywords})}
                                   type={'checkbox'}/>
                            <p>Keywords</p>
                        </div>
                        <div>
                            <input checked={filterTypes.information}
                                   onChange={() => setFilterTypes({
                                       ...filterTypes,
                                       information: !filterTypes.information
                                   })}
                                   type={'checkbox'}/>
                            <p>Information</p>
                        </div>
                        <div>
                            <input checked={filterTypes.notes}
                                   onChange={() => setFilterTypes({...filterTypes, notes: !filterTypes.notes})}
                                   type={'checkbox'}/>
                            <p>Notes</p>
                        </div>
                    </div>
                </div>
                <div className={styles.topics_section}>
                    {
                        Object.keys(filteredData).length < 1 &&
                        <p className={styles.labelText}>
                            No Topics/Subtopics Found
                        </p>
                    }
                    <TopicSelection data={filteredData} selection={selection} updateSelection={updateSelection}/>
                </div>
            </div>
            <div className={styles.divider}/>
            {
                selection.topic === "" ?
                    <div className={styles.detailsColumn}>
                        <div className={styles.noSelection}>
                            <p className={styles.labelText}>
                                Select a topic or subtopic on the left for more information.
                            </p>
                        </div>
                    </div> :
                    <div className={styles.detailsColumn}>
                        <div className={styles.metadata_section}>
                            <h3 className={styles.sectionHeader} style={{textAlign: 'left'}}>Section
                                Title: {calculatedFields.section_title == null ? "Unknown" : calculatedFields.section_title}</h3>
                            <p className={styles.labelText}>
                                Section: <span className={styles.valueText}
                                               style={{marginRight: 32}}>{calculatedFields.section == null ? "Unknown" : calculatedFields.section}</span>
                                FR Page: <span className={styles.valueText}
                                               style={{marginRight: 32}}>{calculatedFields.fr_page == null ? "Unknown" : calculatedFields.fr_page}</span>
                                PDF Page: <span className={styles.valueText}
                                                style={{marginRight: 32}}>{calculatedFields.pdf_page == null ? "Unknown" : calculatedFields.pdf_page}</span>
                            </p>
                            <p className={styles.labelText}>
                                Topic: <span className={styles.valueText}
                                             style={{marginRight: 32}}>{calculatedFields.topic == null ? "Unknown" : calculatedFields.topic}</span>
                            </p>
                            {
                                calculatedFields.subtopic !== undefined &&
                                <p className={styles.labelText}>
                                    Subtopic: <span className={styles.valueText}
                                                    style={{marginRight: 32}}>{calculatedFields.subtopic == null ? "Unknown" : calculatedFields.subtopic}</span>
                                </p>
                            }
                        </div>
                        <div className={styles.text_section}>
                            <h3 className={styles.sectionHeader}>Information:</h3>
                            <p className={styles.labelText}>Background: </p>
                            {
                                (calculatedFields.background == null || calculatedFields.background === "") ?
                                    <p className={styles.valueText}>No Background Text Found</p> :
                                    calculatedFields.background.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                            <p style={{marginTop: 12}} className={styles.labelText}>Provision: </p>
                            {
                                (calculatedFields.provision == null || calculatedFields.provision === "") ?
                                    <p className={styles.valueText}>No Provision Text Found</p> :
                                    calculatedFields.provision.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                            {
                                (selection.subtopic === "") && <>
                                    {
                                        // Only do this section for topics, since they're the only one with other solicitations!
                                    }
                                    <p style={{marginTop: 12}} className={styles.labelText}>Other Solicitations: </p>
                                    {
                                        (calculatedFields.other_solicitations == null || calculatedFields.other_solicitations === "") ?
                                            <p className={styles.valueText}>No Other Solicitations Found</p> :
                                            calculatedFields.other_solicitations.split("\n").map((text, idx) => (
                                                <p key={idx} className={styles.valueText}>{text}</p>
                                            ))
                                    }
                                </>

                            }

                            <p style={{marginTop: 12}} className={styles.labelText}>Notes: </p>
                            {
                                (calculatedFields.notes == null || calculatedFields.notes === "") ?
                                    <p className={styles.valueText}>No Notes Found</p> :
                                    calculatedFields.notes.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }

                        </div>
                        <div className={styles.keyword_section}>
                            <h3 className={styles.sectionHeader}>Keywords:</h3>
                            <p className={styles.valueText}>
                                {calculatedFields.keywords == null ? "No Keywords Loaded" : calculatedFields.keywords.join(", ")}
                            </p>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Provisions;