import React from 'react';
import styles from "./multiselectFilter.module.css"
import useOutsideClick from "../../../../helper/useOutsideClick";


const searchFilterValues = (values, searchStr) => {
    return values.filter(element => element.label.includes(searchStr))
}

const MultiSelectFilter = ({
                               title = "", selected = [], setSelected = (arr) => {
    }, width = "15vw", options = [], noOptionsText = ""
                           }) => {

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const searchOptions = React.useMemo(() => searchFilterValues(options, search), [options, search])

    const alterSelected = (target) => {
        let temp = [...selected]

        let tempTarget = target;

        if (target === '') {
            tempTarget = null
        }

        if (temp.includes(tempTarget)) {
            temp = temp.filter(item => item !== tempTarget)
        } else {
            temp.push(tempTarget)
        }

        setSelected(temp)
    }

    const selectAll = () => {
        let all = []
        for (let item of options) {
            all.push(item.value)
        }
        setSelected(all)
    }

    const removeAll = () => {
        setSelected([])
    }

    const closeFunc = () => {
        setSearch("");
        setDropdownOpen(false)
    }

    const wrapperRef = React.useRef(null);
    useOutsideClick(wrapperRef, closeFunc)

    return (
        <div ref={wrapperRef} style={{width: width}} className={styles.stackedFilter}>
            <p className={styles.filterText}>{title} <b>{selected.length > 0 ? `(${selected.length} active)` : ""}</b>
            </p>
            <button onClick={() => setDropdownOpen(!dropdownOpen)}
                    aria-label={`Filter for ${title}`} className={styles.selectedOptions}>
                {dropdownOpen ? "Close" : "Open"} Filter
            </button>
            {
                dropdownOpen &&
                <div className={styles.optionDropdown}>
                    <div className={styles.searchItem}>
                        <label id="filterSearch" htmlFor="search">Search:</label>
                        <input aria-label={"Search Filters"} onChange={e => setSearch(e.currentTarget.value)}
                               name={"search"}
                               id={"search"} type={"text"} value={search == null ? "" : search}/>
                    </div>
                    <div className={styles.optionsLine}>
                        {
                            searchOptions.map(element => {

                                    if ("text" in element) {
                                        return (
                                            <div key={element.value} className={styles.lineItem}>
                                                <p style={{marginLeft: 0}}><b>{element.label}</b></p>
                                            </div>
                                        )
                                    }

                                    return (
                                        <div key={element.value} className={styles.lineItem}>
                                            <input onChange={(e) => alterSelected(e.currentTarget.value)}
                                                   value={element.value}
                                                   checked={selected.includes(element.value)}
                                                   type={"checkbox"} aria-label={`Checkbox for ${title} ${element.label}`}/>
                                            <p>{element.label}</p>
                                        </div>
                                    )
                                }
                            )
                        }
                        {
                            searchOptions.length < 1 && noOptionsText
                        }
                    </div>
                    <div className={styles.buttonsItem}>
                        <button onClick={selectAll}
                                aria-label={`Select All Items`}>
                            Select All
                        </button>
                        <button onClick={removeAll}
                                aria-label={`Remove All Selections`}>
                            Remove All
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default MultiSelectFilter;