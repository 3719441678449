import React from 'react';
import styles from "./overview.module.css"
import {useSelector} from "react-redux";
import {getOverviewData, getReportData} from "../../../api/reportsAPI";
import Loading from "../../General/loading/loading";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../General/icons/chevronIcon";

//TODO: move timeline into own component file
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from "react-chartjs-2";
import DownloadIcon from "../../General/icons/downloadIcon";
import {getRiderTopics} from "../../../helper/getRiderTopics";
import downloadReportData from "../../../helper/downloadReportData";
import MaskSentimentValues from "../../../helper/maskSentimentValues";
import {MC_TITLES} from "../../../helper/constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const commentDatabaseFileHeaders = [
    {
        accessorKey: "Document ID".toString().replaceAll(" ", "").toLowerCase(),
        header: "Document ID"
    },
    {
        accessorKey: "Regulationsgov Link".toString().replaceAll(" ", "").toLowerCase(),
        header: "Regulations.gov Link"
    },
    {
        accessorKey: "Tracking Number".toString().replaceAll(" ", "").toLowerCase(),
        header: "Tracking Number"
    },
    {
        accessorKey: "Attachment Count".toString().replaceAll(" ", "").toLowerCase(),
        header: "Attachment Count"
    },
    {
        accessorKey: "Posted Date".toString().replaceAll(" ", "").toLowerCase(),
        header: "Posted Date"
    },
    {
        accessorKey: "Loaded Date".toString().replaceAll(" ", "").toLowerCase(),
        header: "Loaded Date"
    },
    {
        accessorKey: "Completed Date".toString().replaceAll(" ", "").toLowerCase(),
        header: "Completed Date"
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter"
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group"
    },
    {
        accessorKey: "Form Letter Name".toString().replaceAll(" ", "").toLowerCase(),
        header: "Form Letter Name"
    },
    {
        accessorKey: "Form Letter Standard".toString().replaceAll(" ", "").toLowerCase(),
        header: "Form Letter Standard"
    },
    {
        accessorKey: "Topic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Topic"
    },
    {
        accessorKey: "Subtopic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Subtopic"
    },
    {
        accessorKey: "Summary".toString().replaceAll(" ", "").toLowerCase(),
        header: "Summary"
    },
    {
        accessorKey: "Out Of Scope".toString().replaceAll(" ", "").toLowerCase(),
        header: "Out Of Scope"
    },
    {
        accessorKey: "Sentiment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Sentiment"
    },
    {
        accessorKey: "Annotation".toString().replaceAll(" ", "").toLowerCase(),
        header: "Annotation"
    },
]

const calculateUniqueKeyCount = (summaryData, key) => {
    let sum = summaryData['unique'][1][key] + summaryData['unique'][2][key]
    sum += summaryData['unique'][3][key]

    return sum
}

const Overview = () => {

    const [data, setData] = React.useState({
        "summary": {
            'total': 0,
            'formLetters': {
                'completed': 0,
                'backlog': 0
            },
            'formLetterCampaigns': {
                'completed': 0,
                'backlog': 0
            },
            'unique': {
                1: {
                    'backlog': 0,
                    'progress': 0,
                    'completed': 0
                },
                2: {
                    'backlog': 0,
                    'progress': 0,
                    'completed': 0
                },
                3: {
                    'backlog': 0,
                    'progress': 0,
                    'completed': 0
                },
            }
        },
            "commenter_breakdown": [],
            "sentiment_breakdown": [],
            "timeline": {
                "current": [],
                "previous": []
            }
        }
    )


    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)

    const [loadingCommentDatabase, setLoadingCommentDatabase] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        setLoading(true)
        getOverviewData(jwt, docket).then(r => {
            setData(r)
            setLoading(false)
        })
    }, [jwt, docket])


    const downloadCommentDatabase = () => {
        setLoadingCommentDatabase(true)
        getReportData(jwt, docket, 10).then(rawReportData => {
            let reportData = []

            let riders = getRiderTopics(docketTopics)

            for (let item of rawReportData) {

                let tempItem = [...item]

                if (tempItem[7] == null) {
                    tempItem[7] = ''
                }

                if (tempItem[9] == null) {
                    tempItem[9] = ''
                }

                if (tempItem[10] == null) {
                    tempItem[10] = ''
                }

                if (tempItem[11] == null) {
                    tempItem[11] = ''
                }

                if (tempItem[12] == null) {
                    tempItem[12] = ''
                }

                if (tempItem[13] == null) {
                    tempItem[13] = ''
                }

                if (tempItem[14] == null) {
                    tempItem[14] = ''
                }

                if (tempItem[15] != null) {
                    if (riders.includes(tempItem[11])) {
                        tempItem[15] = ""
                    } else {
                        tempItem[15] = MaskSentimentValues(tempItem[15], tempItem[5], undefined, docket)
                    }
                } else {
                    tempItem[15] = ''
                }

                if (tempItem[16] == null) {
                    tempItem[16] = ''
                }

                tempItem[8] = MC_TITLES[tempItem[8]]

                reportData.push(tempItem)
            }

            downloadReportData(commentDatabaseFileHeaders, reportData, "comment_database")
            setLoadingCommentDatabase(false)
        }).catch(e => {
            setLoadingCommentDatabase(false)
        })
    }

    const allCommentTotals = data["summary"]["total"]
    const formLetterCampaignTotal = (data['summary']['formLetterCampaigns']['completed'] + data['summary']['formLetterCampaigns']['backlog'])
    const formLetterTotal = (data['summary']['formLetters']['completed'] + data['summary']['formLetters']['backlog'])
    const formLetterPercent = Math.round((formLetterTotal / allCommentTotals) * 100)
    const uniqueCommentPercent = 100 - formLetterPercent
    const uniqueCommentCount = allCommentTotals - formLetterTotal
    const uniqueCommentBacklog = calculateUniqueKeyCount(data['summary'], 'backlog')
    const uniqueCommentProgress = calculateUniqueKeyCount(data['summary'], 'progress')
    const uniqueCommentCompleted = calculateUniqueKeyCount(data['summary'], 'completed')

    if (loading) {
        return <Loading/>
    } else {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h2>Comments For: {docket}</h2>
                    <button disabled={loadingCommentDatabase || loading} className={styles.expandedDownloadButton}
                            onClick={downloadCommentDatabase}>
                        <DownloadIcon size={24}/> Download Comment Database
                    </button>
                </div>
                <div className={styles.stats}>
                    <div className={styles.double}>
                        <div className={styles.statHeading}>
                            <p>Total Comments</p>
                        </div>
                        <div className={styles.statsDoubleTable}>
                            <div className={styles.statsDoubleTableRow}>
                                <h3>Comment Count:</h3>
                                <p>{allCommentTotals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                            <div className={styles.statsDoubleTableDivider}/>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>Form Letters</h4>
                                <p>{formLetterTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}&nbsp;<span
                                    style={{marginLeft: 8}}>({formLetterPercent}%)</span></p>
                            </div>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>Unique Comments</h4>
                                <p>{uniqueCommentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}&nbsp;<span
                                    style={{marginLeft: 8}}>({uniqueCommentPercent}%)</span></p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.double}>
                        <div className={styles.statHeading}>
                            <p>Form Letter Campaigns</p>
                        </div>
                        <div className={styles.statsDoubleTable}>
                            <div className={styles.statsDoubleTableRow}>
                                <h3>Total:</h3>
                                <p>{formLetterCampaignTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                            <div className={styles.statsDoubleTableDivider}/>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>Completed</h4>
                                <p>{data['summary']['formLetterCampaigns']['completed'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>In Progress</h4>
                                <p>{data['summary']['formLetterCampaigns']['backlog'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.double}>
                        <div className={styles.statHeading}>
                            <p>Form Letters</p>
                        </div>
                        <div className={styles.statsDoubleTable}>
                            <div className={styles.statsDoubleTableRow}>
                                <h3>Total:</h3>
                                <p>{formLetterTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                            <div className={styles.statsDoubleTableDivider}/>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>Completed</h4>
                                <p>{data['summary']['formLetters']['completed'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                            <div className={styles.statsDoubleTableRow}>
                                <h4>In Progress</h4>
                                <p>{data['summary']['formLetters']['backlog'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.expansiveOverview}>
                        <div className={styles.statHeading}>
                            <p>Unique Comments</p>
                        </div>
                        <div className={styles.statsDoubleTable} style={{padding: 4}}>
                            <div className={styles.statsUniqueTable}>
                                <table>
                                    <thead>
                                    <tr style={{height: 24}}>
                                        <th width={"25%"}></th>
                                        <th width={"25%"}><p>Queue</p></th>
                                        <th width={"25%"}><p>In Progress</p></th>
                                        <th width={"25%"}><p>Completed</p></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style={{borderBottom: "1px solid #b3b3b3"}}>
                                        <td style={{textAlign: "right", fontWeight: 600}}>Totals</td>
                                        <td title={"Totals/Queue"}>
                                            <p>{uniqueCommentBacklog.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Totals/In Progress"}>
                                            <p>{uniqueCommentProgress.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Totals/Completed"}>
                                            <p>{uniqueCommentCompleted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                    </tr>
                                    <tr style={{fontSize: 18}}>
                                        <td style={{textAlign: "right"}}>Priority MCs</td>
                                        <td title={"Priority MCs/Queue"}>
                                            <p>{data['summary']['unique'][1]['backlog'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Priority MCs/In Progress"}>
                                            <p>{data['summary']['unique'][1]['progress'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Priority MCs/Completed"}>
                                            <p>{data['summary']['unique'][1]['completed'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                    </tr>
                                    <tr style={{fontSize: 18}}>
                                        <td style={{textAlign: "right"}}>Other MCs</td>
                                        <td title={"Other MCs/Queue"}>
                                            <p>{data['summary']['unique'][2]['backlog'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Other MCs/In Progress"}>
                                            <p>{data['summary']['unique'][2]['progress'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Other MCs/Completed"}>
                                            <p>{data['summary']['unique'][2]['completed'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                    </tr>
                                    <tr style={{fontSize: 18}}>
                                        <td style={{textAlign: "right"}}>Non-MCs</td>
                                        <td title={"Non-MCs/Queue"}>
                                            <p>{data['summary']['unique'][3]['backlog'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Non-MCs/In Progress"}>
                                            <p>{data['summary']['unique'][3]['progress'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                        <td title={"Non-MCs/Completed"}>
                                            <p>{data['summary']['unique'][3]['completed'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.majorContent}>
                    <OverviewTables commenterStats={data["commenter_breakdown"]}
                                    sentimentStats={data["sentiment_breakdown"]}/>
                    <Timeline currentStats={data["timeline"]["current"]} pastStats={data["timeline"]["previous"]}/>
                </div>
            </div>
        );
    }
};

const OverviewTables = ({sentimentStats, commenterStats}) => {

    const [showCommenter, setShowCommenter] = React.useState(true)


    const getHeadings = () => {
        if (showCommenter) {
            return ["Topic", "Total", "Priority MCs", "Other MCs", "Non-MCs"]
        } else {
            return ["Topic", "Total", "Positive", "Mixed", "Negative"]
        }
    }

    const getStats = () => {
        let temp = []

        let stats = sentimentStats
        if (showCommenter) {
            stats = commenterStats
        }

        for (let oldLine of stats) {
            let line = [...oldLine]
            if (line[1] === null || line[1] === undefined || line[1] === '') {
                line.push(false)
            } else {
                line[0] = `\t${line[1]}`
                line.push(true)
            }

            if (line[2] === null || line[2] === undefined) {
                line[2] = 0
            }
            if (line[3] === null || line[3] === undefined) {
                line[3] = 0
            }
            if (line[4] === null || line[4] === undefined) {
                line[4] = 0
            }

            line[1] = line[2] + line[3] + line[4]
            temp.push(line)
        }

        return temp
    }

    const headings = React.useMemo(() => getHeadings(), [showCommenter])
    const tableStats = React.useMemo(() => getStats(), [showCommenter])

    return <div className={styles.tables}>
        <div className={styles.contentHeader}>
            <h2>
                {showCommenter ? "Commenter Group by Topic/Subtopic" : "Sentiment by Topic/Subtopic"}
            </h2>
            <div>
                <button onClick={() => setShowCommenter(true)} className={styles.contentHeaderButton}>
                    <ChevronIcon disabled={showCommenter} blackColor={false}
                                 bold={true} direction={CHEVRON_DIRECTIONS.LEFT}/>
                </button>
                <button onClick={() => setShowCommenter(false)} className={styles.contentHeaderButton}>
                    <ChevronIcon disabled={!showCommenter} blackColor={false}
                                 bold={true} direction={CHEVRON_DIRECTIONS.RIGHT}/>
                </button>
            </div>
        </div>
        <div className={styles.tableWrapper}>
            <table>
                <thead>
                <tr style={{height: 50}}>
                    <th width={"60%"}>
                        <span>{headings[0]}</span>
                    </th>
                    <th width={"10%"}>
                        <span>{headings[1]}</span>
                    </th>
                    <th width={"10%"}>
                        <span>{headings[2]}</span>
                    </th>
                    <th width={"10%"}>
                        <span>{headings[3]}</span>
                    </th>
                    <th width={"10%"}>
                        <span>{headings[4]}</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    tableStats.map((element) => (
                            <tr key={element}>
                                <td title={element[0]}>
                                    <p className={element[5] ? styles.subtopic : styles.topic}>
                                        {element[0]}
                                    </p>
                                </td>
                                <td title={element[1]}>
                                    <p className={styles.tablesText}>
                                        {element[1]}
                                    </p>
                                </td>
                                <td title={element[2]}>
                                    <p className={styles.tablesText}>
                                        {element[2]}
                                    </p>
                                </td>
                                <td title={element[3]}>
                                    <p className={styles.tablesText}>
                                        {element[3]}
                                    </p>
                                </td>
                                <td title={element[4]}>
                                    <p className={styles.tablesText}>
                                        {element[4]}
                                    </p>
                                </td>

                            </tr>
                        )
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
}

const Timeline = ({currentStats, pastStats}) => {

    return <div className={styles.timeline}>
        <div className={styles.contentHeader}>
            <h2>
                Timeline
            </h2>
        </div>
        <Line
            options={{
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: 'Comments Received'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Day Posted to Regulations.Gov'
                        }
                    }
                },
                tooltips: {
                    // Overrides the global setting
                    mode: 'label'
                },
                interaction: {
                    mode: 'index'
                }
            }}
            data={{
                // x-axis label values
                labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'],
                datasets: [
                    {
                        label: "Current Years Data",
                        // y-axis data plotting values
                        data: currentStats,
                        fill: false,
                        borderWidth: 4,
                        backgroundColor: "#1D6583",
                        borderColor: '#1D6583',
                        responsive: true
                    },
                    {
                        label: "Previous Years Data",
                        // y-axis data plotting values
                        data: pastStats,
                        fill: false,
                        borderWidth: 4,
                        backgroundColor: "#baddf1",
                        borderColor: '#baddf1',
                        responsive: true
                    }
                ],
            }}
        />
    </div>
}

export default Overview;