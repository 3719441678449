import React from 'react';
import styles from './TopicSelection.module.css'
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../../General/icons/chevronIcon";

const TopicSelection = ({data, selection, updateSelection}) => {
    return (
        <div className={styles.wrapper}>
            {
                Object.keys(data).map(topic => (
                    <TopicGrouping key={topic}
                                   topicName={topic}
                                   topicChildren={data[topic].children}
                                   selection={selection}
                                   updateSelection={updateSelection}/>
                ))
            }
        </div>
    );
};

const TopicGrouping = ({topicName, topicChildren, selection, updateSelection}) => {

    const [expanded, setExpanded] = React.useState(false)

    const childrenCount = Object.keys(topicChildren).length

    const disabled = (childrenCount === 0 || selection.topic === topicName)

    const updateExpanded = (newVal) => {
        if (topicName === selection.topic) {
            setExpanded(true)
            return
        }

        if (disabled) {
            return;
        }

        setExpanded(newVal)
    }

    React.useEffect(() => {
        if (topicName === selection.topic) {
            setExpanded(true)
        }
    }, [topicName, selection])

    return (
        <div style={{marginBottom: 12}} key={topicName}>
            <div className={styles.topic_line}>
                <button onClick={() => updateExpanded(!expanded)}>
                    <ChevronIcon disabled={disabled}
                                 size={24}
                                 direction={(expanded && childrenCount > 0) ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN}/>
                </button>
                <p onClick={() => updateSelection(topicName, '')}
                   className={styles.topic}
                   style={{backgroundColor: (selection.topic === topicName && selection.subtopic === '') ? '#baf2ff' : ''}}
                >
                    {topicName} ({childrenCount} subtopic{childrenCount !== 1 ? 's' : ''})
                </p>
            </div>

            {
                expanded && Object.keys(topicChildren).map(subtopic => (
                    <p key={subtopic}
                       onClick={() => updateSelection(topicName, subtopic)}
                       className={styles.subtopic}
                       style={{backgroundColor: (selection.topic === topicName && selection.subtopic === subtopic) ? '#baf2ff' : ''}}
                    >
                        • {subtopic}
                    </p>
                ))
            }
        </div>
    )
}

export default TopicSelection;