import React from 'react';
import Loading from "../../General/loading/loading";

const CountdownEstimation = ({finishTime}) => {

    const [currentTime, setCurrentTime] = React.useState(new Date().getTime())

    React.useEffect(() => {
        const intervalID = window.setInterval(() => {
            setCurrentTime(new Date().getTime())
        }, 1000)

        return () => {
            window.clearInterval(intervalID)
        }
    }, [])

    let displayTime = "00:00:00"
    if (finishTime > currentTime) {
        const rawSeconds = Math.floor((finishTime - currentTime) / 1000)
        const hoursRemain = Math.floor(rawSeconds / 3600)
        const minutesRemain = Math.floor((rawSeconds - (hoursRemain * 3600)) / 60)
        const secondsRemain = Math.floor(rawSeconds - (hoursRemain * 3600) - (minutesRemain * 60))
        displayTime = `${hoursRemain.toString().padStart(2, "0")}:${minutesRemain.toString().padStart(2, "0")}:${secondsRemain.toString().padStart(2, "0")}`
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loading/>
            <h2 style={{margin: 0, marginTop: 24, padding: 0, fontSize: 22}}>Estimated Time Remaining: </h2>
            <p style={{margin: 0, padding: 0, fontSize: 22}}>{displayTime}</p>
        </div>
    );
};

export default CountdownEstimation;