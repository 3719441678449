//Returns the saved user preferences
import {useProductionURL} from "../helper/constants";

export const getUserPreferencesAPI = async (jwt) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/preferences`, {
        headers: {
            "auth": jwt
        }
    })

    return resp.json()
}

export const setUserPreferencesAPI = async (jwt, pdf, autoMarkViewedTopic, expandedKeywords) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/preferences`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            "pdf": pdf == null ? false : pdf,
            "autoMarkViewedTopic": autoMarkViewedTopic == null ? false : autoMarkViewedTopic,
            "expandedKeywords": expandedKeywords == null ? true : expandedKeywords
        })
    })

    return await resp.json()
}