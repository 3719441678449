const getNonRiderTopics = (jwt, docket, selectedTopics, docketCurrentTopics) => {
    let allTopicsResp = docketCurrentTopics

    let nonRiderTopics = {};

    for (let topicKey of Object.keys(selectedTopics)) {
        if (!allTopicsResp[topicKey].isRider) {
            nonRiderTopics[topicKey] = selectedTopics[topicKey];
        }
    }

    return nonRiderTopics;
};

export default getNonRiderTopics;