import {Document, Page, StyleSheet, Text, View, Image, Font} from "@react-pdf/renderer";
import React from "react";
import {useSelector} from "react-redux";

Font.register({
    family: 'Lato',
    fonts: [
        {src: 'https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk.ttf'},
        {src: 'https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk.ttf', fontWeight: 400},
        {src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew8.ttf', fontWeight: 700}
    ]
})

Font.register({
    family: 'Source_Sans_Pro',
    fonts: [
        {src: 'https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM4.ttf'},
        {
            src: 'https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM4.ttf',
            fontWeight: 400
        },
        {
            src: 'https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DCuXhM4.ttf',
            fontWeight: 700
        }
    ]
})

const myStyles = StyleSheet.create({
    pageWrapper: {
        display: "flex",
        flexDirection: 'column',
    },
    homepage: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 16,
        backgroundColor: '#FFFFFF',
        padding: 24,
    },
    section: {
        margin: 0,
        padding: 0,
        marginTop: "auto",
    },
    image: {
        width: 200
    },
    textSection: {
        width: '100%',
        textAlign: 'center'
    },
    topic: {
        fontWeight: 700,
        fontSize: 20,
        fontFamily: 'Source_Sans_Pro',
    },
    subtitle: {
        fontWeight: 400,
        fontSize: 16,
        fontFamily: 'Source_Sans_Pro',
    },
    aiDisclaimer: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: 12,
    },
    page: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 24,
        backgroundColor: '#FFFFFF',
        padding: 16,
    },
    summaryMetadataSection: {
        width: '100%',
        textAlign: 'left'
    },
    summarySection: {
        width: '100%',
        textAlign: 'justify'
    },
    summaryTopic: {
        fontWeight: 700,
        fontSize: 14,
        fontFamily: 'Source_Sans_Pro',
    },
    summaryText: {
        fontWeight: 400,
        fontSize: 12,
        fontFamily: 'Lato',
    },
    summaryComments: {
        fontWeight: 400,
        fontSize: 8,
        fontFamily: 'Lato',
    },
});

const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

// Create Document Component
const SummaryOfSummariesPDF = ({selected, comments, summaries}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);

    return (
        <Document>
            <Page size="A4" style={myStyles.pageWrapper}>
                <View style={myStyles.homepage}>
                    <View style={myStyles.section}>
                        <Image style={myStyles.image} source={"/assets/imgs/logo.jpg"}></Image>
                    </View>
                    <View style={myStyles.textSection}>
                        <Text style={myStyles.topic}>Top Level Summaries</Text>
                    </View>
                    <View style={myStyles.textSection}>
                        <Text style={myStyles.subtitle}>Prepared On {`${month}/${day}/${year}`}</Text>
                    </View>
                    <View style={myStyles.textSection}>
                        <Text style={myStyles.subtitle}>Docket: {docket}</Text>
                    </View>
                    <View style={{...myStyles.textSection, marginTop: "auto"}}>
                        <Text style={myStyles.aiDisclaimer}>This report was automatically produced with the assistance
                            of generative Artificial Intelligence and Large Language Models (LLMs).</Text>
                    </View>
                </View>
            </Page>
            {
                Object.keys(summaries).map(topicKey => (
                    <React.Fragment key={topicKey}>
                        <Page size="A4" style={myStyles.pageWrapper}>
                            <View style={myStyles.page}>
                                <View style={myStyles.summaryMetadataSection}>
                                    <Text style={myStyles.summaryTopic}>Topic: </Text>
                                    <Text style={myStyles.summaryText}>
                                        {topicKey}
                                    </Text>
                                </View>
                                <View style={myStyles.summaryMetadataSection}>
                                    <Text style={myStyles.summaryTopic}>Included Comments:</Text>
                                    <Text
                                        style={myStyles.summaryComments}>{comments[topicKey].comments.join(", ")}</Text>
                                </View>
                                <View style={myStyles.summarySection}>
                                    <Text style={myStyles.summaryTopic}>Top-Level Topic Summary:</Text>
                                    <Text style={myStyles.summaryText}>{summaries[topicKey].summary}</Text>
                                </View>
                            </View>
                        </Page>
                        {
                            Object.keys(summaries[topicKey].subtopics).map(subtopic => (
                                <React.Fragment key={subtopic}>
                                    <Page size="A4" style={myStyles.pageWrapper}>
                                        <View style={myStyles.page}>
                                            <View style={myStyles.summaryMetadataSection}>
                                                <Text style={myStyles.summaryTopic}>Topic: </Text>
                                                <Text style={myStyles.summaryText}>
                                                    {topicKey}
                                                </Text>
                                                <Text style={{...myStyles.summaryTopic, marginTop: 8}}>Subtopic: </Text>
                                                <Text style={myStyles.summaryText}>
                                                    {subtopic}
                                                </Text>
                                            </View>
                                            <View style={myStyles.summaryMetadataSection}>
                                                <Text style={myStyles.summaryTopic}>Included Comments:</Text>
                                                <Text
                                                    style={myStyles.summaryComments}>{comments[topicKey].subtopics[subtopic].join(", ")}</Text>
                                            </View>
                                            <View style={myStyles.summarySection}>
                                                <Text style={myStyles.summaryTopic}>Top-Level Subtopic Summary:</Text>
                                                <Text
                                                    style={myStyles.summaryText}>{summaries[topicKey].subtopics[subtopic]}</Text>
                                            </View>
                                        </View>
                                    </Page>
                                </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                ))
            }

        </Document>
    );
}

export default SummaryOfSummariesPDF;