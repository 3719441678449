import React from 'react';
import styles from './DiffDisplay.module.css'
import {useSelector} from "react-redux";
import {pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import cleanUpHTMLTags from "../../../helper/cleanUpHTMLTags";
import * as diff from "diff";
import {getCommentTextAPI} from "../../../api/commentAPI";
import Loading from "../loading/loading";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DiffDisplay = ({
                         trackingNum1,
                         attachment1,
                         trackingNum2,
                         attachment2,
                         mainDocumentName,
                         secondDocumentName,
                         showSimilarities = false
                     }) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [text1, setText1] = React.useState("");
    const [text2, setText2] = React.useState("")

    const [diffList, setDiffList] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const calculateDifferenceFields = () => {
        const temp = []

        console.log(diffList)
        let index = 0
        for (let diffItem of diffList) {
            if (diffItem.value.toString().trim() === "") {
                continue
            }
            if (diffItem.removed === true) {
                temp.push(<p className={styles.textSection} key={`${index}_${diffItem.value}`}
                             style={{backgroundColor: "#FFCECE", marginRight: 64}}>
                    <b>{mainDocumentName}</b>: {diffItem.value}</p>)
            } else if (diffItem.added === true) {
                temp.push(<p className={styles.textSection} key={`${index}_${diffItem.value}`}
                             style={{backgroundColor: "#CEFFCE", marginLeft: 64}}>
                    <b>{secondDocumentName}</b>: {diffItem.value}</p>)
            } else {
                if (showSimilarities) {
                    temp.push(<p className={styles.textSection}
                                 style={{backgroundColor: "transparent", marginLeft: 32, marginRight: 32}}
                                 key={`${index}_${diffItem.value}`}>{diffItem.value}</p>)
                }
            }
            index += 1
        }

        if (diffList.length > 0 && temp.length === 0 && !showSimilarities) {
            temp.push(<p key={"1"}>No Difference in Comments</p>)
        }

        return temp
    }

    React.useEffect(() => {
        setLoading(true)
        getCommentTextAPI(jwt, docket, trackingNum1, attachment1).then(r => {
            getCommentTextAPI(jwt, docket, trackingNum2, attachment2).then(r2 => {
                setText1(cleanUpHTMLTags(r).replaceAll("<br/>", " "))
                setText2(cleanUpHTMLTags(r2).replaceAll("<br/>", " "))
                setLoading(false)
            })
        })


    }, [attachment1, trackingNum1, trackingNum2, attachment2])

    React.useEffect(() => {
        const myDiff = diff.diffSentences(text1, text2)
        setDiffList(myDiff)
    }, [text1, text2])

    const diffText = React.useMemo(() => calculateDifferenceFields(), [diffList])

    return (
        <div className={styles.wrapper}>
            {
                loading ?
                    <Loading/> :
                    diffText
            }
        </div>
    );
};

export default DiffDisplay;