//Returns user data to populate user reducer
import {useProductionURL} from "../helper/constants";

export const loginAPI = async (username, password) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/signin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    });
    return await resp.json();
}

//Returns valid JWT on success or blank string on fail
export const isJWTValidAPI = async (jwt) => {

    if (jwt.includes(":")) {
        return ""
    }

    let resp;

    try {
        resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/verify_session`, {
            headers: {
                "auth": jwt
            }
        })
    } catch (error) {
        return ""
    }


    return resp.text()
}

export const checkUserAPI = async (fullName, email) => {
    try {
        const url = `http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/auth/check_user`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                full_name: fullName,
                email: email,
            }),
        });

        if (!response.ok) {
            const errorDetails = await response.text(); // Retrieve additional error details if available
            throw new Error(`HTTP error! Status: ${response.status}. Details: ${errorDetails}`);
        }

        const data = await response.json();

        // Validate response structure
        if (data && typeof data === 'object' && data.hasOwnProperty('success')) {
            return data;
        } else {
            throw new Error("Unexpected response structure. Data received: " + JSON.stringify(data));
        }
    } catch (error) {
        console.error("Error in checkUserAPI:", {
            message: error.message,
            stack: error.stack,
            fullName,
            email
        });
        return { success: false, message: error.message || "An error occurred" };
    }
};

export const verifyTempPasswordAPI = async (userName,tempPassword) => {
    try {
        const response = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/auth/verify_temp_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: userName,
                temp_password: tempPassword,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Validate response structure if needed
        if (data && typeof data === 'object' && data.hasOwnProperty('success')) {
            return data;
        } else {
            throw new Error("Unexpected response structure");
        }
    } catch (error) {
        console.error("Error in verifyTempPasswordAPI:", error);
        return { success: false, message: error.message || "An error occurred" };
    }
};

export const setNewPasswordAPI = async (newPassword, tempPassword) => {
    try {
        const response = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/auth/set_new_password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                new_password: newPassword,
                temp_password: tempPassword,
            }),
        });

        if (!response.ok) {
            // Try to get error message from JSON response
            const errorResponse = await response.json();
            const errorMessage = errorResponse.message || 'An unexpected error occurred';
            throw new Error(errorMessage);
        }

        const data = await response.json();

        // Validate response structure if needed
        if (data && typeof data === 'object' && data.hasOwnProperty('success')) {
            return data;
        } else {
            throw new Error("Unexpected response structure");
        }
    } catch (error) {
        console.error("Error in setNewPasswordAPI:", error);
        return { success: false, message: error.message || "An error occurred" };
    }
};

//Returns true if password was successfully changed
export const changePasswordAPI = async (jwt, oldPassword, newPassword) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/change_password?` + new URLSearchParams({
        oldpassword: oldPassword,
        newpassword: newPassword,
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns a list of all available dockets for a user
export const getAvailableDocketsAPI = async (jwt) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/get_available_dockets`, {
        headers: {
            "auth": jwt
        }
    })

    return resp.json()
}

export const getAvailableDocketsMetadataAPI = async (jwt) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/get_available_dockets/metadata`, {
        headers: {
            "auth": jwt
        }
    })

    return resp.json()
}
