import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import getUniqueColumnValues, {
    MajorCommenterReportFilterData_onlyMCs
} from "../../../helper/getUniqueColumnValues";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import {sortReportFilterDocIDs} from "../../../helper/sortDocIDsTo5Digits";
import updateReportFilters from "../../../helper/updateReportFilters";
import { setReportFilters, clearReportFilters } from "../../../store/reportsSlice";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import MultiSelectClear from "./multiselectFilter/multiselectClear";
import parseReportFilters from "../../../helper/parseReportFilters";

const headings = [
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 500
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 100
    },
    {
        accessorKey: "Status".toString().replaceAll(" ", "").toLowerCase(),
        header: "Status",
        size: 150
    },
    {
        accessorKey: "docid",
        header: "Document ID",
        size: 250,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Att Count".toString().replaceAll(" ", "").toLowerCase(),
        header: "Att Count",
        size: 100
    }
]
//["Commenter", "MC Group", "Status", "Document ID", "Attachment Count"]

const filterData = (data, docIDs, mcGroups, commenter, status) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[3]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[1]}`))) {
                continue
            }
        }

        if (commenter.length > 0) {
            if (!(commenter.includes(tempItem[0]))) {
                continue
            }
        }

        if (status.length > 0) {
            if (!(status.includes(tempItem[2]))) {
                continue
            }
        }


        tempItem[1] = MC_TITLES[tempItem[1]]
        temp.push(tempItem)
    }

    return [...temp]
}

const organizeDocumentIDs = (rawReportData, column) => {
    let temp = getUniqueColumnValues(rawReportData, column)
    temp = sortReportFilterDocIDs(temp)
    return temp
}

const downloadMCReport = (rawReportData, docket) => {

    let reportData = []

    for (let item of rawReportData) {

        let tempItem = [...item]

        if (tempItem[3] !== undefined && tempItem[3] !== null && tempItem[3] !== '') {
            tempItem.splice(4, 0, `${docket}-${tempItem[3]}`)
            tempItem.splice(6, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[3]}`)
        }

        reportData.push(tempItem)
    }

    let tempHeadings = [...headings]

    tempHeadings.splice(4, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(6, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    downloadReportData(tempHeadings, reportData, "major_commenter_report")
}

const MCReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const storedFilters = useSelector((state) => state.reports.mcReport);
    const dispatch = useDispatch()

    const uniqueDocumentIDs = React.useMemo(() => organizeDocumentIDs(rawReportData, 3), [rawReportData])
    const uniqueCommenters = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const uniqueStatuses = [{ label: "Not Submitted", value: "Not Submitted" },
    { label: "Under Review", value: "Under Review" },
    { label: "Complete", value: "Complete" }
    ]

    const [loading, setLoading] = React.useState(false)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "mcReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true);
        getReportData(jwt, docket, 3).then(r => {
            setRawReportData(r)
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "mcReport" }))
    }

    const filteredData = React.useMemo(
        () => filterData(rawReportData,
            parseReportFilters('docID', storedFilters), parseReportFilters('mcGroups', storedFilters),
            parseReportFilters('commenter', storedFilters), parseReportFilters('status', storedFilters)),
        [rawReportData, storedFilters])
    const uniqueCommentCount = React.useMemo(() => filteredData.length, [filteredData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"15vw"}
                    selected={parseReportFilters('docID', storedFilters)}
                    setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs} />
                <MultiSelectFilter title={"Commenter"} width={"15vw"}
                    selected={parseReportFilters('commenter', storedFilters)}
                    setSelected={(values) => updateFilter('commenter', values)}
                    options={uniqueCommenters} />
                <MultiSelectFilter title={"MC Group"} width={"15vw"}
                    selected={parseReportFilters('mcGroups', storedFilters)}
                    setSelected={(values) => updateFilter('mcGroups', values)}
                    options={MajorCommenterReportFilterData_onlyMCs} />
                <MultiSelectFilter title={"Status"} width={"15vw"}
                    selected={parseReportFilters('status', storedFilters)}
                    setSelected={(values) => updateFilter('status', values)} options={uniqueStatuses} />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "mcReport" }))} />
            </div>
            <div className={styles.title}>
                <p>Major Commenter Report <span style={{ marginLeft: 16 }}>({uniqueCommentCount} rows shown)</span>
                </p>
                <button onClick={() => downloadMCReport(rawReportData, docket)}>
                    <DownloadIcon />
                </button>
            </div>
            <div className={styles.table}>
                {loading ? <Loading /> :
                    <GenericTable data={filteredData}
                        headingsList={finalHeadings} />}
            </div>
        </div>
    );
};

export default MCReport;