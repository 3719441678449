import React, { useState } from 'react';
import styles from './login.module.css';
import LoginWrapper from "./loginWrapper";
import { useDispatch } from "react-redux";
import { LOGIN_STEP } from "../../helper/constants";
import { checkUserAPI, verifyTempPasswordAPI, setNewPasswordAPI } from "../../api/authAPI";
import { setLoginStatus } from "../../store/loginSlice";

const FORGOT_PASSWORD_STEPS = {
    VERIFICATION_INFO: 1,
    TEMP_PASSWORD: 2,
    NEW_PASSWORD: 3
};

const ForgotPassword = () => {
    const dispatch = useDispatch();

    // State for managing steps, form inputs, and loading status (1: Email/Name, 2: Temporary Password from Email, 3: New Password)
    const [state, setState] = useState({
        step: FORGOT_PASSWORD_STEPS.VERIFICATION_INFO,
        email: "",
        firstName: "",
        lastName: "",
        username: "",
        tempPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        loading: false,
        loadingMessage: "",
        error: ""
    });

    const { step, email, firstName, lastName, username, tempPassword, newPassword, confirmNewPassword,
            loading, loadingMessage, error } = state;
    const updateState = (updates) => setState(prevState => ({ ...prevState, ...updates }));

    const fullNameFormat = /^[a-zA-Z\s.'-]+$/;
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const name = `${firstName.trim()} ${lastName.trim()}`;

    // Handle the initial reset request
    const reset = async () => {
        // Validate input formats
        if (firstName === '') {
            window.alert("User's first name is blank");
            return;
        }

        if (lastName === '') {
            window.alert("User's last name is blank");
            return;
        }

        if (email === '') {
            window.alert("User's email is blank");
            return;
        }

        if (!fullNameFormat.test(name.trim().toLowerCase())) {
            window.alert("Full Name format is incorrect. Please use only letters and spaces.");
            return;
        }

        if (!emailFormat.test(email)) {
            window.alert("Email format is incorrect. Please enter a valid email address.");
            return;
        }

        updateState({ loading: true, loadingMessage: 'Verifying User in the Database' });
        try {
            const response = await checkUserAPI(name, email);
            if (response.success) {
                updateState({ step: FORGOT_PASSWORD_STEPS.TEMP_PASSWORD });
            } else {
                window.alert(response.message);
            }
        } catch (error) {
            console.error("Failed to check user:", error);
            window.alert("An error occurred. Please try again later.");
        } finally {
            updateState({ loading: false });
        }
    };

    // Handle temporary password verification
    const verifyTempPassword = async () => {
        updateState({ loading: true, loadingMessage: 'Checking Temporary Password' });
        try {
            // API call uses name (username) and tempPassword
            const response = await verifyTempPasswordAPI(username, tempPassword);
            if (response.success) {
                updateState({ step: FORGOT_PASSWORD_STEPS.NEW_PASSWORD });
            } else {
                window.alert(response.message);
            }
        } catch (error) {
            console.error("Failed to verify temporary password:", error);
            window.alert("An error occurred. Please try again later.");
        } finally {
            updateState({ loading: false });
        }
    };

    // Handle new password submission
    const submitNewPassword = async () => {
        if (newPassword !== confirmNewPassword) {
            return updateState({ error: "Passwords do not match each other." });
        }

        if (newPassword.length < 12) {
            return updateState({ error: "New password must be 12 characters or longer." });
        }

        // Check for spaces in the new password
        if (/\s/.test(newPassword)) {
            return updateState({ error: "New password must not contain spaces." });
        }

        let hasLower = false;
        let hasUpper = false;
        let hasNumber = false;
        let hasSymbol = false;

        for (let char of newPassword) {
            if (char >= 'a' && char <= 'z') hasLower = true;
            else if (char >= 'A' && char <= 'Z') hasUpper = true;
            else if (char >= '0' && char <= '9') hasNumber = true;
            else hasSymbol = true;
        }

        if (!hasLower) return updateState({ error: "New password must have a lowercase letter" });
        if (!hasUpper) return updateState({ error: "New password must have an uppercase letter" });
        if (!hasNumber) return updateState({ error: "New password must have a numerical digit" });
        if (!hasSymbol) return updateState({ error: "New password must have a special character" });

        updateState({ loading: true, loadingMessage: 'Setting New Password' });
        try {
            const response = await setNewPasswordAPI(newPassword, tempPassword);
            if (response.success) {
                window.alert("Password has been reset successfully. You can now log in.");
                dispatch(setLoginStatus(LOGIN_STEP.NEW_LOGIN));
            } else {
                window.alert(response.message);
            }
        } catch (error) {
            console.error("Failed to set new password:", error);
            window.alert("An error occurred. Please try again later.");
        } finally {
            updateState({ loading: false });
        }
    };

    // Return to the sign-in page
    const returnToSignIn = () => {
        dispatch(setLoginStatus(LOGIN_STEP.NEW_LOGIN));
    };

    // Render forms based on the current step
    const renderForm = () => {
        if (loading) {
            return (
                <div className={styles.loadingWrapper}>
                    <div className={styles.loadingSpinner} />
                    <p className={styles.loadingMessage}>{loadingMessage}</p>
                </div>
            );
        }

        switch (step) {
            case FORGOT_PASSWORD_STEPS.VERIFICATION_INFO:
                return (
                    <form className={styles.form}>
                        <label className={styles.fieldLabel} htmlFor="firstName">First Name:</label>
                        <input
                            aria-label="First Name"
                            onChange={e => updateState({ firstName: e.currentTarget.value })}
                            id="firstName"
                            className={styles.textField}
                            type="text"
                            value={firstName}
                        />

                        <label className={styles.fieldLabel} htmlFor="lastName">Last Name:</label>
                        <input
                            aria-label="Last Name"
                            onChange={e => updateState({ lastName: e.currentTarget.value })}
                            id="lastName"
                            className={styles.textField}
                            type="text"
                            value={lastName}
                        />

                        <label className={styles.fieldLabel} htmlFor="email">Recovery Email:</label>
                        <input
                            aria-label="Recovery Email"
                            onChange={e => updateState({ email: e.currentTarget.value })}
                            id="email"
                            className={styles.textField}
                            type="email"
                            value={email}
                        />

                        <button
                            style={{ marginTop: 50 }}
                            onClick={reset}
                            type="button"
                            className={styles.button}
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </button>

                        <button
                            disabled={false}
                            onClick={returnToSignIn}
                            type="button"
                            className={styles.returnToLoginButton}
                        >
                            Return to Sign In
                        </button>
                    </form>
                );
            case FORGOT_PASSWORD_STEPS.TEMP_PASSWORD:
                return (
                    <>
                        <p className={styles.infoText}>
                            A temporary password has been sent to your email. Please check your email and enter it below.
                        </p>
                        <form className={styles.form}>
                            <label className={styles.fieldLabel} htmlFor="userName">Username:</label>
                            <input
                                aria-label="Username"
                                onChange={e => updateState({ username: e.currentTarget.value })}
                                id="userName"
                                className={styles.textField}
                                type="text"
                                value={username}
                            />

                            <label className={styles.fieldLabel} htmlFor="tempPassword">Temporary Password:</label>
                            <input
                                aria-label="Temporary Password"
                                onChange={e => updateState({ tempPassword: e.currentTarget.value })}
                                id="tempPassword"
                                className={styles.textField}
                                type="password"
                                value={tempPassword}
                            />

                            <button
                                style={{ marginTop: 50 }}
                                onClick={verifyTempPassword}
                                type="button"
                                className={styles.button}
                                disabled={loading}
                            >
                                {loading ? "Verifying..." : "Submit"}
                            </button>
                            <button
                                disabled={false}
                                onClick={returnToSignIn}
                                type="button"
                                className={styles.returnToLoginButton}
                            >
                                Return to Sign In
                            </button>
                        </form>
                    </>
                );
            case FORGOT_PASSWORD_STEPS.NEW_PASSWORD:
                return (
                    <form className={styles.form}>
                        <label className={styles.fieldLabel} htmlFor="newPassword">New Password:</label>
                        <input
                            aria-label="New Password"
                            onChange={e => updateState({ newPassword: e.currentTarget.value })}
                            id="newPassword"
                            className={styles.textField}
                            type="password"
                            value={newPassword}
                        />

                        <label className={styles.fieldLabel} htmlFor="confirmNewPassword">Confirm Password:</label>
                        <input
                            aria-label="Confirm New Password"
                            onChange={e => updateState({ confirmNewPassword: e.currentTarget.value })}
                            id="confirmNewPassword"
                            className={styles.textField}
                            type="password"
                            value={confirmNewPassword}
                        />

                        <p className={styles.passwordRequirements}>
                            Passwords must be at least 12 characters long, have upper and lower case letters,
                            and include a number and a symbol.
                        </p>

                        {/* Displaying error message */}
                        {error && <div className={styles.errorText}>{error}</div>}

                        <button
                            style={{ marginTop: 50 }}
                            onClick={submitNewPassword}
                            type="button"
                            className={styles.button}
                            disabled={loading}
                        >
                            {loading ? "Setting Password..." : "Submit"}
                        </button>
                        <button
                            disabled={false}
                            onClick={returnToSignIn}
                            type="button"
                            className={styles.returnToLoginButton}
                        >
                            Return to Sign In
                        </button>
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <LoginWrapper>
            {renderForm()}
        </LoginWrapper>
    );
};

export default ForgotPassword;