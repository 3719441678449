import React from 'react';
import styles from "./reviewScreen.module.css"
import {useDispatch, useSelector} from "react-redux";
import {
    changeCommentStateAPI, checkIfCanReturnToPR_API, getCommentKeywords_API,
    getCommentMetadataAPI,
    getInProgressReviewsAPI, markCommentAsFinished,
    pauseAllAPI, returnToPR_API, startNewReviewAPI, updateNameAPI
} from "../../../api/reviewsAPI";
import TextDisplay from "../../General/TextDisplay/TextDisplay";
import ReviewIcon from "../../General/icons/reviewIcon";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../General/icons/chevronIcon";
import {MC_TITLES, REVIEW_TYPES} from "../../../helper/constants";
import ReviewProgressCard from "./reviewProgressCard/reviewProgressCard";
import {modalClose, modalOpen} from "../../../store/modalSlice";
import HistoryModal from "./modals/historyModal";
import FormLetterModal from "./modals/formLetterModal";
import {addToFormLetterAPI} from "../../../api/formLetterAPI";
import Loading from "../../General/loading/loading";
import {addNewExcerptAPI, deleteExistingExcerptAPI, getCommentExcerptsAPI} from "../../../api/excerptsAPI";

const REVIEW_SCREEN_MODALS = {
    NONE: 0,
    MOVE_TO_FL: 1,
    HISTORY: 2
}

const ReviewScreen = ({setReviewType, reviewType, prAllowed, qaAllowed}) => {

    const [inProgressReviews, setInProgressReviews] = React.useState([])
    const [pausedReviews, setPausedReviews] = React.useState([])
    const [selectedReviewComment, setSelectedReviewComment] = React.useState(["", 0, ""])
    const selectedReviewID = (selectedReviewComment[0] === undefined || selectedReviewComment[0] === "") ? "No Comment Selected" : selectedReviewComment[0]
    const [attachments, setAttachments] = React.useState([0])
    const [isInProgress, setIsInProgress] = React.useState(true)
    const [metadata, setMetadata] = React.useState(["Unknown", "Unknown", "Unknown"])
    const [commenterInfo, setCommenterInfo] = React.useState(["Unknown", 3])
    const [loadingNewReview, setLoadingNewReview] = React.useState(false)
    const [openModalType, setOpenModalType] = React.useState(REVIEW_SCREEN_MODALS.NONE)
    const [excerpts, setExcerpts] = React.useState({})

    const [keywords, setKeywords] = React.useState({})
    const [keywordTopicSelected, setKeywordTopicSelected] = React.useState('')
    const [showKeywordDisplay, setShowKeywordDisplay] = React.useState(true)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const excerptDocketPerms = useSelector((state) => state.dockets.docketExcerpts);
    const dispatch = useDispatch();

    const [canReturnToPRFromQA, setCanReturnToPRFromQA] = React.useState(false)
    const [expandedQueue, setExpandedQueue] = React.useState(true);
    const useExcerpts = (excerptDocketPerms[docket] ?? false) && (commenterInfo[1] === 1 || commenterInfo[1] === 2) && (selectedReviewComment[2]);

    const changeModal = (type) => {
        if (type === REVIEW_SCREEN_MODALS.NONE) {
            dispatch(modalClose())
        } else {
            dispatch(modalOpen())
        }

        setOpenModalType(type)
    }

    const fetchInProgressReviews = () => {
        getInProgressReviewsAPI(jwt, docket, reviewType === REVIEW_TYPES.PR).then(r => {
            if (r.success) {
                setInProgressReviews(r.data.inProgress)
                setPausedReviews(r.data.other)

                let tNum = ''
                if (r.data.inProgress.length > 0) {
                    let item = r.data.inProgress[0]
                    setSelectedReviewComment([item[0], item[1][0], item[2]])
                    setAttachments(item[1])
                    setIsInProgress(true)
                    tNum = item[2]

                } else if (r.data.other.length > 0) {
                    let item = r.data.other[0]
                    setSelectedReviewComment([item[0], item[1][0], item[2]])
                    setAttachments(item[1])
                    setIsInProgress(false)

                    tNum = item[2]
                } else {
                    setSelectedReviewComment(["", 0, ""])
                    setAttachments([0])
                    setIsInProgress(true)
                }

                if (tNum !== '') {
                    setKeywords({})
                    getCommentKeywords_API(jwt, docket, tNum).then(r => {
                        if (r.success) {
                            setShowKeywordDisplay(true)
                            setKeywords(r.data)
                        }
                    })
                }
            }
        })
    }
    const returnToPR = () => {
        returnToPR_API(jwt, docket, selectedReviewComment[2]).then(r => {
            if (r.success) {
                fetchInProgressReviews()
            }
        })
    }

    const updateSelected = (trackingNumber, isPR) => {
        setKeywords({})
        getCommentKeywords_API(jwt, docket, trackingNumber).then(r => {
            if (r.success) {
                setShowKeywordDisplay(true)
                setKeywords(r.data)
            }
        })

        if (isPR) {
            for (let item of inProgressReviews) {
                if (item[2] === trackingNumber) {
                    setSelectedReviewComment([item[0], item[1][0], item[2]])
                    setAttachments(item[1])
                    setIsInProgress(true)
                    return
                }
            }
        } else {
            for (let item of pausedReviews) {
                if (item[2] === trackingNumber) {
                    setSelectedReviewComment([item[0], item[1][0], item[2]])
                    setAttachments(item[1])
                    setIsInProgress(false)
                    return;
                }
            }
        }

        setSelectedReviewComment(["", 0, ""])
        setAttachments([0])
        setIsInProgress(false)
    }

    const moveCommentStatus = (movingToPaused) => {
        changeCommentStateAPI(jwt, docket, selectedReviewComment[2], movingToPaused, reviewType === REVIEW_TYPES.PR).then(r => {
            let inProgress = []
            let paused = []
            if (movingToPaused) {
                paused = [...pausedReviews]

                for (let item of inProgressReviews) {
                    if (item[2] === selectedReviewComment[2]) {
                        paused.push(item)
                    } else {
                        inProgress.push(item)
                    }
                }
            } else {
                inProgress = [...inProgressReviews]

                for (let item of pausedReviews) {
                    if (item[2] === selectedReviewComment[2]) {
                        inProgress.push(item)
                    } else {
                        paused.push(item)
                    }
                }
            }

            setInProgressReviews(inProgress)
            setPausedReviews(paused)
            setIsInProgress(!movingToPaused)
        })
    }

    const pauseAll = () => {
        pauseAllAPI(jwt, docket, reviewType === REVIEW_TYPES.PR).then(r => {
            if (r.success) {
                setPausedReviews([...inProgressReviews, ...pausedReviews])
                setInProgressReviews([])
                setIsInProgress(false)
                setSelectedReviewComment(["", 0, ""])
            }
        })
    }

    const changeViewedAttachment = (attachment) => {
        setSelectedReviewComment([selectedReviewComment[0], attachment, selectedReviewComment[2]])
    }

    const startNewReview = () => {
        setLoadingNewReview(true)
        startNewReviewAPI(jwt, docket, reviewType === REVIEW_TYPES.PR).then(r => {
            setLoadingNewReview(false)
            if (r.success) {
                setInProgressReviews([r.data])
                setSelectedReviewComment([r.data[0], r.data[1][0], r.data[2]])
                setAttachments(r.data[1])
                setIsInProgress(true)
            } else {
                if (reviewType === REVIEW_TYPES.PR) {
                    window.alert("The queue is empty.")
                } else {
                    window.alert("The QA queue is either empty or has no more comments available for you to QA (because you were the PR).")
                }
            }
        })
    }

    // console.log(selectedReviewComment)

    const updateCommenterInfo = (commenterName) => {
        updateNameAPI(jwt, docket, selectedReviewComment[2], commenterName).then(r => {
            if (r.success) {
                setCommenterInfo(r.data)

                // Update comment excerpts if changing back to an MC in an excerpt-enabled docket
                if (r.data[1] < 3 && (excerptDocketPerms[docket] ?? false)) {
                    getCommentExcerptsAPI(jwt, docket, selectedReviewComment[2]).then(r => {
                        if (r.success) {
                            setExcerpts(r.data)
                        }
                    })
                }
            }
        })
    }

    const finishComment = () => {
        markCommentAsFinished(jwt, docket, selectedReviewComment[2], reviewType === REVIEW_TYPES.PR).then(r => {
            if (r.success) {
                let temp = []

                for (let item of inProgressReviews) {
                    if (item[2] === selectedReviewComment[2]) {

                    } else {
                        temp.push(item)
                    }
                }

                setInProgressReviews(temp)

                if (temp.length < 1) {
                    setSelectedReviewComment(["", 0, ""])
                    setAttachments([0])
                    setIsInProgress(true)
                } else {
                    setSelectedReviewComment([temp[0][0], 0, temp[0][2]])
                    setAttachments(temp[0][1])
                    setIsInProgress(true)
                }
            } else {
                if (r.message !== undefined && r.message !== '') {
                    window.alert(r.message)
                } else {
                    window.alert("Required fields are missing in this comment. Please make sure to check the topic, summary, and out of scope tabs")
                }
            }
        }).catch(e => window.alert("There was an error finishing the comment. Please work with the helpdesk to resolve this issue"))
    }

    const moveToFormLetter = (batchID) => {

        let documentID = selectedReviewComment[0]
        let attachment = selectedReviewComment[1]

        addToFormLetterAPI(jwt, docket, batchID, documentID, attachment).then(r => {
            if (r.success) {
                let temp = []

                for (let item of inProgressReviews) {
                    if (item[0] === documentID && item[1].includes(attachment)) {

                    } else {
                        temp.push(item)
                    }
                }

                setInProgressReviews(temp)

                changeModal(REVIEW_SCREEN_MODALS.NONE)

                if (temp.length < 1) {
                    setSelectedReviewComment(["", 0, ""])
                    setAttachments([0])
                    setIsInProgress(true)
                } else {
                    setSelectedReviewComment([temp[0][0], 0, temp[0][2]])
                    setAttachments(temp[0][1])
                    setIsInProgress(true)
                }
            } else {
                window.alert(r.msg ?? "An unknown error occurred")
            }
        })
    }

    const addNewExcerptBulk = (text, indexes, provisions, setLoading = (val) => {
    }, currentAttach = undefined) => {
        let att = currentAttach;
        if (currentAttach === undefined) {
            att = selectedReviewComment[1]
        }

        const promiseArray = [];
        for (let provision of provisions) {
            promiseArray.push(addNewExcerptAPI(jwt, docket, selectedReviewComment[2], att,
                provision[0], provision[1], text, indexes))
        }

        Promise.allSettled(promiseArray).then(values => {
            getCommentExcerptsAPI(jwt, docket, selectedReviewComment[2]).then(r => {
                if (r.success) {
                    setExcerpts(r.data)
                    setLoading(true)
                }
            })
        })
    }

    const removeNewExcerptBulk = (
        excerpt_ids,
        setLoading = (val) => {
        }
    ) => {

        const promiseArray = [];
        for (let excerptID of excerpt_ids) {
            promiseArray.push(deleteExistingExcerptAPI(jwt, docket, excerptID))
        }

        Promise.allSettled(promiseArray).then(values => {
            getCommentExcerptsAPI(jwt, docket, selectedReviewComment[2]).then(r => {
                if (r.success) {
                    setExcerpts(r.data)
                    setLoading(true)
                }
            })
        })
    }

    const getCurrentTopicKeywordList = () => {
        if (!showKeywordDisplay) {
            return []
        }

        try {
            const kwTopicSplit = keywordTopicSelected.split("||")
            if (kwTopicSplit[1] == null || kwTopicSplit[1] === '') {
                return keywords[kwTopicSplit[0]].keywords
            } else {
                return keywords[kwTopicSplit[0]].children[kwTopicSplit[1]]
            }
        } catch (e) {
            return []
        }
    }

    React.useEffect(() => {
        fetchInProgressReviews()
    }, [jwt, docket, reviewType])

    React.useEffect(() => {
        setMetadata(["Loading", "Unknown", "Unknown"])
        getCommentMetadataAPI(jwt, docket, selectedReviewComment[2]).then(r => {
            if (r.success) {
                setMetadata([r.data[0], r.data[5], r.data[4]])
                setCommenterInfo([r.data[1], r.data[2]])
            }
        })
    }, [jwt, docket, selectedReviewComment, isInProgress])

    React.useEffect(() => {
        setCanReturnToPRFromQA(false)
        checkIfCanReturnToPR_API(jwt, docket, selectedReviewComment[2]).then(r => {
            if (r.success) {
                setCanReturnToPRFromQA(r.data)
            }
        })

        setExcerpts({})
        getCommentExcerptsAPI(jwt, docket, selectedReviewComment[2]).then(r => {
            if (r.success) {
                setExcerpts(r.data)
            }
        })

        setKeywords({})
        getCommentKeywords_API(jwt, docket, selectedReviewComment[2]).then(r => {
            if (r.success) {
                setShowKeywordDisplay(true)
                setKeywords(r.data)
            }
        })
    }, [jwt, docket, selectedReviewComment])

    return (
        <div className={`${styles.wrapper} ${expandedQueue ? "" : styles.wrapperRowShrink}`}>

            <div className={styles.controlBar}>
                <div className={styles.controlBarReviewGrouping}>
                    <button disabled={inProgressReviews.length > 0 || loadingNewReview} onClick={startNewReview}>
                        <img
                            onContextMenu={() => {
                                return false;
                            }}
                            style={{
                                height: 18,
                                width: 18,
                                opacity: (inProgressReviews.length > 0 || loadingNewReview) ? 0.4 : 1
                            }}
                            src={"/assets/imgs/add.svg"}
                            alt={"Start Icon"}
                        />

                        Start New Review
                    </button>
                </div>

                <div className={styles.controlBarReviewGrouping}>
                    {
                        reviewType === REVIEW_TYPES.QA && (
                            <button onClick={returnToPR} disabled={!canReturnToPRFromQA || !isInProgress}>

                                <img
                                    onContextMenu={() => {
                                        return false;
                                    }}
                                    style={{
                                        height: 18,
                                        width: 18,
                                        opacity: (!canReturnToPRFromQA || !isInProgress) ? 0.4 : 1
                                    }}
                                    src={"/assets/imgs/return.svg"}
                                    alt={"Return Icon"}
                                />

                                Return to Primary Reviewer
                            </button>
                        )
                    }
                    <button onClick={() => changeModal(REVIEW_SCREEN_MODALS.MOVE_TO_FL)} disabled={!isInProgress}>
                        <img
                            onContextMenu={() => {
                                return false;
                            }}
                            style={{height: 18, width: 18, opacity: (!isInProgress) ? 0.4 : 1}}
                            src={"/assets/imgs/folder.svg"}
                            alt={"Move Icon"}
                        />

                        Move to Form Letter
                    </button>
                </div>

                <div className={styles.controlBarReviewGrouping}>
                    <p className={styles.controlBarLabel}>Current Review Type:</p>
                    <p className={styles.controlBarValue}>{reviewType}</p>
                    {
                        prAllowed && qaAllowed && (
                            <>
                                {
                                    reviewType === REVIEW_TYPES.PR ?
                                        <button onClick={() => setReviewType(REVIEW_TYPES.QA)}>
                                            <img
                                                onContextMenu={() => {
                                                    return false;
                                                }}
                                                style={{height: 18, width: 18, opacity: (!isInProgress) ? 0.4 : 1}}
                                                src={"/assets/imgs/arrows.svg"}
                                                alt={"Switch Icon"}
                                            />

                                            Switch to QA Reviews
                                        </button> :
                                        <button onClick={() => setReviewType(REVIEW_TYPES.PR)}>
                                            <img
                                                onContextMenu={() => {
                                                    return false;
                                                }}
                                                style={{height: 18, width: 18, opacity: (!isInProgress) ? 0.4 : 1}}
                                                src={"/assets/imgs/arrows.svg"}
                                                alt={"Switch Icon"}
                                            />

                                            Switch to Primary Reviews
                                        </button>
                                }
                                <div></div>
                            </>
                        )
                    }
                </div>
            </div>

            <div className={styles.historyModal}>
                <button onClick={() => changeModal(REVIEW_SCREEN_MODALS.HISTORY)} className={styles.historyButton}>
                    <ReviewIcon size={"70%"}/>
                </button>
            </div>

            <div className={`${styles.commentSelect} ${expandedQueue ? "" : styles.commentSelectClosed}`}>

                {
                    expandedQueue &&
                    <React.Fragment>
                        <div style={{width: '100%', marginBottom: 8}}>
                            <button style={{textDecoration: "underline"}} className={styles.queueToggleButton}
                                    onClick={() => setExpandedQueue(false)}>
                                <ChevronIcon size={20} direction={CHEVRON_DIRECTIONS.LEFT} bold={false}/>
                                Minimize Queue
                            </button>
                        </div>
                        <p className={styles.commentSelectLabel}>In Progress Comments</p>
                        {
                            loadingNewReview ?
                                <Loading/> :
                                <select value={selectedReviewComment[2]}
                                        onChange={e => updateSelected(e.currentTarget.value, true)}
                                        className={styles.selectBox} name="inProgress" id="inProgress" size={8}>
                                    <option value={""}>---</option>
                                    {
                                        inProgressReviews.map(review => (
                                            <option key={review[0]} value={review[2]}>{review[0]}</option>
                                        ))
                                    }
                                </select>
                        }

                        <div className={styles.commentSelectButtonWrapper}>
                            <button onClick={() => moveCommentStatus(true)} disabled={!isInProgress}>
                                <ChevronIcon disabled={!isInProgress} direction={CHEVRON_DIRECTIONS.DOWN}/>
                            </button>

                            <button disabled={inProgressReviews.length < 1} onClick={pauseAll}>
                                Pause All
                            </button>

                            <button onClick={() => moveCommentStatus(false)} disabled={isInProgress}>
                                <ChevronIcon disabled={isInProgress} direction={CHEVRON_DIRECTIONS.UP}/>
                            </button>
                        </div>

                        <p className={styles.commentSelectLabel}>Paused Comments</p>
                        <select value={selectedReviewComment[2]}
                                onChange={e => updateSelected(e.currentTarget.value, false)}
                                className={styles.selectBox} name="pausedReviews" id="pausedReviews" size={8}>
                            <option value={""}>---</option>
                            {
                                pausedReviews.map(review => (
                                    <option key={review[0]} value={review[2]}>{review[0]}</option>
                                ))
                            }
                        </select>
                    </React.Fragment>
                }

                {
                    !expandedQueue &&
                    <button className={styles.queueToggleButton} onClick={() => setExpandedQueue(true)}>
                        <ChevronIcon size={20} direction={CHEVRON_DIRECTIONS.RIGHT} bold={true}/>
                    </button>
                }

            </div>

            <div className={styles.commentStatus} style={{display: expandedQueue ? "" : "none"}}>

                <div className={styles.commentStatusItem}>
                    <p className={styles.commentStatusLabel}>
                        Status:
                    </p>
                    <p className={styles.commentStatusValue}>
                        {metadata[0] ?? "Unknown"}
                    </p>
                </div>

                <div className={styles.commentStatusItem}>
                    <p className={styles.commentStatusLabel}>
                        Major Commenter?
                    </p>
                    <p className={styles.commentStatusValue}>
                        {MC_TITLES[commenterInfo[1] ?? 3]}
                    </p>
                </div>

                {
                    reviewType === REVIEW_TYPES.QA && (
                        <>
                            <div className={styles.commentStatusDivider}/>
                            <div className={styles.commentStatusItem}>
                                <p className={styles.commentStatusLabel}>
                                    Primary Reviewer:
                                </p>
                                <p className={styles.commentStatusValue}>
                                    {metadata[1] ?? "Unknown"}
                                </p>
                            </div>
                        </>
                    )
                }
            </div>

            <div className={`${styles.documentViewer} ${expandedQueue ? "" : styles.documentViewerExpanded}`}>
                <div className={styles.attachmentSelectLine}>
                    {
                        attachments.length === 1 &&
                        <p className={styles.attachmentSelectLabel}>{selectedReviewID}: General Comment (0) </p>
                    }
                    {
                        attachments.length === 2 &&
                        <>
                            <p className={styles.attachmentSelectLabel}>{selectedReviewID}: {selectedReviewComment[1] === 1 ? "Attachment 1" : "General Comment (0)"}</p>
                            <button className={styles.attachmentButton}
                                    onClick={() => changeViewedAttachment(selectedReviewComment[1] === 1 ? 0 : 1)}>View {selectedReviewComment[1] === 1 ? "General Comment" : "Attachment 1"}</button>
                        </>
                    }
                    {
                        attachments.length > 2 &&
                        <>
                            <p className={styles.attachmentSelectLabel}>{selectedReviewID} ({attachments.length - 1} attachments): </p>
                            <select value={selectedReviewComment[1]}
                                    onChange={e => changeViewedAttachment(e.currentTarget.value)}
                                    className={styles.attachmentSelectDropdown} name="attachmentSelect"
                                    id="attachmentSelect">
                                {
                                    attachments.map(attachment => {
                                        if (attachment === 0) {
                                            return <option key={attachment} value={attachment}>General Comment
                                                (0)</option>
                                        } else {
                                            return <option key={attachment}
                                                           value={attachment}>Attachment {attachment}</option>
                                        }
                                    })
                                }

                            </select>
                        </>
                    }
                </div>
                <div className={styles.documentViewDivider}/>
                <div className={styles.textDisplayWrapper}>
                    <TextDisplay documentID={selectedReviewComment[0]} attachment={selectedReviewComment[1]}
                                 trackingNum={selectedReviewComment[2]} isPaused={!isInProgress}
                                 useExcerpt={useExcerpts} excerpts={excerpts}
                                 keywordList={getCurrentTopicKeywordList()}
                                 newExcerptFunc={addNewExcerptBulk} deleteExcerptFunc={removeNewExcerptBulk}/>
                </div>
            </div>

            <div className={styles.reviewPanel}>
                <ReviewProgressCard isPaused={!isInProgress} submitFunction={finishComment} useExcerpts={useExcerpts}
                                    excerpts={excerpts}
                                    keywords={keywords} keywordTopic={keywordTopicSelected}
                                    setKeywordTopic={setKeywordTopicSelected}
                                    keywordDisplay={showKeywordDisplay} setKeywordDisplay={setShowKeywordDisplay}
                                    commenterInfo={commenterInfo} updateCommenterInfo={updateCommenterInfo}
                                    trackingNumber={selectedReviewComment[2]}/>
            </div>
            <HistoryModal isPrimary={reviewType === REVIEW_TYPES.PR}
                          open={openModalType === REVIEW_SCREEN_MODALS.HISTORY}
                          closeModal={() => changeModal(REVIEW_SCREEN_MODALS.NONE)}/>
            <FormLetterModal moveToFLFunction={moveToFormLetter} selectedDocument={selectedReviewComment}
                             open={openModalType === REVIEW_SCREEN_MODALS.MOVE_TO_FL}
                             closeModal={() => changeModal(REVIEW_SCREEN_MODALS.NONE)}/>
        </div>
    );
};

export default ReviewScreen;