import React from 'react';
import styles from "./approveIcon.module.css"

const ApproveIcon = ({
                         size = 24, disabled = false, invert = false, onClick = () => {
    }
                     }) => {

    let iconStr = "/assets/imgs/approve.svg"

    const whenClicked = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <img onClick={whenClicked}
             className={`${disabled ? styles.disabled : styles.icon}  ${invert ? styles.invert : ""}`}
             onContextMenu={() => {
                 return false;
             }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default ApproveIcon;