import React from 'react';
import styles from "./components.module.css"
import {calculateTempScoreFLAPI, getNonFLParentAttachmentsAPI} from "../../../../api/formLetterAPI";
import {useSelector} from "react-redux";
import TextDisplay from "../../../General/TextDisplay/TextDisplay";
import {getTrackingNumberAPI} from "../../../../api/commentAPI";
import TrashIcon from "../../../General/icons/trashIcon";
import DiffDisplay from "../../../General/DiffDisplay/DiffDisplay";

const AddNewFormLetterChild = ({standard, items, setItems}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [item, setItem] = React.useState(["", 0, ""])

    const [attachments, setAttachments] = React.useState([])
    const [error, setError] = React.useState(false)

    const [tempScore, setTempScore] = React.useState(0)

    const [showDiff, setShowDiff] = React.useState(false)

    const updateChildItem = (value, index) => {
        let temp = [...item]
        temp[index] = value
        setItem([...temp])
    }

    const updateDocID = (value) => {
        setAttachments([])
        setError(false)
        setItem([value, 0, ''])
    }

    const searchAttachments = () => {
        if (item[0] === '') {
            return;
        }

        getNonFLParentAttachmentsAPI(jwt, docket, item[0]).then(r => {
            if (r.success) {

                let attachs = [...r.data]
                if (standard[0] === item[0]) {
                    attachs = attachs.filter(datapoint => datapoint !== parseInt(standard[1]))
                }

                if (attachs.length > 0) {
                    setError(false)
                    setAttachments(attachs)

                    getTrackingNumberAPI(jwt, docket, item[0]).then(r => {
                            console.log(r)
                            if (r.length > 0) {
                                console.log([item[0], attachs[0], r])
                                setItem([item[0], attachs[0], r])
                            } else {
                                setItem([item[0], attachs[0], ''])
                            }
                        }
                    )
                } else {
                    setAttachments([])
                    setError(true)
                    updateChildItem(-1, 1)
                }
            }
        })

    }

    const submitItem = () => {
        for (let storedItem of items) {
            if (item[2] === storedItem[2] && item[1] === storedItem[1]) {
                //Item is already in list
                window.alert("This item is already in your list")
                return;
            }
        }

        if (item[2] === standard[2] && item[1] === standard[1]) {
            //Item is the standard
            window.alert("You cannot add the standard to the form letter batch")
            return;
        }

        setItems([...items, item])
        setItem(['', 0, ''])
        setError(false)
        setAttachments([])
    }

    const deleteItem = (trackingNumber, attachments) => {
        const tempItems = []

        for (let storedItem of items) {
            if (trackingNumber === storedItem[2] && attachments === storedItem[1]) {
                //Item should be deleted
            } else {
                tempItems.push(storedItem)
            }
        }

        setItems([...tempItems])
    }

    const rapidSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchAttachments();
        }
    }

    React.useEffect(() => {
        setTempScore(-1)
        setShowDiff(false)

        if (item[2] === undefined || item[2] === '') {
            return;
        }

        calculateTempScoreFLAPI(jwt, docket, standard[2], standard[1], item[2], item[1]).then(r => {
            if (r.success) {
                setTempScore(r.data)
            }
        })
    }, [jwt, docket, item[2], item[1]])

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.sectionHeader}>Add Documents to New Form Letter</h3>
            <p style={{marginBottom: 12}} className={styles.label}>Standard: {standard[0]} {standard[1]}</p>
            <div className={styles.standardSelect}>
                <div
                    style={{display: 'flex', flexDirection: 'column', gap: 16, width: 320, flexGrow: 0, flexShrink: 0}}>
                    <p className={styles.label}>Enter Document ID: </p>
                    <input onKeyDown={rapidSubmit} className={styles.input} value={item[0]}
                           onChange={e => updateDocID(e.currentTarget.value)} type={'text'}/>
                    <button style={{marginBottom: 24}} className={styles.button} onClick={searchAttachments}>Find
                        Eligible Attachments
                    </button>

                    {
                        (!error && attachments.length > 0) ?
                            <React.Fragment>
                                <p className={styles.label}>Choose Eligible Attachment: </p>
                                <select className={styles.input} value={item[1]}
                                        onChange={e => updateChildItem(e.currentTarget.value, 1)}>
                                    {
                                        attachments.map(attachment => {
                                            if (attachment === 0) {
                                                return (<option key={attachment} value={0}>General Comment (0)</option>)
                                            }

                                            return (<option key={attachment}
                                                            value={attachment}>Attachment {attachment}</option>)
                                        })
                                    }
                                </select>
                                <button style={{marginBottom: 24, backgroundColor: '#225e7c', color: 'white'}}
                                        className={styles.button} onClick={submitItem}>Add Document to New Form Letter
                                </button>
                            </React.Fragment> :
                            (error) ?
                                <p className={styles.error}>Either the document ID does not exist
                                    or all attachments are parents of other Form Letters</p>
                                : null
                    }
                </div>
                <div className={styles.textDisplayWrapper}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 8,
                        padding: 8,
                        borderBottom: '1px solid black'
                    }}>
                        <p style={{margin: 0}}><b>Similarity to
                            Standard: </b>{tempScore === -1 ? 'Unknown' : `${tempScore}%`}</p>
                        <button onClick={() => setShowDiff(!showDiff)} style={{flexGrow: 0, width: 250}}
                                className={styles.button}>{showDiff ? "Switch to Text View" : "Switch to Difference View"}</button>
                    </div>
                    <div style={{width: "100%", height: "90%", flexGrow: 1, display: showDiff ? 'none' : 'block'}}>
                        {
                            (!error && attachments.length > 0) ?
                                <div className={styles.compareTextDisplays}>
                                    <div className={styles.textDisplayFlexWrapper}>
                                        <p style={{margin: 0}}><b>Standard</b></p>
                                        <TextDisplay documentID={standard[0]} attachment={standard[1]}
                                                     trackingNum={standard[2]}/>
                                    </div>
                                    <div className={styles.divider}/>
                                    <div className={styles.textDisplayFlexWrapper}>
                                        <p style={{margin: 0}}><b>Potential New Comment</b></p>
                                        <TextDisplay documentID={item[0]} attachment={item[1]} trackingNum={item[2]}/>
                                    </div>
                                </div>
                                :
                                <p>Waiting for document ID & attachment to be selected...</p>
                        }
                    </div>
                    <div style={{width: "100%", height: "90%", flexGrow: 1, display: showDiff ? 'block' : 'none'}}>
                        {
                            (!error && attachments.length > 0) ?
                                <DiffDisplay attachment2={item[1]} trackingNum2={item[2]} attachment1={standard[1]}
                                             trackingNum1={standard[2]} mainDocumentName={"Standard"}
                                             showSimilarities={true}
                                             secondDocumentName={"Potential Comment"}/> :
                                <p>Difference calculation not possible...</p>
                        }
                    </div>
                </div>
                <div className={styles.divider}/>
                <div
                    style={{display: 'flex', flexDirection: 'column', gap: 12, width: 250, flexGrow: 0, flexShrink: 0}}>
                    <p className={styles.label}>Comments to Add:</p>
                    <p className={styles.label}>(4 minimum)</p>
                    {
                        items.map(storedItem => (
                            <div className={styles.spreadItems}>
                                <p>{storedItem[0]} {storedItem[1]}</p>
                                <button onClick={() => deleteItem(storedItem[2], storedItem[1])}>
                                    <TrashIcon size={16}/>
                                </button>
                            </div>
                        ))
                    }
                    {
                        items.length === 0 &&
                        <p className={styles.error}>
                            No items in new Form Letter batch
                        </p>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddNewFormLetterChild;