import {createSlice} from '@reduxjs/toolkit'

export const docketsSlice = createSlice({
    name: 'dockets',
    initialState: {
        docketLinks: {},
        docketExcerpts: {},
        docketCurrentTopics: {},
        docketTopicsReference: {}
    },
    reducers: {
        setDocketLinks: (state, action) => {
            state.docketLinks = action.payload;
        },
        setDocketExcerpts: (state, action) => {
            state.docketExcerpts = action.payload;
        },
        setDocketTopics: (state, action) => {
            state.docketCurrentTopics = action.payload;
        },
        setDocketTopicsReference: (state, action) => {
            state.docketTopicsReference = action.payload
        },
        clearDocketTopics: (state) => {
            state.docketCurrentTopics = {}
        },
        clearDocketMetadata: (state) => {
            state.docketLinks = {}
            state.docketExcerpts = {}
            state.docketCurrentTopics = {}
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    setDocketLinks,
    setDocketExcerpts,
    setDocketTopics,
    setDocketTopicsReference,
    clearDocketTopics,
    clearDocketMetadata
} = docketsSlice.actions

export default docketsSlice.reducer