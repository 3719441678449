import {createSlice} from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        modalOpen: false
    },
    reducers: {
        modalOpen: (state) => {
            state.modalOpen = true;
        },
        modalClose: (state) => {
            state.modalOpen = false
        }
    }
})

// Action creators are generated for each case reducer function
export const {modalOpen, modalClose} = modalSlice.actions

export default modalSlice.reducer