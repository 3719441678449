import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import getUniqueColumnValues, {
    getSubtopicValueLabelPair,
    getTopicValueLabelPair,
    MajorCommenterReportFilterData, orderUniqueColumnValues
} from "../../../helper/getUniqueColumnValues";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import MaskSentimentValues from "../../../helper/maskSentimentValues";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import { getRiderTopics } from "../../../helper/getRiderTopics";
import { clearReportFilters, setReportFilters } from "../../../store/reportsSlice";
import MultiSelectClear from "./multiselectFilter/multiselectClear";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import updateReportFilters from "../../../helper/updateReportFilters";
import parseReportFilters from "../../../helper/parseReportFilters";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 100,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 200
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 75
    },
    {
        accessorKey: "FL Name".toString().replaceAll(" ", "").toLowerCase(),
        header: "FL Name",
        size: 150
    },
    {
        accessorKey: "Topic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Topic",
        size: 250
    },
    {
        accessorKey: "Subtopic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Subtopic",
        size: 250
    },
    {
        accessorKey: "Sentiment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Sentiment",
        size: 100
    },
    {
        accessorKey: "User".toString().replaceAll(" ", "").toLowerCase(),
        header: "User",
        size: 100
    },
    {
        accessorKey: "textfield".toString().replaceAll(" ", "").toLowerCase(),
        header: "Annotation",
        size: 400
    }
]
//["Document ID", "Commenter", "MC Group", "FL Name", "Topic", "Subtopic", "Sentiment", "User", "Annotation"]

const filterData = (data, topics, docIDs, mcGroups, fls, filteredTopics, filteredSubtopics, users, docket) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        // Removing timing from the reports
        tempItem.splice(1, 3)

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[0]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[2]}`))) {
                continue
            }
        }

        if (fls.length > 0) {
            if (!(fls.includes(tempItem[3]))) {
                continue
            }
        }

        if (filteredTopics.length > 0) {
            if (!(filteredTopics.includes(tempItem[4]))) {
                continue
            }
        }

        if (filteredSubtopics.length > 0) {
            if (!(filteredSubtopics.includes(tempItem[5]))) {
                continue
            }
        }

        if (users.length > 0) {
            if (!(users.includes(tempItem[7]))) {
                continue
            }
        }

        if (topics !== {}) {
            try {
                tempItem[4] = topics[tempItem[4]]["name"]
            } catch (e) {

            }
        }

        tempItem[6] = MaskSentimentValues(tempItem[6], tempItem[2], undefined, docket)
        tempItem[2] = MC_TITLES[tempItem[2]]
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

const downloadAnnotationReport = (rawReportData, topics, docket) => {

    let reportData = []

    let riders = getRiderTopics(topics)

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem[9] = MaskSentimentValues(tempItem[9], tempItem[5], undefined, docket)

        if (riders.includes(tempItem[7])) {
            tempItem[9] = ""
        }

        tempItem[5] = MC_TITLES[tempItem[5]]
        tempItem.splice(1, 0, `${docket}-${tempItem[0]}`)
        tempItem.splice(2, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[0]}`)

        reportData.push(tempItem)
    }

    let tempHeadings = [...headings]

    tempHeadings.splice(1, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(2, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    tempHeadings.splice(3, 0, {
        accessorKey: "posted_date",
        header: "Posted Date"
    })

    tempHeadings.splice(4, 0, {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "completed_date",
        header: "Completed Date"
    })

    downloadReportData(tempHeadings, reportData, "annotation_report")
}

const AnnotationReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const storedFilters = useSelector((state) => state.reports.annotationReport);
    const dispatch = useDispatch()

    const uniqueDocumentIDs = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const uniqueFLNames = React.useMemo(() => orderUniqueColumnValues(getUniqueColumnValues(rawReportData, 6)), [rawReportData])
    const uniqueUsers = React.useMemo(() => getUniqueColumnValues(rawReportData, 10), [rawReportData])
    const allTopics = React.useMemo(() => getTopicValueLabelPair(docketTopics), [docketTopics])
    const allSubtopics = React.useMemo(() => getSubtopicValueLabelPair(docketTopics, parseReportFilters('topic', storedFilters)), [docketTopics, storedFilters])

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "annotationReport", storedFilters, key, value)
    }

    const [loading, setLoading] = React.useState(false)
    const [loadingProgressReport, setLoadingProgressReport] = React.useState(false)
    const downloadProgressReport = () => {
        setLoadingProgressReport(true)
        getReportData(jwt, docket, 9).then(r => {

            const progressHeadings = [
                {
                    accessorKey: "topic",
                    header: "Topic"
                },
                {
                    accessorKey: "subtopic",
                    header: "Subtopic"
                },
                {
                    accessorKey: "state",
                    header: "Status"
                },
                {
                    accessorKey: "comments",
                    header: "Comments"
                }
            ]

            downloadReportData(progressHeadings, r, "view_progress_report")
            setLoadingProgressReport(false)
        })
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 6).then(r => {
            setRawReportData(r);
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "annotationReport" }))
    }

    const filteredData = React.useMemo(() =>
        filterData(rawReportData, docketTopics,
            parseReportFilters('docID', storedFilters), parseReportFilters('mcGroups', storedFilters),
            parseReportFilters('flName', storedFilters), parseReportFilters('topic', storedFilters),
            parseReportFilters('subtopic', storedFilters), parseReportFilters('user', storedFilters),
            docket),
        [rawReportData, storedFilters])
    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [headings, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"10vw"}
                    selected={parseReportFilters('docID', storedFilters)}
                    setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs} />
                <MultiSelectFilter title={"MC Group"} width={"10vw"}
                    selected={parseReportFilters('mcGroups', storedFilters)}
                    setSelected={(values) => updateFilter('mcGroups', values)}
                    options={MajorCommenterReportFilterData} />
                <MultiSelectFilter title={"FL Name"} width={"15vw"}
                    selected={parseReportFilters('flName', storedFilters)}
                    setSelected={(values) => updateFilter('flName', values)} options={uniqueFLNames} />
                <MultiSelectFilter title={"Topic"} width={"15vw"} selected={parseReportFilters('topic', storedFilters)}
                    setSelected={(values) => updateFilter('topic', values)} options={allTopics} />
                <MultiSelectFilter title={"Subtopic"} width={"15vw"}
                    selected={parseReportFilters('subtopic', storedFilters)}
                    setSelected={(values) => updateFilter('subtopic', values)} options={allSubtopics}
                    noOptionsText={parseReportFilters('topic', storedFilters).length === 0 ? "No Topics Selected" : "No Subtopics for Selected Topics"}
                />
                <MultiSelectFilter title={"User"} width={"10vw"} selected={parseReportFilters('user', storedFilters)}
                    setSelected={(values) => updateFilter('user', values)}
                    options={uniqueUsers} />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "annotationReport" }))} />
            </div>
            <div className={styles.title}>
                <p>Annotation Report <span style={{ marginLeft: 16 }}>({uniqueCommentCount} comments shown)</span></p>
                <div className={styles.multiButton}>
                    <button className={styles.expandedDownloadButton}
                        onClick={() => downloadAnnotationReport(rawReportData, allTopics, docket)}>
                        <DownloadIcon size={16} /> Download Annotation Report
                    </button>
                    <button disabled={loadingProgressReport} className={styles.expandedDownloadButton}
                        onClick={downloadProgressReport}>
                        <DownloadIcon size={16} /> Download Progress Report
                    </button>
                </div>
            </div>
            <div className={styles.table}>
                {loading ? <Loading /> :
                    <GenericTable data={filteredData}
                        headingsList={finalHeadings} allowExpansionPanel={true} />}
            </div>
        </div>
    );
};

export default AnnotationReport;