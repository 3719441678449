import React from 'react';
import styles from './commentTimestampModal.module.css';
import {createPortal} from "react-dom";
import Loading from '../../General/loading/loading';
import calculateBusinessHourDifference from "../../../helper/calculateBusinessHourDifference";
import {POSTED_OFFSET_USE_LOADED} from "../../../helper/constants";


const CommentTimestampModal = ({open, closeModal, data}) => {

    if (Object.keys(data).length < 1) {
        return null;
    }

    const clearAndCloseModal = () => {
        closeModal();
    };

    const calculateShowFLValues = () => {
        return data.flComplete != null
    }

    const getValue = (key) => {

        if (key in data) {

            if (key === 'posted') {
                if ('loaded' in data && data['loaded'] < data['posted']) {
                    return "Not Stored"
                }
            }

            // Handle missing data
            if (data[key] === null) {
                return "Incomplete"
            }

            // Handle dates
            if (typeof data[key] === 'number') {
                return `${new Date(Math.round(data[key], 0) * 1000).toLocaleString(['en-US'], {
                    timeZone: 'America/New_York',
                    hour12: true,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                })}`
            } else {
                return data[key]
            }
        }

        return "Unknown."
    }

    const getBusinessHours = () => {
        const calcStart = () => {
            // Invalid posted date
            if ('loaded' in data && 'posted' in data && data['loaded'] < data['posted']) {
                return data['loaded']
            }

            // Posted date is from previous years (loading test data), use loaded date from pipeline
            if ('loaded' in data && 'posted' in data && data['posted'] + POSTED_OFFSET_USE_LOADED < data['loaded']) {
                return data['loaded']
            }

            if ('posted' in data) {
                return data['posted']
            }

            if ('loaded' in data) {
                return data['loaded']
            }
        }

        const calcEnd = () => {
            if ('triageComplete' in data && data['triageComplete'] != null) {
                return data['triageComplete']
            }

            return Math.floor(Date.now() / 1000)
        }

        const startTime = calcStart()
        const endTime = calcEnd()

        const elapsedHours = calculateBusinessHourDifference(startTime, endTime)
        return `${(elapsedHours - (elapsedHours % 8)) / 8} days, ${elapsedHours % 8} hours`
    }


    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open}
             className={styles.wrapper} aria-describedby={'modalDescription'} onClick={clearAndCloseModal}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Comment Timestamps&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Comment Timestamps</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.displayRow}>
                        <h3>Document ID:</h3>
                        <p style={{width: '40%', textAlign: "right"}}>{getValue('documentID')}</p>
                    </div>
                    <div className={styles.displayRow}>
                        <h3>Posted to Regulations.gov:</h3>
                        <p>{getValue('posted')}</p>
                        <p className={styles.timezone}>EDT</p>
                    </div>
                    <div className={styles.displayRow}>
                        <h3>Loaded into InsightsAI:</h3>
                        <p>{getValue('loaded')}</p>
                        <p className={styles.timezone}>EDT</p>
                    </div>
                    {
                        calculateShowFLValues() ?
                            <>
                                <div className={styles.displayRow}>
                                    <h3>Form Letter Complete:</h3>
                                    <p>{getValue('flComplete')}</p>
                                    <p className={styles.timezone}>EDT</p>
                                </div>
                            </> :
                            <>
                                <div className={styles.displayRow}>
                                    <h3>Primary Review Completed:</h3>
                                    <p>{getValue('prEnd')}</p>
                                    <p className={styles.timezone}>EDT</p>
                                </div>
                                <div className={styles.displayRow}>
                                    <h3>QA Review Completed:</h3>
                                    <p>{getValue('qaEnd')}</p>
                                    <p className={styles.timezone}>EDT</p>
                                </div>
                            </>
                    }
                    <div className={styles.displayRow}>
                        <h3>Triage Completed:</h3>
                        <p>{getValue('triageComplete')}</p>
                        <p className={styles.timezone}> EDT</p>
                    </div>
                    <div className={styles.displayRow}>
                        <h3>Total Elapsed Time:</h3>
                        <p style={{width: '40%', textAlign: "right"}}>{getBusinessHours()}</p>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default CommentTimestampModal;
