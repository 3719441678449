import React from 'react';
import styles from './userSettingsModal.module.css'
import {createPortal} from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import {changePasswordAPI} from "../../../api/authAPI";
import {setKeywordsExpansionDefault, setPDFDownload} from "../../../store/preferenceSlice";
import {setUserPreferencesAPI} from "../../../api/preferenceAPI";
import { setViewAnnotation } from '../../../store/preferenceSlice';

const UserSettingsModal = ({open, closeModal}) => {

    const displayName = useSelector((state) => state.user.displayName)
    const username = useSelector((state) => state.user.username)
    const jwt = useSelector((state) => state.user.jwt);
    const docketLockFlag = useSelector((state) => state.user.lockToCurrentDocketFlag)
    const markViewed = useSelector((state) => state.prefs.viewAnnotation)
    const expanded_keywords = useSelector((state) => state.prefs.keywordsExpansionDefault)
    const pdfDownload = useSelector((state) => state.prefs.pdfDownload);
    const isReviewer = useSelector((state) => state.user.isReviewer)
    const dispatch = useDispatch();

    const [currentPassword, setCurrentPassword] = React.useState("")
    const [newPassword, setNewPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [errorText, setErrorText] = React.useState("")
    const [wasSuccessful, setWasSuccessful] = React.useState(false)
    
    const changePDFPreferences = (value) => {
        setUserPreferencesAPI(jwt, value, markViewed, expanded_keywords).then(() => {
            dispatch(setPDFDownload(value))
            
        })
    }
    const changeAnnotationPreferences = (value) => {
        setUserPreferencesAPI(jwt, pdfDownload, value, expanded_keywords).then(() => {
            dispatch(setViewAnnotation(!markViewed))
            
        })
    }
    const changeKeywordSuggestionsPreferences = (value) => {
        setUserPreferencesAPI(jwt, pdfDownload, markViewed, value).then(() => {
            dispatch(setKeywordsExpansionDefault(!expanded_keywords))
            
        })
    }

    const clearAndCloseModal = () => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")

        setWasSuccessful(false)
        setErrorText("")

        closeModal()
    }

    const changePassword = () => {
        setWasSuccessful(false)

        if (currentPassword === "" || newPassword === "" || confirmPassword === "") {
            setErrorText("Ensure all fields are not blank")
            return
        }

        if (newPassword !== confirmPassword) {
            setErrorText("Ensure the new and confirm password fields match")
            return
        }

        changePasswordAPI(jwt, currentPassword, newPassword).then(r => {
            if (r.success) {
                setWasSuccessful(true)
                setErrorText("")

                setCurrentPassword("")
                setNewPassword("")
                setConfirmPassword("")
            } else {
                setErrorText(r.msg)
            }
        })
    }

    useEscape(clearAndCloseModal)
    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.content} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Account Settings&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <h1 className={styles.h1}>Account Settings</h1>
                <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                </button>

                <div className={styles.fields}>
                    <div className={styles.accountSettingsWrapper}>
                        <div className={styles.profilePictureDiv}>
                            {username[0]}
                        </div>
                        <div>
                            <div className={styles.lineItem}>
                                <p className={styles.textLabel}>Username:</p>
                                <p className={styles.textValue}>{username}</p>
                            </div>
                            <div className={styles.lineItem}>
                                <p className={styles.textLabel}>Display Name:</p>
                                <p className={styles.textValue}>{displayName}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{width: '100%'}} className={styles.userPrefContainer}>
                        <h2 className={styles.h2}>User Preferences</h2>
                        <div className={styles.lineItem}>
                            <p className={styles.textLabel} style={{minWidth: '30%'}}>Attachment Preference:</p>
                            {
                                pdfDownload ?
                                    <React.Fragment>
                                        <p style={{marginRight: 0}} className={styles.textValue}>Download PDFs</p>
                                        <button onClick={() => changePDFPreferences(false)}
                                                className={styles.pdfButton}>Switch to Open New Window
                                        </button>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <p style={{marginRight: 4}} className={styles.textValue}>Open in New Window</p>
                                        <button onClick={() => changePDFPreferences(true)}
                                                className={styles.pdfButton}>Switch to Download
                                        </button>
                                    </React.Fragment>
                            }
                        </div>
                        <div className={styles.lineItemCheckbox}>
                        <p className={styles.textLabel} style={{minWidth: '30%'}}>Mark topic viewed when editing annotation:</p>
                        <input onChange={() => changeAnnotationPreferences(!markViewed)} className={styles.preferenceCheckBox}
                        checked={markViewed} type={"checkbox"} aria-label={`Checkbox for marking topic viewed when editing annotation.`}/>
                        </div>
                        { isReviewer &&
                        <div className={styles.lineItemCheckbox}>
                        <p className={styles.textLabel} style={{minWidth: '30%'}}>Automatically display topic suggestions:</p>
                        <input onChange={() => changeKeywordSuggestionsPreferences(!expanded_keywords)} className={styles.preferenceCheckBox}
                        checked={expanded_keywords} type={"checkbox"} aria-label={`Checkbox for expanding keyword suggestions by default.`}/>
                        </div>
                        }
                    </div>
                    <div style={{width: "100%"}}>
                        <h2 className={styles.h2}>Change Password</h2>
                        <div className={styles.changePassword}>
                            <form action="src/components/UserSettings/modals/userSettingsModal" className={styles.form}>
                                {
                                    wasSuccessful &&
                                    <p className={styles.successMessage}>Password was successfully updated</p>
                                }
                                {
                                    errorText !== "" &&
                                    <p className={styles.errorMessage}>{errorText}</p>
                                }

                                <div className={styles.formLine}>
                                    <label className={styles.fieldLabel} id="oldPasswordLabel" htmlFor="oldPassword">Current
                                        Password:</label>
                                    <input autoComplete={"current-password"} value={currentPassword} disabled={docketLockFlag}
                                           onChange={e => setCurrentPassword(e.currentTarget.value)}
                                           name={"oldPassword"} id={"oldPassword"} className={styles.textField}
                                           type={"password"}/>
                                </div>

                                <div className={styles.formLine} disabled={docketLockFlag}>
                                    <label className={styles.fieldLabel} id="newPasswordLabel" htmlFor="newPassword">New
                                        Password:</label>
                                    <input autoComplete={"new-password"} value={newPassword} disabled={docketLockFlag}
                                           onChange={e => setNewPassword(e.currentTarget.value)}
                                           name={"newPassword"} id={"newPassword"} className={styles.textField}
                                           type={"password"}/>
                                </div>

                                <div className={styles.formLine}>
                                    <label className={styles.fieldLabel} disabled={docketLockFlag} id="confirmPasswordLabel"
                                           htmlFor="confirmPassword">Confirm New
                                        Password:</label>
                                    <input autoComplete={"new-password"} value={confirmPassword} disabled={docketLockFlag}
                                           onChange={e => setConfirmPassword(e.currentTarget.value)}
                                           name={"confirmPassword"} id={"confirmPassword"} className={styles.textField}
                                           type={"password"}/>
                                </div>
                                <button disabled={docketLockFlag} onClick={changePassword} type={"button"}
                                        className={styles.button}>Change
                                    Password
                                </button>
                            </form>
                            <div className={styles.passwordSettings} disabled={docketLockFlag}>
                                <p><b>Password Requirements:</b></p>
                                <p>12+ characters<br/>1+ lowercase character<br/>1+ uppercase character<br/>1+
                                    number<br/> 1+ special
                                    symbol</p>
                            </div>
                        
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>,
        document.body
    );
};

export default UserSettingsModal;