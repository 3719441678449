const cleanUpHTMLTags = (text) => {
    let newText = text;

    newText = newText.replaceAll("&bull;", "--")
    newText = newText.replaceAll("&ldquo;", "\"")
    newText = newText.replaceAll("&rdquo;", "\"")
    newText = newText.replaceAll("&rsquo;", "'")
    newText = newText.replaceAll("&lsquo;", "'")
    newText = newText.replaceAll("&#39;", "'")
    newText = newText.replaceAll("&ndash;", "- ")
    newText = newText.replaceAll("&amp;", "&")
    newText = newText.replaceAll("&middot;", "- ")
    newText = newText.replaceAll("&quot;", "\"")
    newText = newText.replaceAll("&mdash;", "-- ")
    newText = newText.replaceAll("&hellip;", "...")
    newText = newText.replaceAll("&thinsp;", " ")
    newText = newText.replaceAll("&sect;", "[section]")
    newText = newText.replaceAll("&nbsp;", " ")
    newText = newText.replaceAll("&gt;", ">")
    newText = newText.replaceAll("&lt;", "<")
    newText = newText.replaceAll("&minus;", " minus ")

    return newText
}

export default cleanUpHTMLTags;