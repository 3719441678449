import {useEffect} from 'react';

const useEnter = (onEnterFunction) => {
    useEffect(() => {
        const handleEnter = (event) => {
            if (event.keyCode === 13) {
                onEnterFunction();
            }
        };
        window.addEventListener('keydown', handleEnter);

        return () => {
            window.removeEventListener('keydown', handleEnter);
        };
    }, [onEnterFunction]);
}

export default useEnter