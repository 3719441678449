import React from 'react';
import cleanUpHTMLTags from "../../../helper/cleanUpHTMLTags";
import styles from './TextDisplay.module.css'
import {EXCERPT_HIGHLIGHT_1, EXCERPT_HIGHLIGHT_2, KEYWORD_HIGHLIGHT_1} from "../../../helper/constants";

const TextElement = ({text, useExcerpt, excerpts, attachment, setHoveredExcerptTopics, keywordList}) => {
    let newText = cleanUpHTMLTags(text)

    const lines = []
    let counter = 0

    const onExcerptHover = (e) => {
        if (!useExcerpt || setHoveredExcerptTopics === undefined) {
            return;
        }

        if (e.target.tagName === 'SPAN' &&
            e.target.dataset !== undefined && e.target.dataset.type !== undefined && e.target.dataset.type === 'excerpt') {

            const targetText = e.target.innerText;
            if (useExcerpt && excerpts[attachment] !== undefined && excerpts[attachment].length > 0) {
                const list = {}

                for (let excerpt of excerpts[attachment]) {

                    if (excerpt.text.includes(targetText) || targetText.includes(excerpt.text)) {
                        for (let topic of Object.keys(excerpt.topics)) {
                            if (!(Object.keys(list).includes(topic))) {
                                list[topic] = []
                            }

                            for (let subtopic of excerpt.topics[topic].subtopics) {
                                if (!(list[topic].includes(subtopic[0]))) {
                                    list[topic].push(subtopic[0])
                                }
                            }
                        }
                    }

                }

                setHoveredExcerptTopics(list)
            }
        } else {
            setHoveredExcerptTopics({})
        }
    }

    if (useExcerpt && excerpts[0] !== undefined && excerpts[0].length > 0) {
        newText = newText.replaceAll("  ", " ")

        for (let excerpt of excerpts[0]) {
            const splitExcerptText = excerpt.text.split("\n")

            for (let splitText of splitExcerptText) {

                if (splitText.length > 20) {

                    newText = newText.replaceAll(splitText, `&lt;${splitText}&gt;`)
                }
            }
        }

        let alternateHighlight = true;
        for (let line of newText.split("<br/>")) {
            line = line.replaceAll(/(<([^>]+)>)/ig, '');

            while (line.includes("&lt;")) {
                let color = alternateHighlight ? EXCERPT_HIGHLIGHT_1 : EXCERPT_HIGHLIGHT_2
                line = line.replace("&lt;",
                    `<span data-type="excerpt" style="background-color: ${color}" class=${styles.excerpt_highlight_comment}>`)
                alternateHighlight = !alternateHighlight;
            }

            line = line.replaceAll("&gt;", "</span>")

            console.log(line)

            if (keywordList != null) {
                for (let kw of keywordList) {
                    line = line.replaceAll(kw, `<span data-type="keyword" style="background-color: ${KEYWORD_HIGHLIGHT_1}">${kw}</span>`)
                }
            }

            lines.push(<p style={{margin: 0, padding: 0}} key={counter} dangerouslySetInnerHTML={{__html: line}}></p>)
            lines.push(<br key={counter + 1}/>)
            counter += 2
        }
    } else {
        for (let line of newText.split("<br/>")) {
            line = line.replaceAll(/(<([^>]+)>)/ig, '');

            if (keywordList != null) {
                for (let kw of keywordList) {
                    line = line.replaceAll(kw, `<span data-type="keyword" style="background-color: ${KEYWORD_HIGHLIGHT_1}">${kw}</span>`)
                }
            }

            lines.push(<p style={{margin: 0, padding: 0}} key={counter} dangerouslySetInnerHTML={{__html: line}}></p>)
            lines.push(<br key={counter + 1}/>)
            counter += 2
        }
    }

    return (
        <div onMouseOver={onExcerptHover} onMouseLeave={onExcerptHover}>
            {
                lines.map((element, index) => (
                    <React.Fragment key={index}>
                        {element}
                    </React.Fragment>
                ))
            }
        </div>
    );
};

export default TextElement;