import React from 'react';
import styles from './UserManagement.module.css';
import {adminAddUserAPI, adminChangePasswordAPI} from "../../api/usersAPI";
import {useSelector} from "react-redux";

const UserManagement = () => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [passwordChangeFormInformation, setPasswordFormInformation] = React.useState({
        "username": "",
        "newPassword": "",
        "errorMessage": "",
        "wasSuccessful": false
    })

    const [newUserFormInformation, setNewUserFormInformation] = React.useState({
        "fullName": "",
        "fullNameConfirm": "",
        "email": "",
        "emailConfirm": "",
        "errorMessage": "",
        "successMessage": ""
    })

    const alterPasswordInformation = (key, value) => {
        setPasswordFormInformation({
            ...passwordChangeFormInformation,
            [key]: value,
            wasSuccessful: false,
            errorMessage: ''
        })
    }

    const alterNewUserFormInformation = (key, value) => {
        setNewUserFormInformation({...newUserFormInformation, [key]: value, successMessage: ''})
    }

    const submitPasswordChange = () => {
        adminChangePasswordAPI(jwt, docket,
            passwordChangeFormInformation.username, passwordChangeFormInformation.newPassword).then(r => {
            if (r.success) {
                setPasswordFormInformation({
                    ...passwordChangeFormInformation,
                    'wasSuccessful': true
                })
            } else {
                if (r.msg && r.msg !== '') {
                    setPasswordFormInformation({
                        ...passwordChangeFormInformation,
                        'errorMessage': r.msg,
                        'wasSuccessful': false
                    })
                } else {
                    setPasswordFormInformation({
                        ...passwordChangeFormInformation,
                        'errorMessage': 'An unknown error occurred',
                        'wasSuccessful': false
                    })
                }
            }
        }).catch(error => {
            setPasswordFormInformation({
                ...passwordChangeFormInformation,
                'errorMessage': `An unknown error occurred -- ${error.message}`,
                'wasSuccessful': false
            })
        })
    }

    const fullNameFormat = /^[a-zA-Z\s.'-]+$/;
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const submitNewUser = () => {
        const trimmedFullName = newUserFormInformation.fullName.trim();
        const trimmedEmail = newUserFormInformation.email.trim();
        const trimmedFullNameConfirm = newUserFormInformation.fullNameConfirm.trim();
        const trimmedEmailConfirm = newUserFormInformation.emailConfirm.trim();

        if (trimmedFullName === '') {
            window.alert("User's full name is blank");
            return;
        }

        if (trimmedEmail === '') {
            window.alert("User's email is blank");
            return;
        }

        if (trimmedFullName !== trimmedFullNameConfirm) {
            window.alert("User's full name does not match");
            return;
        }

        if (trimmedEmail !== trimmedEmailConfirm) {
            window.alert("User's email does not match");
            return;
        }

        if (!fullNameFormat.test(trimmedFullName)) {
            window.alert("Full Name format is incorrect. Please use only letters and spaces.");
            return;
        }

        if (!emailFormat.test(trimmedEmail)) {
            window.alert("Email format is incorrect. Please enter a valid email address.");
            return;
        }

        if (!window.confirm(`Are you sure you want to create a user with the following information?\n\n` +
            `Full name: ${trimmedFullName}\n` +
            `Email: ${trimmedEmail}`)) {
            return;
        }

        adminAddUserAPI(jwt, docket, trimmedFullName, trimmedEmail).then(r => {
            if (r.success) {
                setNewUserFormInformation({
                    ...newUserFormInformation,
                    'successMessage': r.msg,
                    'errorMessage': ''
                })
            } else {
                if (r.msg && r.msg !== '') {
                    setNewUserFormInformation({
                        ...newUserFormInformation,
                        'errorMessage': r.msg,
                        'successMessage': ''
                    })
                } else {
                    setNewUserFormInformation({
                        ...newUserFormInformation,
                        'errorMessage': 'An unknown error occurred',
                        'successMessage': ''
                    })
                }
            }
        }).catch(error => {
            setNewUserFormInformation({
                ...newUserFormInformation,
                'errorMessage': `An unknown error occurred -- ${error.message}`,
                'successMessage': ''
            })
        })
    }

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.header}>Change User Password</h3>
            <p className={styles.label}>Username:</p>
            <input value={passwordChangeFormInformation.username} className={styles.input}
                   onChange={(e) => alterPasswordInformation('username', e.currentTarget.value)}/>
            <p className={styles.label}>New Password:</p>
            <input value={passwordChangeFormInformation.newPassword} className={styles.input}
                   onChange={(e) => alterPasswordInformation('newPassword', e.currentTarget.value)}/>
            {
                passwordChangeFormInformation.errorMessage && !passwordChangeFormInformation.wasSuccessful &&
                <p className={styles.message}>
                    {passwordChangeFormInformation.errorMessage}
                </p>
            }
            {
                passwordChangeFormInformation.wasSuccessful && <p className={styles.message}>
                    Password change was a success
                </p>
            }
            <button onClick={submitPasswordChange}>Submit Password Change</button>
            <div className={styles.horizontalLines}/>
            <h3 className={styles.header}>Add User to System</h3>
            <p className={styles.label}>Full Name:</p>
            <input value={newUserFormInformation.fullName} className={styles.input}
                   onChange={(e) => alterNewUserFormInformation('fullName', e.currentTarget.value)}/>
            <p className={styles.label}>Full Name [Confirm]:</p>
            <input value={newUserFormInformation.fullNameConfirm} className={styles.input}
                   onChange={(e) => alterNewUserFormInformation('fullNameConfirm', e.currentTarget.value)}/>
            <p className={styles.label}>Email:</p>
            <input value={newUserFormInformation.email} className={styles.input}
                   onChange={(e) => alterNewUserFormInformation('email', e.currentTarget.value)}/>
            <p className={styles.label}>Email [Confirm]:</p>
            <input value={newUserFormInformation.emailConfirm} className={styles.input}
                   onChange={(e) => alterNewUserFormInformation('emailConfirm', e.currentTarget.value)}/>
            <select className={styles.input} disabled={true}>
                <option>CMS - Viewer</option>
            </select>
            {
                newUserFormInformation.errorMessage !== '' && <p className={styles.message}>
                    {newUserFormInformation.errorMessage}
                </p>
            }
            {
                newUserFormInformation.successMessage !== undefined && newUserFormInformation.successMessage !== '' &&
                newUserFormInformation.successMessage.split("\n").map(text => (
                    <p className={styles.message}>
                        {text}
                    </p>
                ))

            }
            <button onClick={submitNewUser}>Generate New User</button>
        </div>
    );
};

export default UserManagement;
