import React from 'react';
import styles from './viewerModeModal.module.css'
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import { setAuthToken, setDisplayName, setUsername, setLockToCurrentDocketFlag, setReviewerStatus } from "../../../store/userSlice";
import { adminEmulateViewerAPI } from "../../../api/usersAPI";

const ViewerModeModal = ({
    open, closeModal,
}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const dispatch = useDispatch();

    const clearAndCloseModal = () => {
        closeModal()
    }
    useEscape(clearAndCloseModal)

    const emulateUser = () => {
            adminEmulateViewerAPI(jwt, docket).then(r => {
                if (r.success) {
                    dispatch(setAuthToken(r.data.jwt));
                    dispatch(setUsername(r.data.username));
                    dispatch(setDisplayName(r.data.displayName));
                    dispatch(setLockToCurrentDocketFlag(true));
                    dispatch(setReviewerStatus({
                        isPrimaryReviewer: false,
                        isQAReviewer: false,
                        isAdmin: false
                    }));
                }
            })
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a paragraph stating &quot;Are you sure that you would like to sign out and enter viewer mode? Any unsaved changes will be lost&quot;.
                    Pressing the stay signed in button at the top will close the modal and bring you back to where you were on the page.
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.modalText} >Are you sure that you would like to sign out and enter viewer mode? Any unsaved changes will be lost.</div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.leftButton} onClick={clearAndCloseModal}>No, Stay Signed In</button>
                        <button className={styles.rightButton} onClick={emulateUser}>Yes, Change Accounts</button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ViewerModeModal;