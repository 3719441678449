import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import useEscape from "../../../../../helper/keypressListeners/escape";
import TextDisplay from "../../../../General/TextDisplay/TextDisplay";


const FormLetterReferenceModal = ({isOpen, closeModal, data}) => {

    const clearAndCloseModal = () => {
        closeModal()
    }

    useEscape(clearAndCloseModal)

    if (data === null) {
        return null;
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: isOpen ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!isOpen} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Form Letter: {data.name}&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Form Letter: {data.name}</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>

                <div className={styles.inside_wrapper}>
                    <div className={styles.text}>
                        {
                            (data.text === null || data.text === undefined) ?
                                <p>No Parent Text Identified</p>
                                :
                                <TextDisplay excerpts={{}} useExcerpt={false} isPaused={false}
                                             documentID={data.standard[0]}
                                             attachment={data.standard[1]} trackingNum={data.standard[2]}
                                />
                        }
                    </div>
                    <div className={styles.metadata}>
                        <h2>Standard: </h2>
                        <p style={{marginBottom: 24}}>{data.standard[0]} {data.standard[1]}</p>
                        <h2>Description: </h2>
                        {
                            (data.desc === null || data.desc === undefined) ?
                                <p><b>No Description Provided</b></p>
                                :
                                data.desc.split("\n").map((text, idx) => (
                                    <p>{text}</p>
                                ))
                        }

                        <h2 style={{marginTop: 24}}>Topics: </h2>
                        {
                            data.topics.map((text, idx) => (
                                <p>•&nbsp;{text}</p>
                            ))
                        }
                        {
                            data.topics.length < 1 &&
                            <p><b>No Topics Identified</b></p>
                        }

                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};


export default FormLetterReferenceModal;