import React from 'react';
import styles from './FormLetters.module.css'
import {useSelector} from "react-redux";
import {
    addToFormLetterAPI, bulkViewFormLetterAPI,
    getFormLettersAPI, removeFromFormLetterAPI,
    updateFormLetterNameAPI,
    updateFormLetterStandardAPI,
    updateFormLetterStatusAPI, updateFormLetterUnViewedAPI, updateFormLetterViewedAPI
} from "../../../../api/formLetterAPI";
import FormLetterItem from "./Components/FormLetterItem";
import MergeModal from "../../modals/mergeModal";
import CreateModal from "../../modals/createModal";
import Loading from "../../../General/loading/loading";

const FormLetters = () => {

    const [formLetters, setFormLetters] = React.useState([]);
    const [openBatch, setOpenBatch] = React.useState(-1);
    const [loading, setLoading] = React.useState(false);

    const [mergeModalOpen, setMergeModalOpen] = React.useState(false);
    const [createModalOpen, setCreateModalOpen] = React.useState(false);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const displayName = useSelector((state) => state.user.displayName);

    const openMergeModal = () => {
        setMergeModalOpen(true)
        setCreateModalOpen(false)
    }

    const openCreateModal = () => {
        setMergeModalOpen(false)
        setCreateModalOpen(true)
    }

    const closeAllModals = () => {
        setMergeModalOpen(false)
        setCreateModalOpen(false)

        refreshFormLetters()
    }

    const refreshFormLetters = () => {
        setLoading(true)
        getFormLettersAPI(jwt, docket).then(r => {
            setFormLetters(r)
            setOpenBatch(-1)
            setLoading(false)
        })
    }

    const openFL = (batch) => {
        // eslint-disable-next-line eqeqeq
        if (batch == openBatch) {
            setOpenBatch(-1)
        } else {
            setOpenBatch(batch)
        }
    }

    const addToFormLetter = (documentID, attachment, batchID) => {

        addToFormLetterAPI(jwt, docket, batchID, documentID, attachment).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (formLetter["batchID"] !== batchID) {
                        continue
                    }

                    formLetter["children"].push(
                        {
                            "document": r.data,
                            "score": -1,
                            "viewed": false,
                            "source": displayName
                        }
                    )

                    break
                }

                setFormLetters(temp)
            } else {
                window.alert(r.msg ?? "An unknown error occurred")
            }
        })
    }

    const removeFromFormLetter = (trackingNumber, attachment, batchID, isMC) => {

        removeFromFormLetterAPI(jwt, docket, batchID, trackingNumber, attachment, isMC).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (!isMC && formLetter["batchID"] !== batchID) {
                        continue
                    }

                    for (let childIndex in formLetter["children"]) {
                        let child = formLetter["children"][childIndex]
                        if (isMC) {
                            if (child["document"][2] === trackingNumber) {
                                formLetter["children"].splice(childIndex, 1)
                            }
                        } else {
                            if (child["document"][2] === trackingNumber && child["document"][1] === attachment) {
                                formLetter["children"].splice(childIndex, 1)
                            }
                        }
                    }
                }

                setFormLetters(temp)
            } else {
                window.alert("Error removing comment from form letter")
            }
        })

    }

    const changeStandard = (trackingNumber, attachment, batchID) => {

        updateFormLetterStandardAPI(jwt, docket, batchID, trackingNumber, attachment).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (formLetter["batchID"] !== batchID) {
                        continue
                    }

                    let storedDocumentID = ""

                    //Remove from child list
                    for (let childIndex in formLetter["children"]) {
                        let child = formLetter["children"][childIndex]
                        if (child["document"][2] === trackingNumber && child["document"][1] === attachment) {
                            storedDocumentID = child["document"][0]
                            formLetter["children"].splice(childIndex, 1)
                        } else {
                            child["score"] = -1;
                        }
                    }

                    formLetter["children"].push(
                        {
                            "document": [...formLetter["parent"]],
                            "score": -1,
                            "viewed": false
                        }
                    )

                    formLetter["parent"] = [storedDocumentID, attachment, trackingNumber]

                    break
                }

                setFormLetters(temp)
                getFormLettersAPI(jwt, docket).then(r => {
                    setFormLetters(r)
                })
            } else {
                window.alert("Error updating form letter standard")
            }
        })
    }

    const markAsViewed = (trackingNumber, attachment, batchID) => {

        updateFormLetterViewedAPI(jwt, docket, trackingNumber, attachment).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (formLetter["batchID"] !== batchID) {
                        continue
                    }

                    //Remove from child list
                    for (let childIndex in formLetter["children"]) {
                        let child = formLetter["children"][childIndex]
                        if (child["document"][2] === trackingNumber && child["document"][1] === attachment) {
                            child["viewed"] = true;
                            break
                        }
                    }
                }

                setFormLetters(temp)
            } else {
                console.log("Error marking comment as viewed")
            }
        })

    }

    const markAsUnViewed = (trackingNumber, attachment, batchID) => {

        updateFormLetterUnViewedAPI(jwt, docket, trackingNumber, attachment).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (formLetter["batchID"] !== batchID) {
                        continue
                    }

                    //Remove from child list
                    for (let childIndex in formLetter["children"]) {
                        let child = formLetter["children"][childIndex]
                        if (child["document"][2] === trackingNumber && child["document"][1] === attachment) {
                            child["viewed"] = false;
                            break
                        }
                    }
                }

                setFormLetters(temp)
            } else {
                console.log("Error marking comment as viewed")
            }
        })

    }

    const bulkViewByPercent = (size, batchID) => {

        bulkViewFormLetterAPI(jwt, docket, batchID, size).then(r => {
            if (r.success) {
                let temp = [...formLetters]
                for (let formLetter of temp) {
                    if (formLetter["batchID"] !== batchID) {
                        continue
                    }

                    //Remove from child list
                    for (let childIndex in formLetter["children"]) {
                        let child = formLetter["children"][childIndex]
                        if (child["score"] >= size) {
                            child["viewed"] = true;
                        }
                    }
                }

                setFormLetters(temp)
            } else {
                console.log("Error marking comment as viewed")
            }
        })

    }

    const changeState = async (batchID, state) => {
        let resp = await updateFormLetterStatusAPI(jwt, docket, batchID, state)
        if (resp["success"] === true) {
            let temp = [...formLetters]
            for (let formLetter of temp) {
                if (formLetter["batchID"] !== batchID) {
                    continue
                }

                formLetter["status"] = state
                break
            }

            setFormLetters(temp)
        }
    }

    const changeName = async (batchID, newName) => {
        let resp = await updateFormLetterNameAPI(jwt, docket, batchID, newName)
        if (resp["success"] === true) {
            let temp = [...formLetters]
            for (let formLetter of temp) {
                if (formLetter["batchID"] !== batchID) {
                    continue
                }

                formLetter["name"] = newName
                break
            }

            setFormLetters(temp)
        }
    }

    React.useEffect(() => {
        refreshFormLetters()
    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            <div className={styles.commandBar}>
                <button onClick={refreshFormLetters}>
                    Refresh Form Letters
                </button>
                <button onClick={openCreateModal}>
                    Create New Form Letter
                </button>
                <button onClick={openMergeModal}>
                    Merge Form Letters
                </button>
            </div>
            <div className={styles.formLetters}>
                {
                    loading && <Loading/>
                }
                {!loading &&
                    formLetters.map(element => (
                            <FormLetterItem formLetter={element} key={element["batchID"]} stateFunc={changeState}
                                            changeNameFunc={changeName}
                                            addFunc={addToFormLetter} removeFunc={removeFromFormLetter}
                                            changeStandardFunc={changeStandard}
                                            viewElementFunc={markAsViewed}
                                            unviewElementFunc={markAsUnViewed}
                                            bulkViewFunc={bulkViewByPercent}
                                            isOpen={element["batchID"] === openBatch} batchID={element["batchID"]}
                                            openFunction={() => openFL(element["batchID"])}
                            />
                        )
                    )
                }
            </div>
            <MergeModal open={mergeModalOpen} closeModal={closeAllModals}/>
            <CreateModal open={createModalOpen} closeModal={closeAllModals}/>
        </div>
    );
};

export default FormLetters;