import {MC_TITLES} from "./constants";

const getUniqueColumnValues = (dataset, column) => {

    let temp = []
    let tracking = []

    for (let row of dataset) {
        if (!(tracking.includes(row[column]))) {
            tracking.push(row[column])
            if (row[column] === null) {
                temp.unshift({value: row[column], label: row[column] ?? "None"})
            } else {
                temp.push({value: row[column], label: row[column] ?? "None"})
            }
        }
    }

    return temp
}

export const getTopicValueLabelPair = (topicsDict) => {
    let temp = []

    for (let key of Object.keys(topicsDict)) {
        temp.push({value: key, label: topicsDict[key].name ?? key})
    }

    return temp
}

export const getSubtopicValueLabelPair = (topicsDict, topicsList = []) => {
    let temp = []

    for (let key of Object.keys(topicsDict)) {

        if (!(topicsList.includes(key))) {
            continue
        }

        for (let subtopic of topicsDict[key].children) {
            temp.push({value: subtopic[0], label: subtopic[0]})
        }
    }

    return temp
}

export const orderUniqueColumnValues = (dataset) => {

    const sortFn = (a, b) => {

        if (a['label'] === 'None' || a['value'] == null) {
            return -1
        }

        if (b['label'] === 'None' || b['value'] == null) {
            return 1
        }

        if (a['label'] < b['label']) {
            return -1;
        } else if (a['label'] > b['label']) {
            return 1;
        }

        return 0;
    }

    return dataset.sort(sortFn)
}


export const MajorCommenterReportFilterData = [
    {value: `1`, label: MC_TITLES[1]},
    {value: `2`, label: MC_TITLES[2]},
    {value: `3`, label: MC_TITLES[3]}
]

export const MajorCommenterReportFilterData_onlyMCs = [
    {value: `1`, label: MC_TITLES[1]},
    {value: `2`, label: MC_TITLES[2]}
]

export default getUniqueColumnValues;