import React from 'react';
import styles from "./Administration.module.css"
import Tabs from "./tabs/tabs";
import {useSelector} from "react-redux";
import {getAdminTabsAPI} from "../../api/adminAPI";
import Loading from "../General/loading/loading";
import WorkflowAdministration from "../WorkflowAdministration/WorkflowAdministration";
import UserManagement from "../UserManagement/UserManagement";
import DocketAdministration from "../DocketAdministration/DocketAdministration";

const getAdminPages = (pageName) => {
    if (pageName === "Workflow") {
        return <WorkflowAdministration/>
    }

    if (pageName === "Docket") {
        return <DocketAdministration/>
    }

    if (pageName === "Users") {
        return <UserManagement/>
    }

    return null
}

const Administration = () => {

    const [pages, setPages] = React.useState([])
    const [selectedPage, setSelectedPage] = React.useState(0)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    React.useEffect(() => {
        getAdminTabsAPI(jwt, docket).then(r => setPages(r))
    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            {
                pages.length < 1 &&
                <Loading/>
            }
            <Tabs selectedTab={selectedPage} tabsList={pages} selectItem={(item) => setSelectedPage(item)}/>
            <div className={styles.content}>
                {getAdminPages(pages[selectedPage])}
            </div>
        </div>
    );
};

export default Administration;