const getTriageReferenceTopicData = (data, topic, subtopic) => {

    if (topic != null && topic !== '' && subtopic != null && subtopic !== '') {
        if (Object.keys(data).includes(topic)) {
            if (Object.keys(data[topic]['children']).includes(subtopic)) {
                return data[topic]['children'][subtopic]
            }
        }
    } else if (topic != null && topic !== '') {
        if (Object.keys(data).includes(topic)) {
            return data[topic]
        }
    }

    return null
}

export default getTriageReferenceTopicData;