//Returns a list with 2 sublist, one for PR reviewers and 1 for QA reviewers
import {useProductionURL} from "../helper/constants";

export const getDuplicateTabsAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/duplicate/get_tabs?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getDuplicateQueueAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/duplicate/get_queue?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const approveDuplicateAPI = async (jwt, docket, parentTracking, parentAttach, childTracking, childAttach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/duplicate/approve?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'parent_track': parentTracking,
            'parent_attach': parentAttach,
            'child_track': childTracking,
            'child_attach': childAttach
        })
    })

    return await resp.json()
}

export const denyDuplicateAPI = async (jwt, docket, parentTracking, parentAttach, childTracking, childAttach) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/duplicate/reject?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'parent_track': parentTracking,
            'parent_attach': parentAttach,
            'child_track': childTracking,
            'child_attach': childAttach
        })
    })

    return await resp.json()
}