import React from 'react';
import styles from "./AdminChangeRequest.module.css"
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../General/icons/chevronIcon";
import {
    getChangeRequestAdminAPI,
    updateChangeRequestNotesAPI,
    updateChangeRequestStatusAPI
} from "../../../api/changeRequestAPI";
import {useSelector} from "react-redux";
import Loading from "../../General/loading/loading";

const AdminChangeRequests = () => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [loading, setLoading] = React.useState(false);

    const [expandedIndex, setExpandedIndex] = React.useState(-1);
    const [showCompleted, setShowCompleted] = React.useState(false)
    const [changeRequests, setChangeRequests] = React.useState([]);

    const sortFunc = (a, b) => {
        if (a.status < b.status) {
            return 1
        } else if (a.status > b.status) {
            return -1
        }

        if (a.documentID < b.documentID) {
            return -1
        } else if (a.documentID > b.documentID) {
            return 1
        }

        return 0
    }

    const expandChangeRequest = (idx) => {
        if (expandedIndex === idx) {
            setExpandedIndex(-1)
        } else {
            setExpandedIndex(idx)
        }
    }

    const changeStatus = (reqID) => {
        let temp = [...changeRequests]
        for (let index in temp) {
            if (temp[index].requestID === reqID && temp[index].status === 'In Progress') {
                if (temp[index].resolutionNotes === '') {
                    if (!(window.confirm("This change request has no resolution. Are you sure you want to mark it as done?"))) {
                        return
                    }
                }
            }
        }

        updateChangeRequestStatusAPI(jwt, docket, reqID).then(r => {
            if (r.success) {
                let temp = [...changeRequests]

                for (let index in temp) {
                    if (temp[index].requestID === reqID) {

                        if (temp[index].status === 'Open') {
                            temp[index].status = 'In Progress'
                        } else if (temp[index].status === 'In Progress') {
                            temp[index].status = 'Closed'
                        }
                    }
                }

                setChangeRequests(temp.sort(sortFunc))
            }
        })
    }

    const updateTextField = (reqID, itemKey, itemText) => {
        let temp = [...changeRequests]

        for (let index in temp) {
            if (temp[index].requestID === reqID) {
                temp[index][itemKey] = itemText
            }
        }

        setChangeRequests(temp)
    }

    React.useEffect(() => {
        setLoading(true)
        getChangeRequestAdminAPI(jwt, docket).then(r => {
            if (r.success) {
                setChangeRequests(r.data.sort(sortFunc))
                setLoading(false)
            }
        })
    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            <div className={styles.header}>
                <h3>Change Requests</h3>
                <div>
                    <p>Show Closed Changes: </p>
                    <input type={'checkbox'} checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)}/>
                </div>
            </div>

            <div className={styles.content}>

                {
                    loading &&
                    <Loading/>
                }

                {
                    changeRequests.map((element, index) => (
                        <DetailSection key={element.requestID} element={element} index={index}
                                       expandedIndex={expandedIndex}
                                       showCompleted={showCompleted} changeStatus={changeStatus}
                                       expandChangeRequest={expandChangeRequest} updateTextField={updateTextField}/>
                    ))
                }

                {
                    changeRequests.length === 0 &&
                    <p>No Change Requests Found</p>
                }

            </div>
        </div>
    );
};

const DetailSection = ({
                           element,
                           index,
                           expandedIndex,
                           showCompleted,
                           changeStatus,
                           expandChangeRequest,
                           updateTextField
                       }) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    React.useEffect(() => {
        if (element.status === 'Closed' || element.status === 'Open') {
            return;
        }

        const timeout = setTimeout(() => {
            updateChangeRequestNotesAPI(jwt, docket, element.requestID,
                element.resolutionNotes, element.internalNotes).then(r => {
            })
        }, 600)

        return () => {
            clearTimeout(timeout)
        }
    }, [element.resolutionNotes, element.internalNotes])

    const isExpanded = (expandedIndex === index);
    let color = '#0300A3';
    let inProgress = false;
    let statusText = 'In Progress';
    if (element.status === 'In Progress') {
        color = '#9D750E'
        inProgress = true;
        statusText = 'Closed'
    } else if (element.status === 'Closed') {
        color = '#108B15'
        statusText = ''

        if (!showCompleted) {
            return null;
        }
    }

    return (
        <div key={element.requestID} style={{backgroundColor: index % 2 ? '#DEDEDE' : '#FFFFFF'}}
             className={styles.changeRequest}>

            <div className={styles.changeRequestTitle}>
                <h2>{element.documentID}</h2>
                <p>Submitted: {element.dateSubmitted}</p>
                <p>Status: <span style={{color: color}}>{element.status}</span></p>
                {
                    statusText !== '' &&
                    <button onClick={() => changeStatus(element.requestID)} className={styles.markAs}>
                        Mark as {statusText}
                    </button>
                }
                <button style={{marginLeft: statusText === '' ? 'auto' : 0}} className={styles.expand}
                        onClick={() => expandChangeRequest(index)}>
                    <ChevronIcon direction={isExpanded ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN} size={28}/>
                </button>
            </div>

            <div style={{height: isExpanded ? 280 : 0}} className={styles.changeRequestDetails}>

                <div className={styles.detailSection}>
                    <p><b style={{width: '40%', display: 'inline-block'}}>Submitter Name: </b> {element.submitterName}
                    </p>
                    <p><b style={{width: '40%', display: 'inline-block'}}>Submitter Email: </b> {element.submitterEmail}
                    </p>
                    <p><b style={{width: '40%', display: 'inline-block'}}>Topics to
                        Remove: </b> {element.topicRemove === '' ? 'None' : element.topicRemove}</p>
                    <p><b style={{width: '40%', display: 'inline-block'}}>Topics to
                        Add: </b> {element.topicAdd === '' ? 'None' : element.topicAdd}</p>
                    <p><b style={{width: '40%', display: 'inline-block'}}>Request Description: </b>
                        <br/>{element.description === '' ? 'None' : element.description}</p>
                </div>

                <div className={styles.separation}/>

                <div className={styles.detailSection}>
                    <p><b>Resolution Notes:</b></p>
                    <textarea value={element.resolutionNotes}
                              onChange={(e) => updateTextField(element.requestID, 'resolutionNotes', e.currentTarget.value)}
                              disabled={!inProgress} className={styles.textArea}/>

                    <p><b>Internal Comments:</b></p>
                    <textarea value={element.internalNotes}
                              onChange={(e) => updateTextField(element.requestID, 'internalNotes', e.currentTarget.value)}
                              disabled={!inProgress} className={styles.textArea}/>

                    {
                        !inProgress &&
                        <p style={{color: "darkred"}}>
                            Fields are disabled unless the change request is marked as 'In Progress'
                        </p>
                    }
                </div>

            </div>

        </div>
    )
}

export default AdminChangeRequests;