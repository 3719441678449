import React from 'react';
import parent from "../Duplicates.module.css"
import styles from "./DuplicateDisplay.module.css"
import TextDisplay from "../../../../General/TextDisplay/TextDisplay";

const DuplicateDisplay = ({
                              trackingNumber,
                              documentID,
                              attachmentSeq,
                              commenter,
                              attachCount,
                              status,
                              score = undefined,
                              flagCommenter = true,
                              flagAttachCount = true
                          }) => {
    return (
        <div className={`${parent.column} ${styles.content}`}>

            <div className={styles.metadata}>
                <div className={styles.line}>
                    <p>
                        <b>Commenter: </b>
                        <span style={{backgroundColor: flagCommenter ? '#F7F7A4' : 'transparent'}}>
                            {commenter}
                        </span>
                    </p>
                    <p><b>Status: </b> {status}</p>

                </div>
                <div className={styles.line}>
                    <p>
                        <b>Other Attachments: </b>
                        <span style={{backgroundColor: flagAttachCount ? '#F7F7A4' : 'transparent'}}>
                            {attachCount >= 1 ? attachCount - 1 : 0}
                        </span>
                    </p>

                    {
                        score !== undefined &&
                        <p><b>Similarity: </b> {score}%</p>
                    }

                    <p><b>Document ID: </b> {documentID} {attachmentSeq}</p>
                </div>

            </div>

            <div className={styles.divider}/>

            <div className={styles.textWrapper}>
                <TextDisplay trackingNum={trackingNumber} documentID={documentID} attachment={attachmentSeq}/>
            </div>

        </div>
    );
};

export default DuplicateDisplay;