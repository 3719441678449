import React from 'react';
import styles from "./validateIcon.module.css"

const ValidateIcon = ({
                          size = 24
                      }) => {

    let iconStr = "/assets/imgs/validate.svg"


    return (
        <img className={styles.icon} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default ValidateIcon;