import React from 'react';
import styles from './FormLetterItem.module.css'
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../../General/icons/chevronIcon";
import ManageDocuments from "./ManageDocuments";
import ReviewFormLetter from "./ReviewFormLetter";

const getBackgroundColor = (status) => {
    if (status === "Reviewing") {
        return "#f5f5f5"
    }

    if (status === "Reviewed") {
        return "#e7ffff"
    }

    if (status === "Released") {
        return "#ff9494"
    }

    return "#fff0ab"
}

const FormLetterItem = ({
                            formLetter,
                            isOpen,
                            openFunction,
                            addFunc,
                            removeFunc,
                            changeStandardFunc,
                            changeNameFunc,
                            stateFunc,
                            viewElementFunc,
                            unviewElementFunc,
                            bulkViewFunc,
                            batchID = 0
                        }) => {

    const [isOnManagingDocumentsTab, setIsOnManagingDocumentsTab] = React.useState(true)

    return (
        <div style={{backgroundColor: getBackgroundColor(formLetter["status"])}}
             className={`${styles.wrapper} ${isOpen ? styles.wrapperOpen : styles.wrapperClosed}`}>
            <div onClick={openFunction} className={styles.header}>
                <ChevronIcon direction={isOpen ? CHEVRON_DIRECTIONS.DOWN : CHEVRON_DIRECTIONS.RIGHT}/>
                <h3 className={styles.headerText}>
                    {(formLetter["name"] !== undefined && formLetter['name'] !== '') ? formLetter['name'] : `Form Letter #${batchID}`}&nbsp;
                    (Unread: {formLetter["children"].filter(x => x["viewed"] === false).length},
                    Total: {formLetter["children"].length + 1})
                </h3>
            </div>
            {
                <div className={`${styles.content} ${isOpen ? styles.contentOpen : styles.contentClosed}`}>
                    <div className={styles.tabs}>
                        <button onClick={() => setIsOnManagingDocumentsTab(true)} disabled={isOnManagingDocumentsTab}>
                            Manage Documents
                        </button>
                        <button onClick={() => setIsOnManagingDocumentsTab(false)} disabled={!isOnManagingDocumentsTab}>
                            Review Form Letter
                        </button>
                    </div>

                    {
                        !isOpen ? null :
                            isOnManagingDocumentsTab
                                ?
                                <ManageDocuments addFunc={addFunc} removeFunc={removeFunc}
                                                 viewElementFunc={viewElementFunc}
                                                 bulkViewFunc={bulkViewFunc}
                                                 changeStandardFunc={changeStandardFunc} formLetter={formLetter}
                                                 unviewElementFunc={unviewElementFunc}/>
                                :
                                <ReviewFormLetter changeNameFunc={changeNameFunc} stateFunc={stateFunc}
                                                  formLetter={formLetter}/>
                    }

                </div>
            }
        </div>
    );
};

export default FormLetterItem;