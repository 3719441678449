import React from 'react';
import styles from './summaryOfSummariesModal.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import ReactPDF from "@react-pdf/renderer";
import SummaryOfSummariesPDF from "./SummaryOfSummariesPDF/SummaryOfSummariesPDF";
import {generateTopLevelSummariesAPI, getTopLevelSummaryCountAPI} from "../../../api/aiAPI";
import {TOP_LEVEL_SUMMARY_TIME_ESTIMATION} from "../../../helper/constants";
import CountdownEstimation from "./CountdownEstimation";

const SummaryOfSummariesModal = ({
                                     open, closeModal,
                                 }) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics);

    const [selected, setSelected] = React.useState({})
    const [summaryComments, setSummaryComments] = React.useState({})
    const [summaries, setSummaries] = React.useState({})
    const [topicListMismatch, setTopicListMismatch] = React.useState(false) //tracks if the topics list & the generated summaries are on a different list
    const [estimatedLoadingTime, setEstimatedLoadingTime] = React.useState(-1);
    const [error, setError] = React.useState("")

    const getSummaryCount = (topic, subtopic) => {
        if (Object.keys(summaryComments).includes(topic)) {
            if (subtopic === "" || subtopic === undefined) {
                return summaryComments[topic].comments.length
            } else {
                if (Object.keys(summaryComments[topic].subtopics).includes(subtopic)) {
                    return summaryComments[topic].subtopics[subtopic].length
                } else {
                    return 0
                }
            }
        } else {
            return 0
        }
    }

    const updateSelected = (topic, subtopic) => {
        let temp = {...selected}

        const summaryCount = getSummaryCount(topic, subtopic)

        if (subtopic === "") {
            if (Object.keys(temp).includes(topic)) {
                delete temp[topic]
            } else if (summaryCount > 0) {
                temp[topic] = []
            }
        } else {
            if (Object.keys(temp).includes(topic)) {
                if (temp[topic].includes(subtopic)) {
                    temp[topic] = temp[topic].filter(item => item !== subtopic)
                } else if (summaryCount > 0) {
                    temp[topic].push(subtopic)
                }
            } else if (summaryCount > 0) {
                temp[topic] = [subtopic]
            }
        }

        setSelected(temp)
        setTopicListMismatch(true)
    }

    const clearSelected = () => {
        setTopicListMismatch(true)
        setSelected({})
    }

    const allSelectedToggle = () => {
        const temp = {}
        Object.keys(docketTopics).forEach(topicKey => {
            const summaryCount = getSummaryCount(topicKey, '')
            if (summaryCount > 0) {
                temp[topicKey] = []
                docketTopics[topicKey].children.forEach(subtopicList => {
                    const subtopicSummaryCount = getSummaryCount(topicKey, subtopicList[0])
                    if (subtopicSummaryCount > 0) {
                        temp[topicKey].push(subtopicList[0])
                    }
                })
            }
        })

        setTopicListMismatch(true)
        setSelected(temp)
    }

    const clearAndCloseModal = () => {
        closeModal()
    }


    const downloadPDF = React.useCallback(async () => {
        const blob = await ReactPDF.pdf(<SummaryOfSummariesPDF selected={selected} summaries={summaries}
                                                               comments={summaryComments}/>).toBlob()
        const url = URL.createObjectURL(blob)

        if (url && url.length > 0) {
            let aTag = document.createElement('a')
            aTag.href = url
            aTag.style = "display: none";
            aTag.download = 'myTest.pdf'
            document.body.appendChild(aTag)
            aTag.click()
        }
    }, [selected, summaries, summaryComments])

    const generateSummaries = () => {
        setTopicListMismatch(false)
        setError("")

        const topicsList = []
        Object.keys(selected).forEach(topicKey => {
            topicsList.push([topicKey, ""])
            selected[topicKey].forEach(subtopic => {
                topicsList.push([topicKey, subtopic])
            })
        })

        if (topicsList.length < 1 || estimatedLoadingTime > 0) {
            return;
        }

        const estimatedTime = topicsList.length * TOP_LEVEL_SUMMARY_TIME_ESTIMATION

        let timeObject = new Date();
        timeObject = new Date(timeObject.getTime() + (estimatedTime * 1000))
        const timeNumber = timeObject.getTime()

        setEstimatedLoadingTime(timeNumber)
        generateTopLevelSummariesAPI(jwt, docket, topicsList).then(r => {
            setEstimatedLoadingTime(-1)
            setSummaries(r)
            setError("")
        }).catch(e => {
            setError("There was an error generating the top level summaries")
            setEstimatedLoadingTime(-1)
            setSummaries({})
        })
    }

    React.useEffect(() => {
        getTopLevelSummaryCountAPI(jwt, docket).then(r => {
            if (r.success) {
                setSummaryComments(r.data)
            }
        })
    }, [jwt, docket])

    if (!open) {
        return null;
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Generate Top-Level Summaries&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Generate Top-Level Summaries</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.topicSelectionColumn}>
                        <div className={styles.topicSelectionGrouping}>
                            <div className={styles.topicSelectionList}>
                                {
                                    Object.keys(docketTopics).map(topicKey => {
                                        const topic = docketTopics[topicKey]
                                        if (topic.isRider) {
                                            return null
                                        }

                                        const topicSummaryCount = getSummaryCount(topicKey, '')

                                        return (
                                            <div key={topic.name} className={styles.topicWrapper}>
                                                <div className={styles.topicRowItem}>
                                                    <input type={'checkbox'}
                                                           disabled={topicSummaryCount < 1}
                                                           checked={Object.keys(selected).includes(topicKey)}
                                                           onChange={() => updateSelected(topicKey, '')}
                                                    />
                                                    <p>{topic.name} ({topicSummaryCount} Summaries)</p>
                                                </div>
                                                {
                                                    topic.children.map(subtopicList => {

                                                        const subtopicSummaryCount = getSummaryCount(topicKey, subtopicList[0])

                                                        return (
                                                            <div key={subtopicList[1]}
                                                                 className={styles.subtopicRowItem}>
                                                                <input
                                                                    disabled={subtopicSummaryCount < 1}
                                                                    checked={Object.keys(selected).includes(topicKey) && selected[topicKey].includes(subtopicList[0])}
                                                                    onChange={() => updateSelected(topicKey, subtopicList[0])}
                                                                    type={'checkbox'}/>
                                                                <p>{subtopicList[1]} ({subtopicSummaryCount} Summaries)</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.controlButtonsWrapper}>
                                <button onClick={() => allSelectedToggle()}>Select All</button>
                                <button onClick={() => clearSelected()}>Unselect All</button>
                            </div>
                        </div>
                        <button onClick={generateSummaries}
                                disabled={Object.keys(selected).length < 1 || estimatedLoadingTime > 0}
                                className={styles.generateSummariesButton}>
                            Generate Top-Level Summary Report
                        </button>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.reportDisplay}>
                        {
                            estimatedLoadingTime > 0 ?
                                <div className={styles.loadingContent}>
                                    <CountdownEstimation finishTime={estimatedLoadingTime}/>
                                </div> :
                                error !== "" ?
                                    <div style={{boxSizing: "border-box", padding: 24}}
                                         className={styles.loadingContent}>
                                        <div className={styles.misalignmentWarning}>
                                            {error}
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        {
                                            topicListMismatch && Object.keys(summaries).length > 0 &&
                                            <div className={styles.misalignmentWarning}>
                                                The selected topics list has changed since this report was generated
                                            </div>
                                        }
                                        <div className={styles.textContent}>
                                            {
                                                Object.keys(summaries).map(topicKey => {

                                                    const topicSummaryCount = getSummaryCount(topicKey, '')

                                                    return (
                                                    <React.Fragment key={topicKey}>
                                                        <h2>{topicKey}</h2>
                                                        <p style={{fontStyle: "italic"}}>
                                                            {topicSummaryCount} Major Commenter{topicSummaryCount>1 ? "s" : ""}
                                                        </p>
                                                        <p><b>Topic Summary:</b> {summaries[topicKey].summary}</p>
                                                        <div style={{lineHeight: "4px"}}>
                                                            <br />
                                                        </div>
                                                        {
                                                            Object.keys(summaries[topicKey].subtopics).map(subtopic => {

                                                                const subtopicSummaryCount = getSummaryCount(topicKey, subtopic)

                                                                return (
                                                                    <React.Fragment key={subtopic}>
                                                                        <h3 style={{marginLeft: 16}}>{subtopic}</h3>
                                                                        <p style={{fontStyle: "italic", marginLeft: 16}}>
                                                                            {subtopicSummaryCount} Major Commenter{subtopicSummaryCount>1 ? "s" : ""}
                                                                        </p>
                                                                        <p style={{marginLeft: 16}}><b>Subtopic
                                                                            Summary:</b> {summaries[topicKey].subtopics[subtopic]}
                                                                        </p>
                                                                        <div style={{lineHeight: "4px"}}>
                                                                            <br />
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            )
                                                        }
                                                        <div className={styles.topicDivider}/>
                                                    </React.Fragment>
                                                )})
                                            }
                                        </div>
                                        {
                                            Object.keys(summaries).length > 0 &&
                                            <div className={styles.downloadWrapper}>
                                                <button disabled={Object.keys(summaries).length < -1}
                                                        onClick={downloadPDF}>Download Report
                                                </button>
                                            </div>
                                        }
                                    </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default SummaryOfSummariesModal;