import React from 'react';
import styles from './ExcerptTopicReference.module.css'
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../icons/chevronIcon";
import {useSelector} from "react-redux";
import getTriageReferenceTopicData from "../../../../../helper/getTriageReferenceTopicData";

const ExcerptTopicReference = ({open, closeFunction, topic, subtopic}) => {

    const docketReference = useSelector((state) => state.dockets.docketTopicsReference);

    const contentDiv = React.useRef(null)

    React.useEffect(() => {
        if (contentDiv === null || contentDiv.current === null) {
            return
        }

        contentDiv.current.scrollTop = 0
    }, [topic, subtopic])

    if (!open) {
        return null;
    }

    const topicInformation = getTriageReferenceTopicData(docketReference, topic, subtopic)

    return (
        <div className={styles.excerptReferenceWrapper}>
            <div className={styles.closeButtonWrapper}>
                <button onClick={closeFunction}>
                    <ChevronIcon size={20} bold={true} direction={CHEVRON_DIRECTIONS.RIGHT}/>
                </button>
            </div>
            <div ref={contentDiv} className={styles.contentWrapper}>
                <div style={{width: '100%'}}>
                    <h3 style={{marginTop: 0}} className={styles.sectionHeader}>Reference: </h3>
                    <p className={styles.labelText}>
                        Topic:&nbsp;
                        <span className={styles.valueText}>{topic == null ? "Unknown" : topic}</span>
                    </p>
                    {
                        subtopic != null && subtopic !== '' &&
                        <p className={styles.labelText}>
                            Subtopic:&nbsp;
                            <span className={styles.valueText}>{subtopic == null ? "Unknown" : subtopic}</span>
                        </p>
                    }
                </div>

                <div className={styles.wrapperDivider}/>

                <div className={styles.topicInformation}>
                    <h3 style={{marginTop: 0}} className={styles.sectionHeader}>Section Information: </h3>
                    <p className={styles.labelText}>
                        Section:&nbsp;
                        <span
                            className={styles.valueText}>{topicInformation.section == null ? "Unknown" : topicInformation.section}</span>
                    </p>
                    <p className={styles.labelText}>FR Page:&nbsp;
                        <span
                            className={styles.valueText}>{topicInformation.fr_page == null ? "Unknown" : topicInformation.fr_page}</span>
                    </p>
                    <p className={styles.labelText}>
                        PDF Page:&nbsp;
                        <span
                            className={styles.valueText}>{topicInformation.pdf_page == null ? "Unknown" : topicInformation.pdf_page}</span>
                    </p>
                    <p className={styles.labelText}>
                        Topic Name: <span className={styles.valueText}
                                          style={{marginRight: 32}}>{topicInformation.topic == null ? "Unknown" : topicInformation.topic}</span>
                    </p>
                    {
                        topicInformation.subtopic != null && topicInformation.subtopic !== '' &&
                        <p className={styles.labelText}>
                            Subtopic Name: <span className={styles.valueText}
                                                 style={{marginRight: 32}}>{topicInformation.subtopic == null ? "Unknown" : topicInformation.subtopic}</span>
                        </p>
                    }

                    <h3 className={styles.sectionHeader}>Summary: </h3>
                    <p className={styles.labelText}>Background: </p>
                    {
                        (topicInformation.background == null || topicInformation.background === "") ?
                            <p className={styles.valueText}>No Background Text Found</p> :
                            topicInformation.background.split("\n").map((text, idx) => (
                                <p key={idx} className={styles.valueText}>{text}</p>
                            ))
                    }
                    <p style={{marginTop: 12}} className={styles.labelText}>Provision: </p>
                    {
                        (topicInformation.provision == null || topicInformation.provision === "") ?
                            <p className={styles.valueText}>No Provision Text Found</p> :
                            topicInformation.provision.split("\n").map((text, idx) => (
                                <p key={idx} className={styles.valueText}>{text}</p>
                            ))
                    }
                    {
                        (subtopic === "" || subtopic == null) && <>
                            {
                                // Only do this section for topics, since they're the only one with other solicitations!
                            }
                            <p style={{marginTop: 12}} className={styles.labelText}>Other Solicitations: </p>
                            {
                                (topicInformation.other_solicitations == null || topicInformation.other_solicitations === "") ?
                                    <p className={styles.valueText}>No Other Solicitations Found</p> :
                                    topicInformation.other_solicitations.split("\n").map((text, idx) => (
                                        <p key={idx} className={styles.valueText}>{text}</p>
                                    ))
                            }
                        </>

                    }
                    <p style={{marginTop: 12}} className={styles.labelText}>Notes: </p>
                    {
                        (topicInformation.notes == null || topicInformation.notes === "") ?
                            <p className={styles.valueText}>No Notes Found</p> :
                            topicInformation.notes.split("\n").map((text, idx) => (
                                <p key={idx} className={styles.valueText}>{text}</p>
                            ))
                    }

                    <h3 className={styles.sectionHeader}>Keywords: </h3>
                    <p className={styles.valueText}>
                        {topicInformation.keywords == null ?
                            "No Keywords Loaded" :
                            <p className={styles.valueText}>{topicInformation.keywords.join(", ")}</p>
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ExcerptTopicReference;