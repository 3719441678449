import {useProductionURL} from "../helper/constants";

export const getUserGuideEntriesAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/get_guides?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getUserGuidePDFAPI = async (jwt, id, docket, options = {}) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/get_pdf?` + new URLSearchParams({
        docket: docket,
        id: id
    }), {
        headers: {
            "auth": jwt
        },
        ...options
    });

    return resp;
}


export const postUserGuideEntryAPI = async (jwt, data) => {
    const formData = new FormData();

    if (!(data.pdf) || !(data.docket) || !(data.option)) {
        window.alert('Error reading data');
        return;
    }

    if (data.pdf) {
        formData.append('pdf', data.pdf);
    } else {
        window.alert('PDF data does not exist');
        return;
    }
    if (data.option) {
        formData.append('option', data.option);
    }
    if (data.docket) {
        formData.append('docket', data.docket);
    }
        formData.append('category', data.category)

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/entry`, {
        method: "POST",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });
    return resp.json();
}

export const getUserGuideMangementAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/get_admin_status?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json();
}

export const replaceUserGuidePDFAPI = async (jwt, data) => {
    const formData = new FormData();
    if (data.pdf) {
        formData.append('pdf', data.pdf);
    } else {
        window.alert('PDF data does not exist');
        return;
    }
    if (data.docket) {
        formData.append('docket', data.docket);
    }

    if (data.entry_id) {
        formData.append('entry_id', data.entry_id)
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/replace_pdf`, {
        method: "POST",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });

    return await resp.json();
};

export const setGuidePDFDeletedAPI = async (jwt, docket, entry_id) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/delete_entry?` + new URLSearchParams({
        docket: docket,
        entry_id: entry_id
    }), {
        method: "DELETE",
        headers: {
            "auth": jwt
        }
    })
    return resp.json();
}

export const renameGuideEntryAPI = async (jwt, docket, entry_id, name) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user_guide/rename_entry?` + new URLSearchParams({
        docket: docket,
        entry_id: entry_id,
        name: name
    }), {
        method: "PUT",
        headers:
         {
            "auth": jwt
        }
    })

    return await resp.json();
}