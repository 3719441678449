import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import {FILTER_PERSIST_EXPIRY} from "../../../helper/constants";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import getUniqueColumnValues, {
    getSubtopicValueLabelPair,
    getTopicValueLabelPair, orderUniqueColumnValues
} from "../../../helper/getUniqueColumnValues";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import MaskSentimentValues from "../../../helper/maskSentimentValues";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import cleanUpHTMLTags from "../../../helper/cleanUpHTMLTags";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import { setReportFilters, clearReportFilters } from "../../../store/reportsSlice";
import parseReportFilters from "../../../helper/parseReportFilters";
import updateReportFilters from "../../../helper/updateReportFilters";
import MultiSelectClear from "./multiselectFilter/multiselectClear";

const headings = [
    {
        accessorKey: "flname".toString().replaceAll(" ", "").toLowerCase(),
        header: "Form Letter Name",
        size: 200
    },
    {
        accessorKey: "Batch Size".toString().replaceAll(" ", "").toLowerCase(),
        header: "Batch Size",
        size: 75
    },
    {
        accessorKey: "docid",
        header: "Standard",
        size: 100,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Attachment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Attachment",
        size: 75
    },
    {
        accessorKey: "textfield",
        header: "Text",
        size: 200
    },
    {
        accessorKey: "Topic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Topic",
        size: 150
    },
    {
        accessorKey: "Subtopic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Subtopic",
        size: 100
    },
    {
        accessorKey: "Sentiment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Sentiment",
        size: 100
    },
    {
        accessorKey: "Out Of Scope".toString().replaceAll(" ", "").toLowerCase(),
        header: "Out Of Scope",
        size: 100
    },
]
//["Form Letter Name", "Batch Size", "Standard ID", "Attachment", "Text", "Topic", "Subtopic", "Sentiment"]

const filterData = (data, topics, fls, filteredTopics, filteredSubtopics, docket) => {
    let temp = []

    for (let item of data) {

        let tempItem = [...item]

        if (fls.length > 0) {
            if (!(fls.includes(tempItem[0]))) {
                continue
            }
        }

        if (filteredTopics.length > 0) {
            if (!(filteredTopics.includes(tempItem[5]))) {
                continue
            }
        }

        if (filteredSubtopics.length > 0) {
            if (!(filteredSubtopics.includes(tempItem[6]))) {
                continue
            }
        }


        if (topics !== {}) {
            try {
                tempItem[5] = topics[tempItem[5]]["name"]
            } catch (e) {

            }
        }

        try {
            tempItem[7] = MaskSentimentValues(tempItem[7], 1, undefined, docket)
        } catch (e) {

        }

        tempItem[4] = cleanUpHTMLTags(tempItem[4]).replaceAll(/(<([^>]+)>)/ig, '');

        temp.push(tempItem)
    }

    return [...temp]
}

const downloadFLReport = (rawReportData, docket) => {

    let reportData = []

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem[7] = MaskSentimentValues(tempItem[7], 1, undefined, docket)
        tempItem[4] = cleanUpHTMLTags(tempItem[4]).replaceAll(/(<([^>]+)>)/ig, '').replaceAll('\r\n', '').replaceAll('\n', '');

        if (tempItem[4].length > 32000) {
            tempItem[4] = `${tempItem[4].toString().substring(0, 32000)} -- CELL TEXT OVERFLOW IN EXCEL --`
        }

        if (tempItem[8] == null) {
            tempItem[8] = ""
        }

        if (tempItem[10] == null) {
            tempItem[10] = ""
        }

        tempItem.splice(3, 0, `${docket}-${tempItem[2]}`)
        tempItem.splice(5, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[2]}`)

        reportData.push(tempItem)
    }

    let tempHeadings = [...headings]

    tempHeadings.splice(3, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    downloadReportData(tempHeadings, reportData, "form_letter_report")
}

const FormLetterReport = () => {

    const [rawReportData, setRawReportData] = React.useState([]);

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const storedFilters = useSelector((state) => state.reports.flReport);
    const dispatch = useDispatch()

    const uniqueFLNames = React.useMemo(() => orderUniqueColumnValues(getUniqueColumnValues(rawReportData, 0)), [rawReportData])
    const allTopics = React.useMemo(() => [{
        value: '0A_OUT_OF_SCOPE',
        label: '0A_OUT_OF_SCOPE'
    }, ...getTopicValueLabelPair(docketTopics)], [docketTopics])
    const allSubtopics = React.useMemo(() => getSubtopicValueLabelPair(docketTopics, parseReportFilters('topic', storedFilters)), [docketTopics, storedFilters])

    const [loading, setLoading] = React.useState(false)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "flReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 4).then(r => {
            setRawReportData(r)
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "flReport" }))
    }

    const filteredData = React.useMemo(() =>
        filterData(rawReportData, docketTopics,
            parseReportFilters('flName', storedFilters),
            parseReportFilters('topic', storedFilters), parseReportFilters('subtopic', storedFilters),
            docket),
        [rawReportData, docketTopics, storedFilters])
    const uniqueFLBatches = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(headings, dispatch, docket), [dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"FL Name"} width={"15vw"}
                    selected={parseReportFilters('flName', storedFilters)}
                    setSelected={(values) => updateFilter('flName', values)} options={uniqueFLNames} />
                <MultiSelectFilter title={"Topic"} width={"15vw"} selected={parseReportFilters('topic', storedFilters)}
                    setSelected={(values) => updateFilter('topic', values)} options={allTopics} />
                <MultiSelectFilter title={"Subtopic"} width={"15vw"}
                    selected={parseReportFilters('subtopic', storedFilters)}
                    setSelected={(values) => updateFilter('subtopic', values)} options={allSubtopics}
                    noOptionsText={
                        parseReportFilters('topic', storedFilters).length === 0 ?
                            "No Topics Selected" :
                            "No Subtopics for Selected Topics"
                    } />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "flReport" }))} />
            </div>
            <div className={styles.title}>
                <p>Form Letter Report <span style={{ marginLeft: 16 }}>({uniqueFLBatches} form letters)</span></p>
                <button onClick={() => downloadFLReport(rawReportData, docket)}>
                    <DownloadIcon />
                </button>
            </div>
            <div className={styles.table}>
                {loading ? <Loading /> :
                    <GenericTable data={filteredData}
                        headingsList={finalHeadings} allowExpansionPanel={true} />}
            </div>
        </div>
    );
};

export default FormLetterReport;