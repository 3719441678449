import React from 'react';
import loginStyles from './login.module.css'
import LoginWrapper from "./loginWrapper";
import {useDispatch, useSelector} from "react-redux";
import {setDocket} from "../../store/navSlice";
import {LOGIN_STEP} from "../../helper/constants";
import {getAvailableDocketsAPI} from "../../api/authAPI";
import signOutFunc from "../../helper/signOutFunc";
import {setLoginStatus} from "../../store/loginSlice";
import { getReviewerTypeAPI } from '../../api/reviewsAPI';
import { setReviewerStatus } from '../../store/userSlice';

const ChooseDocket = () => {

    const dispatch = useDispatch()
    const jwt = useSelector((state) => state.user.jwt)
    const username = useSelector((state) => state.user.username)
    const [availableDockets, setAvailableDockets] = React.useState([])
    const [selectedDocket, setSelectedDocket] = React.useState("")

    const accessSystem = () => {
        dispatch(setDocket(selectedDocket))
        dispatch(setLoginStatus(LOGIN_STEP.FINISHED))
        getReviewerTypeAPI(jwt, selectedDocket).then((resp) => {
            const [pr, qa, admin] = [resp.data.pr, resp.data.qa, resp.data.admin]
            dispatch(setReviewerStatus({isPrimaryReviewer: pr, isQAReviewer: qa, isAdmin: admin}))
        })
    }

    const restart = React.useCallback(() => {
        signOutFunc(dispatch)
    }, [dispatch])

    React.useEffect(() => {
        getAvailableDocketsAPI(jwt).then(dockets => {
            setAvailableDockets(dockets);
            if (dockets.length > 0) {
                setSelectedDocket(dockets[0][0])
            } else {
                restart()
            }
        })
    }, [jwt, restart])

    return (
        <LoginWrapper>
            <p className={loginStyles.fieldLabel}>Signing in as: <b>{username}</b></p>
            <form action="" className={loginStyles.form}>
                <label className={loginStyles.fieldLabel} id="docketsLabel" htmlFor="dockets">Available Dockets:</label>
                <select className={loginStyles.selectField} onChange={e => setSelectedDocket(e.currentTarget.value)}
                        name={"dockets"} id={"dockets"} value={selectedDocket} size={10}>
                    {
                        availableDockets.map((docket) => 
                        <option key={docket} value={docket[0]}>{docket[0]}{docket[1] ? " (" + docket[1] + ")" : null} </option>)
                    }
                </select>
                <button className={loginStyles.forgotPasswordButton} type={"button"} onClick={restart}>Switch User
                </button>
                <button style={{marginTop: 50}} onClick={accessSystem} type={"button"}
                        className={loginStyles.button}>Continue
                </button>
            </form>
        </LoginWrapper>
    );
};

export default ChooseDocket;