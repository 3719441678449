
//Returns all topics, subtopics, and rider status for a docket
import {useProductionURL} from "../helper/constants";

export const getDocketTopicsAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/docket/get_topics?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns all comments with their topics and FL status for easy sorting
export const getCommentsAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/docket/get_comments?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getReportsAPI = async (jwt, docket) => {
    return [
        "Overview",
        "Search Comment Text",
        "Topic Report",
        "Major Commenter Report",
        "MC Summary Report",
        "Form Letter Report",
        "Rider Report",
        "Out of Scope Report",
        "Annotation Report"
    ]
}