import React from 'react';
import styles from './Reviews.module.css'
import SelectInitialReview from "./selectInitialReview/selectInitialReview";
import {REVIEW_TYPES} from "../../helper/constants";
import {useDispatch, useSelector} from "react-redux";
import {setLastReviewType} from "../../store/preferenceSlice";
import {getReviewerTypeAPI} from "../../api/reviewsAPI";
import ReviewScreen from "./reviewScreen/reviewScreen";

const Reviews = () => {

    const [prAllowed, setPRAllowed] = React.useState(false)
    const [qaAllowed, setQAllowed] = React.useState(false)

    const dispatch = useDispatch()
    const reviewType = useSelector((state) => state.prefs.reviewType);
    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const setReviewType = (type) => {
        dispatch(setLastReviewType(type))
    }

    React.useEffect(() => {
        getReviewerTypeAPI(jwt, docket).then(r => {
            if (r.success) {
                setPRAllowed(r.data.pr)
                setQAllowed(r.data.qa)

                if (reviewType === REVIEW_TYPES.QA && !(r.data.qa)) {
                    setLastReviewType(REVIEW_TYPES.PR)
                }
            }
        })
    }, [jwt, docket])

    return (
        <div className={styles.wrapper}>
            {
                reviewType === REVIEW_TYPES.notSelected ?
                    <SelectInitialReview prAllowed={prAllowed} qaAllowed={qaAllowed} setReviewType={setReviewType}/> :
                    <ReviewScreen reviewType={reviewType} prAllowed={prAllowed} qaAllowed={qaAllowed}
                                  setReviewType={setReviewType}/>
            }
        </div>
    );
};

export default Reviews;