import React from 'react';
import styles from "./FormLetters.module.css"
import SearchIcon from "../../../General/icons/searchIcon";
import FormLetterReferenceModal from "./modals/FormLetterReferenceModal";
import cleanUpHTMLTags from "../../../../helper/cleanUpHTMLTags";

const rightBorder = {
    borderRight: '2px solid #aaaaaa'
}

const filter = (data, text) => {
    let temp = []

    const lowerText = text.toLowerCase()
    for (let item of data) {
        if (item.name.toLowerCase().includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.desc !== null && item.desc !== undefined && item.desc.toLowerCase().includes(text)) {
            temp.push(item)
            continue;
        }

        if (item.text !== null && item.text !== undefined && item.text.toLowerCase().includes(text)) {
            temp.push(item)
            continue;
        }

        let topicFound = false
        for (let topic of item.topics) {
            if (topic.toLowerCase().includes(text)) {
                topicFound = true
                break;
            }
        }

        if (topicFound) {
            temp.push(item)
        }
    }

    return temp
}

const FormLetters = ({data}) => {

    const [filterText, setFilterText] = React.useState('')

    const [modalData, setModalData] = React.useState(null)

    const expandFormLetter = (id) => {
        for (let item of data) {
            if (item.id === id) {
                setModalData(item)
                break;
            }
        }
    }


    const filteredData = React.useMemo(() => filter(data, filterText), [data, filterText])


    return (
        <div className={styles.content}>
            <div className={styles.search_wrapper}>
                <input value={filterText}
                       onChange={e => setFilterText(e.currentTarget.value)}
                       type={'text'}
                       placeholder={'Search All Fields...'}/>
                <button>
                    <SearchIcon size={28} opacity={0.6}/>
                </button>
            </div>
            <div className={styles.table_wrapper}>
                <table style={{position: 'relative'}}>
                    <thead>
                    <tr style={{backgroundColor: '#c7c7c7', height: 30}}>
                        <th style={{...rightBorder, borderTopLeftRadius: 24, minWidth: 120, width: '20%'}}>FL Name</th>
                        <th style={{...rightBorder, minWidth: 120, width: '20%'}}>Description</th>
                        <th style={{...rightBorder, minWidth: 240, width: '40%'}}>Text</th>
                        <th style={{top: 0, borderTopRightRadius: 12, minWidth: 120, width: '20%'}}>Topics</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        filteredData.map(fl => (
                            <tr key={fl.id}>
                                <td style={rightBorder}>
                                    <div className={styles.contentWrapper}>
                                        {fl.name}
                                    </div>
                                </td>
                                <td style={rightBorder}>
                                    <div className={styles.contentWrapper}>
                                        {
                                            (fl.desc === null || fl.desc === undefined) ?
                                                <p><b>No Description Provided</b></p>
                                                :
                                                fl.desc.split("\n").map((text, idx) => (
                                                    <p className={styles.topic} key={idx}>
                                                        {text}
                                                    </p>
                                                ))
                                        }
                                    </div>
                                </td>
                                <td style={rightBorder}>
                                    <div className={styles.contentWrapper}>
                                        <div className={styles.textWrapper}>
                                            {
                                                (fl.text === null || fl.text === undefined) ?
                                                    <p><b>Parent Text Missing in Database...?</b></p>
                                                    :
                                                    cleanUpHTMLTags(fl.text).replaceAll("<br/>", " ").split("\n").map((text, idx) => (
                                                        <p className={styles.topic} key={idx}>
                                                            {text}
                                                        </p>
                                                    ))
                                            }
                                        </div>

                                        <button onClick={() => expandFormLetter(fl.id)}
                                                className={styles.expandButton}>-- Click to View In Fullscreen --
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.contentWrapper}>
                                        {fl.topics.map((topic, idx) => (
                                            <p className={styles.topic} key={idx}>
                                                <span>•&nbsp;{topic}</span>
                                            </p>
                                        ))}
                                        {
                                            fl.topics.length < 1 &&
                                            <p><b>No Topics Identified</b></p>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>

            <FormLetterReferenceModal isOpen={modalData !== null} closeModal={() => setModalData(null)}
                                      data={modalData}/>
        </div>
    );
};

export default FormLetters;