//Returns the saved user preferences
import {useProductionURL} from "../helper/constants";

export const createChangeRequestAPI = async (jwt, docket, topicsAdd, topicsRemove, tracking, description) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change/create`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            "docket": docket,
            "add_topics": topicsAdd,
            "remove_topics": topicsRemove,
            "tracking": tracking,
            "description": description
        })


    })

    return await resp.json()
}

export const getChangeRequestUserAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateChangeRequestReadUserAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change/read_all`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "docket": docket
        })


    })

    return await resp.json()
}


export const getChangeRequestAdminAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change/admin?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateChangeRequestStatusAPI = async (jwt, docket, id) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change/admin/status`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "docket": docket,
            "id": id
        })


    })

    return await resp.json()
}

export const updateChangeRequestNotesAPI = async (jwt, docket, id, resolution, internal) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/change/admin/notes`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "docket": docket,
            "id": id,
            'resolution': resolution,
            'internal': internal
        })
    })

    return await resp.json()
}