import React from 'react';
import styles from "./reviewProgressCard.module.css"
import {Step, StepLabel, Stepper} from "@mui/material";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../General/icons/chevronIcon";
import Commenter from "./sections/commenter";
import updateTopicsDictionary from "../../../../helper/updateTopicsDictionary";
import Summary from "./sections/summary";
import SingleTextArea from "./sections/singletextarea";
import Submit from "./sections/submit";
import { getCommentTopicsAPI } from "../../../../api/commentAPI";
import { useSelector } from "react-redux";
import { updateTopicsAPI, getMCNamesAPI } from "../../../../api/reviewsAPI";
import TopicDisplay from "./sections/TopicDisplay/topicDisplay";

const steps = ['Commenter', 'Topics', 'Summary', 'Out of Scope', 'Internal', 'Finish'];
const mcOnlySteps = ['Summary'] //Cannot be the first or last step


const ReviewProgressCard = ({
                                commenterInfo,
                                updateCommenterInfo,
                                trackingNumber,
                                submitFunction,
                                isPaused,
                                useExcerpts,
                                excerpts,
                                keywords,
                                keywordTopic,
                                setKeywordTopic,
                                keywordDisplay,
                                setKeywordDisplay
                            }) => {
    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [activeStep, setActiveStep] = React.useState(0);
    const [hasSaved, setHasSaved] = React.useState(true);

    const [selectedTopics, setSelectedTopics] = React.useState({});
    const [MCList, setMCList] = React.useState([])
    const updateSelectedTopics = (topic, subtopic, sentiment) => {
        updateTopicsAPI(jwt, docket, trackingNumber, 0, sentiment, topic, subtopic).then(r => {
            if (r.success) {
                let resp = updateTopicsDictionary(topic, subtopic, sentiment, selectedTopics);
                setSelectedTopics(resp)
            }
        })
    }

    const isMC = (commenterInfo[1] === 1 || commenterInfo[1] === 2);

    const nextStep = () => {
        let nextStep = activeStep + 1

        if (nextStep > steps.length - 1) {
            setActiveStep(steps.length - 1)
            return
        }

        while (!isMC && mcOnlySteps.includes(steps[nextStep])) {
            nextStep += 1

            if (nextStep > steps.length - 1) {
                setActiveStep(steps.length - 1)
                return
            }
        }

        if (isPaused && activeStep === 0) {
            if (!(window.confirm("Comment is currently paused & editing is disabled - review data will remain read only until the comment is moved to in progress. Would you like to proceed?"))) {
                return;
            }
        }

        setActiveStep(nextStep)
    }

    const pastStep = () => {
        let backStep = activeStep - 1

        if (backStep < 0) {
            setActiveStep(0)
            return
        }

        while (!isMC && mcOnlySteps.includes(steps[backStep])) {
            backStep -= 1

            if (backStep < 0) {
                setActiveStep(0)
                return
            }
        }

        setActiveStep(backStep)
    }

    const getCard = () => {
        if (activeStep === 0) {
            return <Commenter isPaused={isPaused} trackingNumber={trackingNumber} updateSavedState={setHasSaved}
                commenterInfo={commenterInfo} updateCommenter={updateCommenterInfo}
                savedState={hasSaved} MCList={MCList} />
        }

        if (activeStep === 1) {
            return <TopicDisplay selectedTopics={selectedTopics} useExcerpts={useExcerpts} isPaused={isPaused}
                                 isMC={isMC} updateSelectedTopics={updateSelectedTopics}
                                 keywords={keywords ?? {}} keywordTopic={keywordTopic} setKeywordTopic={setKeywordTopic}
                                 keywordDisplay={keywordDisplay} setKeywordDisplay={setKeywordDisplay}
            />
        }

        if (activeStep === 2) {
            return <Summary isPaused={isPaused} trackingNumber={trackingNumber} topics={selectedTopics}
                            updateSavedState={setHasSaved} useExcerpts={useExcerpts} excerpts={excerpts}/>
        }

        if (activeStep === 3) {
            return <SingleTextArea title={"Out of Scope"}
                isPaused={isPaused}
                trackingNumber={trackingNumber}
                updateSavedState={setHasSaved}
                textCategory={2} />
        }

        if (activeStep === 4) {
            return <SingleTextArea
                requireConfirmation={true}
                title={"Internal Comments"}
                isPaused={isPaused}
                trackingNumber={trackingNumber}
                updateSavedState={setHasSaved}
                textCategory={3} />
        }

        if (activeStep === 5) {
            return <Submit isPaused={isPaused} submitFunction={submitFunction} />
        }

        return null
    }

    const getBackgroundColor = () => {

        if (activeStep === 4) {
            return '#dce9f1'
        }

        return 'white'
    }

    React.useEffect(() => {
        const fetchMCList = async () => {
            try {
                const response = await getMCNamesAPI(jwt, docket);
                if (response.success) {
                    // console.log("Fetched MC List:", response.data);
                    setMCList(response.data)
                } else {
                    // console.error("Failed to fetch MC List:", response.message);
                }
            } catch (error) {
                //  console.error("Error fetching MC List:", error);
            }
        };
        fetchMCList();
    }, []);

    //Reset when comment changes
    React.useEffect(() => {
        setActiveStep(0)

        if (!trackingNumber) { // no comment is selected
            setHasSaved(false)
        }
    }, [trackingNumber])

    React.useEffect(() => {
        getCommentTopicsAPI(jwt, docket, trackingNumber).then(r => {
            if (r.success) {
                setSelectedTopics(r.data)
            }
        })
    }, [trackingNumber, excerpts])

    return (
        <div className={styles.wrapper} style={{ backgroundColor: getBackgroundColor() }}>
            {
                isPaused &&
                <p className={styles.pausedBar}>
                    {trackingNumber ? 'Comment Paused - Editing Disabled' : 'No Comment Selected - Editing Disabled'}
                </p>
            }
            <div className={styles.stepWrapper}>
                <Stepper className={styles.stepper} activeStep={activeStep} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                        color: '#008000', // circle color (COMPLETED)
                    },
                }}>
                    {steps.map((label) => {
                        const labelProps = {};

                        if (!isMC && mcOnlySteps.includes(label)) {
                            labelProps.optional = (
                                <p className={styles.stepNotNeededLabel}>Not Required</p>
                            );
                            labelProps.disabled = true
                        }

                        return (
                            <Step disabled={label === "Summary" && !isMC} key={label}>
                                <StepLabel {...labelProps} >{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <div className={styles.contentWrapper}>
                {getCard()}
            </div>
            <div className={styles.navButtonWrapper}>
                <button className={styles.navButton} disabled={!hasSaved || activeStep === 0}
                    onClick={pastStep}><ChevronIcon disabled={!hasSaved || activeStep === 0}
                        direction={CHEVRON_DIRECTIONS.LEFT} /> Back
                </button>
                <button className={styles.navButton} disabled={!hasSaved || activeStep === steps.length - 1}
                    onClick={nextStep}>Next <ChevronIcon disabled={!hasSaved || activeStep === steps.length - 1}
                        direction={CHEVRON_DIRECTIONS.RIGHT} /></button>
            </div>
        </div>
    );
};

export default ReviewProgressCard;