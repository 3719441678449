import React from 'react';
import styles from "./TriageReference.module.css"
import Tabs from "./tabs/tabs";
import {useSelector} from "react-redux";
import Loading from "../General/loading/loading";
import MajorCommenters from "./pages/MajorCommenters/MajorCommenters";
import FormLetters from "./pages/FormLetters/FormLetters";
import Provisions from "./pages/Provisions/Provisions";
import {getTriageFLListAPI, getTriageMCListAPI} from "../../api/triageReferenceAPI";

const getAdminPages = (pageName, mcData, flData, provisionsData) => {
    if (pageName === "Provisions") {
        return <Provisions data={provisionsData}/>
    }

    if (pageName === "Form Letters") {
        return <FormLetters data={flData}/>
    }

    if (pageName === "Major Commenters") {
        return <MajorCommenters data={mcData}/>
    }

    return null
}


const TriageReference = () => {

    const [pages, setPages] = React.useState([])
    const [selectedPage, setSelectedPage] = React.useState(0)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const provisionsData = useSelector((state) => state.dockets.docketTopicsReference)

    const [majorCommenterData, setMajorCommenterData] = React.useState([])
    const [formLetterData, setFormLetterData] = React.useState([])

    React.useEffect(() => {

        // TODO: setup endpoint to dynamically get these from docket config
        setPages(['Provisions', 'Form Letters', 'Major Commenters'])

        getTriageMCListAPI(jwt, docket).then(r => {
            setMajorCommenterData(r)
        })

        getTriageFLListAPI(jwt, docket).then(r => {
            setFormLetterData(r)
        })

    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            {
                pages.length < 1 &&
                <Loading/>
            }
            <Tabs selectedTab={selectedPage} tabsList={pages} selectItem={(item) => setSelectedPage(item)}/>
            <div className={styles.content}>
                {getAdminPages(pages[selectedPage], majorCommenterData, formLetterData, provisionsData)}
            </div>
        </div>
    );
};

export default TriageReference;