import React from 'react';
import styles from "./genericTable.module.css"
import {MaterialReactTable} from 'material-react-table';

//https://www.npmjs.com/package/react-csv

const generateData = (data, columnHeaders) => {
    let dictionaryValues = []

    for (let line of data) {
        let tempDict = {}
        for (let index in columnHeaders) {
            tempDict[columnHeaders[index].accessorKey] = line[index]
        }
        dictionaryValues.push(tempDict)
    }

    return dictionaryValues
}

const GenericTable = ({headingsList, data, allowExpansionPanel = false}) => {

    const [sortedData, setSortedData] = React.useState([])

    React.useEffect(() => {
        setSortedData([...generateData(data, headingsList)])
    }, [data, headingsList])

    return (
        <div className={`${styles.wrapper} ${allowExpansionPanel ? styles.useExpansion : styles.noExpansion}`}>
            <MaterialReactTable
                columns={headingsList}
                data={sortedData}

                enableColumnResizing={true}
                enableColumnVirtualization={false}
                enablePagination={false}
                enablePinning={false}
                enableHiding={false}

                enableRowVirtualization={true}
                rowVirtualizerProps={{overscan: 10}}

                enableColumnFilters={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableStickyHeader={true}

                muiTablePaperProps={{sx: {height: '95%'}}}
                muiTableContainerProps={{sx: {height: '100%'}}}

                muiTableHeadRowProps={{sx: {height: '60px', backgroundColor: "#d2d2d2"}}}
                muiTableHeadProps={{
                    sx: {
                        height: '100%', width: "auto", backgroundColor: "#d2d2d2",
                        whiteSpace: 'wrap', textOverflow: 'unset'
                    }
                }}
                muiTableHeadCellProps={{sx: {whiteSpace: 'wrap', textOverflow: 'unset',}}}


                muiTableBodyRowProps={{sx: {overflowX: "clip"}}}
                muiTableBodyCellProps={{sx: {whiteSpace: 'nowrap', overflowX: "clip", textOverflow: "ellipsis"}}}

                muiTableDetailPanelProps={{sx: {width: "100%"}}}
                positionExpandColumn={"last"}
                enableExpandAll={false}
                renderDetailPanel={allowExpansionPanel ? (({row}) => <p
                    className={styles.textfieldContent}>{row.original.textfield ?? ""}</p>) : undefined}

                sortingFns={{
                    extendedDocIDStringSort: (rowA, rowB, columnId) => {
                        const valA = rowA.getValue(columnId).toString().padStart(5, '0');
                        const valB = rowB.getValue(columnId).toString().padStart(5, '0');

                        if (valA < valB) {
                            return 1
                        }

                        if (valB < valA) {
                            return -1
                        }

                        return 0
                    }
                }}
            />
        </div>
    );
};

export default GenericTable;

/*
<table>
    <tr>
        {
            headingsList.map((text, index) => (
                <th key={text} onClick={() => toggleSortColumn(index)}>
                    <span style={{marginRight: 8}}>{text}</span>
                    {
                        sortCol === index &&
                        <ChevronIcon size={20}
                                     direction={isDesc ? CHEVRON_DIRECTIONS.DOWN : CHEVRON_DIRECTIONS.UP}/>
                    }
                </th>
            ))
        }
    </tr>
    {
        sortedData.map((element) => (
                <tr key={element}>
                    {
                        element.map((item, index) => (
                            <td key={item ?? `${index}`} title={item}>
                                <div className={styles.cellContentsDiv}>
                                    {item ?? ""}
                                </div>
                            </td>
                        ))
                    }
                </tr>
            )
        )
    }
</table>
 */