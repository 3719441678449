import React from 'react';
import styles from "../Duplicates.module.css"

const DuplicateHelp = ({show}) => {
    if (!show) {
        return null;
    }

    return (
        <div className={styles.helpContents}>
            <h1>Duplicate Processing FAQs:</h1>
            <p>This section addresses common questions regarding the identification and processing
                of potential duplicate comments within InsightsAI.
                Each comment suspected of being a duplicate undergoes a manual confirmation to ensure accurate triage.
            </p>

            <h2>Q: What defines a duplicate?</h2>
            <p>
                <b>A: </b>
                A comment is deemed a duplicate if it contains an attachment that is at least 90% similar to an
                attachment in another comment, with both comments being the only ones in a group exhibiting
                such similarity.
            </p>

            <h2>Q: When are duplicates identified?</h2>
            <p>
                <b>A: </b>
                Duplicates are identified after form letters are batched together but before triaging.
                Because of this, they are able to be pulled out of the review queue until a manual determination is
                made.
            </p>

            <h2>Q: What happens if a duplicate is rejected?</h2>
            <p>
                <b>A: </b>
                If a duplicate is rejected during the manual review,
                both comments flagged in as the potential duplicate
                will be sent into the review queue with their appropriate priorities.
            </p>

            <h2>Q: What happens if a duplicate is accepted?</h2>
            <p>
                <b>A: </b>
                If a duplicate is accepted during the manual review,
                only the first comment will be put into the triage queue.
                Upon completion, the second comment will automatically be completed and have identical information.
            </p>

            <h2>Q: What if a completed comment is accepted as duplicate?</h2>
            <p>
                <b>A: </b>
                If a comment has been completed and later accepted as a duplicate, the newly identified duplicate
                will be immediately marked as completed, mirroring the triage details of the initially completed
                comment.
            </p>

            <h2>Q: Why can attachment counts and commenters differ?</h2>
            <p>
                <b>A: </b>
                These variables are excluded from the matching criteria to identify cases where the primary
                content is identical but minor alterations exist; such instances are flagged for manual review.
            </p>

            <h2>Q: What happens if a third comment is over 90% similar?</h2>
            <p>
                <b>A: </b>
                If a third comment fulfilling the duplicate criteria emerges after identification,
                all related comments will be withdrawn from the duplicate queue and triaged as standard entries.
            </p>

            <h2>Q: Is manual identification of duplicates permissible?</h2>
            <p>
                <b>A: </b>
                For manual identification of a comment as a duplicate,
                please contact the helpdesk with details specifying which comment should be triaged and which should be
                disregarded.
            </p>

            <h2>Q: How do duplicates appear in reports?</h2>
            <p>
                <b>A: </b>
                Duplicates are shown as unique comments both in the reporting overview & on individual reports.
                These comments will have identical data in all the reports, separate from their document IDs.
            </p>
        </div>
    );
};

export default DuplicateHelp;