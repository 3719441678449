import { createSlice } from '@reduxjs/toolkit'
import getUnixSeconds from "../helper/getUnixSeconds";

const generateSetValue = (payload) => {
    return {
        saved: getUnixSeconds(),
        filters: payload
    }
}
export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        commentExplorer: {saved: undefined, filters: {}},
        searchCommentText: {saved: undefined, filters: {}},
        topicReport: {saved: undefined, filters: {}},
        mcReport: {saved: undefined, filters: {}},
        summaryReport: {saved: undefined, filters: {}},
        flReport: {saved: undefined, filters: {}},
        riderReport: {saved: undefined, filters: {}},
        outOfScopeReport: {saved: undefined, filters: {}},
        annotationReport: {saved: undefined, filters: {}}
    },
    reducers: {
        setReportFilters: (state, action) => {
            const { reportType, data } = action.payload;
            state[reportType] = generateSetValue(data);
        },
        clearReportFilters: (state, action) => {
            const { reportType } = action.payload;
            state[reportType] = { saved: undefined, filters: {} };
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    setReportFilters, clearReportFilters
} = reportsSlice.actions

export default reportsSlice.reducer