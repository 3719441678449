import React from 'react';
import styles from './KWTableEntryModal.module.css';
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import Loading from '../../General/loading/loading';
import { updateKWListAPI } from '../../../api/adminAPI';
import { getTopicsListAPI } from '../../../api/adminAPI';
//import { updateKeywordsListAPI } from '../../../api/adminAPI';
const UPDATE_KW_LIST_STEPS = {
    TOPICS_LOADING: 0,
    TYPE_REQUIRED_FIELDS: 1,
    UPDATE_LIST: 2,
    UPDATE_LIST_LOADING: 3
};

const sortData = (data) => { //sort data again when updating table
    if (!data) return [];
    const compareFn = (a, b) => {
        if (a.topic < b.topic) {
            return -1
        }

        if (a.topic > b.topic) {
            return 1
        }

        if (a.subtopic < b.subtopic) {
            return -1
        }

        if (a.subtopic > b.subtopic) {
            return 1
        }

        return 0
    }

    return data.sort(compareFn)
}

const KWTableEntryModal = ({ open, closeModal, filteredKWTableData, setFilteredKWTableData }) => {
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [topics, setTopics] = React.useState({})
    const [formState, setFormState] = React.useState({
        topic: "NULL",
        subtopic: "",
        keyword: "",
    });
    const clearAndCloseModal = () => {
        setFormState({
            topic: "",
            subtopic: "",
            keyword: "",
        });
        setCurrentStep(UPDATE_KW_LIST_STEPS.TOPICS_LOADING);
        closeModal();
    };

    const handleTextChange = (event, field) => {
        const newValue = event.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
        if (field === 'topic') { //every topic switch should make subtopic blank
            setFormState((prevState) => ({
                ...prevState,
                subtopic: ''
            }));
        }
        const newState = {
            topic: field === 'topic' ? event.target.value : formState.topic,
            subtopic: field === 'subtopic' ? event.target.value : formState.subtopic,
            keyword: field === 'keyword' ? event.target.value : formState.keyword
        }
        if (newState.topic !== '' && newState.keyword !== '') { // allow user to update list if topic and kw are not empty
            setCurrentStep(UPDATE_KW_LIST_STEPS.UPDATE_LIST)
        }
    };

    const updateList = () => {
        if (formState.topic === '' || formState.keyword === '') {
            window.alert("Missing required field");
            return;
        }
        setCurrentStep(UPDATE_KW_LIST_STEPS.UPDATE_LIST_LOADING);
        const data = {
            topic: formState.topic,
            subtopic: formState.subtopic,
            keyword: formState.keyword,
            docket: docket
        };
        updateKWListAPI(jwt, data)
            .then((r) => {
                if (r.success) {
                    // cache new value into table
                    //Remove the potential empty entry for topic/subtopic from the table cache before adding new keyword
                    const updatedData = filteredKWTableData.filter(item =>
                        !(item.topic === data.topic &&
                            item.subtopic === data.subtopic &&
                            !item.keyword)
                    );

                    setFilteredKWTableData(sortData([
                        ...updatedData,
                        {
                            topic: data.topic,
                            subtopic: data.subtopic,
                            keyword: data.keyword,
                            accuracy: null,
                            delete: true
                        }
                    ]));
                } else {
                    if (r.error) {
                        window.alert(r.error)
                    }
                    else {
                        window.alert("Failed to add Keyword.")
                    }
                }
                clearAndCloseModal();
            })
            .catch(() => {
                window.alert("An Unexpected Error Occurred. Verify all fields were entered correctly.");
                clearAndCloseModal();
            });
    };
    React.useEffect(() => {
        if (open) {
            getTopicsListAPI(jwt, docket).then((r) => {
                setTopics(r)
                setFormState((prevState) => ({
                    ...prevState,
                    topic: Object.keys(r)[0]
                }));
                setCurrentStep(UPDATE_KW_LIST_STEPS.TYPE_REQUIRED_FIELDS)
            })
        }
    }, [open])

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'} onClick={clearAndCloseModal}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Update Keyword List&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className={styles.h1}>Update Keyword List</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    (currentStep === UPDATE_KW_LIST_STEPS.UPDATE_LIST_LOADING || currentStep === UPDATE_KW_LIST_STEPS.TOPICS_LOADING) ? <React.Fragment><Loading /></React.Fragment> :
                        <div className={styles.contentWrapper}>
                            <div className={styles.topInput}>
                                <div className={styles.sectionText}>Topic<span style={{ color: "#AA0000" }}>*</span>:
                                </div>
                                <select value={formState.topic} placeholder={""} onChange={(event) => handleTextChange(event, 'topic')}
                                    className={styles.inputSelect}>
                                    {Object.keys(topics).map((topic) => {
                                        return (
                                            <option key={topic} value={topic}>
                                                {topic}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className={styles.topInput}>
                                <div className={styles.sectionText}>Subtopic<span style={{ color: "#AA0000" }}>*</span>:
                                </div>
                                <select value={formState.subtopic} placeholder={""} onChange={(event) => handleTextChange(event, 'subtopic')}
                                    className={styles.inputSelect}>
                                    {(topics[formState.topic] || []).map((subtopic, index) => {
                                        return (
                                            <option key={subtopic} value={subtopic}>
                                                {subtopic}
                                            </option>
                                        )
                                    })}
                                </select>

                            </div>

                            <div className={styles.topInput}>
                                <div className={styles.sectionText}>Keyword<span style={{ color: "#AA0000" }}>*</span>:
                                </div>
                                <input value={formState.keyword} placeholder={""}
                                    className={styles.inputText} onChange={(event) => handleTextChange(event, 'keyword')} />
                            </div>
                            <button className={styles.submitButton} disabled={currentStep !== UPDATE_KW_LIST_STEPS.UPDATE_LIST} onClick={updateList}>Update List</button>
                        </div>
                }
            </div>
        </div>,
        document.body
    );
};

export default KWTableEntryModal;
