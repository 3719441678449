import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        username: "",
        displayName: "",
        jwt: "",
        isPrimaryReviewer: false,
        isQAReviewer: false,
        isReviewer: false,
        isAdmin: false,
        lockToCurrentDocketFlag: false
    },
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setDisplayName: (state, action) => {
            state.displayName = action.payload
        },
        setAuthToken: (state, action) => {
            state.jwt = action.payload
        },
        setReviewerStatus: (state, action) => {
            state.isPrimaryReviewer = action.payload.isPrimaryReviewer;
            state.isQAReviewer = action.payload.isQAReviewer;
            state.isReviewer = (action.payload.isPrimaryReviewer || action.payload.isQAReviewer);
            state.isAdmin = action.payload.isAdmin;
        },
        setLockToCurrentDocketFlag: (state, action) => {
            state.lockToCurrentDocketFlag = action.payload
        },
        unsetAll: (state) => {
            state.username = "";
            state.displayName = "";
            state.jwt = "";
            state.isPrimaryReviewer = false;
            state.isQAReviewer = false;
            state.isReviewer = false;
            state.lockToCurrentDocketFlag = false;
            state.isAdmin = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const {setUsername, setDisplayName, setAuthToken, setReviewerStatus, setLockToCurrentDocketFlag, unsetAll} = userSlice.actions

export default userSlice.reducer