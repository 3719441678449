import React from 'react';
import styles from "./layouts.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../../api/reportsAPI";
import GenericTable from "../genericTable/genericTable";
import { FILTER_PERSIST_EXPIRY, MC_TITLES } from "../../../helper/constants";
import getUniqueColumnValues, {
    getSubtopicValueLabelPair,
    getTopicValueLabelPair,
    MajorCommenterReportFilterData, orderUniqueColumnValues
} from "../../../helper/getUniqueColumnValues";
import MultiSelectFilter from "./multiselectFilter/multiselectFilter";
import Loading from "../../General/loading/loading";
import downloadReportData from "../../../helper/downloadReportData";
import DownloadIcon from "../../General/icons/downloadIcon";
import addOnClickToReportDocIDs from "../../../helper/addOnClickToReportDocIDs";
import maskSentimentValues from "../../../helper/maskSentimentValues";
import getUniqueArrayLinesByValue from "../../../helper/getUniqueArrayLinesByValue";
import sortDocIDsTo5Digits from "../../../helper/sortDocIDsTo5Digits";
import { getRiderTopics } from "../../../helper/getRiderTopics";
import getUnixSeconds from "../../../helper/getUnixSeconds";
import { clearReportFilters, setReportFilters } from "../../../store/reportsSlice";
import MultiSelectClear from "./multiselectFilter/multiselectClear";
import parseReportFilters from "../../../helper/parseReportFilters";
import updateReportFilters from "../../../helper/updateReportFilters";

const headings = [
    {
        accessorKey: "docid",
        header: "Doc ID",
        size: 100,
        sortingFn: 'extendedDocIDStringSort'
    },
    {
        accessorKey: "Commenter".toString().replaceAll(" ", "").toLowerCase(),
        header: "Commenter",
        size: 200
    },
    {
        accessorKey: "MC Group".toString().replaceAll(" ", "").toLowerCase(),
        header: "MC Group",
        size: 75
    },
    {
        accessorKey: "FL Name".toString().replaceAll(" ", "").toLowerCase(),
        header: "FL Name",
        size: 150
    },
    {
        accessorKey: "Topic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Topic",
        size: 250
    },
    {
        accessorKey: "Subtopic".toString().replaceAll(" ", "").toLowerCase(),
        header: "Subtopic",
        size: 250
    },
    {
        accessorKey: "Sentiment".toString().replaceAll(" ", "").toLowerCase(),
        header: "Sentiment",
        size: 100
    },
    {
        accessorKey: "Out Of Scope".toString().replaceAll(" ", "").toLowerCase(),
        header: "Out Of Scope",
        size: 200
    },
]
//["Document ID", "Commenter", "MC Group", "FL Name", "Topic", "Subtopic", "Sentiment"]

const filterData = (data, topics, docIDs, mcGroups, fls, filteredTopics, filteredSubtopics, showKeywords, docket) => {
    let temp = []

    let riders = getRiderTopics(topics)

    for (let item of data) {

        let tempItem = [...item]

        // Removing timing from the reports
        tempItem.splice(1, 3)

        if (!showKeywords) {
            //TODO: add keywords to request to get them to show
            //tempItem.pop()
        }

        if (docIDs.length > 0) {
            if (!(docIDs.includes(tempItem[0]))) {
                continue
            }
        }

        if (mcGroups.length > 0) {
            if (!(mcGroups.includes(`${tempItem[2]}`))) {
                continue
            }
        }

        if (fls.length > 0) {
            if (!(fls.includes(tempItem[3]))) {
                continue
            }
        }

        if (filteredTopics.length > 0) {
            if (!(filteredTopics.includes(tempItem[4]))) {
                continue
            }
        }

        if (filteredSubtopics.length > 0) {
            if (!(filteredSubtopics.includes(tempItem[5]))) {
                continue
            }
        }

        let topicKey = tempItem[4]
        if (topics !== {}) {
            try {
                tempItem[4] = topics[tempItem[4]]["name"]
            } catch (e) {

            }
        }

        tempItem[6] = maskSentimentValues(tempItem[6], tempItem[2], tempItem[3], docket)

        if (riders.includes(topicKey)) {
            tempItem[6] = ""
        }


        tempItem[2] = MC_TITLES[tempItem[2]]
        temp.push(tempItem)
    }

    temp = sortDocIDsTo5Digits(temp, 0)

    return [...temp]
}

const downloadTopicReport = (rawReportData, topics, docket) => {

    let reportData = []

    let riders = getRiderTopics(topics)

    for (let item of rawReportData) {

        let tempItem = [...item]

        tempItem[9] = maskSentimentValues(tempItem[9], tempItem[5], tempItem[6], docket)

        if (riders.includes(tempItem[7])) {
            tempItem[9] = ""
        }

        if (tempItem[12] == null) {
            tempItem[12] = ""
        }

        if (tempItem[8] == null) {
            tempItem[8] = ""
        }

        tempItem[5] = MC_TITLES[tempItem[5]]
        tempItem.splice(1, 0, `${docket}-${tempItem[0]}`)
        tempItem.splice(2, 0, `https://www.regulations.gov/comment/${docket}-${tempItem[0]}`)

        reportData.push(tempItem)
    }

    reportData = sortDocIDsTo5Digits(reportData, 0)

    let tempHeadings = [...headings]

    tempHeadings.splice(1, 0, {
        accessorKey: "document_id",
        header: "Document ID"
    })

    tempHeadings.splice(2, 0, {
        accessorKey: "document_link",
        header: "Regulations.gov Link"
    })

    tempHeadings.splice(3, 0, {
        accessorKey: "posted_date",
        header: "Posted Date"
    })

    tempHeadings.splice(4, 0, {
        accessorKey: "loaded_date",
        header: "Loaded Date"
    })

    tempHeadings.splice(5, 0, {
        accessorKey: "completed_date",
        header: "Completed Date"
    })

    downloadReportData(tempHeadings, reportData, "topic_report")
}

const TopicsReport = () => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const storedFilters = useSelector((state) => state.reports.topicReport);
    const dispatch = useDispatch()

    const [rawReportData, setRawReportData] = React.useState([]);
    const showKeywords = false;

    const filteredDocIDs = parseReportFilters('docID', storedFilters)
    const filteredMCGroups = parseReportFilters('mcGroups', storedFilters)
    const filteredFLNames = parseReportFilters('flName', storedFilters)
    const filteredTopics = parseReportFilters('topic', storedFilters)
    const filteredSubtopics = parseReportFilters('subtopic', storedFilters)

    const uniqueDocumentIDs = React.useMemo(() => getUniqueColumnValues(rawReportData, 0), [rawReportData])
    const uniqueFLNames = React.useMemo(() => orderUniqueColumnValues(getUniqueColumnValues(rawReportData, 6)), [rawReportData])
    const allTopics = React.useMemo(() => [{
        value: '0A_OUT_OF_SCOPE',
        label: '0A_OUT_OF_SCOPE'
    }, ...getTopicValueLabelPair(docketTopics)], [docketTopics])
    const allSubtopics = React.useMemo(() => getSubtopicValueLabelPair(docketTopics, filteredTopics), [docketTopics, filteredTopics])

    const [loading, setLoading] = React.useState(false)

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "topicReport", storedFilters, key, value)
    }

    React.useEffect(() => {
        setLoading(true)
        getReportData(jwt, docket, 2).then(r => {
            setRawReportData(r)
            setLoading(false)
        })
    }, [jwt, docket]);

    if (storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())) {
        dispatch(clearReportFilters({ reportType: "topicReport" }))
    }

    const filteredData = React.useMemo(() =>
        filterData(rawReportData, docketTopics, filteredDocIDs, filteredMCGroups, filteredFLNames, filteredTopics, filteredSubtopics, showKeywords, docket),
        [rawReportData, docketTopics, storedFilters])
    const uniqueCommentCount = React.useMemo(() => getUniqueArrayLinesByValue(filteredData, 0), [filteredData])

    const getHeadingsList = () => {
        let headingList = [...headings]

        if (showKeywords) {
            headingList.push(
                {
                    accessorKey: "keyword",
                    header: "Keyword",
                    size: 200
                }
            )
        }

        return headingList
    }

    const finalHeadings = React.useMemo(() => addOnClickToReportDocIDs(getHeadingsList(), dispatch, docket), [headings, showKeywords, dispatch, docket])

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <MultiSelectFilter title={"Document ID"} width={"15vw"} selected={filteredDocIDs}
                    setSelected={(values) => updateFilter('docID', values)} options={uniqueDocumentIDs} />
                <MultiSelectFilter title={"MC Group"} width={"10vw"} selected={filteredMCGroups}
                    setSelected={(values) => updateFilter('mcGroups', values)}
                    options={MajorCommenterReportFilterData} />
                <MultiSelectFilter title={"FL Name"} width={"15vw"} selected={filteredFLNames}
                    setSelected={(values) => updateFilter('flName', values)} options={uniqueFLNames} />
                <MultiSelectFilter title={"Topic"} width={"15vw"} selected={filteredTopics}
                    setSelected={(values) => updateFilter('topic', values)} options={allTopics} />
                <MultiSelectFilter title={"Subtopic"} width={"15vw"} selected={filteredSubtopics}
                    setSelected={(values) => updateFilter('subtopic', values)} options={allSubtopics}
                    noOptionsText={filteredTopics.length === 0
                        ? "No Topics Selected"
                        : "No Subtopics for Selected Topics"
                    } />

                <MultiSelectClear mLeft={"auto"} width={"160px"} clearFunc={() => dispatch(clearReportFilters({ reportType: "topicReport" }))} />
            </div>
            <div className={styles.title}>
                <p>Topic Report <span style={{ marginLeft: 16 }}>({uniqueCommentCount} comments shown)</span></p>
                <button onClick={() => downloadTopicReport(rawReportData, allTopics, docket)}>
                    <DownloadIcon />
                </button>
            </div>
            <div className={styles.table}>
                {
                    loading ? <Loading /> :
                        <GenericTable data={filteredData}
                            headingsList={finalHeadings} />
                }
            </div>
        </div>
    );
};

export default TopicsReport;