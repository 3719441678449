import React from 'react';
import styles from './replacePDFModal.module.css';
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { postUploadPDFAPI } from '../../../api/adminAPI';
import useEnter from '../../../helper/keypressListeners/enter';
import Loading from '../../General/loading/loading';
import { getCommentAttachmentsAPI } from '../../../api/commentAPI';

const REPLACE_PDF_STEPS = {
    UPLOAD_FILE: 0,
    ENTER_DOC_ID: 1,
    ATTACHMENTS_LOADING: 2,
    SUBMIT_FILE: 3,
    SUBMIT_FILE_LOADING: 4
};

const ReplacePDFModal = ({ open, closeModal }) => {
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [selectedFile, setSelectedFile] = React.useState(undefined);
    const [documentData, setDocumentData] = React.useState({
        documentID: "",
        attachments: [-1, 1],
        currentAttachment: 1
    });
    const[currentStep, setCurrentStep] = React.useState(0);

    const clearAndCloseModal = () => {
        setSelectedFile(undefined);
        setDocumentData({
            documentID: "",
            attachments: [-1, 1],
            currentAttachment: 1
        })  
        setCurrentStep(REPLACE_PDF_STEPS.UPLOAD_FILE);
        closeModal();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType !== "application/pdf") {
                window.alert("File must be PDF");
                setSelectedFile(undefined);
                setCurrentStep(REPLACE_PDF_STEPS.UPLOAD_FILE);
                return;
            }
            setSelectedFile(file);
            setCurrentStep(file === undefined ? REPLACE_PDF_STEPS.UPLOAD_FILE : REPLACE_PDF_STEPS.ENTER_DOC_ID)
        }
    };

    const handleDocumentIDChange = (event) => {
        setDocumentData(prevState => ({
            ...prevState,
            documentID: event.target.value,
        }))
    };

    const getAttachments = () => {
        setCurrentStep(REPLACE_PDF_STEPS.ATTACHMENTS_LOADING)
        getCommentAttachmentsAPI(jwt, docket, documentData.documentID).then(r => {
            if (r.success) {
                if (r.data.length < 1) { //set the attachments
                    setDocumentData(prevState => ({
                        ...prevState,
                        attachments: []
                    }))
                }
                else {
                setDocumentData(prevState => ({
                    ...prevState,
                    currentAttachment: r.data[0],
                    attachments: r.data
                }))
            }
                if (r.data.length > 0) { //enable the submit button
                    setCurrentStep(REPLACE_PDF_STEPS.SUBMIT_FILE)
                } else {
                    setCurrentStep(REPLACE_PDF_STEPS.ENTER_DOC_ID)
                }
            }
        });
    };
    useEnter(getAttachments);

    const submitFiles = () => {
        setCurrentStep(REPLACE_PDF_STEPS.SUBMIT_FILE_LOADING)
        const data = {
            pdf: selectedFile,
            documentID: documentData.documentID,
            attach_seq: documentData.currentAttachment,
            docket: docket
        };
        postUploadPDFAPI(jwt, data)
            .then((r) => {
                if (r.success) {
                    //console.log("upload successful")
                } else {
                    window.alert("File failed to upload");
                }
                clearAndCloseModal()
            })
            .catch(() => {
                window.alert("An Unexpected Error Occurred");
                clearAndCloseModal();
            });
    };

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true} 
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'} onClick={clearAndCloseModal}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Replace PDF&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className={styles.h1}>Replace PDF</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    (currentStep === REPLACE_PDF_STEPS.SUBMIT_FILE_LOADING) ? <React.Fragment><Loading /></React.Fragment> :
                        <div className={styles.contentWrapper}>
                            <div className={styles.uploadText}>Upload File:
                                <span className={styles.fileName}>
                                    {selectedFile ? selectedFile.name : "no file selected"}
                                </span>
                            </div>
                            <label htmlFor="replacePDFfileInput" className={styles.modalButton}>
                                Add/Change PDF
                            </label>
                            <input
                                type="file"
                                id="replacePDFfileInput"
                                className={styles.hiddenFileInput}
                                accept=".pdf"
                                onClick={(event) => event.target.value = null}
                                onChange={handleFileChange}
                            />
                            <br />
                            <div style={{ color: currentStep < REPLACE_PDF_STEPS.ENTER_DOC_ID ? '#565656' : 'black' }} className={styles.uploadText}>Document ID:</div>
                            <input disabled={currentStep < REPLACE_PDF_STEPS.ENTER_DOC_ID} value={documentData.documentID} placeholder={"CMS-0000-0000-0010"} className={styles.inputComment} onChange={handleDocumentIDChange} />
                            <br />
                            <button className={styles.modalButton} onClick={getAttachments} disabled={currentStep < REPLACE_PDF_STEPS.ENTER_DOC_ID}>
                                Search Attachments
                            </button>
                            <br />
                            <div style={{ color: currentStep < REPLACE_PDF_STEPS.SUBMIT_FILE ? '#565656' : 'black' }} className={styles.uploadText}>Loaded Attachment IDs:</div>
                            {
                                (currentStep === REPLACE_PDF_STEPS.ATTACHMENTS_LOADING) ?
                                    <Loading />
                                    : (documentData.attachments.length > 0) ?
                                        <React.Fragment>
                                            <select disabled={currentStep < REPLACE_PDF_STEPS.SUBMIT_FILE} className={styles.selectField} value={documentData.currentAttachment}
                                                onChange={e => setDocumentData({ ...documentData, currentAttachment: e.currentTarget.value })}>
                                                {
                                                    documentData.attachments.map((attachment) => {
                                                        if (attachment !== 0 && attachment !== -1) {
                                                            return (<option key={attachment}
                                                                value={attachment}>{attachment}</option>)
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </select>
                                        </React.Fragment> :
                                        <div className={styles.errorMessage}>There are no eligible attachments available for replacement in the selected comment.</div>
                            }
                            <button className={styles.submitButton} disabled={currentStep !== REPLACE_PDF_STEPS.SUBMIT_FILE} onClick={submitFiles}>Submit New File</button>
                        </div>
                }
            </div>
        </div>,
        document.body
    );
};

export default ReplacePDFModal;
