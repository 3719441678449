import React from 'react';
import styles from './validateCommentsModal.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import Papa from "papaparse";
import { postCSV_ValidationDataAPI } from "../../../api/adminAPI";
import Loading from "../../General/loading/loading";
import useEscape from '../../../helper/keypressListeners/escape';

const VALIDATE_COMMENTS_STEPS = {
    UPLOAD_FILE: 0,
    LOADING_RESULTS: 1,
    DISPLAY_RESULTS: 2
}

const ValidateLoadedComments = ({
    open, closeModal,
}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [validationData, setValidationData] = React.useState({})

    const [currentStep, setCurrentStep] = React.useState(VALIDATE_COMMENTS_STEPS.UPLOAD_FILE)

    const clearAndCloseModal = () => {
        setValidationData({})
        setCurrentStep(VALIDATE_COMMENTS_STEPS.UPLOAD_FILE)
        closeModal()
    }
    useEscape(clearAndCloseModal)

    const changeHandler = (event) => {
        const file = event.target.files[0]
        if (file === undefined) {
            return;
        }
        if (file.type !== "text/csv") {
            window.alert("File must be CSV");
            return;
        }
        setCurrentStep(VALIDATE_COMMENTS_STEPS.LOADING_RESULTS)
        runValidation(file)
    };

    const runValidation = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                if (results.data.length > 0) {
                    if (results.data[0]['Tracking Number'] === undefined || results.data[0]['Document ID'] === undefined || results.data[0]['Attachment Files'] === undefined) {
                        window.alert("Bulk Extract Missing Fields")
                        clearAndCloseModal()
                    } else {
                        //Process data
                        let datapoints = []
                        for (let row of results.data) {
                            let rawAttachments = row['Attachment Files']
                            rawAttachments = rawAttachments.split(",")
                            let attachments = [0]

                            for (let attachment of rawAttachments) {

                                if (attachment === undefined || attachment === '') {
                                    continue
                                }

                                let attach = attachment.toString().split("/")
                                attach = attach[attach.length - 1]
                                attach = attach.replaceAll('attachment_', '')
                                attach = attach.split('.')[0]

                                attachments.push(attach)
                            }

                            attachments = attachments.sort()

                            datapoints.push([row['Tracking Number'], row['Document ID'], attachments])
                        }

                        postCSV_ValidationDataAPI(jwt, docket, datapoints).then(r => {
                            if (r.success) {
                                setValidationData(r.data)
                            } else {
                                window.alert('There was an error validating data from the bulk extract')
                                clearAndCloseModal()
                            }
                            setCurrentStep(VALIDATE_COMMENTS_STEPS.DISPLAY_RESULTS)
                        })
                    }
                } else {
                    window.alert("Error in reading bulk extract. Is the file empty?")
                    clearAndCloseModal()
                }
            },
        });
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Validate Loaded Comments&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className={styles.h1}>Validate Loaded Comments</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.contentWrapper}>
                    <label htmlFor="validateCommentFileInput" className={styles.button} hidden={currentStep >= VALIDATE_COMMENTS_STEPS.LOADING_RESULTS}>
                        Add Bulk Extract File
                    </label>
                    <input
                        type="file"
                        id="validateCommentFileInput"
                        className={styles.hiddenFileInput}
                        accept=".csv"
                        onClick={(event) => event.target.value = null}
                        onChange={changeHandler}
                    />
                    {
                        currentStep === VALIDATE_COMMENTS_STEPS.LOADING_RESULTS &&
                        <Loading />
                    }
                    {
                        !(currentStep === VALIDATE_COMMENTS_STEPS.LOADING_RESULTS) && Object.keys(validationData).length > 0 && (
                            <div className={styles.responseContainer}>
                                <p className={styles.responseLabels}>Last Comment From Bulk Extract: <span
                                    style={{ fontWeight: 400 }}>{validationData.finalBulkID}</span></p>
                                <p className={styles.responseLabels}>Last Comment ID Loaded Into Tool: <span
                                    style={{ fontWeight: 400 }}>{validationData.finalID}</span></p>
                                <div className={styles.responseSection}>
                                    <p className={styles.responseLabels}>Attachments Removed From Regulations.gov:</p>
                                    {
                                        validationData.removed.length === 0
                                            ?
                                            <p className={styles.responseValue}>No Attachments Removed</p>
                                            :
                                            validationData.removed.map((element, index) => (
                                                <p key={index} className={styles.responseValue}>{element[1]} ({element[0]}),
                                                    attachment {element[2]}</p>
                                            ))
                                    }
                                </div>
                                <div className={styles.responseSection}>
                                    <p className={styles.responseLabels}>Comments / Attachments Missing From Tool:</p>
                                    {
                                        validationData.missing.length === 0
                                            ?
                                            <p className={styles.responseValue}>No Attachments Missing</p>
                                            :
                                            validationData.missing.map((element, index) => (
                                                <p key={index} className={styles.responseValue}>{element[1]} ({element[0]}),
                                                    attachment {element[2]}</p>
                                            ))
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ValidateLoadedComments;