import React from 'react';
import styles from "./selectInitialReview.module.css"
import {REVIEW_TYPES} from "../../../helper/constants";

const SelectInitialReview = ({
                                 prAllowed,
                                 qaAllowed,
                                 setReviewType
                             }) => {
    return (
        <div className={styles.wrapper}>
            <h1 className={styles.header}>Select A Review Type</h1>
            <div className={styles.buttons}>
                {
                    prAllowed &&
                    <button onClick={() => setReviewType(REVIEW_TYPES.PR)} className={styles.selectButton}>Primary
                        Review</button>
                }
                {
                    qaAllowed &&
                    <button onClick={() => setReviewType(REVIEW_TYPES.QA)} className={styles.selectButton}>QA
                        Review</button>
                }
            </div>
        </div>
    );
};

export default SelectInitialReview;