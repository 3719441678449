import React from 'react';
import styles from "./loading.module.css"

const Loading = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.loader}/>
        </div>
    );
};

export default Loading;