import React from 'react';
import styles from "./trashIcon.module.css"


    const TrashIcon = ({
        size = 24, useRed = false, disabled = false, onClick = () => {
}
    }) => {
    let iconStr = "/assets/imgs/trash-can.svg"
    if (useRed === true) {
        iconStr = "/assets/imgs/trash-can-red.svg"
    }
    const whenClicked = () => {
        if (!disabled) {
            onClick()
        }
    }
    return (
        <img onClick={whenClicked} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}}
             className={`${styles.icon} noSelect`} src={iconStr} alt={"Open Icon"}/>
    );
};

export default TrashIcon;