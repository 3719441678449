import React, { useRef, useEffect, useState } from 'react';
import styles from './modals.module.css';
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import useEscape from "../../../helper/keypressListeners/escape";
import { getUserGuidePDFAPI, replaceUserGuidePDFAPI, setGuidePDFDeletedAPI, renameGuideEntryAPI } from "../../../api/userGuideAPI";
import { Document, Page } from "react-pdf";
import ZoomIcon from "../../General/icons/zoomIcon";
import Loading from "../../General/loading/loading";
import DownloadIcon from "../../General/icons/downloadIcon";

const PDFModal = ({ closeModal, modalInformation, enableManagement, docketEntryType, guideData, setGuideData }) => {
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [file, setFile] = useState(null);
    const abortControllerRef = useRef(null);
    const clearAndCloseModal = () => {
        setFile(null);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        closeModal();
    };

    useEscape(clearAndCloseModal);

    useEffect(() => {
        if (modalInformation[0] === -1) {
            return;
        }
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;

        getUserGuidePDFAPI(jwt, modalInformation[0], docket, { signal: newAbortController.signal }).then(r => {
            const processBlob = async (resp) => {
                const blb = await resp.blob();
                setFile(blb);
            };

            processBlob(r);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                setFile(null);
            }
        });

    }, [docket, jwt, modalInformation]);

    const handleFileChange = (event) => {
        const selectFile = event.target.files[0];
        if (selectFile) {
            const fileType = selectFile.type;
            if (fileType !== "application/pdf") {
                window.alert("File must be PDF");
                return;
            }
            const data = {
                pdf: selectFile,
                docket: docket,
                entry_id: modalInformation[0]
            };
            replaceUserGuidePDFAPI(jwt, data).then((r) => {
                if (r.success) {
                    clearAndCloseModal()
                } else {
                    window.alert("File failed to upload");
                }
            })
                .catch(() => {
                    window.alert("An Unexpected Error Occurred");
                });
        }
    };

    const deleteGuideEntry = () => {
        const confirmation = window.confirm("Are you sure you want to delete this entry?");
        if (confirmation) {
            setGuidePDFDeletedAPI(jwt, docket, modalInformation[0]).then((r) => {
                if (r.success) {
                    const updatedGuideData = { ...guideData };
                    Object.keys(updatedGuideData).forEach(key => {
                        updatedGuideData[key].data = updatedGuideData[key].data.filter(item => {
                            return item[0].toString() !== modalInformation[0].toString();
                        });
                    });
                    setGuideData(updatedGuideData);
                } else {
                    window.alert("File failed to delete");
                }
            }).catch(() => {
                window.alert("An Unexpected Error Occurred");
            });
            clearAndCloseModal()
        }
    };

    const renameGuideEntry = () => {
        const newName = window.prompt("Please enter a new name for the guide entry:");
        if (newName !== null && newName !== "") {
            renameGuideEntryAPI(jwt, docket, modalInformation[0], newName).then((r) => {
                if (r.success) {
                    const updatedGuideData = { ...guideData };
                    Object.keys(updatedGuideData).forEach(key => {
                        updatedGuideData[key].data.forEach((item, index) => {
                            if (item[0].toString() === modalInformation[0].toString()) {
                                updatedGuideData[key].data[index][1] = newName
                            } else {

                            }
                        })
                    });
                    setGuideData(updatedGuideData);
                } else {
                    window.alert("Failed to rename entry");
                }
            }).catch(() => {
                window.alert("An Unexpected Error Occurred");
            });
        } else {
            window.alert("Name field can not be empty");
        }
    };

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: modalInformation[0] !== -1 ? 'flex' : 'none'}}
             aria-modal={true}
             aria-hidden={modalInformation[0] === -1} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;How To: {modalInformation[1]}&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>How To: {modalInformation[1]}</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.PDFWrapper} style={{ height: enableManagement ? '90%' : '100%' }}>
                    <div className={styles.PDFContent}>
                        {
                            file !== null ?
                                <PDFElement file={file} name={modalInformation[1]} /> :
                                <Loading />
                        }
                    </div>
                </div>
                {enableManagement &&
                    <div className={styles.buttonLine}>
                        <label htmlFor="replacePDFGuidefileInput" className={styles.button}>
                            Replace PDF
                        </label>
                        <input
                            type="file"
                            id="replacePDFGuidefileInput"
                            className={styles.hiddenFileInput}
                            accept=".pdf"
                            onClick={(event) => event.target.value = null}
                            onChange={handleFileChange}
                        />
                        <button className={styles.button} onClick={renameGuideEntry}>Rename Entry</button>
                        {(docketEntryType !== 'all') &&
                            <button className={styles.deleteButton} onClick={deleteGuideEntry}>Delete User Guide Entry</button>
                        }
                    </div>
                }
            </div>
        </div>,
        document.body
    );
};

const PDFElement = ({ file, name = 'user_guide.pdf' }) => {
    const [numPages, setNumPages] = useState(null);
    const [pdfZoom, setPDFZoom] = useState(0.95);
    const pdfRef = useRef(undefined);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const zoomOutFunc = () => {
        let newZoom = pdfZoom;
        newZoom -= 0.1;
        if (newZoom < 0.4) {
            newZoom = 0.4;
        }

        setPDFZoom(newZoom);
    };

    const zoomInFunc = () => {
        let newZoom = pdfZoom;
        newZoom += 0.1;
        if (newZoom > 2) {
            newZoom = 2;
        }

        setPDFZoom(newZoom);
    };

    const downloadFile = () => {
        const blob = new Blob([file], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        const anchorElement = document.createElement('a');
        anchorElement.href = url;
        anchorElement.download = `insightsai_user_guide__${name.replaceAll(" ", "_").toLowerCase()}.pdf`;
        anchorElement.target = "_blank";

        anchorElement.click();
        anchorElement.remove();
        URL.revokeObjectURL(url);
    };

    let pages = [];
    if (numPages != null) {
        for (let i = 1; i <= numPages; i++) {
            if (pdfRef === undefined) {
                pages.push(<Page key={i} pageNumber={i} />);
                pages.push(<div key={`div_${i}`} style={{ width: '100%', height: 16 }} />);
            } else {
                pages.push(<Page key={i} pageNumber={i} width={pdfRef.current.clientWidth} scale={pdfZoom} />);
                pages.push(<div key={`div_${i}`} style={{ width: '100%', height: 16 }} />);
            }
        }
    }

    if (file === null) {
        return null;
    }

    return (
        <div className={styles.pdfDiv}>
            <div className={styles.pdfWrapper}>
                {file &&
                    <Document inputRef={pdfRef} file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        {
                            pages
                        }
                    </Document>
                }
            </div>
            <div className={styles.zoomBar}>
                <button onClick={zoomInFunc} disabled={pdfZoom >= 2} className={styles.zoomButton}>
                    <ZoomIcon zoomIn={true}/>
                </button>
                <button onClick={zoomOutFunc} disabled={pdfZoom <= 0.4} className={styles.zoomButton}>
                    <ZoomIcon zoomIn={false}/>
                </button>
                <button onClick={downloadFile} className={styles.zoomButton}>
                    <DownloadIcon invert={true}/>
                </button>
            </div>
        </div>
    );
};

export default PDFModal;