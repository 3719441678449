import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import useEscape from "../../../../helper/keypressListeners/escape";
import {getReviewerDashboardStats, getReviewerHistory} from "../../../../api/reviewsAPI";
import {setDocToView} from "../../../../store/navSlice";
import Loading from "../../../General/loading/loading";
import {MC_TITLES} from "../../../../helper/constants";

const HistoryModal = ({open, closeModal, isPrimary = true}) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const dispatch = useDispatch();

    const [history, setHistory] = React.useState([]);
    const [queueData, setQueueData] = React.useState({
        queue: {1: 0, 2: 0, 3: 0},
        progress: {user: 0, others: 0},
        paused: {user: 0, others: 0}
    })
    const [loading, setLoading] = React.useState(false);

    const clearAndCloseModal = () => {
        closeModal()
    }

    useEscape(clearAndCloseModal)

    React.useEffect(() => {

        if (!open) {
            return;
        }

        setLoading(true)

        getReviewerDashboardStats(jwt, docket, isPrimary).then(r => {
            setQueueData(r)
            getReviewerHistory(jwt, docket, isPrimary).then(r => {
                setLoading(false)
                if (r.success) {
                    setHistory(r.data)
                } else {
                    setHistory([])
                }
            })
        })

    }, [jwt, docket, isPrimary, open])

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div style={{overflow: "clip", display: "flex", flexDirection: "column", gap: 4}}
                 className={styles.content} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Reviewer Dashboard&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div className={styles.reviewerDashboardControls}>
                    <h1 className={styles.h1}>Reviewer Dashboard ({isPrimary ? "Primary" : "QA"} Reviews)</h1>
                    <button style={{position: "relative", marginTop: -8}} className={styles.closeX}
                            title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    !loading &&
                    <>
                        <p className={styles.h2}>{isPrimary ? "Primary" : "QA"} Queue Statistics:</p>
                        <div style={{marginBottom: 12, marginTop: 4}} className={styles.dashboardContent}>
                            <div className={styles.dashboardItemGroupWrapper}>
                                <div className={styles.dashboardItemGroup}>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.queue[1]}</h3>
                                        <p>{MC_TITLES[1]}</p>
                                    </div>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.queue[2]}</h3>
                                        <p>{MC_TITLES[2]}</p>
                                    </div>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.queue[3]}</h3>
                                        <p>{MC_TITLES[3]}</p>
                                    </div>
                                </div>
                                <p>In Queue</p>
                            </div>
                            <div className={styles.dashboardItemGroupWrapper}>
                                <div className={styles.dashboardItemGroup}>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.progress.user}</h3>
                                        <p>By You</p>
                                    </div>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.progress.others}</h3>
                                        <p>By Others</p>
                                    </div>
                                </div>
                                <p>In Progress</p>
                            </div>
                            <div className={styles.dashboardItemGroupWrapper}>
                                <div className={styles.dashboardItemGroup}>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.paused.user}</h3>
                                        <p>By You</p>
                                    </div>
                                    <div className={styles.dashboardItem}>
                                        <h3>{queueData.paused.others}</h3>
                                        <p>By Others</p>
                                    </div>
                                </div>
                                <p>Paused</p>
                            </div>
                        </div>
                    </>
                }
                {
                    !loading ?
                        <>
                            <p className={styles.h2}>Completed Reviews ({history.length} comments): </p>
                            <div className={styles.historyItems}>
                                {
                                    history.map(historyItem => (
                                        <div key={historyItem[0]} className={styles.lineItem}>
                                            <p onClick={() => dispatch(setDocToView(historyItem[0] ?? ""))}
                                               className={styles.textButton}>{historyItem[0]} ({historyItem[1]})</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                        :
                        <Loading/>
                }

            </div>
        </div>,
        document.body
    );
};

export default HistoryModal;