import {createSlice} from '@reduxjs/toolkit'
import {REVIEW_TYPES} from "../helper/constants";

export const preferenceSlice = createSlice({
    name: 'prefs',
    initialState: {
        reviewType: REVIEW_TYPES.notSelected,
        pdfDownload: false,
        viewAnnotation: false,
        keywordsExpansionDefault: true
    },
    reducers: {
        setLastReviewType: (state, action) => {
            state.reviewType = action.payload;
        },
        setPDFDownload: (state, action) => {
            state.pdfDownload = action.payload
        },
        setViewAnnotation: (state, action) => {
            state.viewAnnotation = action.payload
        },
        setKeywordsExpansionDefault: (state, action) => {
            state.keywordsExpansionDefault = action.payload
        },
        unsetPreferences: (state) => {
            state.reviewType = REVIEW_TYPES.notSelected;
            state.pdfDownload = false;
            state.viewAnnotation = false;
            state.keywordsExpansionDefault = true;
        }
    }
})

// Action creators are generated for each case reducer function
export const {setLastReviewType, setPDFDownload, setViewAnnotation, setKeywordsExpansionDefault, unsetPreferences} = preferenceSlice.actions

export default preferenceSlice.reducer