import {useProductionURL} from "../helper/constants";

export const getCommentExcerptsAPI = async (jwt, docket, trackingNumber) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/excerpts/comment?` + new URLSearchParams({
        docket: docket,
        tracking: trackingNumber
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const addNewExcerptAPI = async (jwt, docket, tracking, attachment, topic, subtopic, text, indexes) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/excerpts/comment?` + new URLSearchParams({}), {
        method: "POST",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'tracking': tracking,
            'attach': attachment,
            'topic': topic,
            'subtopic': subtopic,
            'text': text,
            'indexes': indexes
        })
    })

    if (!resp.ok) {
        const error = await resp.text()

        throw new Error(error)
    }

    return await resp.json()
}

export const deleteExistingExcerptAPI = async (jwt, docket, excerptID) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/excerpts/comment?` + new URLSearchParams({}), {
        method: "DELETE",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'excerptID': excerptID,
        })
    })

    if (!resp.ok) {
        const error = await resp.text()

        throw new Error(error)
    }

    return await resp.json()
}

export const deleteAllAttachmentExcerptsAPI = async (jwt, docket, trackingNumber, attach_seq) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/excerpts/delete_from_attachment?` + new URLSearchParams({}), {
        method: "DELETE",
        headers: {
            "auth": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'docket': docket,
            'tracking_number': trackingNumber,
            'attach_seq': attach_seq
        })
    })

    if (!resp.ok) {
        const error = await resp.text()

        throw new Error(error)
    }

    return await resp.json()
}