const downloadReportData = (headers, data, reportName = "report") => {

    const lines = []
    let headerLine = ""
    for (let header of headers) {
        headerLine += `"${header.header}",`
    }

    headerLine = headerLine.substring(0, headerLine.length - 1);
    lines.push(headerLine)

    for (let dataline of data) {

        let dataStr = ""
        dataline.forEach((element, index) => {
            if (index <= headers.length) {
                dataStr += `"${(element ?? "None").toString().replaceAll('"', '""')}",`
            }
        })

        dataStr = dataStr.substring(0, dataStr.length - 1);

        lines.push(dataStr)
    }

    let csvContent = lines.join("\n");
    const utf8char = "\uFEFF"

    var blob = new Blob([utf8char + csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, `${reportName}.csv`);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${reportName}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export default downloadReportData