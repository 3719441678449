import React from 'react';
import styles from "./Reports.module.css"
import Tabs from "./tabs/tabs";
import {getReportsAPI} from "../../api/docketAPI";
import {useSelector} from "react-redux";
import Overview from "./overview/overview";
import SearchCommentText from "./reportLayouts/searchCommentText";
import TopicsReport from "./reportLayouts/topicsReport";
import MCReport from "./reportLayouts/mcReport";
import FormLetterReport from "./reportLayouts/formLetterReport";
import RiderReport from "./reportLayouts/riderReport";
import AnnotationReport from "./reportLayouts/annotationReport";
import SummaryReport from "./reportLayouts/summaryReport";
import OutOfScopeReport from "./reportLayouts/outOfScopeReport";

const getReport = (reportID) => {
    if (reportID === 0) {
        return <Overview/>
    }

    if (reportID === 1) {
        return <SearchCommentText/>
    }

    if (reportID === 2) {
        return <TopicsReport/>
    }

    if (reportID === 3) {
        return <MCReport/>
    }

    if (reportID === 5) {
        return <FormLetterReport/>
    }

    if (reportID === 6) {
        return <RiderReport/>
    }

    if (reportID === 8) {
        return <AnnotationReport/>
    }

    if (reportID === 4) {
        return <SummaryReport/>
    }

    if (reportID === 7) {
        return <OutOfScopeReport/>
    }
}

const Reports = () => {

    const [reports, setReports] = React.useState([])
    const [selectedReport, setSelectedReport] = React.useState(0)

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    React.useEffect(() => {
        getReportsAPI().then(r => setReports(r))
    }, [jwt, docket])

    return (
        <div className={styles.parent}>
            <Tabs selectedTab={selectedReport} tabsList={reports} selectItem={(item) => setSelectedReport(item)}/>
            <div className={styles.content}>
                {getReport(selectedReport)}
            </div>
        </div>
    );
};

export default Reports;