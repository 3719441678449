import {createSlice} from '@reduxjs/toolkit'
import {LOGIN_STEP} from "../helper/constants";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loginStatus: LOGIN_STEP.LOAD_PREVIOUS_LOGIN
    },
    reducers: {
        setLoginStatus: (state, action) => {
            state.loginStatus = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const {setLoginStatus} = loginSlice.actions

export default loginSlice.reducer