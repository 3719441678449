import {SENTIMENT_OFFSET, SENTIMENTS} from "./constants";

const maskSentimentValues = (sentiment, mcGroup, inFL = undefined, docket = undefined) => {

    //Missing sentiment
    if (sentiment === null || sentiment === undefined) {
        return ""
    }

    //Missing MC group
    if (mcGroup === null || mcGroup === undefined) {
        return ""
    }

    // In this docket, non-MCs didn't have sentiments
    if (docket === 'CMS-2023-0121') {
        if (mcGroup === 3 && (inFL === undefined || inFL === false || inFL === null)) {
            return ""
        }
    }

    // Sentiment hasn't been assigned yet (default sentiment) or sentiment is invalid
    if (sentiment === -99) {
        return ""
    }

    return SENTIMENTS[sentiment + SENTIMENT_OFFSET]
}

export default maskSentimentValues