import React from 'react';
import TopicCard from "../../../../../General/topicCard/topicCard";
import styles from "./topicDisplay.module.css"
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../../../General/icons/chevronIcon";
import {useSelector} from "react-redux";

const TopicDisplay = ({
                          useExcerpts,
                          isMC, isPaused,
                          selectedTopics, updateSelectedTopics,
                          keywords, keywordTopic, setKeywordTopic,
                          keywordDisplay, setKeywordDisplay
                      }) => {
    const keywordExpansionPreference = useSelector((state) => state.prefs.keywordsExpansionDefault)
    const [expandKeywords, setExpandKeywords] = React.useState(keywordExpansionPreference);

    const updateKeywordDisplay = () => {
        let temp = !expandKeywords;
        setExpandKeywords(temp)
        setKeywordDisplay(temp)
    }

    const updateKeywordTopic = (kwList, nextTopic = true) => {
        let idx = -1
        for (let index in kwList) {
            if (kwList[index][0] === keywordTopic) {
                idx = index
                break
            }
        }

        idx = parseInt(idx)

        if (idx < 0) {
            setKeywordTopic(kwList[0][0])
            return
        }

        if (!nextTopic && idx > 0) {
            idx -= 1
        } else if (nextTopic && idx < kwList.length - 1) {
            idx += 1
        }

        setKeywordTopic(kwList[idx][0])
    }

    const processKeywordTopics = () => {

        let kwTopicList = []

        for (let kwTopic of Object.keys(keywords)) {
            if (Object.keys(selectedTopics).includes(kwTopic)) {
                kwTopicList.push([`${kwTopic}`, true])

                for (let kwSubtopic of Object.keys(keywords[kwTopic].children)) {
                    if (Object.keys(selectedTopics[kwTopic].children).includes(kwSubtopic)) {
                        kwTopicList.push([`${kwTopic}||${kwSubtopic}`, true])
                    } else {
                        kwTopicList.push([`${kwTopic}||${kwSubtopic}`, false])
                    }
                }

            } else {
                kwTopicList.push([`${kwTopic}`, false])
                for (let kwSubtopic of Object.keys(keywords[kwTopic].children)) {
                    kwTopicList.push([`${kwTopic}||${kwSubtopic}`, false])
                }
            }
        }

        // KW Topics list, number of topics in list, number of topics approved in list
        return [kwTopicList, kwTopicList.length, kwTopicList.filter(element => element[1] === true).length]
    }

    const getKeywordList = () => {
        try {
            const kwTopicSplit = keywordTopic.split("||")
            if (kwTopicSplit[1] == null || kwTopicSplit[1] === '') {
                return keywords[kwTopicSplit[0]].keywords
            } else {
                return keywords[kwTopicSplit[0]].children[kwTopicSplit[1]]
            }
        } catch {
            return []
        }
    }

    const [keywordTopics, keywordCount, approvedKeywordCount] = React.useMemo(
        () => processKeywordTopics(),
        [keywords, selectedTopics]
    )

    const keywordList = React.useMemo(() => getKeywordList(), [keywords, keywordTopic])


    return (
        <div className={styles.wrapperFlexCenter}>
            <div className={styles.suggestionToggle}>
                <p><b>Suggested Topics</b> ({approvedKeywordCount} approved, {keywordCount} total) </p>
                <button onClick={() => updateKeywordDisplay()}>
                    <ChevronIcon direction={expandKeywords ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN}/>
                </button>
            </div>
            <div className={`${styles.suggestionArea} ${expandKeywords ? "" : styles.suggestionAreaCollapsed}`}>
                <div className={styles.divider}/>
                <div className={styles.suggestionSelectWrapper}>
                    <button
                        onClick={() => updateKeywordTopic(keywordTopics, false)}>
                        <ChevronIcon bold={true} size={20} direction={CHEVRON_DIRECTIONS.LEFT}/>
                    </button>
                    <select className={styles.suggestionSelect} aria-label={"Summary Topic Select"} value={keywordTopic}
                            onChange={(event) => setKeywordTopic(event.currentTarget.value)}>
                        <option value={""}>-- Select --</option>
                        {
                            keywordTopics.map(topicStr => (
                                <option key={topicStr[0]} value={topicStr[0]}>
                                    {topicStr[1] ? ' [Approved]  ' : ''}{topicStr[0].replace("||", " || ")}
                                </option>
                            ))
                        }
                    </select>
                    <button
                        onClick={() => updateKeywordTopic(keywordTopics, true)}>
                        <ChevronIcon
                            bold={true} size={20} direction={CHEVRON_DIRECTIONS.RIGHT}/>
                    </button>
                </div>

                <div className={styles.keywordList}>
                    {
                        keywordList.map(keyphrase => (
                            <p key={keyphrase}>• {keyphrase}</p>
                        ))
                    }
                    {
                        keywordList.length < 1 && keywordTopic !== '' &&
                        <div className={styles.noKeywordsFound}>
                            <p>No Keywords Identified for This Topic/Subtopic</p>
                        </div>
                    }
                </div>

                <div className={styles.displayKeywordCheck}>
                    <input type='checkbox' checked={keywordDisplay}
                           onChange={() => setKeywordDisplay(!keywordDisplay)}/>
                    <p>Highlight Keywords In Comment Text &nbsp;<b>(Unchecks when minimized)</b></p>
                </div>
            </div>
            <div className={styles.divider} style={{}}/>
            {
                useExcerpts ?
                    <StaticTopicDisplay selectedTopics={selectedTopics} updateSelectedTopics={updateSelectedTopics}/> :
                    <TopicCard useReferenceHelp={'modal'}
                               useSentiment={isMC}
                               isPaused={isPaused}
                               selectedTopics={selectedTopics}
                               toggleSelectedTopicFunc={updateSelectedTopics}
                    />
            }
        </div>
    );
};

const StaticTopicDisplay = ({selectedTopics, updateSelectedTopics}) => {

    const docketTopics = useSelector((state) => state.dockets.docketCurrentTopics);

    const getRiderStatus = (topic) => {
        if (docketTopics === undefined || Object.keys(docketTopics).length < 1) {
            return false
        }

        if (docketTopics[topic] === undefined) {
            return false
        }

        if (docketTopics[topic].isRider === undefined) {
            return false
        }

        return docketTopics[topic].isRider
    }

    return (
        <>
            <div className={styles.ridersSection} style={{display: "flex", flexDirection: "column"}}>
                <p className={styles.topicsWarning}>Rider Selection:</p>
                <TopicCard useSentiment={true} selectedTopics={selectedTopics}
                           toggleSelectedTopicFunc={updateSelectedTopics}
                           isPaused={false} useReferenceHelp={'modal'}
                           disabled={false} riderShow={'only'}/>
            </div>
            <div className={styles.topicsWrapper}>
                <p className={styles.topicsWarning}>Topics Assigned During Excerpt Process:</p>
                <div className={styles.staticTopicsWrapper}>
                    {
                        Object.keys(selectedTopics).map(element => {
                            if (getRiderStatus(element)) {
                                return null
                            }

                            return (
                                <div className={styles.topicSection} key={element}>
                                    <div className={`${styles.topicRow}`}>
                                        <p>• {getRiderStatus(element) ? '**' : ''}{element}</p>
                                    </div>
                                    {
                                        Object.keys(selectedTopics[element].children).map(subtopic => (
                                            <div key={subtopic}
                                                 className={`${styles.subtopicRow}`}>
                                                <p>- {subtopic}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        Object.keys(selectedTopics).length < 1 &&
                        <p className={styles.noSelection}>No Topics Selected From Excerpts</p>
                    }
                </div>
            </div>
        </>
    )
}

export default TopicDisplay;