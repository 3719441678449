import React from 'react';
import styles from "./sections.module.css"

const Submit = ({submitFunction, isPaused}) => {
    return (
        <div className={styles.wrapperAllCentered}>
            <button onClick={submitFunction} disabled={isPaused} className={styles.submitButton}>Complete Review
            </button>
        </div>
    );
};

export default Submit;