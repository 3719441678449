import React from 'react';
import styles from "./multiselectFilter.module.css"

const MultiSelectClear = ({
                              title = "", width = "15vw", mRight = "0", mLeft = "0", clearFunc = () => {
    }, background = "#e5bdbd"
                          }) => {

    const wrapperRef = React.useRef(null);

    const onClickFunc = () => {
        if (window.confirm("Are you sure that you want to clear all filters?")) {
            clearFunc()
        }
    }

    return (
        <div ref={wrapperRef} style={{
            width: width, marginLeft: mLeft,
            marginRight: mRight, padding: 0,
            justifyContent: "center", alignItems: "center",
            backgroundColor: background
        }} className={styles.stackedFilter}>
            <button onClick={onClickFunc}
                    aria-label={`${title}`} className={styles.selectedOptions}>
                {title === "" ? "Clear Filters" : title}
            </button>
        </div>
    );
};

export default MultiSelectClear;