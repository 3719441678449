export const useProductionURL = true;

export const LOGIN_STEP = {
    NEW_LOGIN: 0,
    CHOOSE_DOCKET: 1,
    FORGOT_PASSWORD: 2,
    LOAD_PREVIOUS_LOGIN: 3,
    FINISHED: 99
}

export const MC_TITLES = ["Unknown", "Priority MC", "Other MC", "Non-MC"]
export const SENTIMENTS = ["Negative", "Mixed", "Positive"]
export const SENTIMENT_OFFSET = 1

export const REVIEW_TYPES = {
    PR: "Primary",
    QA: "QA",
    notSelected: "none"
}

export const MAX_WF_ADMIN_ROWS = 150

export const FILTER_PERSIST_EXPIRY = 12 * 60 * 60 //12 hours

export const EXCERPT_HIGHLIGHT_1 = "rgba(255,247,195,1)"
export const EXCERPT_HIGHLIGHT_2 = "rgba(255,218,195,1)"
export const KEYWORD_HIGHLIGHT_1 = "rgba(80,255,80,0.20)"
export const TOP_LEVEL_SUMMARY_TIME_ESTIMATION = 15 // 15 seconds per summary

// month * days * hours * minutes * seconds
export const POSTED_OFFSET_USE_LOADED = 4 * 30 * 24 * 60 * 60 // Over 4 months offset