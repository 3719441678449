import {useProductionURL} from "../helper/constants";

export const adminEmulateUserAPI = async (jwt, docket, username) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/admin/emulate?` + new URLSearchParams({
        docket: docket,
        username: username
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const adminEmulateViewerAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/admin/emulate_viewer?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const adminChangePasswordAPI = async (jwt, docket, username, password) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/admin/change_password?` + new URLSearchParams({
        docket: docket,
        username: username,
        password: password
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const adminAddUserAPI = async (jwt, docket, fullname, email) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/user/admin/add_user?` + new URLSearchParams({
        docket: docket,
        fullname: fullname,
        email: email
    }), {
        method: "POST",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}