import {createSlice} from '@reduxjs/toolkit'
import {LOGIN_STEP} from "../helper/constants";

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        loginStatus: LOGIN_STEP.LOAD_PREVIOUS_LOGIN,
        currentDocket: "",
        docToView: ""
    },
    reducers: {
        setLoginStatus: (state, action) => {
            state.loginStatus = action.payload;
        },
        setDocket: (state, action) => {
            state.currentDocket = action.payload
        },
        setDocToView: (state, action) => {
            state.docToView = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const {setLoginStatus, setDocket, setDocToView} = navigationSlice.actions

export default navigationSlice.reducer