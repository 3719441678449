import React from 'react';
import styles from './FLDocumentSelector.module.css'
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../../../../General/icons/chevronIcon";

const searchFunction = (list, search, sortByScores, sortDescending) => {
    let temp = [];

    for (let item of list) {
        if (`${item["document"][0]} ${item["document"][1]}`.includes(search)) {
            temp.push(item)
        }
    }

    let compareFunc = () => {
    };

    if (sortByScores) {
        compareFunc = (a, b) => {

            let valueA = a["score"];
            let valueB = b["score"];

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    } else {
        compareFunc = (a, b) => {

            let documentID_splitA = ((a["document"][0]).split('-')).pop().padStart(5, '0')
            let documentID_splitB = ((b["document"][0]).split('-')).pop().padStart(5, '0')
            let valueA = `${documentID_splitA} ${a["document"][1]}`;
            let valueB = `${documentID_splitB} ${b["document"][1]}`;

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
    }

    temp = temp.sort(compareFunc)

    if (sortDescending === false) {
        temp = temp.reverse()
    }
    return temp
}
const FlDocumentSelector = ({list, selected, setSelected}) => {

    const [search, setSearch] = React.useState("");
    const [sortByScore, setSortByScore] = React.useState(false);
    const [sortDesc, setSortDesc] = React.useState(true);

    const [showViewed, setShowViewed] = React.useState(true);
    const availableElements = searchFunction(list, search, sortByScore, sortDesc);

    const sortByAlphabetFunction = () => {
        if (sortByScore) {
            setSortDesc(true)
            setSortByScore(false)
        } else {
            setSortDesc(!sortDesc)
        }
    }

    const sortByScoreFunction = () => {
        if (sortByScore) {
            setSortDesc(!sortDesc)
        } else {
            setSortDesc(true)
            setSortByScore(true)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.search}>
                <input value={search} onChange={e => setSearch(e.currentTarget.value)}
                       type={"text"} placeholder={"Search Comments"}/>
            </div>
            <div className={styles.items}>
                {
                    availableElements.map(
                        element => {

                            const isElementViewed = (element["viewed"] ?? false)

                            if (!showViewed && isElementViewed && selected !== element["document"]) {
                                return null;
                            }

                            return (
                                <div key={`${element["document"][0]} ${element["document"][1]}`}
                                     onClick={() => setSelected(element["document"])}
                                     className={`${styles.line} ${selected === element["document"] && styles.selected}`}>
                                    <p className={styles.percent}>{element["score"] === -1 ? "Unk" : element["score"]}</p>
                                    <p className={isElementViewed ? styles.id : styles.notViewed}>{element["document"][0]} {element["document"][1]}</p>
                                </div>
                            )
                        }
                    )
                }
            </div>
            <div className={styles.sorts}>
                <button className={!sortByScore ? styles.selectedButton : undefined} onClick={sortByAlphabetFunction}>
                    Aa
                    {
                        !sortByScore &&
                        <ChevronIcon bold={false} size={24}
                                     direction={sortDesc ? CHEVRON_DIRECTIONS.DOWN : CHEVRON_DIRECTIONS.UP}/>
                    }
                </button>
                <button className={sortByScore ? styles.selectedButton : undefined} onClick={sortByScoreFunction}>
                    %
                    {
                        sortByScore &&
                        <ChevronIcon bold={false} size={24}
                                     direction={sortDesc ? CHEVRON_DIRECTIONS.DOWN : CHEVRON_DIRECTIONS.UP}/>
                    }
                </button>
            </div>
            <div className={styles.showView}>
                <p>Show Read Comments:</p>
                <input onChange={() => setShowViewed(!showViewed)} type={'checkbox'} checked={showViewed}/>
            </div>
        </div>
    );
};

export default FlDocumentSelector;