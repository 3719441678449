import React from 'react';
import styles from "./refreshIcon.module.css"

const RefreshIcon = ({
                         size = 24
                     }) => {

    let iconStr = "/assets/imgs/refresh.svg"


    return (
        <img className={styles.icon} onContextMenu={() => {
            return false;
        }} style={{height: size, width: size}} src={iconStr} alt={"Refresh Icon"}/>
    );
};


export default RefreshIcon;