import React from 'react';
import styles from './modals.module.css'
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import useEscape from "../../../../helper/keypressListeners/escape";
import {getFormLetterBatches} from "../../../../api/reviewsAPI";
import TextDisplay from "../../../General/TextDisplay/TextDisplay";

const FormLetterModal = ({
                             open, closeModal, selectedDocument, moveToFLFunction = () => {
    }
                         }) => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);

    const [batches, setBatches] = React.useState([])
    const [selectedBatch, setSelectedBatch] = React.useState(0)
    const [selectedParent, setSelectedParent] = React.useState(["", 0, ""])

    const clearAndCloseModal = () => {
        closeModal()
    }

    const updateSelected = (newBatchID) => {
        for (let batch of batches) {
            // eslint-disable-next-line eqeqeq
            if (batch.batchID == newBatchID) {
                setSelectedBatch(newBatchID)
                setSelectedParent([...batch.parent])
                break;
            }
        }

    }

    useEscape(clearAndCloseModal)

    const sortFn = (a, b) => {
        const aBucketName = `Form Letter #${a.batchID}`
        const bBucketName = `Form Letter #${b.batchID}`

        const aName = `${a.status === "Reviewed" ? "1__" : "2__"}${a.name === '' ? aBucketName : a.name}`
        const bName = `${b.status === "Reviewed" ? "1__" : "2__"}${b.name === '' ? bBucketName : b.name}`

        if (aName < bName) {
            return -1
        } else if (aName > bName) {
            return 1
        }

        return 0
    }

    React.useEffect(() => {
        getFormLetterBatches(jwt, docket).then(r => {
            if (r.success) {
                let temp = [...r.data]
                temp.sort(sortFn)
                setBatches(temp)

                if (r.data.length > 0) {
                    setSelectedBatch(r.data[0].batchID)
                    setSelectedParent(r.data[0].parent)
                } else {
                    setSelectedBatch(0)
                    setSelectedParent(["", 0, ""])
                }
            }
        })
    }, [jwt, docket])

    if (!open) {
        return null;
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: open ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!open} onClick={clearAndCloseModal}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Reviewer History&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Move to Form Letter</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>
                <div className={styles.formLetterWrapper}>
                    <div className={styles.formLetterButtonWrapper}>
                        <select value={selectedBatch} onChange={e => updateSelected(e.currentTarget.value)}
                                name="inProgress" id="inProgress" size={8}>
                            <optgroup label={"Reviewed"}>
                                {
                                    batches.map(batch => {
                                        if (batch.status === "Reviewed") {
                                            return (
                                                <option key={batch.batchID}
                                                        value={batch.batchID}>{batch.name === "" ? `Form Letter #${batch.batchID}` : batch.name}</option>
                                            )
                                        }

                                        return null
                                    })
                                }
                            </optgroup>
                            <optgroup label={"In Review"}>
                                {
                                    batches.map(batch => {
                                        if (batch.status === "Reviewed") {
                                            return null
                                        }

                                        return (
                                            <option key={batch.batchID}
                                                    value={batch.batchID}>{batch.name === "" ? `Form Letter #${batch.batchID}` : batch.name}</option>
                                        )
                                    })
                                }
                            </optgroup>
                        </select>
                        <button onClick={() => moveToFLFunction(selectedBatch)} disabled={selectedBatch === 0}>
                            Move to Form Letter
                        </button>
                    </div>
                    <div className={styles.textDisplayWrapper}>
                        <p className={styles.header}>Current Document: {selectedDocument[0]} {selectedDocument[1]}</p>
                        <div className={styles.horizontalBar}/>
                        <div className={styles.textDisplay}>
                            <TextDisplay documentID={selectedDocument[0]} attachment={selectedDocument[1]}
                                         trackingNum={selectedDocument[2]}/>
                        </div>
                    </div>
                    <div className={styles.textDisplayWrapper}>
                        <p className={styles.header}>Form Letter Standard: {selectedParent[0]} {selectedParent[1]}</p>
                        <div className={styles.horizontalBar}/>
                        <div className={styles.textDisplay}>
                            <TextDisplay documentID={selectedParent[0]} attachment={selectedParent[1]}
                                         trackingNum={selectedParent[2]}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default FormLetterModal;