import React from 'react';
import styles from "./tabbar.module.css"

const TabBar = ({
                    tabs = [], selectedTabIndex = 0, updateTab = () => {
    }, ariaLabelPrefix = ""
                }) => {
    return (
        <div className={styles.wrapper}>
            {
                tabs.map((element, index) => <Tab key={element} ariaLabelPrefix={ariaLabelPrefix} element={element}
                                                  onClick={() => updateTab(index)}
                                                  selected={index === selectedTabIndex}/>)
            }
        </div>
    );
};

const Tab = ({element, selected, onClick, ariaLabelPrefix}) => {
    return <button aria-label={ariaLabelPrefix !== "" ? `${ariaLabelPrefix} ${element}` : element}
                   onClick={onClick}
                   className={`${styles.tab} ${selected && styles.selectedTab}`}>
        {element}</button>
}

export default TabBar;