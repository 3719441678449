import React from 'react';
import styles from './MCTableEntryModal.module.css';
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import Loading from '../../General/loading/loading';
import { updateMCListAPI } from '../../../api/adminAPI';
const UPDATE_MC_LIST_STEPS = {
    TYPE_MC_NAME: 0,
    SELECT_COMMENTER: 1,
    UPDATE_LIST: 2,
    UPDATE_LIST_LOADING: 3
};

const sortData = (data) => { //sort data again when updating table
    if (!data) return [];
    const compareFn = (a, b) => {
        if (a.mcGroup < b.mcGroup) {
            return -1
        }

        if (a.mcGroup > b.mcGroup) {
            return 1
        }

        if (a.org < b.org) {
            return -1
        }

        if (a.org > b.org) {
            return 1
        }

        return 0
    }

    return data.sort(compareFn)
}

const MCTableEntryModal = ({ open, closeModal, filteredMCTableData, setFilteredMCTableData }) => {
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [formState, setFormState] = React.useState({
        mcName: "",
        altName: "",
        acronym: "",
        notes: "",
        selectedOption: '',
    });
    const clearAndCloseModal = () => {
        setFormState({
            mcName: "",
            altName: "",
            acronym: "",
            notes: "",
            selectedOption: '', 
        });
        setCurrentStep(UPDATE_MC_LIST_STEPS.TYPE_MC_NAME);
        closeModal();
    };

    const handleSelection = (event) => {
        const { value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            selectedOption: value
        }));
        setCurrentStep(UPDATE_MC_LIST_STEPS.UPDATE_LIST);
    };

    const handleTextChange = (event, field) => {
        setFormState((prevState) => ({
            ...prevState,
            mcName: field === 'mcName' ? event.target.value : formState.mcName,
            altName: field === 'altName' ? event.target.value : formState.altName,
            acronym: field === 'acronym' ? event.target.value : formState.acronym,
            notes: field === 'notes' ? event.target.value : formState.notes,
        }));
        if (field == 'mcName' && event.target.value !== '' && currentStep === UPDATE_MC_LIST_STEPS.TYPE_MC_NAME) {
            setCurrentStep(UPDATE_MC_LIST_STEPS.SELECT_COMMENTER)
        }
    }
    const updateList = () => {
        if (formState.mcName === '' || formState.selectedOption === '') {
            window.alert("Missing required field");
            return;
        }
        setCurrentStep(UPDATE_MC_LIST_STEPS.UPDATE_LIST_LOADING);
        const data = {
            mcName: formState.mcName,
            altName: formState.altName,
            acronym: formState.acronym,
            notes: formState.notes,
            mcGroup: formState.selectedOption === "Priority Major Commenter" ? 1 : 2,
            docket: docket
        };
        updateMCListAPI(jwt, data)
            .then((r) => {
                if (r.success) {
                    // cache new value into table
                    setFilteredMCTableData(filteredMCTableData => sortData([
                        ...filteredMCTableData,
                        {
                            mcGroup: data.mcGroup,
                            org: data.mcName,
                            acronym: data.acronym,
                            commentsTotal: 0
                        }
                    ]));
                } else {
                    if (r.error) {
                        window.alert(r.error)
                    }
                    else {
                    window.alert("Failed to add Major Commenter.")
                    }
                }
                clearAndCloseModal();
            })
            .catch(() => {
                window.alert("An Unexpected Error Occurred. Verify all fields were entered correctly.");
                clearAndCloseModal();
            });
    };

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'} onClick={clearAndCloseModal}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Update Major Commenter List&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className={styles.h1}>Update Major Commenter List</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    (currentStep === UPDATE_MC_LIST_STEPS.UPDATE_LIST_LOADING) ? <React.Fragment><Loading /></React.Fragment> :
                        <div className={styles.contentWrapper}>
                            <div className={styles.sectionText}>Major Commenter Name<span style={{color: "#AA0000"}}>*</span>:
                            </div>
                            <input value={formState.mcName} placeholder={""}
                            className={styles.inputComment} onChange={(event) => handleTextChange(event, 'mcName')}  />
                            <br />
                            <div style={{ color: currentStep < UPDATE_MC_LIST_STEPS.SELECT_COMMENTER ? '#565656' : 'black' }} className={styles.MCSelectionText}>Commenter<span style={{color: "#AA0000"}}>*</span>:</div>
                            <div className={styles.MCSelectionContainer}>
                                <label className={styles.radioLabel}>
                                    <input type="radio" value="Priority Major Commenter" className={styles.radioInput}
                                        checked={formState.selectedOption === 'Priority Major Commenter'} onChange={handleSelection} disabled={currentStep < UPDATE_MC_LIST_STEPS.SELECT_COMMENTER} />
                                    Priority Major Commenter
                                </label>
                                <label className={styles.radioLabel} >
                                    <input type="radio" value="Other Major Commenter" className={styles.radioInput} disabled={currentStep < UPDATE_MC_LIST_STEPS.SELECT_COMMENTER}
                                        checked={formState.selectedOption === 'Other Major Commenter'} onChange={handleSelection} />
                                    Other Major Commenter
                                </label>
                            </div>
                            <br />

                            <div className={styles.inputContainer}>
                            <div className={styles.sectionText}>Alternative Name:
                            </div>
                            <input disabled={currentStep < UPDATE_MC_LIST_STEPS.UPDATE_LIST} value={formState.altName}
                            className={styles.inputText} onChange={(event) => handleTextChange(event, 'altName')}  />
                            </div>

                            <div className={styles.inputContainer}>
                            <div className={styles.sectionText}>Acronym:
                            </div>
                            <input disabled={currentStep < UPDATE_MC_LIST_STEPS.UPDATE_LIST} value={formState.acronym}
                            className={styles.inputText} onChange={(event) => handleTextChange(event, 'acronym')}  />
                            </div>

                            <div className={styles.inputContainer}>
                            <div className={styles.sectionText}>Notes:
                            </div>
                            <input disabled={currentStep < UPDATE_MC_LIST_STEPS.UPDATE_LIST} value={formState.notes}
                            className={styles.inputText} onChange={(event) => handleTextChange(event, 'notes')}  />
                            </div>

                            <button className={styles.submitButton} disabled={currentStep !== UPDATE_MC_LIST_STEPS.UPDATE_LIST} onClick={updateList}>Update List</button>
                        </div>
                }
            </div>
        </div>,
        document.body
    );
};

export default MCTableEntryModal;
