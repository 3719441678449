//Returns a list with 2 sublist, one for PR reviewers and 1 for QA reviewers
import {useProductionURL} from "../helper/constants";

export const getReviewersAPI = async (jwt, docketID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/workflow/get_reviewers?` + new URLSearchParams({
        docket: docketID,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns a list where each sublist represents one unique comment
export const getAllCommentInfoAPI = async (jwt, docketID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/workflow/get_comments?` + new URLSearchParams({
        docket: docketID,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns a list where each sublist represents one unique comment
export const getAllTrackingInfoAPI = async (jwt, docketID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/admin/get_all_tracking?` + new URLSearchParams({
        docket: docketID,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Post endpoint to change the current reviewer
//Returns if the reviewer change was successful on the backend
export const postChangeReviewerAPI = async (jwt, docketID, trackingNum, newReviewer, isPR) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/workflow/change_reviewers?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNum,
        reviewer: newReviewer,
        isPR: isPR
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()

}

//Post endpoint to change the current reviewer
//Returns if the reviewer change was successful on the backend
export const postCSV_ValidationDataAPI = async (jwt, docketID, data) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/workflow/validate_contents?` + new URLSearchParams({
        docket: docketID
    }), {
        method: "POST",
        headers: {
            "auth": jwt,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return await resp.json()

}

export const getAdminTabsAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/get_tabs?` + new URLSearchParams({
        docket: docket,
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const postUploadPDFAPI = async (jwt, data) => {
    const formData = new FormData();
    if (data.pdf) {
        formData.append('pdf', data.pdf);
    } else {
        window.alert('PDF data does not exist');
        return;
    }
    if (data.attach_seq) {
        formData.append('attach_seq', data.attach_seq);
    }
    if (data.documentID) {
        formData.append('documentID', data.documentID);
    }
    if (data.docket) {
        formData.append('docket', data.docket);
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/upload_pdf`, {
        method: "POST",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });

    return await resp.json();
};

export const clearCacheForDocketAPI = async (jwt, docket) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/clear_cache?` + new URLSearchParams({
        docket: docket,
        
    }), {
        method: "POST",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getAdminMCListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/major_commenters?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateMCListAPI = async (jwt, data) => {
    const requiredFields = ["mcName", "mcGroup",];
    for (let field of requiredFields) {
        if (!data[field]) {
            return {
                success: false,
                error: `Missing Required Field`
            };
        }
    }

    const formData = new FormData();
    if (data.mcName) {
        formData.append('mcName', data.mcName);
    }
    if (data.altName) {
        formData.append('altName', data.altName);
    }
    if (data.acronym) {
        formData.append('acronym', data.acronym);
    }
    if (data.notes) {
        formData.append('notes', data.notes)
    }
    if (data.mcGroup) {
        formData.append('mcGroup', data.mcGroup)
    }
    if (data.docket) {
        formData.append('docket', data.docket);
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/add_mc`, {
        method: "POST",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });

    return await resp.json();
};

export const getAdminKeywordsListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/keywords?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateKWListAPI = async (jwt, data) => {
    const requiredFields = ["topic", "keyword",];
    for (let field of requiredFields) {
        if (!data[field]) {
            return {
                success: false,
                error: `Missing Required Field`
            };
        }
    }

    const formData = new FormData();
    if (data.topic) {
        formData.append('topic', data.topic);
    }
    if (data.subtopic) {
        formData.append('subtopic', data.subtopic);
    }
    if (data.keyword) {
        formData.append('keyword', data.keyword);
    }

    if (data.docket) {
        formData.append('docket', data.docket);
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/add_keyword`, {
        method: "POST",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });

    return await resp.json();
};

export const getTopicsListAPI = async (jwt, docket) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/topics?` + new URLSearchParams({
        docket: docket
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const deleteKWFromListAPI = async (jwt, data) => {
    const requiredFields = ["topic", "keyword",];
    for (let field of requiredFields) {
        if (!data[field]) {
            return {
                success: false,
                error: `Missing Required Field`
            };
        }
    }

    const formData = new FormData();
    if (data.topic) {
        formData.append('topic', data.topic);
    }
    if (data.subtopic) {
        formData.append('subtopic', data.subtopic);
    }
    if (data.keyword) {
        formData.append('keyword', data.keyword);
    }
        
    if (data.docket) {
        formData.append('docket', data.docket);
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/admin/delete_keyword`, {
        method: "DELETE",
        headers: {
            "auth": jwt,
        },
        body: formData 
    });

    return await resp.json();
};