import React from 'react';
import styles from './userGuideEntryModal.module.css';
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import Loading from '../../General/loading/loading';
import { postUserGuideEntryAPI } from '../../../api/userGuideAPI';

const ADD_GUIDE_ENTRY_STEPS = {
    UPLOAD_FILE: 0,
    SELECT_DOCKET_SETTING: 1,
    SUBMIT_FILE: 2,
    SUBMIT_ENTRY_LOADING: 3
};

const UserGuideEntryModal = ({ open, closeModal, guideCategories }) => {
    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [formState, setFormState] = React.useState({
        selectedFile: undefined,
        selectedOption: '',
        currentCategory: 0
    });
    const clearAndCloseModal = () => {
        setFormState({
            selectedFile: undefined,
            selectedOption: '',
            currentCategory: 0
        });
        setCurrentStep(ADD_GUIDE_ENTRY_STEPS.UPLOAD_FILE);
        closeModal();
    };

    const handleSelection = (event) => {
        const { value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            selectedOption: value
        }));
        setCurrentStep(ADD_GUIDE_ENTRY_STEPS.SUBMIT_FILE);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType !== "application/pdf") {
                window.alert("File must be PDF");
                setFormState((prevState) => ({
                    ...prevState,
                    selectedFile: undefined
                }));
                setCurrentStep(ADD_GUIDE_ENTRY_STEPS.UPLOAD_FILE);
                return;
            }
            setFormState((prevState) => ({
                ...prevState,
                selectedFile: file
            }));
            setCurrentStep(ADD_GUIDE_ENTRY_STEPS.SELECT_DOCKET_SETTING);
        }
    };

    const submitFiles = () => {
        setCurrentStep(ADD_GUIDE_ENTRY_STEPS.SUBMIT_ENTRY_LOADING);
        const data = {
            pdf: formState.selectedFile,
            option: formState.selectedOption,
            docket: docket,
            category: formState.currentCategory
        };
        postUserGuideEntryAPI(jwt, data)
            .then((r) => {
                if (r.success) {
                    //console.log("upload successful")
                } else {
                    window.alert("File failed to upload");
                }
                clearAndCloseModal();
            })
            .catch(() => {
                window.alert("An Unexpected Error Occurred");
                clearAndCloseModal();
            });
    };

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{ display: open ? 'flex' : 'none' }} aria-modal={true}
            aria-hidden={!open}
            className={styles.wrapper} aria-describedby={'modalDescription'} onClick={clearAndCloseModal}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Add New User Guide&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className={styles.h1}>Add New User Guide</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                        onClick={clearAndCloseModal}>X
                    </button>
                </div>
                {
                    (currentStep === ADD_GUIDE_ENTRY_STEPS.SUBMIT_ENTRY_LOADING) ? <React.Fragment><Loading /></React.Fragment> :
                        <div className={styles.contentWrapper}>
                            <div className={styles.uploadText}>Upload File:
                                <span className={styles.fileName}>
                                    {formState.selectedFile ? formState.selectedFile.name : "no file selected"}
                                </span>
                            </div>
                            <label htmlFor="addGuideEntryFileInput" className={styles.modalButton}>
                                Add/Change PDF
                            </label>
                            <input
                                type="file"
                                id="addGuideEntryFileInput"
                                className={styles.hiddenFileInput}
                                accept=".pdf"
                                onClick={(event) => event.target.value = null}
                                onChange={handleFileChange}
                            />
                            <br />
                            <div style={{ color: currentStep < ADD_GUIDE_ENTRY_STEPS.SELECT_DOCKET_SETTING ? '#565656' : 'black' }} className={styles.docketSelectionText}>Docket:</div>
                            <div className={styles.docketSelectionContainer}>
                                <label className={styles.radioLabel}>
                                    <input type="radio" value="Current" className={styles.radioInput}
                                        checked={formState.selectedOption === 'Current'} onChange={handleSelection} disabled={currentStep < ADD_GUIDE_ENTRY_STEPS.SELECT_DOCKET_SETTING} />
                                    Current Docket
                                </label>
                                <label className={styles.radioLabel} >
                                    <input type="radio" value="All" className={styles.radioInput} disabled={currentStep < ADD_GUIDE_ENTRY_STEPS.SELECT_DOCKET_SETTING}
                                        checked={formState.selectedOption === 'All'} onChange={handleSelection} />
                                    All Dockets
                                </label>
                            </div>
                            <br />
                            <div style={{ color: currentStep < ADD_GUIDE_ENTRY_STEPS.SUBMIT_FILE ? '#565656' : 'black' }} className={styles.uploadText}>Category</div>
                            <select disabled={currentStep < ADD_GUIDE_ENTRY_STEPS.SUBMIT_FILE} className={styles.selectField} value={formState.currentCategory}
                                onChange={e => {
                                    setFormState((prevState) => ({
                                        ...prevState,
                                        currentCategory: e.target.value
                                    }))
                                }}>
                                {
                                    guideCategories.map((guideCategory, index) => {
                                        return (
                                            <option key={index} value={index}>
                                                {guideCategory}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <button className={styles.submitButton} disabled={currentStep !== ADD_GUIDE_ENTRY_STEPS.SUBMIT_FILE} onClick={submitFiles}>Add Entry</button>
                        </div>
                }
            </div>
        </div>,
        document.body
    );
};

export default UserGuideEntryModal;
