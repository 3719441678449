import React from 'react';
import styles from "./rejectIcon.module.css"

const RejectIcon = ({
                        size = 24, disabled = false, invert = false, onClick = () => {
    }
                    }) => {

    let iconStr = "/assets/imgs/reject.svg"

    const whenClicked = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <img onClick={whenClicked}
             className={`${disabled ? styles.disabled : styles.icon}  ${invert ? styles.invert : ""}`}
             onContextMenu={() => {
                 return false;
             }} style={{height: size, width: size}} src={iconStr} alt={"Zoom Icon"}/>
    );
};


export default RejectIcon;